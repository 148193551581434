import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";
import Navbar from "../Nav";

function Form_Gesp() {
  const baseURL = `${process.env.REACT_APP_API_URL}/gesp/create`;
  const [intitule, setIntitule] = useState("");
  const [typeEsp, setTypeEsp] = useState("");



  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Effectuer la soumission du formulaire si la validation réussit.
      axios
        .post(baseURL, {
          libelleGesp: intitule,
          typeESP: typeEsp

        })
        .then((response) => {
          alert(
            " le Groupe d'ESP " +
            intitule +
            " a été enregistré  avec succès"

          );
          navigate("/liste_gesp");
        })
        .catch((error) => {
          alert("Erreur : " + error);
        });
    } else {
      alert("Veuillez remplir tous les champs obligatoires.");
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (intitule.trim() === "") {
      setIntituleIsValid(false);
      isValid = false;
    } else {
      setIntituleIsValid(true);
    }



    return isValid;
  };

  const [intituleIsValid, setIntituleIsValid] = useState(true);


  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            {/* <Navbar /> */}
            {/*  <!-- Content Row --> */}
            <br></br>
            <br></br>
            <div className="row">
              <div className="col-xxl-4 col-md-12">
                <div className="card info-card sales-card">

                  <div className="row align-items-stretch no-gutters contact-wrap">
                    <div className="col-md-12">
                      <div className="form h-100">

                        <h2 className="title-text">Ajouter un Groupe d'ESP</h2>
                        <Form
                          onSubmit={handleSubmit}
                          className="mb-5"
                          noValidate
                        >

                          <div className="row">

                            <div className="col-md-12 form-group mb-5">
                              <label
                                className="col-form-label"
                                style={{ color: "black" }}
                              >
                                Type d'ESP{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                name="typeEsp"
                                value={typeEsp}
                                onChange={(e) => setTypeEsp(e.target.value)}
                                className="form-control"
                                id="service"
                                required
                              >
                                <option value="">
                                  Sélectionnez un Type{" "}
                                </option>
                                <option value="CONVENTIONNE">
                                  Conventionné{" "}
                                </option>
                                <option value="NonCONVENTIONNE">
                                  Non Conventionné{" "}
                                </option>


                              </select>
                            </div>
                            <div className="col-md-12 form-group mb-6">
                              <label
                                className="col-form-label"
                                style={{ color: "black" }}
                              >
                                Nom du Groupe d'ESP *
                              </label>
                              <Form.Control
                                type="text"
                                value={intitule}
                                onChange={(e) => setIntitule(e.target.value)}
                                className={`form-control ${intituleIsValid ? "" : "is-invalid"
                                  }`}
                                id="yo"
                                required
                              />
                            </div>

                          </div>




                          <div className="row">
                            <div className="col-md-12 form-group">
                              <Button
                                className="btn btn-primary w-100"
                                type="submit"
                              >
                                <i className="fas fa-save"></i> Enregistrer
                              </Button>
                              <span className="submitting"></span>
                            </div>
                          </div>
                        </Form>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_Gesp;
