import React, { useState } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

function CustomTutorial({ onComplete }) {
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(true);

  const steps = {
    1: {
      title: "Bienvenue sur notre plateforme !",
      content: "Nous allons vous guider à travers les principales fonctionnalités de la plateforme de demandes des licences d'affaires.",
      buttonText: "Commencer"
    },
    2: {
      title: "Inscription",
      content: "Pour commencer, vous devez créer un compte en cliquant sur le bouton 'S'inscrire' en haut à droite de l'écran.",
      target: ".appointment-btn[href='/user_inscription']",
      buttonText: "Suivant"
    },
    3: {
      title: "Connexion",
      content: "Si vous avez déjà un compte, vous pouvez vous connecter en cliquant sur le bouton 'Se connecter' en haut à droite de l'écran.",
      target: ".appointment-btn[href='/login']",
      buttonText: "Suivant"
    },
    4: {
      title: "Ouverture et Exploitation",
      content: "Accédez aux informations pour une demande d'ouverture et d'exploitation d'un ESPS en cliquant sur cette carte.",
      target: "a[href='/ouverture']",
      buttonText: "Suivant"
    },
    5: {
      title: "Convention",
      content: "Vous pouvez également aux informations pour une demande de convention en cliquant sur cette carte.",
      target: "a[href='/convention']",
      buttonText: "Terminer"
    }
  };

  const handleNext = () => {
    if (step < Object.keys(steps).length) {
      setStep(step + 1);
    } else {
      handleComplete();
    }
  };

  const handleComplete = () => {
    setShowModal(false);
    if (onComplete) {
      onComplete();
    }
  };

  const modalStyle = {
    minWidth: '60vw',  // 60% de la largeur de l'écran
    maxWidth: '800px', // Largeur maximale
    margin: '0 auto',  // Centrage horizontal
    top: '50%',       // Position verticale
    transform: 'translateY(-50%)', // Centrage vertical
  };

  const tooltipStyle = {
    maxWidth: '400px',
    padding: '15px',
    fontSize: '16px',
    lineHeight: '1.5',
    backgroundColor: 'white',
    color: '#333',
    border: '2px solid #0056b3',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  };

  return (
    <>
      <Modal
        show={showModal && step === 1}
        onHide={handleComplete}
        centered
        size="lg"
        dialogClassName="tutorial-modal"
      >
        <Modal.Header style={{ borderBottom: '2px solid #0056b3', padding: '20px' }}>
          <Modal.Title style={{ fontSize: '24px', color: '#0056b3' }}>
            {steps[1].title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '30px', fontSize: '18px', lineHeight: '1.6' }}>
          {steps[1].content}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: '1px solid #dee2e6', padding: '20px' }}>
          <Button 
            variant="outline-secondary" 
            onClick={handleComplete}
            style={{ padding: '10px 20px' }}
          >
            Passer
          </Button>
          <Button 
            variant="primary" 
            onClick={handleNext}
            style={{ padding: '10px 20px', backgroundColor: '#0056b3' }}
          >
            {steps[1].buttonText}
          </Button>
        </Modal.Footer>
      </Modal>

      {step > 1 && step <= Object.keys(steps).length && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1050 }}>
          <div 
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              maxWidth: '500px',
              width: '90%',
              zIndex: 1051
            }}
          >
            <h4 style={{ color: '#0056b3', marginBottom: '15px' }}>{steps[step].title}</h4>
            <p style={{ fontSize: '16px', marginBottom: '20px' }}>{steps[step].content}</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
              <Button 
                variant="outline-secondary" 
                onClick={handleComplete}
                style={{ padding: '8px 16px' }}
              >
                Passer
              </Button>
              <Button 
                variant="primary" 
                onClick={handleNext}
                style={{ padding: '8px 16px', backgroundColor: '#0056b3' }}
              >
                {steps[step].buttonText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomTutorial; 