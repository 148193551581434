/* eslint-disable react-hooks/exhaustive-deps */
import react, { useState, useEffect } from "react";
import armoiries from "./img/armoiries.jpg";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { Alert, Spinner } from "react-bootstrap";
import Head from "../Head";
import Foot from "../Foot"
import { accountService } from "./AccountService";
import { toast } from 'react-toastify';
import { AiOutlineWarning } from 'react-icons/ai'; // Exemple d'icône
import ForgotPasswordModal from './ForgotPasswordModal';

const LoginPage = ({ setIsLoggedIn, loading, error, ...props }) => {
  const [values, setValues] = useState({
    userName: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [loginAttempts, setLoginAttempts] = useState(0);

  const handleChange = (e) => {
    e.persist();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };


  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const baseURL = `${process.env.REACT_APP_API_URL}/users/login`;
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsLoading(true); // Activer l'indicateur de chargement

    try {
      // Vérifiez si le nombre de tentatives est inférieur à la limite
      if (loginAttempts < 3) {
        const response = await axios.post(baseURL, {
          username: values.userName,
          password: values.password,
        });

        // Réinitialisez le compteur de tentatives après une connexion réussie
        setLoginAttempts(0);

        // Vérifiez si la requête a réussi (statut 2xx)
        if (response.status >= 200 && response.status < 300) {
          // Récupérez le token de la réponse
          const tokenValue = response.data.token;

          // Vérifiez si le token est défini et non "undefined"
          if (tokenValue && tokenValue !== "undefined") {
            // Stockez le token dans le sessionStorage
            accountService.saveToken(tokenValue);

            // Définissez l'état de connexion comme true
            setIsLoggedIn(true);
            setIsLoading(false);

            // Redirigez vers la page d'administration
            navigate("/admin");
            accountService.isLogged(true);

            // Notification de succès
            toast.success("Connexion réussie ! BIENVENUE");
          } else {
            // Si le token est indéfini ou invalide, avertir l'utilisateur
            toast.warn(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AiOutlineWarning style={{ marginRight: '8px', fontSize: '1.5rem' }} />
                <span>Erreur : Token invalide. Veuillez réessayer ou contacter l'administrateur pour activer votre compte.</span>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                className: "custom-toast",
                style: { borderRadius: '8px' },
              }
            );
            setIsLoading(false);
          }
        } else {
          // Si la requête échoue, affichez l'erreur
          setIsLoading(false);
          console.error("Erreur de connexion :", response.status);
        }
      }
    } catch (error) {
      // Notification d'erreur en cas de mauvais identifiants
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineWarning style={{ marginRight: '8px', fontSize: '1.5rem' }} />
          <span>Veuillez vérifier votre nom d'utilisateur et votre mot de passe.</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          className: "custom-toast",
          style: { borderRadius: '8px' },
        }
      );
      setIsLoading(false);
      console.error("Erreur :", error);

      // Incrémenter les tentatives en cas d'échec
      setLoginAttempts(loginAttempts + 1);
    }
  };



  return (
    <div className="login-page"  >
      <Head />
      <section id="hero" className="h-100 custom-section">
        <div className="container h-100">
          <div className="row justify-content-md-center h-100">
            <div className="card-wrapper">
              <div className="card fat custom-carde">
                <div className="conect">
                  <img
                    src={armoiries}
                    alt=""
                    className="img-fluid"
                    width={70}
                    height={70}
                  />
                </div>
                <h5 className="card-title" style={{ color: "black" }}>
                  <b>Ministère de la Santé</b>
                </h5>
                <div className="card-body">

                  <form className="my-login-validation p-4 border rounded shadow" onSubmit={handleSubmit} noValidate>
                    <h2 className="text-center mb-4" style={{ color: '#0056b3' }}><b>Connexion</b></h2>

                    <div className="form-group">
                      <label htmlFor="username" className="black-label">
                        <b><i className="fas fa-user fa-fw"></i> Email </b>
                      </label>
                      <input
                        id="username"
                        type="text"
                        className="form-control"
                        minLength={3}
                        value={values.userName}
                        onChange={handleChange}
                        name="userName"
                        required
                      />
                      {/* <div className="invalid-feedback">UserId is invalid</div> */}
                    </div>

                    <div className="form-group">
                      <label htmlFor="password" className="black-label">
                        <b><i className="fas fa-lock fa-fw"></i> Mot de passe</b>
                      </label>
                      <div className="input-group">
                        <input
                          id="password"
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          minLength={3}
                          value={values.password}
                          onChange={handleChange}
                          name="password"
                          required
                        />
                        <span className="input-group-addon" onClick={handleTogglePassword} style={{ cursor: 'pointer' }}>
                          {showPassword ? "🙈" : "👁️"}
                        </span>
                      </div>
                      {/* <div className="invalid-feedback">Password is required</div> */}
                    </div>

                    <div className="form-group text-center mt-4 d-flex flex-column align-items-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mx-auto"
                        style={{ minWidth: '215px' }}
                        disabled={loading || isLoading}
                      >
                        <i className="fab fa-telegram-plane fa-fw"></i>
                        {isLoading ? <Spinner animation="border" size="sm" /> : "Se connecter"}
                      </button>
                      <br />
                      <a href="#" className="text-primary" onClick={() => setShowModal(true)}>Mot de passe oublié ?</a>
                    </div>
                  </form>



                  {error && (
                    <Alert style={{ marginTop: "20px" }} variant="danger">
                      {error}
                    </Alert>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div><br /><br /><br /><br />
      </section>
      <br /><br /><br /><br /><br />
      <Foot />
      <ForgotPasswordModal show={showModal} handleClose={() => setShowModal(false)} />

    </div>

  );
};

export default LoginPage;
