import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";
import Boutons from "../Bouton";
import Header from "../../Header";
import Footer from "../../Footer";

function ListeDemandeRetire() {
  const [utilisateurs, setTypeDemande] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6); // Nombre d'éléments par page

  const formatDate = (dateStr) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateStr).toLocaleDateString(undefined, options);
  };

  const [selectedButton, setSelectedButton] = useState(null);

  const affURL = `${process.env.REACT_APP_API_URL}/api/demandes/retirer`;

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    console.log("Selected Button:", buttonName);
    axios
      .get(`${affURL}/{codeParamTypeDemande}?code=${buttonName}`)
      .then((response) => {
        setTypeDemande(response.data);
      })
      .catch((error) => {
        alert(" type de demande introuvable : " + error);
      });
  };

  useEffect(() => {
    handleButtonClick();
  }, [setSelectedButton]);

  // Calculez l'index de début et de fin pour l'affichage des éléments actuels sur la page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUtilisateurs = utilisateurs.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Fonction pour changer de page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calcul du nombre total de pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(utilisateurs.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const buttons = [
    { id: 1, code: "01", label: "ESPS" },
    // { id: 2, code: "02", label: "Demande Terrain" },
    // { id: 3, code: "06", label: "Extrait de plan" },
    // { id: 4, code: "05", label: "Identification" },
    // { id: 5, code: "04", label: "Changement" },
    // { id: 6, code: "03", label: "Autorisation" },
    // Ajoutez d'autres boutons au besoin
  ];
  const [roles, setRoles] = useState([]);
  const userRoles = roles.map((role) => role.intitule);
  // Définissez les rôles autorisés pour chaque section du menu
  const allowedRolesDemandeBornage = [
    "administrateur",
    "bornage_reception",
    "bornage_travail_terrain",
    "bornage_dessin_plan",
    "bornage_verification",
    "bornage_numerotation",
    "bornage_transmission_cadastre",
    "bornage_gestion",
  ];
  const allowedRolesDemandeTerrain = [
    "Administrateur",
    "terrain_reception",
    "dsps _affectation_service_urbanisme",
    "dsps _imputation_visite_terrain",
    "terrain_elaboration_rapport_technique",
    "terrain_traitement_dr",
    "terrain_transmission_cadastre",
    "terrain_envoi_receveur",
    "terrain_gestion",
  ];
  const allowedRolesDemandeExtrait = ["Administrateur", "extrait_plan_gestion"];
  const allowedRolesDemandeAutorisation = [
    "Administrateur",
    "autorisation_lotir_gestion",
  ];
  const allowedRolesDemandeIdentification = [
    "Administrateur",
    "identification_gestion",
  ];

  const allowedRolesDemandeChangement = [
    "Administrateur",
    "changement_destination_gestion",
  ];

  const filteredButtons = buttons.filter((button) => {
    if (
      (button.label === "ESPS" &&
        allowedRolesDemandeBornage.some((role) => userRoles.includes(role))) ||
      (button.label === "Demande Terrain" &&
        allowedRolesDemandeTerrain.some((role) => userRoles.includes(role))) ||
      (button.label === "Extrait de plan" &&
        allowedRolesDemandeExtrait.some((role) => userRoles.includes(role))) ||
      (button.label === "Identification" &&
        allowedRolesDemandeIdentification.some((role) =>
          userRoles.includes(role)
        )) ||
      (button.label === "Changement" &&
        allowedRolesDemandeChangement.some((role) =>
          userRoles.includes(role)
        )) ||
      (button.label === "Autorisation" &&
        allowedRolesDemandeAutorisation.some((role) =>
          userRoles.includes(role)
        ))
    ) {
      return true;
    }
    return false;
  });
  // Ajoutez d'autres sections du menu avec les rôles autorisés nécessaires...

  const token = sessionStorage.getItem("token"); // Récupérer le token du stockage local

  // Vérifier si l'utilisateur a un rôle autorisé

  // Fonction pour envoyer le token au serveur
  const serverUrl = `${process.env.REACT_APP_API_URL}/authentification/utilisateur-connecte`;
  axios.defaults.debug = true;
  const sendTokenToServer = async () => {
    try {
      // Vérifier si le token existe
      if (!token) {
        console.error("Token not found in sessionStorage");
        return;
      }

      // Envoyer la requête au serveur avec le token inclus dans les en-têtes
      const response = await axios.get(serverUrl, {
        headers: {
          Authorization: `${token}`, // Inclure le token dans l'en-tête Authorization
        },
      });

      // Vérifier la réponse du serveur
      if (response.status === 200) {
        // Convertir la réponse en JSON
        const userData = response.data;

        // Assurez-vous que userData.appRoles existe et est un tableau
        const userRoles = userData.appRoles || [];

        // Mettre à jour l'état ou effectuer d'autres actions avec les rôles de l'utilisateur
        setRoles(userRoles);

        // Afficher la liste des rôles dans la console
        console.log("User Roles:", userRoles);

        console.log("Token sent successfully");
      } else {
        console.error("Error sending token to server:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending token to server:", error.message);
    }
  };

  // Appeler la fonction lorsque le composant est monté
  useEffect(() => {
    sendTokenToServer();
  }, []);

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="container-fluid">
            {/*  <!-- Content Row --> */}
            <div className="row">
              <div className="col-xxl-4 col-md-12">
                <div className="card info-card sales-card">
                  <div className="container">
                    <br></br>
                    <div>
                      <Boutons
                        buttons={filteredButtons}
                        selectedButton={selectedButton}
                        handleButtonClick={handleButtonClick}
                      />
                    </div>
                    <br></br>
                    <h4 className="title-text">
                      Listes des Demandes Terminées - Retrirées
                    </h4>
                    {/* <Link to="/demande_type">
                      <button className="custom-button">
                        {" "}
                        <i className="fas fa-fw fa-plus"> </i> Ajouter
                      </button>
                    </Link> */}
                    <div className="card-body ">
                      <table className="table table-bordered table-striped  ">
                        <thead>
                          <tr>
                            <th>Date - retrait</th>
                            <th>N° Demande</th>
                            <th>Ref document</th>
                            <th>Retireur</th>
                            <th>NIP</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUtilisateurs.length === 0 ? (
                            <tr>
                              <td colSpan="9">
                                Aucune nouvelle demande n'a été retiré.
                              </td>
                            </tr>
                          ) : (
                            currentUtilisateurs.map((utili, index) => (
                              <tr key={index}>
                                {
                                  <>
                                    <td className="text-noir">
                                      {utili.retrait.dateSortie
                                        ? formatDate(utili.retrait.dateSortie)
                                        : "N/A"}
                                    </td>

                                    <td className="text-noir">
                                      {utili.numero}
                                    </td>
                                    <td className="text-noir">
                                      {utili.retrait.referenceDocument}
                                    </td>

                                    <td className="text-noir">
                                      {utili.retrait.nomRetirant}{" "}
                                      {utili.retrait.prenomRetirant}
                                    </td>

                                    <td className="text-noir">
                                      {utili.retrait.cnibRetirant}
                                    </td>

                                    <td className="text-noir">
                                      <Link
                                        to={
                                          "/demandes/12efld/çsd/6-ld133-/" +
                                          utili.id +
                                          "-236dad/2345FTksmckd"
                                        }
                                        title="voir informations"
                                      >
                                        <div className="round-button d-flex justify-content-center align-items-center">
                                          <i className="bi bi-eye white-icon"></i>
                                        </div>
                                      </Link>
                                    </td>
                                  </>
                                }
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                      {/* Pagination */}
                      {/* Pagination */}
                      <div className="pagination">
                        {pageNumbers.map((pageNumber) => (
                          <button
                            key={pageNumber}
                            onClick={() => paginate(pageNumber)}
                            className="page-number"
                          >
                            {pageNumber}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </body>
    </div>
  );
}

export default ListeDemandeRetire;
