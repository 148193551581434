import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";

function Edit_Nivo_Valid() {
  const [searchTerm, setSearchTerm] = useState("");

  const editURL = `${process.env.REACT_APP_API_URL}/api/param-niveau-validations/`;
  const [intitule, setIntitule] = useState("");
  const [role, setRole] = useState("");
  const [num_ordre, setNum_ordre] = useState("");
  const [param_type_demande_id, setParamTypeDemandeId] = useState("");
  const [paramTypePieceEtapeValidations, setparamTypePiece] = useState([]);
  const navigate = useNavigate();

  const param = useParams();
  useEffect(() => {
    axios
      .get(editURL + param.id)
      .then((response) => {
        const empData = response.data;
        setIntitule(empData.intitule);
        setRole(empData.role);
        setNum_ordre(empData.numOrdre);
        setParamTypeDemandeId(empData.param_type_demande_id);
        setparamTypePiece(empData.paramTypePieceEtapeValidations);
      })
      .catch((error) => {
        alert("erreur pour trouver les details :" + error);
      });
  }, []);
  const editepieceURL = `${process.env.REACT_APP_API_URL}/api/param-type-pieces-niveau-validation`;
  const [utilisateurs, setTypeDemande] = useState([]);
  const setUtilisateursData = () => {
    axios
      .get(editepieceURL)
      .then((response) => {
        setTypeDemande(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention des données :" + error);
      });
  };
  useEffect(() => {
    setUtilisateursData();
  }, []);
  const handlePieceChange = (e, pieceId) => {
    const checked = e.target.checked;
    if (checked) {
      // Ajouter l'ID de la pièce sélectionnée au tableau piece
      setPiece([...piece, pieceId]);
    } else {
      // Retirer l'ID de la pièce décochée du tableau piece
      setPiece(piece.filter((id) => id !== pieceId));
    }
  };
  const [piece, setPiece] = useState([]);
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(editURL + param.id, {
        intitule: intitule,
        role: role,
        numOrdre: num_ordre,
        paramTypePieceEtapeValidations: piece.map((pieceId) => ({
          id: pieceId,
        })),
        param_type_demande_id: param_type_demande_id,
      })
      .then((response) => {
        alert(" " + intitule + " modifiées avec succès !!");
        navigate("/liste_niveau_validation");
      })

      .catch((error) => {
        alert("error===" + error);
      });
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="container-fluid">
            {/*  <!-- Content Row --> */}
            <div className="row">
              <div className="col-xxl-4 col-md-12">
                <div className="card info-card sales-card">
                  <div className="container">
                    <div className="row align-items-stretch no-gutters contact-wrap">
                      <div className="col-md-10">
                        <div className="form h-100">
                          <h2 className="title-text">
                            Modifier Niveau de Validation
                          </h2>
                          <Form
                            onSubmit={handleSubmit}
                            className="mb-5"
                            noValidate
                          >
                            <div className="row">
                              <div className="col-md-6 form-group mb-6">
                                <label className="col-form-label">
                                  Intitulé *
                                </label>
                                <Form.Control
                                  type="text"
                                  value={intitule}
                                  onChange={(e) => setIntitule(e.target.value)}
                                  className="form-control"
                                  id="yourEmail"
                                  required
                                />
                              </div>
                              <div className="col-md-6 form-group mb-5">
                                <label className="col-form-label">
                                  Numero d'ordre *
                                </label>
                                <Form.Control
                                  type="text"
                                  value={num_ordre}
                                  onChange={(e) => setNum_ordre(e.target.value)}
                                  className="form-control"
                                  id="yourEmail"
                                  required
                                />
                              </div>

                              <div className="col-md-12 form-group mb-5">
                                <label className="col-form-label">
                                  Description du niveau de validation *
                                </label>
                                <Form.Control
                                  type="text"
                                  value={role}
                                  onChange={(e) => setRole(e.target.value)}
                                  className="form-control"
                                  id="yourEmail"
                                  required
                                />
                              </div>
                              <div className="col-md-12 form-group mb-5">
                                <label className="col-form-label">
                                  Pièces selectionées *
                                </label>
                                {paramTypePieceEtapeValidations.map(
                                  (utili, index) => (
                                    <ul>
                                      <li key={index}> {utili.intitule}</li>
                                    </ul>
                                  )
                                )}
                              </div>

                              <div className="col-md-6 form-group mb-6">
                                <label
                                  className="col-form-label"
                                  style={{ color: "black" }}
                                >
                                  Recherche de pièce
                                </label>
                                <input
                                  type="text"
                                  value={searchTerm}
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                  className="form-control"
                                  placeholder="Entrez le nom de la pièce..."
                                />
                              </div>

                              <div className="col-md-6 form-group mb-6">
                                <label
                                  className="col-form-label"
                                  style={{ color: "black" }}
                                >
                                  reselectionner les nouvelles Pièces à fournir
                                  *
                                </label>
                                {utilisateurs
                                  .filter((utili) =>
                                    utili.intitule
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                                  )
                                  .map((utili, index) => (
                                    <div key={utili.id} className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={utili.id}
                                        onChange={(e) =>
                                          handlePieceChange(e, utili.id)
                                        }
                                        checked={piece.includes(utili.id)}
                                        id={`piece-${utili.id}`}
                                        aria-required
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`piece-${utili.id}`}
                                      >
                                        {utili.intitule}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 form-group">
                                <Button
                                  className="btn btn-primary w-100"
                                  type="submit"
                                >
                                  Enregistrer
                                </Button>
                                <span className="submitting"></span>
                              </div>
                            </div>
                          </Form>

                          <div id="form-message-warning mt-4"></div>
                          <div id="form-message-success">
                            Veuillez Remplir le Formulaire
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="contact-info h-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Edit_Nivo_Valid;
