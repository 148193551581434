/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, useGlobalFilter, usePagination } from 'react-table';

function PersonnelTable() {
  const [personnels, setPersonnels] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const columns = useMemo(
    () => [
      { Header: 'Nom', accessor: 'nom' },
      { Header: 'Prénom', accessor: 'prenom' },
    //   { Header: 'NIP', accessor: 'nip' },
    //   { Header: 'Genre', accessor: 'genre' },
    //   { Header: 'Date de Naissance', accessor: 'dateNaissance' },
    //   { Header: 'Lieu de Naissance', accessor: 'lieuNaissance' },
    //   { Header: 'Email', accessor: 'email' },
    //   { Header: 'Téléphone', accessor: 'tel' },
      { Header: 'Numéro OP', accessor: 'numOp' },
      { Header: 'Profession', accessor: 'profession' },
    ],
    []
  );
  /* useEffect(() => {
    const fetchPersonnels = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/personnels/list/engage`);
        setPersonnels(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des personnels:', error);
      }
    };

    fetchPersonnels();
  }, []); */
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    page, // Données de la page actuelle
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data: Array.isArray(personnels) ? personnels : [], initialState: { pageSize: 10 } },
    useGlobalFilter,
    usePagination
  );
  const fetchPersonnels = async (pageIndex) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/personnels/list/engage?page=${pageIndex}`);
      setPersonnels(Array.isArray(response.data) ? response.data : []);

      //setPersonnels(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des personnels:', error);
    }
  };
  useEffect(() => {
    fetchPersonnels(pageIndex);
  }, [pageIndex]);
  
  const styles = {
    container: {
      padding: '24px',
      backgroundColor: '#f0f2f5',
      fontFamily: 'Arial, sans-serif',
      color: '#000000', // Changed to black
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '24px',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#000000', // Changed to black
    },
    select: {
      padding: '8px 12px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #d1d5db',
      color: '#000000', // Changed to black
    },
    cardContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '24px',
      marginBottom: '24px',
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
      padding: '20px',
    },
    /*cardTitle: {
      fontSize: '16px',
      color: '#000000', // Changed to black
      marginBottom: '8px',
    },*/
  
    cardTitle: {
      fontSize: '16px',  // Augmente la taille du texte pour plus de visibilité
      fontWeight: 'bold',  // Applique un texte en gras
      color: '#007BFF',  // Une couleur de texte vive (bleu dans cet exemple)
      marginBottom: '16px',  // Espacement sous le titre
      textAlign: 'center',  // Centrer le texte
      padding: '8px',  // Ajoute du padding autour du titre
      borderRadius: '5px',  // Arrondir les coins pour un effet plus moderne
      backgroundColor: '#f0f8ff',  // Fond léger pour faire ressortir le titre
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',  // Ombre légère autour du titre
    },
    cardContent: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#000000', // Changed to black
    },
    chartContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
      gap: '24px',
      marginBottom: '24px',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      marginBottom: '16px',
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
  
    },
    filterField: {
      display: 'flex',
      flexDirection: 'column',
      //alignItems: 'center',
      gap: '10px',
      width: '300px',
      height: '90px',
    },
  
    input: {
      padding: '8px 12px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #d1d5db',
      color: '#000000', // Changed to black
    },
    button: {
      padding: '8px 16px',
      fontSize: '16px',
      backgroundColor: '#001142e3',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    icon: {
      marginRight: '8px',
      fontSize: '18px',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
    },
    th: {
      backgroundColor: '#001142e3', // Changed to blue
      padding: '12px',
      textAlign: 'left',
      fontSize: '14px',
      fontWeight: 'bold',
      color: 'white', // Changed to white for better contrast
    },
    td: {
      padding: '12px',
      borderBottom: '1px solid #e5e7eb',
      color: '#000000', // Changed to black
    },
    statusBadge: {
      padding: '4px 8px',
      borderRadius: '9999px',
      fontSize: '12px',
      fontWeight: 'bold',
      color: 'white',
    },
    cardIcon: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '16px',
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      marginBottom: '16px',
    },
    iconContainer: {
      padding: '12px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  };

  /* const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data: personnels }, useGlobalFilter);
 */
  
  const handleSearch = (e) => {
    const value = e.target.value || '';
    setGlobalFilter(value);
    setSearchInput(value);
  };

  return (
    <div>
      <label>Rechercher un personnel</label>
      <input
        type="text"
        value={searchInput}
        onChange={handleSearch}
        placeholder="Rechercher..."
        style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
      />
      <div style={{ overflowX: 'auto' }}></div>
       
      <table {...getTableProps()}  style={styles.table}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={styles.th}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={styles.td}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        {/* <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody> */}
      </table> 

        
      <div>
        <button onClick={previousPage} disabled={!canPreviousPage}>
          Page Précédente
        </button>
        <button onClick={nextPage} disabled={!canNextPage}>
          Page Suivante
        </button>
        <span>
          Page {pageIndex + 1}
        </span>
      </div>
    </div>
  );
}
export default PersonnelTable;