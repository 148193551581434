import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import { AiOutlineWarning } from 'react-icons/ai'; // Exemple d'icône

import Head from "./Head";
import Foot from "./Foot";

function Form_user() {

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();

  const baseURL = `${process.env.REACT_APP_API_URL}/users/register`;
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!validateForm()) {
      showToast("Veuillez remplir tous les champs obligatoires.", "error", <AiOutlineWarning />);
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(baseURL, {
        nom, prenom, email, tel, password,
      });

      toast.success(`votre compte a été crée avec succès veillez le code recu sur votre email pour l'activer `);
      navigate("/activation_compte");

    } catch (error) {
      // Vérifie spécifiquement l'erreur UserAlreadyExistException
      if (error.response?.data === `L'utilisateur avec l'email ${email} existe déjà !`) {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AiOutlineWarning style={{ marginRight: '8px', fontSize: '1.5rem' }} />
            <span>Cette adresse email est déjà utilisée. Veuillez en choisir une autre.</span>
          </div>
        );
        setEmailIsValid(false);
        
        // Focus sur le champ email
        document.querySelector('input[type="email"]')?.focus();
      } else {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AiOutlineWarning style={{ marginRight: '8px', fontSize: '1.5rem' }} />
            <span>Une erreur s'est produite lors de l'inscription. Veuillez réessayer.</span>
          </div>
        );
      }
      
      console.error("Erreur détaillée:", {
        message: error.response?.data,
        status: error.response?.status
      });
      console.log("Réponse d'erreur complète:", {
        data: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction utilitaire pour afficher les notifications
  const showToast = (message, type, icon = null) => {
    toast[type](
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon && <span style={{ marginRight: '8px', fontSize: '1.5rem' }}>{icon}</span>}
        <span>{message}</span>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        className: "custom-toast",
        style: { borderRadius: '8px' },
      }
    );
  };


  const validateForm = () => {
    let valid = true;

    // Nom
    if (!nom.trim()) {
      setNomIsValid(false);
      valid = false;
    } else {
      setNomIsValid(true);
    }

    // Prénom
    if (!prenom.trim()) {
      setPrenomIsValid(false);
      valid = false;
    } else {
      setPrenomIsValid(true);
    }

    // Téléphone
    // if (!tel.trim() || !/^\d{8,}$/.test(tel)) {
    //   setTelIsValid(false);
    //   valid = false;
    // } else {
    //   setTelIsValid(true);
    // }


    // Email
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailIsValid(false);
      valid = false;
    } else {
      setEmailIsValid(true);
    }

    // Mot de passe
    if (password.length < 6) {
      setPasswordIsValid(false);
      valid = false;
    } else {
      setPasswordIsValid(true);
    }

    // Confirmation du mot de passe
    if (password !== confirmPassword) {
      setConfirmPasswordIsValid(false);
      valid = false;
    } else {
      setConfirmPasswordIsValid(true);
    }

    return valid;
  };

  const [nomIsValid, setNomIsValid] = useState(true);
  const [prenomIsValid, setPrenomIsValid] = useState(true);
  const [loginIsValid, setLoginIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  // const [telIsValid, setTelIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(true);

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          <div className="container-fluid">
            <Head />
            <section id="hero" className="h-100 custom-section">
              <div className="content">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <br />
                    <div>
                      <div className="card fat shadow-sm">
                        <div className="card-body p-5">
                          <h2 className="title-text text-center mb-4" style={{ color: '#0056b3' }}>Formulaire d'Inscription</h2>
                          <Form onSubmit={handleSubmit} className="mb-5" noValidate>
                            <div className="row">
                              <div className="col-md-6 form-group mb-4">
                                <label className="col-form-label"><i className="fas fa-user fa-fw"></i> Nom *</label>
                                <Form.Control
                                  type="text"
                                  placeholder="Nom"
                                  value={nom}
                                  onChange={(e) => setNom(e.target.value)}
                                  className={`form-control ${nomIsValid ? "" : "is-invalid"}`}
                                  required
                                />
                                {!nomIsValid && <div className="invalid-feedback">Le nom est requis.</div>}
                              </div>

                              <div className="col-md-6 form-group mb-4">
                                <label className="col-form-label"><i className="fas fa-user fa-fw"></i> Prénom(s)</label>
                                <Form.Control
                                  type="text"
                                  placeholder="Prénom(s)"
                                  value={prenom}
                                  onChange={(e) => setPrenom(e.target.value)}
                                  className={`form-control ${prenomIsValid ? "" : "is-invalid"}`}
                                  required
                                />
                                {!prenomIsValid && <div className="invalid-feedback">Le prénom est requis.</div>}
                              </div>

                              <div className="col-md-6 form-group mb-4">
                                <label className="col-form-label"><i className="fas fa-phone fa-fw"></i> Numéro de téléphone</label>
                                <Form.Control
                                  type="text"
                                  placeholder="Numéro de téléphone"
                                  value={tel}
                                  maxLength={12}
                                  onChange={(e) => setTel(e.target.value)}
                                  // className={`form-control ${telIsValid ? "" : "is-invalid"}`}
                                  className="form-control"
                                  required
                                />
                                {/* {!telIsValid && <div className="invalid-feedback">Numéro de téléphone invalide (Au moins 08 chiffres requis).</div>} */}
                              </div>

                              <div className="col-md-6 form-group mb-4">
                                <label className="col-form-label">
                                  <i className="fas fa-envelope fa-fw"></i> Email *
                                </label>
                                <Form.Control
                                  type="email"
                                  placeholder="Adresse email"
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailIsValid(true); // Réinitialiser la validation lors de la modification
                                  }}
                                  className={`form-control ${emailIsValid ? "" : "is-invalid"}`}
                                  required
                                />
                                {!emailIsValid && (
                                  <div className="invalid-feedback">
                                    {email.includes('@') ? 
                                      "Cette adresse email est déjà utilisée" : 
                                      "Adresse email invalide"}
                                  </div>
                                )}
                              </div>

                              <div className="col-md-12 form-group mb-4">
                                <label className="col-form-label">
                                  <i className="fas fa-lock fa-fw"></i> Mot de passe *
                                </label>
                                <div className="password-input-wrapper">
                                  <Form.Control
                                    type="password"
                                    placeholder="Mot de passe"
                                    value={password}
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                      // Réinitialiser la validation lors de la saisie
                                      setPasswordIsValid(e.target.value.length >= 6);
                                    }}
                                    className={`form-control ${passwordIsValid ? "" : "is-invalid"}`}
                                    required
                                  />
                                  <div className="password-strength-indicator mt-2">
                                    <div className="strength-bars">
                                      <div 
                                        className={`strength-bar ${password.length >= 2 ? 'filled' : ''}`}
                                      ></div>
                                      <div 
                                        className={`strength-bar ${password.length >= 4 ? 'filled' : ''}`}
                                      ></div>
                                      <div 
                                        className={`strength-bar ${password.length >= 6 ? 'filled' : ''}`}
                                      ></div>
                                    </div>
                                    <small className={`text-${password.length >= 6 ? 'success' : 'muted'}`}>
                                      {password.length}/6 caractères minimum
                                    </small>
                                  </div>
                                  <div className="password-requirements mt-2">
                                    <small className="text-muted">
                                      <ul className="list-unstyled">
                                        <li className={password.length >= 6 ? 'text-success' : ''}>
                                          <i className={`fas fa-${password.length >= 6 ? 'check' : 'times'} me-2`}></i>
                                          Au moins 6 caractères
                                        </li>
                                      </ul>
                                    </small>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 form-group mb-4">
                                <label className="col-form-label"><i className="fas fa-lock fa-fw"></i> Confirmer le mot de passe *</label>
                                <Form.Control
                                  type="password"
                                  placeholder="Confirmer le mot de passe"
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                                  className={`form-control ${confirmPasswordIsValid ? "" : "is-invalid"}`}
                                  required
                                />
                                {!confirmPasswordIsValid && <div className="invalid-feedback">Les mots de passe ne correspondent pas.</div>}
                              </div>
                            </div>

                            <Button
                              type="submit"
                              className="btn btn-primary btn-block"
                              style={{ margin: '0 auto', width: '100%' }}
                              disabled={loading || isLoading}
                            >
                              <i className="fas fa-save fa-fw"></i> {isLoading ? "Enregistrement en cours..." : "Enregistrer"}
                            </Button>
                          </Form>

                          <div id="form-message-warning mt-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <br /><br /><br /><br /><br />
            <Foot />
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_user;
