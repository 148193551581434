import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Nav";
import EnhancedAdminDashboard from "./demande/dsps/AdminStatisticsDashboard";
import UserService from "./service/UserService";
import DemandeService from "./service/DemandeService";
import PromotersTable from "./demande/dsps/PromotersTable";
import PersonnelTable from "./demande/dsps/PersonnelTable";
function Home() {
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };




  const [userId, setUserId] = useState([]);
  const [userData, setUserData] = useState([]);
  const [role, setRole] = useState([]);

  const [userDemande, setUserDemande] = useState([]);
  const [encours, setDemandeEncours] = useState([]);
  const [rejetee, setDemandeRejete] = useState([]);
  const [terminee, setDemandeTermine] = useState([]);

  const userRole = UserService.getRoleInToken();

  // Définissez les rôles autorisés pour chaque section du menu
  const allowedRolesDashboard = 'ADMIN'
  const allowedRolesIntroduireDemande = 'USER';

  //console.log(userRole)


  // Ajoutez d'autres sections du menu avec les rôles autorisés nécessaires...
  const getReadableStatus = (status) => {
    const statusMap = {
      "EN_COURS_PREVALIDATION": "En cours de prévalidation",
      "PREVALIDEE": "Prévalidée",
      "FAVORABLE": "Favorable",
      "EN_ATTENTE_COMPLEMENT": "En attente de complément",
      "EN_ATTENTE_COMISSION": "En commission",
      "ACCEPTEE_SOUS_RESERVE": "Acceptée sous réserves",
      "REJETEE": "Rejetée",
      "AJOURNEE": "Ajournée",
      "ACCEPTEE": "Acceptée",
      "REJETEE_DSPS": "Rejetée par la DSPS",
      "NON_INSCRITE_A_OP": "Rejetée par l'Ordre professionnel",
      "REJETE_COM": "Rejetée par la commission",
      "INSCRITE_A_OP": "Acceptée par de l'ordre",
      "EN_ATTENTE_COMPLEMENT_COM": "En attente de complément par la commission"
    };
    return statusMap[status] || status; // Retourne le statut original si non trouvé dans le map
  };

  const getStatusColor = (status) => {
    const colorMap = {
      "EN_COURS_PREVALIDATION": "primary",
      "PREVALIDEE": "info",
      "FAVORABLE": "blue",
      "EN_ATTENTE_COMPLEMENT": "warning",
      "EN_ATTENTE_COMISSION": "secondary",
      "ACCEPTEE_SOUS_RESERVE": "info",
      "REJETEE": "danger",
      "AJOURNEE": "warning",
      "ACCEPTEE": "success",
      "REJETEE_DSPS": "danger",
      "NON_INSCRITE_A_OP": "danger",
      "REJETE_COM": "danger",
      "INSCRITE_A_OP": "info",
      "EN_ATTENTE_COMPLEMENT_COM": "warning"
    };
    return colorMap[status] || "secondary"; // Retourne "secondary" si non trouvé dans le map
  };
  // Vérifiez si l'utilisateur a le rôle autorisé pour afficher chaque section du menu
  const showDashboard = allowedRolesDashboard;

  const showIntroduireDemande = allowedRolesIntroduireDemande;

  const token = sessionStorage.getItem("token"); // Récupérer le token du stockage local

  // Vérifier si l'utilisateur a un rôle autorisé
  axios.defaults.debug = true;
  const getUserConnect = async () => {
    try {
      // Vérifier si le token existe
      if (!token) {
        console.error("Token not found in sessionStorage");
        return;
      }
      const response = await UserService.getProfil(token);
      if (response) {
        const userData = response;
        const userId = userData.id;
        setUserId(userId);
        setUserData(userData);
        const roleUser = userData.role.libelle;
        setRole(roleUser);
      } else {
        console.error("Error sending token to server:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending token to server:", error.message);
    }
  };

  useEffect(() => {
    getUserConnect();
  }, []);

  useEffect(() => {
    getDemandeData();
  }, []);

  const getDemandeData = async () => {
    try {
      //console.log(userId);
      const response = await DemandeService.getDemandeByUser(token);
      //console.log("demandes:", response);

      // Trier les demandes par date de soumission du plus récent au plus ancien
      const sortedDemandes = response.sort((a, b) => new Date(b.dateSoumission) - new Date(a.dateSoumission));

      setUserDemande(sortedDemandes);
    } catch (error) {
      console.error("Error to fetch demandes list: :", error.message);
    }
  };


  const getDemandeEncours = async () => {
    try {
      const response = await DemandeService.getDemandeByStatut('EnCOURS', token);
      //console.log("demanddes", response)
      setDemandeEncours(response);
    } catch (error) {
      console.error("Error to fetch demandes list: :", error.message);
    }
  };
  useEffect(() => {
    getDemandeEncours();
  }, []);

  const telecharger = async (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-recipisse/${demandeId}`;

    try {
      // Envoi de la requête GET avec Axios
      const response = await axios.get(url, {
        responseType: 'blob', // Spécifie que la réponse sera un blob (pour les fichiers)
        headers: {
          'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
          'Content-Type': 'application/json',
        },
      });

      // Créer un objet URL pour le blob
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      link.href = downloadUrl;
      link.setAttribute('download', `recipisse_${demandeId}.pdf`); // Nom du fichier à télécharger
      // console.log(token)
      // Ajouter le lien au DOM et simuler un clic pour le téléchargement
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Nettoyer le DOM

      // Libérer l'URL créée
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Erreur lors du téléchargement :', error);
    }
  };

  const telechargerPhysique = async (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/recipisse-physique/${demandeId}`;

    try {
      // Envoi de la requête GET avec Axios
      const response = await axios.get(url, {
        responseType: 'blob', // Spécifie que la réponse sera un blob (pour les fichiers)
        headers: {
          'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
          'Content-Type': 'application/json',
        },
      });

      // Créer un objet URL pour le blob
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      link.href = downloadUrl;
      link.setAttribute('download', `recipisse_${demandeId}.pdf`); // Nom du fichier à télécharger
      //console.log(token)
      // Ajouter le lien au DOM et simuler un clic pour le téléchargement
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Nettoyer le DOM

      // Libérer l'URL créée
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Erreur lors du téléchargement :', error);
    }
  };


  const telechargerLettre = async (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-lettre-rejet/${demandeId}`;

    try {
      // Envoi de la requête GET avec Axios
      const response = await axios.get(url, {
        responseType: 'blob', // Spécifie que la réponse sera un blob (pour les fichiers)
        headers: {
          'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
          'Content-Type': 'application/json',
        },
      });

      // Créer un objet URL pour le blob
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      link.href = downloadUrl;
      link.setAttribute('download', `lettre_de_rejet_${demandeId}.pdf`); // Nom du fichier à télécharger
      // console.log(token)
      // Ajouter le lien au DOM et simuler un clic pour le téléchargement
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Nettoyer le DOM

      // Libérer l'URL créée
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Erreur lors du téléchargement :', error);
    }
  };

  const telechargerArrete = async (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-arrete/${demandeId}`;

    try {
      // Envoi de la requête GET avec Axios
      const response = await axios.get(url, {
        responseType: 'blob', // Spécifie que la réponse sera un blob (pour les fichiers)
        headers: {
          'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
          'Content-Type': 'application/json',
        },
      });

      // Créer un objet URL pour le blob
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      link.href = downloadUrl;
      link.setAttribute('download', `Arrete_${demandeId}.pdf`); // Nom du fichier à télécharger
      // console.log(token)
      // Ajouter le lien au DOM et simuler un clic pour le téléchargement
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Nettoyer le DOM

      // Libérer l'URL créée
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Erreur lors du téléchargement :', error);
    }
  };
  const getDemandeTermine = async () => {
    try {
      const response = await DemandeService.getDemandeByStatut('TERMINEE', token);
      //console.log("termines", response)
      setDemandeTermine(response);
    } catch (error) {
      console.error("Error to fetch demandes list: :", error.message);
    }
  };
  useEffect(() => {
    getDemandeTermine();
  }, []);

  const getDemandeRejetee = async () => {
    try {
      const response = await DemandeService.getDemandeByStatut('REJETEE', token);
      setDemandeRejete(response);
    } catch (error) {
      console.error("Error to fetch demandes list: :", error.message);
    }
  };
  useEffect(() => {
    getDemandeRejetee();
  }, []);

  const editURL = `${process.env.REACT_APP_API_URL}/demandes/stats`;
  const [statistique, setStatistique] = useState([]);

  const setstatsData = () => {
    axios
      .get(editURL)
      .then((response) => {
        setStatistique(response.data);
      })
      .catch((error) => {
        console.error("Erreur d'obtention des données statistiques :" + error);
      });
  };

  useEffect(() => {
    setstatsData();
  }, []);


  const formatDate = (dateStr) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateStr).toLocaleDateString(undefined, options);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6); // Nombre d'éléments par page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUtilisateurs = userDemande.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Fonction pour changer de page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calcul du nombre total de pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(userDemande.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const navigate = useNavigate();

  return (
    <div>
      {/*  <!-- Page Heading --> */}
      <br></br>
      {(userRole === 'ADMIN' || userRole === 'DSPS'|| userRole === 'COMMISSION') && <EnhancedAdminDashboard />}
      <br></br>
      {( userRole === 'OP' ) && <PromotersTable />}
      {( userRole === 'OP' ) && <PersonnelTable />}
      <br></br> <br></br>
      {showIntroduireDemande === userRole && (
        <div className="row">
          {/* Card Nombre total de demandes */}
          <div className="col-xl-4 col-md-6 mb-4">
            <div 
              className="card stat-card border-0 shadow-lg clickable-card" 
              onClick={() => navigate('/liste_demande_user')}
            >
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="text-uppercase mb-2 font-weight-bold text-primary">
                      Total Demandes
                    </h6>
                    <h2 className="mb-0 display-4">{userDemande.length}</h2>
                  </div>
                  <div className="stat-icon-box bg-primary-light">
                    <i className="fas fa-folder fa-2x text-primary"></i>
                  </div>
                </div>
                <div className="progress mt-3" style={{height: "4px"}}>
                  <div className="progress-bar bg-primary" style={{width: "100%"}}></div>
                </div>
              </div>
            </div>
          </div>

          {/* Card Demandes en cours */}
          <div className="col-xl-4 col-md-6 mb-4">
            <div className="card stat-card border-0 shadow-lg">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="text-uppercase mb-2 font-weight-bold text-warning">
                    Total Demandes En Cours de Traitement
                    </h6>
                    <h2 className="mb-0 display-4">{encours.length}</h2>
                  </div>
                  <div className="stat-icon-box bg-warning-light">
                    <i className="fas fa-clock fa-2x text-warning"></i>
                  </div>
                </div>
                <div className="progress mt-3" style={{height: "4px"}}>
                  <div 
                    className="progress-bar bg-warning" 
                    style={{width: `${(encours.length/userDemande.length)*100}%`}}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          {/* Card Demandes terminées */}
          <div className="col-xl-4 col-md-6 mb-4">
            <div className="card stat-card border-0 shadow-lg">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="text-uppercase mb-2 font-weight-bold text-success">
                      Total Demandes Terminées
                    </h6>
                    <h2 className="mb-0 display-4">{terminee.length}</h2>
                  </div>
                  <div className="stat-icon-box bg-success-light">
                    <i className="fas fa-check-circle fa-2x text-success"></i>
                  </div>
                </div>
                <div className="progress mt-3" style={{height: "4px"}}>
                  <div 
                    className="progress-bar bg-success" 
                    style={{width: `${(terminee.length/userDemande.length)*100}%`}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;