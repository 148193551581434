import React from "react";
import { Routes, Route } from "react-router-dom";
import Head from "./Head";
import Home from "./Home";

import Foot from "./Foot";

const TemplateLayouts = () => {
  return (
    <div>
      <Head />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="*" element={<Home />} />
      </Routes>
      <br></br>
      <br></br>
      <Foot />
    </div>
  );
};

export default TemplateLayouts;
