import axios from "axios";

class DemandeService {

    static BASE_URL =  `${process.env.REACT_APP_API_URL}/demandes`;

    static piece_URL =  `${process.env.REACT_APP_API_URL}/pieces`;


    static async getDemandeByUser(token) {
        try {
            const response = await axios.get(`${DemandeService.BASE_URL}/user-demandes`, 
                {
                    headers: {Authorization: `Bearer ${token}`}
                })
                return response.data;
        } catch(error) {
            throw error;
        }
    }

    static async getDemandeByStatut(statut, token) {
        try {
            const response = await axios.get(`${DemandeService.BASE_URL}/user-demandes/${statut}`, 
                {
                    headers: {Authorization: `Bearer ${token}`}
                })
                return response.data;
        } catch(error) {
            throw error;
        }
    }

    static async getById(demandeId, token) {
        try {
            const response = await axios.get(`${DemandeService.BASE_URL}/${demandeId}`, 
                {
                    headers: {Authorization: `Bearer ${token}`}
                })
                return response.data;
        } catch(error) {
            throw error;
        }
    }

    static async downloadPiece(nomFile, token) {
        try {
            const response = await axios.get(`${DemandeService.piece_URL}/previsualiser-pdf/${nomFile}`,
                {
                    responseType: 'blob',
                    headers: {Authorization: `Bearer ${token}`}
                })
                return response.data;
        } catch(error) {
            throw error;
        }
    }

}

export default DemandeService;