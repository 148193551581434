import React from 'react';

function ErrorMessage({ message, onClose }) {
  return (
    <div className="error-message">
      <p>{message}</p>
      <button onClick={onClose}>Fermer</button>
    </div>
  );
}

export default ErrorMessage;
