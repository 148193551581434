import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PDFPreviewModal = ({ isModalOpen, closeFileModal, pieceJointe }) => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);

  useEffect(() => {
    if (isModalOpen && pieceJointe) {
      fetchPDF();
    }
  }, [isModalOpen, pieceJointe]);

  const fetchPDF = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pieces/previsualiser-pdf/${pieceJointe.id}`,
        {
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        }
      );
      const blobUrl = URL.createObjectURL(response.data);
      setPdfBlobUrl(blobUrl);
    } catch (error) {
      console.error("Erreur lors du chargement du PDF:", error);
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="pdf-modal">
      <div className="pdf-modal-content">
        <div className="pdf-modal-header">
          <h2>{pieceJointe.nomFile}</h2>
          <button className="close-button" onClick={closeFileModal}>&times;</button>
        </div>
        <div className="pdf-container">
          {pdfBlobUrl ? (
            <iframe
              src={pdfBlobUrl}
              title="PDF Preview"
              width="100%"
              height="600px"
              style={{ border: 'none' }}
              // sandbox="allow-same-origin allow-scripts" // Limite les actions possibles dans l'iframe
            />
          ) : (
            <p>Chargement du PDF...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PDFPreviewModal;