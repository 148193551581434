import React, { useState } from "react";
import Select from "react-select";

const MultiSelectWithCheckbox = ({ directions, selectedDirections, setSelectedDirections }) => {
    // Options formatées pour React-Select
    const options = directions.map((direction) => ({
      value: direction.id,
      label: direction.sigle,
    }));
  
    // Gestion des changements de sélection
    const handleChange = (selectedOptions) => {
      const selectedIds = selectedOptions ? selectedOptions.map((option) => option.value) : [];
      setSelectedDirections(selectedIds);
    };
  
    return (
      <div className="col-md-12 form-group mb-5">
        <label htmlFor="statut" className="col-form-label">
          Ordre Professionnel(s)/ Direction(s):
        </label>
        <Select
          isMulti
          options={options}
          value={options.filter((option) => selectedDirections.includes(option.value))} // Pré-sélection
          onChange={handleChange}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option: CheckboxOption,
          }}
          placeholder="Sélectionnez un ou plusieurs ordres/directions..."
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    );
  };
  
  // Composant personnalisé pour inclure une case à cocher
  const CheckboxOption = (props) => {
    const { data, innerRef, innerProps, isSelected } = props;
  
    return (
      <div ref={innerRef} {...innerProps} className="d-flex align-items-center p-2">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null} // Contrôle géré par React-Select
          className="me-2"
        />
        <label>{data.label}</label>
      </div>
    );
  };
  
  export default MultiSelectWithCheckbox;