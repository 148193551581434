import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import armoiries from "../img/armoiries.jpg";
import Footer from "../Footer";

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import { Typography } from "@mui/material";

function Info_District() {
  const editURL = `${process.env.REACT_APP_API_URL}/districts/`;

  const param = useParams();
  const [empId, setId] = useState("");
  const [empIntitule, setIntitule] = useState("");
  const [empProvince, setProvince] = useState("");


  const navigate = useNavigate();

  const retour = () => {
    navigate("/liste_district");
  };

  useEffect(() => {
    axios
      .get(editURL + param.id)
      .then((response) => {
        const empData = response.data;
        setId(empData.id);
        setIntitule(empData.intituleDistrict);
        setProvince(empData.province.intituleProvince);

      })
      .catch((error) => {
        alert("erreur pour trouver les details :" + error);
      });
  });
  return (
    <div>
      <main id="main" className="main">
        <div className="content center-container">
          <div>
            <Card sx={{ maxWidth: 600 }}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="center-text"
                >
                  <b>
                    <img
                      src={armoiries}
                      alt=""
                      className="img-fluid"
                      width={50}
                    />
                    Informations sur la province &nbsp;&nbsp;&nbsp;
                  </b>
                </Typography>
                <br></br>
        
                <Typography variant="body2" color="text.primary">
                  <h6>
                    <b> Identifant de la province :</b> {empId}
                  </h6>
                  <br></br>
                  <h6>
                    <b> Nom de la province :</b> {empIntitule}{" "}
                  </h6>
                  <br></br>
                  <h6>
                    <b>  Région :</b> {empProvince}{" "}
                  </h6>
        
           

                  <br></br>
                </Typography>
              </CardContent>
              <button
                onClick={() => retour()}
                className="btn btn-sm btn-danger"
              >
                {" "}
                retour <i className="bi bi-back"></i>
              </button>
            </Card>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Info_District;
