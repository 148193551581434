import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Navbar from "../Nav";

function ListeDistrict() {
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const listeURL = `${process.env.REACT_APP_API_URL}/districts/list`;
  const suprimeURL = `${process.env.REACT_APP_API_URL}/districts`;
  const [pieces, setTypePiece] = useState([]);

  const setpiecesData = () => {
    axios
      .get(listeURL)
      .then((response) => {
        setTypePiece(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention de la liste des districts :" + error);
      });
  };

  useEffect(() => {
    setpiecesData();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Nombre d'éléments par page
  // Calculez l'index de début et de fin pour l'affichage des éléments actuels sur la page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPages = pieces.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Fonction pour changer de page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calcul du nombre total de pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(pieces.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const supprimer = (id) => {
    axios
      .delete(`${suprimeURL}/${id}`) // Vous devriez concaténer l'ID à l'URL correctement
      .then((response) => {
        alert(
          "la district identifiée par ID : " + id + " à été supprimé !"
        );
        window.location.reload();
      })
      .catch((error) => {
        alert("Erreur lors de la suppression du type de demande : " + error);
      });
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            {/* <Navbar /> */}
            <h4 className="title-text">Liste des Districts</h4>

            <Link to="/district">
              <button className="custom-button">
                {" "}
                <i className="fas fa-fw fa-plus"> </i> Ajouter
              </button>
            </Link>
            <div className="card-body">
              <table
                id="example1"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nom du District</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPages.length === 0 ? (
                    <tr>
                      <td colSpan="9" className="text-center">
                        Aucun fichier n'a été enregistré.
                      </td>
                    </tr>
                  ) : (
                    currentPages &&
                    currentPages.map((district) => (
                      <tr key={district.id}>
                        <td className="text-noir">{district.id}</td>
                        <td className="text-noir">{district.intituleDistrict}</td>
                        <td className="text-noir button-container d-flex align-items-center">
                          <Link to={"/voir-district/" + district.id}>
                            <div className="round-button d-flex justify-content-center align-items-center">
                              <i className="bi bi-eye white-icon"></i>
                            </div>
                          </Link>

                          <Link
                            to={"/edit-district/" + district.id}
                          >
                            <div className="round-button d-flex justify-content-center align-items-center">
                              <i className="bi bi-pencil white-icon"></i>
                            </div>
                          </Link>
                          <div className="round-buttonr">
                            <i
                              className="bi bi-trash white-icon"
                              onClick={() => supprimer(district.id)}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
                <div className="pagination">
                  {pageNumbers.map((pageNumber) => (
                    <button
                      key={pageNumber}
                      onClick={() => paginate(pageNumber)}
                      className={`page-number ${pageNumber === currentPage ? "selected-page" : ""
                        }`}
                    >
                      {pageNumber}
                    </button>
                  ))}
                </div>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
}

export default ListeDistrict;
