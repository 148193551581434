import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";
import Navbar from "../Nav";

import Header from "../Header";

function Form_typ_dmd() {
  const [searchTerm, setSearchTerm] = useState("");

  const editURL = `${process.env.REACT_APP_API_URL}/api/param-type-pieces`;
  const [utilisateurs, setTypeDemande] = useState([]);

  const setUtilisateursData = () => {
    axios
      .get(editURL)
      .then((response) => {
        setTypeDemande(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention des données :" + error);
      });
  };

  const validateForm = () => {
    let isValid = true;

    if (intitule.trim() === "") {
      setIntituleIsValid(false);
      isValid = false;
    } else {
      setIntituleIsValid(true);
    }

    if (code.trim() === "") {
      setCodeIsValid(false);
      isValid = false;
    } else {
      setCodeIsValid(true);
    }

    if (description.trim() === "") {
      setDescriptionIsValid(false);
      isValid = false;
    } else {
      setDescriptionIsValid(true);
    }

    return isValid;
  };

  const [intituleIsValid, setIntituleIsValid] = useState(true);
  const [descriptionIsValid, setDescriptionIsValid] = useState(true);
  const [codeIsValid, setCodeIsValid] = useState(true);

  useEffect(() => {
    setUtilisateursData();
  }, []);
  const navigate = useNavigate();
  const editeURL = `${process.env.REACT_APP_API_URL}/api/param-niveau-validations`;
  const [niveau, setnivovalid] = useState([]);

  const setUtilisateurData = () => {
    axios
      .get(editeURL)
      .then((response) => {
        setnivovalid(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention des données :" + error);
      });
  };
  useEffect(() => {
    setUtilisateurData();
  }, []);

  const baseURL = `${process.env.REACT_APP_API_URL}/api/param-type-demandes`;
  const [intitule, setIntitule] = useState("");
  const [sous_type, setIntitules] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [requirePayement, setRequirePayement] = useState("");
  // requirePayement;
  const handlePieceChange = (e, pieceId) => {
    const checked = e.target.checked;
    if (checked) {
      // Ajouter l'ID de la pièce sélectionnée au tableau piece
      setPiece([...piece, pieceId]);
    } else {
      // Retirer l'ID de la pièce décochée du tableau piece
      setPiece(piece.filter((id) => id !== pieceId));
    }
  };

  const [piece, setPiece] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Effectuer la soumission du formulaire si la validation réussit.
      axios
        .post(baseURL, {
          intitule: intitule,
          description: description,
          code: code,
          requirePayement: requirePayement,
          sous_type: sous_type,
          paramTypePieces: piece.map((pieceId) => ({
            id: pieceId,
          })),
        })
        .then((response) => {
          alert(
            " " +
            intitule +
            " a été enregistré comme type de demande avec succès !"
          );
          navigate("/liste_dmd_t");
        })
        .catch((error) => {
          alert("Erreur : " + error);
        });
    } else {
      alert("Veuillez remplir tous les champs obligatoires.");
    }
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}

          <div className="custom-wrapper">
            <Navbar />
            {/*  <!-- Content Row --> */}
            <div className="col-md-12">
              <div className="form h-70">
                <h2 className="title-text">
                  Ajouter type de demande
                </h2>
                <Form
                  onSubmit={handleSubmit}
                  className="mb-5"
                  noValidate
                >
                  <div className="row">
                    <div className="col-md-6 form-group mb-6">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Intitulé de la demande*
                      </label>
                      <Form.Control
                        type="text"
                        value={intitule}
                        onChange={(e) => setIntitule(e.target.value)}
                        className={`form-control ${intituleIsValid ? "" : "is-invalid"
                          }`}
                        id="yourEmail"
                        aria-required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-5">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Code du type de demande
                      </label>
                      <Form.Control
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className={`form-control ${codeIsValid ? "" : "is-invalid"
                          }`}
                        id="yourEmail"
                        aria-required
                      />
                    </div>
                    <div className="col-md-12 form-group mb-5">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Description *
                      </label>
                      <Form.Control
                        type="text"
                        value={description}
                        onChange={(e) =>
                          setDescription(e.target.value)
                        }
                        className={`form-control ${descriptionIsValid ? "" : "is-invalid"
                          }`}
                        id="yourEmail"
                        aria-required
                      />
                    </div>

                    <div className="col-md-6 form-group mb-5">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        intitulé de sous type *
                      </label>
                      <Form.Control
                        type="text"
                        value={sous_type}
                        onChange={(e) => setIntitules(e.target.value)}
                        id="yourEmail"
                        aria-required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-6">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Paiement sur la plateforme *
                      </label>
                      <select
                        value={requirePayement}
                        onChange={(e) =>
                          setRequirePayement(e.target.value)
                        }
                        className="form-control"
                        id="service"
                        required
                      >
                        <option value="">Sélectionnez </option>
                        <option value="true">OUI</option>
                        <option value="false">Non</option>
                      </select>
                    </div>

                    <div className="col-md-6 form-group mb-6">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Recherche de pièce
                      </label>
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) =>
                          setSearchTerm(e.target.value)
                        }
                        className="form-control"
                        placeholder="Entrez le nom de la pièce..."
                      />
                    </div>

                    <div className="col-md-6 form-group mb-6">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Pièces à fournir *
                      </label>
                      {utilisateurs
                        .filter((utili) =>
                          utili.intitule
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((utili, index) => (
                          <div key={utili.id} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={utili.id}
                              onChange={(e) =>
                                handlePieceChange(e, utili.id)
                              }
                              checked={piece.includes(utili.id)}
                              id={`piece-${utili.id}`}
                              aria-required
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`piece-${utili.id}`}
                            >
                              {utili.intitule}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 form-group">
                      <Button
                        className="btn btn-primary w-100"
                        type="submit"
                      >
                        <i className="fas fa-save"> </i> Enregistrer
                      </Button>
                      <span className="submitting"></span>
                    </div>
                  </div>
                </Form>

                <div id="form-message-warning mt-4"></div>
                <div id="form-message-success">
                  Veuillez Remplir le Formulaire
                </div>
              </div>
            </div>

          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_typ_dmd;
