/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';

import { Form, Button, ProgressBar } from "react-bootstrap";

import Header from "../Header";
import UserService from "../service/UserService";
import Navbar from "../Nav";

function Form_userm() {

  const navigate = useNavigate();
  const roleUrl = `${process.env.REACT_APP_API_URL}/roles/list`;
  const directionUrl = `${process.env.REACT_APP_API_URL}/directions/list`;

  const [role, setRole] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [direction, setDirection] = useState("");
  const [directionList, setDirectionList] = useState([]);

  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    tel: '',
    password: '',
    actif: false,
    role: {
      id: ''
    },
    direction: {
      id: ''
    }
  });

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
    setFormData({
      ...formData,
      role: {
        id: selectedRole
      }
    });
  };
  const handleDirectionChange = (e) => {
    const selectedDirection = e.target.value;
    setDirection(selectedDirection);
    setFormData({
      ...formData,
      direction: {
        id: selectedDirection
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const roleDatas = () => {
    axios
      .get(roleUrl)
      .then((response) => {
        setRoleList(response.data);
      })
      .catch((error) => {
        console.error("Erreur d'obtention des données :" + error);
      });
  };

  useEffect(() => {
    roleDatas();
  }, []);

  const directionData = () => {
    axios
      .get(directionUrl)
      .then((response) => {
        setDirectionList(response.data);
      })
      .catch((error) => {
        console.error("Erreur d'obtention des données :" + error);
      });
  };

  useEffect(() => {
    directionData();
  }, []);

  const [error, setError] = useState({});

  const validate = () => {
    const newErrors = {};

    if (!formData.nom) {
      newErrors.nom = 'Le nom est requis';
    }

    if (!formData.prenom) {
      newErrors.prenom = 'Le prénom est requis';
    }

    if (!formData.email) {
      newErrors.email = 'L\'email est requis';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'L\'email n\'est pas valide';
    }

    if (!formData.tel) {
      newErrors.tel = 'Le numéro de téléphone est requis';
    } else if (!/^\d+$/.test(formData.tel)) {
      newErrors.tel = 'Le numéro de téléphone n\'est pas valide';
    }

    if (!formData.password) {
      newErrors.password = 'Le mot de passe est requis';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Le mot de passe doit contenir au moins 6 caractères';
    }

    setError(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {

      try {
        const token = sessionStorage.getItem('token');
        await UserService.create(formData, token);

        setFormData({
          nom: '',
          prenom: '',
          email: '',
          tel: '',
          password: '',
          actif: false
        });

        toast.success("Utilisateur créer avec succès");
        navigate('/liste_user_metier');

      } catch (error) {
        console.error("");
      }
    }
  }

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          <Header />
          <div className="custom-wrapper col-md-10">
            {/* <Navbar /> */}   <br></br>
            <h2 className="title-text">Inscription d'un acteur métier</h2>
            <Form onSubmit={handleSubmit} className="mb-5">
              <div className="row">
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Nom{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label> {error.nom && <span>{error.nom}</span>}
                  <Form.Control
                    type="text" name="nom"
                    value={formData.nom}
                    onChange={handleInputChange}
                    required
                  />

                </div>
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Prénom(s){" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    type="text" name="prenom"
                    value={formData.prenom}
                    onChange={handleInputChange}
                    required
                  /> {error.prenom && <span>{error.prenom}</span>}

                </div>
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Email{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    type="text" name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  /> {error.email && <span>{error.email}</span>}

                </div>
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Téléphone
                  </label>
                  <Form.Control
                    type="text" name="tel"
                    value={formData.tel}
                    onChange={handleInputChange}
                    required
                  /> {error.tel && <span>{error.tel}</span>}

                </div>
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Mot de passe{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    type="password" name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  /> {error.password && <span>{error.password}</span>}

                </div>
                <div className="col-md-6 form-group mb-5">
                  <label
                    className="col-form-label"
                    style={{ color: "black" }}
                  >
                    Statut{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Select
                    name="actif"
                    value={formData.actif}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  >
                    <option value="actif.value">
                      Selectionnez le statut du compte  {" "}
                    </option>
                    <option value="true">
                      Activer{" "}
                    </option>
                    <option value="false">
                      Désactiver{" "}
                    </option>

                  </Form.Select>
                </div>
                <div className="col-md-6 form-group mb-5">
                  <label
                    className="col-form-label"
                    style={{ color: "black" }}
                  >
                    Role{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    name="role"
                    value={role}
                    onChange={handleRoleChange}
                    className="form-control"
                    id="role"
                    required
                  >
                    <option value="">
                      Sélectionnez un rôle{" "}
                    </option>

                    {roleList.map((rol, index) => (
                      <option value={rol.id}>
                        {rol.libelle}
                      </option>
                    ))}

                  </select>
                </div>
                <div className="col-md-6 form-group mb-5">
                  <label
                    className="col-form-label"
                    style={{ color: "black" }}
                  >
                    Direction{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    name="direction"
                    value={direction}
                    onChange={handleDirectionChange}
                    className="form-control"
                    id="direction"
                    required
                  >
                    <option value="">
                      Sélectionnez une direction{" "}
                    </option>

                    {directionList.map((directione, index) => (
                      <option value={directione.id}>
                        {directione.sigle}
                      </option>
                    ))}

                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 form-group">
                  <Button
                    className="btn btn-primary w-100"
                    type="submit"
                  >
                    <i className="fas fa-save"></i> Enregistrer
                  </Button>
                  <span className="submitting"></span>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_userm;