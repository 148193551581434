import React, { useState, useEffect } from "react";

import axios from "axios";

import { Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../Header";

function Edit_Commune() {
  const editURL = `${process.env.REACT_APP_API_URL}/communes/update`;
  const editeURL = `${process.env.REACT_APP_API_URL}/communes/`;
  const [intitule, setIntitule] = useState("");
  const [district, setDistrict] = useState("");
  const [listedistrict, setListeDistrict] = useState([]);
  const listeURL = `${process.env.REACT_APP_API_URL}/districts/list`;
  const setDistrictData = () => {
    axios
      .get(listeURL)
      .then((response) => {
        setListeDistrict(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention de la liste des Districts :" + error);
      });
  };

  useEffect(() => {
    setDistrictData();
  }, []);
  const param = useParams();
  useEffect(() => {
    axios
      .get(editeURL + param.id)
      .then((response) => {
        const empData = response.data;
        setIntitule(empData.intituleCommune);
        setDistrict(empData.district.intituleDistrict);

      })
      .catch((error) => {
        alert("erreur pour trouver les details :" + error);
      });
  }, []);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(editURL, {
        id: param.id,
        intituleCommune: intitule,
        district: {
          id: district,
        },

      })

      .then((response) => {
        alert("la commune a été modifiée avec succès !! " + intitule);
        navigate("/liste_commune");
      })
      .catch((error) => {
        alert("Erreur : " + error);
      });
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {/* {<Header />} */}
          <div className="container-fluid">
            {/*  <!-- Content Row --> */}
            <div className="row">
              <div className="col-xxl-4 col-md-12">
                <div className="card info-card sales-card">

                  <div className="row align-items-stretch no-gutters contact-wrap">
                    <div className="col-md-12">
                      <div className="form h-100">
                        <h2 className="title-text">Modifier une commune</h2>
                        <Form
                          onSubmit={handleSubmit}
                          className="mb-5"
                          noValidate
                        >
                          <div className="row">
                            <div className="col-md-12 form-group mb-6">
                              <label
                                className="col-form-label"
                                style={{ color: "black" }}
                              >
                                Nom de la commune *
                              </label>
                              <Form.Control
                                type="text"
                                value={intitule}
                                onChange={(e) => setIntitule(e.target.value)}
                                className="form-control"
                                id="yourEmail"
                                required
                              />
                            </div>

                            <div className="col-md-12 form-group mb-5">
                              <label
                                className="col-form-label"
                                style={{ color: "black" }}
                              >
                                Districts{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                name="typeEsp"
                                value={district}
                                onChange={(e) => setDistrict(e.target.value)}
                                className="form-control"
                                id="service"
                                required
                              >
                                <option value="">
                                  Sélectionnez un district{" "}
                                </option>

                                {listedistrict.map((utili, index) => (
                                  <option value={utili.id}>
                                    {utili.intituleDistrict}
                                  </option>
                                ))}

                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 form-group">
                              <Button
                                className="btn btn-primary w-100"
                                type="submit"
                              >
                                Enregistrer
                              </Button>
                              <span className="submitting"></span>
                            </div>
                          </div>
                        </Form>



                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Edit_Commune;
