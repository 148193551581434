import React, { useEffect, useState } from "react";
import axios from "axios";
import Boutons from "../demande/Bouton";

import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Navbar from "../Nav";

function ListeNivoValid() {
  const editURL = `${process.env.REACT_APP_API_URL}/api/param-type-demandes/`;
  const [nivo, setNiveauValidation] = useState([]);

  const buttons = [
    { id: 1, code: "01", label: "Etablissement Sanitaire Privée de santé" },

    // Ajoutez d'autres boutons au besoin
  ];

  const [selectedButton, setSelectedButton] = useState(null);

  const affURL = `${process.env.REACT_APP_API_URL}/api/param-niveau-validations/findBy`;

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    console.log("Selected Button:", buttonName);

    axios
      .get(`${affURL}/paramTypeDemande?code=${buttonName}`)
      .then((response) => {
        setNiveauValidation(response.data || []);
      })
      .catch((error) => {
        alert("selectionner le type de demande : " + error);
      });
  };
  const supprimer = (id) => {
    axios
      .delete(`${editURL}${id}`) // Vous devriez concaténer l'ID à l'URL correctement
      .then((response) => {
        alert(
          "le niveau de validation identifié par " + id + "à été supprimé !"
        );
        window.location.reload();
      })
      .catch((error) => {
        alert("Impossible de supprimer : " + error);
      });
  };

  useEffect(() => {
    handleButtonClick();
  }, []);

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            <Navbar />
            <br></br>
            <div>
              <Boutons
                buttons={buttons}
                selectedButton={selectedButton}
                handleButtonClick={handleButtonClick}
              />
            </div>
            <br></br>
            <h4 className="title-text">
              Liste des niveaux de validation
            </h4>

            <div className="card-body">
              <table
                id="example1"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>N° Ordre</th>
                    <th>Intitulé</th>
                    <th>Description du niveau</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {nivo.length === 0 ? (
                    <tr>
                      <td colSpan="9" className="text-center">
                        Aucun fichier n'a été enregistré.
                      </td>
                    </tr>
                  ) : (
                    nivo &&
                    nivo.map((utili, index) => (
                      // Assurez-vous que 'id' est un identifiant unique
                      <React.Fragment key={index}>
                        <tr>
                          <td className="text-noir">
                            {utili.numOrdre}
                          </td>
                          <td className="text-noir">
                            {utili.intitule}
                          </td>
                          <td className="text-noir">{utili.role}</td>
                          <td className="text-noir">
                            <Link
                              to={`/param-niveau-validation/${utili.id}`}
                              title="voir informations"
                            >
                              <div className="round-button">
                                <i className="bi bi-eye white-icon"></i>
                              </div>
                            </Link>
                            <Link
                              to={`/edit_param-nivo-valid/${utili.id}`}
                            >
                              <div
                                className="round-button"
                                title="modifier informations"
                              >
                                <i className="bi bi-pencil white-icon"></i>
                              </div>
                            </Link>
                            <div className="round-buttonr">
                              <i
                                className="bi bi-trash white-icon"
                                onClick={() => supprimer(utili.id)}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default ListeNivoValid;
