import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Navbar from "../Nav";

function ListeEsp() {

  const listeURL = `${process.env.REACT_APP_API_URL}/esp/list`;
  const suprimeURL = `${process.env.REACT_APP_API_URL}/esp`;
  const [pieces, setTypePiece] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const listTrieURL = `${process.env.REACT_APP_API_URL}/esp/list/`;

  const setpiecesData = async () => {
    try {
      if (!searchTerm) {
        // Si aucun terme de recherche n'est défini, récupérez toutes les données
        const response = await axios.get(listeURL);
        setTypePiece(response.data);
      } else {
        // Si un terme de recherche est défini, récupérez les données filtrées en fonction du terme spécifié
        const response = await axios.get(listTrieURL + searchTerm);
        setTypePiece(response.data);
      }
    } catch (error) {
      console.error("Erreur d'obtention de la liste des esp :" + error);
    }
  };

  useEffect(() => {
    setpiecesData();
  }, []);

  const supprimer = (id) => {
    axios
      .delete(`${suprimeURL}/${id}`) // Vous devriez concaténer l'ID à l'URL correctement
      .then((response) => {
        alert(
          "l'ESP identifiée par ID : " + id + " à été supprimé !"
        );
        window.location.reload();
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression du ESP : " + error);
      });
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6); // Nombre d'éléments par page
  // Calculez l'index de début et de fin pour l'affichage des éléments actuels sur la page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPages = pieces.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Fonction pour changer de page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calcul du nombre total de pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(pieces.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const [listegesp, setListegesp] = useState([]);
  const listURL = `${process.env.REACT_APP_API_URL}/gesp/list`;
  const setgespData = () => {
    axios
      .get(listURL)
      .then((response) => {
        setListegesp(response.data);
      })
      .catch((error) => {
        console.error("Erreur d'obtention de la liste des gesps :" + error);
      });
  };

  useEffect(() => {
    setgespData();
  }, []);


  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            {/* <Navbar /> */}
            <h4 className="title-text">Liste des Etablissements Sanitaires privés</h4>
            <div className="row">
              <div className="col-md-6 form-group mb-6">
                <Link to="/esp">
                  <button className="custom-button">
                    {" "}
                    <i className="fas fa-fw fa-plus"> </i> Ajouter
                  </button>
                </Link>
              </div>
              <div className="row">
                <div className="col-md-10 form-group mb-6">
                  <select
                    name="typeEsp"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control"
                    id="service"
                    required
                  >
                    <option value="">
                      Trier en fonction des groupes d'esp{" "}
                    </option>
                    {listegesp.map((utili, index) => (
                      <option value={utili.id} key={index}>
                        {utili.libelleGesp}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-1 form-group mb-6">
                  <button className="btn btn-primary" onClick={() => setpiecesData()}>
                    <i className="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <table
                id="example1"
                className="table table-bordered table-striped table-hover"
              >
                <thead>
                  <tr>
                    <th>N°</th>
                    <th>Nom de l'ESP</th>
                    <th>Groupe de l'ESP</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPages.length === 0 ? (
                    <tr>
                      <td colSpan="9" className="text-center">
                        Aucun ESP n'a été enregistré.
                      </td>
                    </tr>
                  ) : (
                    currentPages &&
                    currentPages.map((esp, index) => (
                      <tr key={esp.id}>
                        <td className="text-noir">{index+1}</td>
                        <td className="text-noir">{esp.libelleEsp}</td>
                        <td className="text-noir">{esp.groupeEsp.libelleGesp}</td>
                        <td className="text-noir d-flex align-items-center">
                          <Link to={"/voir-esp/" + esp.id}>
                            <div className="round-button d-flex justify-content-center align-items-center">
                              <i className="bi bi-eye white-icon"></i>
                            </div>
                          </Link>

                          <Link
                            to={"/edit-esp/" + esp.id}
                          >
                            <div className="round-button d-flex justify-content-center align-items-center">
                              <i className="bi bi-pencil white-icon"></i>
                            </div>
                          </Link>
                          <div className="round-buttonr">
                            <i
                              className="bi bi-trash white-icon"
                              onClick={() => supprimer(esp.id)}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
                <div className="pagination">
                  {pageNumbers.map((pageNumber) => (
                    <button
                      key={pageNumber}
                      onClick={() => paginate(pageNumber)}
                      className={`page-number ${pageNumber === currentPage ? "selected-page" : ""
                        }`}
                    >
                      {pageNumber}
                    </button>
                  ))}
                </div>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
}

export default ListeEsp;
