import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import Navbar from "../../Nav";

function ComplementDemande(props) {

    const token = sessionStorage.getItem("token");
    const { demandeId } = useParams();
    const [complementDTO, setComplementDTO] = useState({
        // objet: "",
        observation: "",
        demande: {
            id: demandeId
        },
    });

    const [fileFields, setFileFields] = useState([{ id: 0 }]);
    const [files, setFiles] = useState({});

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setComplementDTO(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChangeComplement = (id, e) => {
        const newFiles = Array.from(e.target.files);
        setFiles(prev => ({
            ...prev,
            [id]: newFiles[0]
        }));
    };

    const addFileField = () => {
        const newId = fileFields.length;
        setFileFields([...fileFields, { id: newId }]);
    };

    const removeFileField = (idToRemove) => {
        if (fileFields.length > 1) {
            setFileFields(fileFields.filter(field => field.id !== idToRemove));
            setFiles(prev => {
                const newFiles = { ...prev };
                delete newFiles[idToRemove];
                return newFiles;
            });
        }
    };
    const handleSubmit = async (e) => { // Ajoutez 'async' ici
        e.preventDefault();
        const result = await Swal.fire({
            title: 'Êtes-vous sûr?',
            text: "Voulez-vous vraiment soumettre ce complement ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, soumettre!',
            cancelButtonText: 'Annuler'
        });

        if (!result.isConfirmed) {
            return; // Annuler l'envoi si l'utilisateur ne confirme pas
        }
        const data = new FormData();

        Object.keys(complementDTO).forEach(key => {
            if (typeof complementDTO[key] === 'object' && !Array.isArray(complementDTO[key])) {
                Object.keys(complementDTO[key]).forEach(nestedKey => {
                    data.append(`${key}.${nestedKey}`, complementDTO[key][nestedKey]);
                });
            } else {
                data.append(key, complementDTO[key]);
            }
        });

        Object.values(files).forEach((file) => {
            if (file) {
                data.append('files', file);
            }
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/complement/create`, data, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                //  if (response === 201) {
                //      Swal.fire("Succès", "Le complément a été enregistré avec succès", "success");
                //      navigate(-1);
                //  }
                 Swal.fire("Succès", "Le complément a été enregistré avec succès", "success");
                 navigate(-1);
            })
             .catch(error => {
                 Swal.fire("Erreur", "Une erreur est survenue lors de l'enregistrement", "error");
                 console.error("Erreur lors de l'enregistrement :", error);
             });
    };

    return (
        <div id="page-top">
            <div id="wrapper">
                {/* <Header /> Sidebar */}
                <div id="content-wrapper">
                    <Navbar />
                    
                    <div id="content">
                    
                        <div className="col-md-12">
                        <br/><br/>
                            <div className="form h-100">
                                <h3 className="title-text">Complément de la demande {complementDTO.demande.numDemande}</h3>
                                <Form onSubmit={handleSubmit}>
                                    {/* <Form.Group className="mb-3">
                                        <Form.Label className="col-form-label" style={{ color: "black" }}>
                                            Objet du complément
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="objet"
                                            value={complementDTO.objet}
                                            className="form-control"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group> */}

                                    <Form.Group className="mb-3">
                                        <Form.Label className="col-form-label" style={{ color: "black" }}>
                                            Observations
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="observation"
                                            className="form-control"
                                            value={complementDTO.observation}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <div className="mb-3">
                                        <Form.Label className="col-form-label" style={{ color: "black" }}>
                                            Dossier(s) de complément
                                        </Form.Label>
                                        
                                        {fileFields.map((field) => (
                                            <div key={field.id} className="d-flex align-items-center mb-2">
                                                <input
                                                    type="file"
                                                    className="form-control me-2"
                                                    accept=".pdf"
                                                    onChange={(e) => handleFileChangeComplement(field.id, e)}
                                                />
                                                {fileFields.length > 1 && (
                                                    <Button 
                                                        variant="danger" 
                                                        onClick={() => removeFileField(field.id)}
                                                        className="ms-2"
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                    </Button>
                                                )}
                                                {files[field.id] && (
                                                    <small className="text-success ms-2">
                                                        {files[field.id].name}
                                                    </small>
                                                )}
                                            </div>
                                        ))}

                                        <Button 
                                            type="button" 
                                            variant="secondary" 
                                            onClick={addFileField}
                                            className="mt-2"
                                        >
                                            <i className="bi bi-plus-circle me-2"></i>
                                            Ajouter un fichier
                                        </Button>
                                    </div>

                                    <Button type="submit" className="mt-3">Enregistrer le complément</Button>
                                </Form>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComplementDemande;
