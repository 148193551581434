import React from 'react';

function Footer() {
    return (
        <div>
                <footer className="sticky-footer bg-white">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright &copy; MS.BF 2024</span>
                                </div>
                            </div>
                        </footer>
        </div>
    );
}

export default Footer;