import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";

function Form_Nivo_Valid() {
  const baseURL = `${process.env.REACT_APP_API_URL}/nivovalidations/create`;
  const [intitule, setIntitule] = useState("");
  const [description, setDescription] = useState("");
  const [num_ordre, setNum_ordre] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(baseURL, {
        numeroOrdre: num_ordre,
        intituleNV: intitule,
        description: description
      })

      .then((response) => {
        if(response.status === 201) {
          alert(
            " " +
              intitule +
              " a été enregistré comme niveau de validation avec succès !!"
          );
          navigate("/liste_niveau_validation");
        }
      })
      .catch((error) => {
        alert("error===" + error);
      });
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="container-fluid">
            {/*  <!-- Content Row --> */}
            <div className="row">
              <div className="col-xxl-12 col-md-12">
                <div className="card info-card sales-card">
                  <div className="container">
                    <div className="row align-items-stretch no-gutters contact-wrap">
                      <div className="col-xxl-12 col-md-10">
                        <div className="form h-100">
                          <h2 className="title-text">
                            Ajouter Niveau de Validation
                          </h2>
                          <Form
                            onSubmit={handleSubmit}
                            className="mb-5"
                            noValidate
                          >
                            <div className="row">
                              <div className="col-md-6 form-group mb-6">
                                <label className="col-form-label">
                                  Intitulé *
                                </label>
                                <Form.Control
                                  type="text"
                                  value={intitule}
                                  onChange={(e) => setIntitule(e.target.value)}
                                  className="form-control"
                                  id="intitule"
                                  required
                                />
                              </div>
                              <div className="col-md-6 form-group mb-5">
                                <label className="col-form-label">
                                  Numero d'ordre *
                                </label>
                                <Form.Control
                                  type="text"
                                  value={num_ordre}
                                  onChange={(e) => setNum_ordre(e.target.value)}
                                  className="form-control"
                                  id="numordre"
                                  required
                                />
                              </div>

                              <div className="col-md-12 form-group mb-5">
                                <label className="col-form-label">
                                  Description du niveau de validation 
                                </label>
                                <Form.Control
                                  type="text"
                                  value={description}
                                  onChange={(e) => setDescription(e.target.value)}
                                  className="form-control"
                                  id="description"
                                  
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 form-group">
                                <Button
                                  className="btn btn-primary w-100"
                                  type="submit"
                                >
                                  {" "}
                                  <i className="fas fa-save"></i> Enregistrer
                                </Button>
                                <span className="submitting"></span>
                              </div>
                            </div>
                          </Form>

                          <div id="form-message-warning mt-4"></div>
                          <div id="form-message-success">
                            Veuillez Remplir le Formulaire
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="contact-info h-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_Nivo_Valid;
