import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import { AiOutlineWarning } from 'react-icons/ai'; // Exemple d'icône

import Head from "./Head";

function Form_Activation() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const baseURL = `${process.env.REACT_APP_API_URL}/users/activate`;

  const [activationCode, setActivationCode] = useState(["", "", "", "", "", ""]);

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCode = [...activationCode];
      newCode[index] = value;
      setActivationCode(newCode);

      // Déplacer le focus vers l'input suivant
      if (value && index < 5) {
        const nextInput = document.getElementById(`activation-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };
  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !activationCode[index] && index > 0) {
      // Déplacer le focus vers l'input précédent lors de la suppression
      const prevInput = document.getElementById(`activation-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError("");
  
    const code = activationCode.join("");
  
    try {
      const response = await axios.post(baseURL, { code: code });
      
      // Si le code HTTP est 200, on considère que l'activation a réussi
      if (response.status === 200 && response.data.message === "Activation réussie !") {
        toast.success("Votre compte a été activé avec succès !!");
        navigate("/login");
      } else {
        setError("Une erreur inattendue s'est produite. Veuillez réessayer.");
        toast.error("Une erreur inattendue s'est produite. Veuillez verifier le code que vous avez saisi.");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      } else if (error.request) {
        setError("Impossible de communiquer avec le serveur. Vérifiez votre connexion internet.");
        toast.error("Impossible de communiquer avec le serveur. Vérifiez votre connexion internet.");
      } else {
        setError("Une erreur inattendue s'est produite. Veuillez réessayer.");
        toast.error("Une erreur inattendue s'est produite. Veuillez réessayer.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  





  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          <div className="container-fluid">
            <Head />
            {/*  <!-- Content Row --> */}
            <div className="content">
              <div className="row">
                <div className="container">
                  <br></br>
                  <div className="row align-items-stretch no-gutters contact-wrap">
                    <div className="col-md-11">
                      <div className="form h-100">
                      <h2 className="title-text">Activation du Compte </h2>

                        <br></br>
                        <Form
                          onSubmit={handleSubmit}
                          className="mb-5"
                          noValidate
                        >
                          <div className="row">


                            <div className="col-md-12 form-group mb-5 d-flex justify-content-center align-items-center">
                              <div>
                                <label className="col-form-label" style={{ color: "black" }}>
                                  <i className="fas fa-lock fa-fw"></i> Code d'activation : &nbsp;&nbsp;&nbsp;&nbsp;
                                </label>
                              </div>
                              <div className="activation-code-input">
                                {activationCode.map((digit, index) => (
                                  <input
                                    key={index}
                                    id={`activation-input-${index}`}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    className="activation-digit-input"
                                  />
                                ))}
                              </div>
                            </div>

                          </div>

                          &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <Button className="btn btn-primary btn-block" onClick={handleSubmit} style={{ margin: '0 auto', width: '400px' }} disabled={loading || isLoading} ><i className="fas fa-save fa-fw"></i>   {isLoading ? "Activation en cours..." : "Activation"}</Button>


                        </Form>

                        <div id="form-message-warning mt-4"></div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_Activation;