import React from "react";


const Boutons = ({ buttons, selectedButton, handleButtonClick }) => {
  return (
    <div>
      {buttons.map((button) => (
        <button
          key={button.id}
          className={`custom-button ${
            selectedButton === button.code ? "selected" : ""
          }`}
          onClick={() => handleButtonClick(button.code)}
        >
          <b>{button.label}</b>
        </button>
      ))}
    </div>
  );
};

export default Boutons;
