import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ErrorMessage from "../ErrorMessage";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";

function Form_user_role() {
  const editURL = `${process.env.REACT_APP_API_URL}/authentification/roles`;
  const [role, setRole] = useState([]);

  const setRolesData = () => {
    axios
      .get(editURL)
      .then((response) => {
        setRole(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention des données :" + error);
      });
  };
  useEffect(() => {
    setRolesData();
  }, []);

  const userURL = `${process.env.REACT_APP_API_URL}/authentification/users`;
  const [utilisateurs, setTypeDemande] = useState([]);

  const setUtilisateursData = () => {
    axios
      .get(userURL)
      .then((response) => {
        setTypeDemande(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention des données :" + error);
      });
  };
  useEffect(() => {
    setUtilisateursData();
  }, []);
  const navigate = useNavigate();

  const [login, setUsername] = useState("");
  const [roles, setRoles] = useState("");

  const [error, setError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("Login:", login);
    console.log("Role:", role);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/authentification/addroletouser?login=${login}&rolename=${roles}`
      )
      .then((response) => {
        alert(" " + login + " enregistré avec succès !!");
        navigate("/liste_user_metier");
      })
      .catch((error) => {
        setError("Erreur : " + error.message);
      });
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}

          <div className="container-fluid">
            {/*  <!-- Content Row --> */}
            <br></br><br></br>
            <div className="row">
              <div className="col-xxl-4 col-md-12">
                <div className="card info-card sales-card">
                  <div className="container">
                    <div className="row align-items-stretch no-gutters contact-wrap">
                      <div className="col-md-10">
                        <div className="form h-100">
                          <h2 className="title-text">
                            Ajouter un Acteur Metier{" "}
                          </h2>
                          {error && (
                            <ErrorMessage
                              message={error}
                              onClose={() => setError(null)}
                            />
                          )}

                          <h4 className="titles-text">Affecter Roles</h4>

                          <br></br>
                          <Form
                            onSubmit={handleSubmit}
                            className="mb-5"
                            noValidate
                          >
                            <div className="row">
                              <div className="col-md-12 form-group mb-5">
                                <label
                                  className="col-form-label"
                                  style={{ color: "black" }}
                                >
                                  login
                                </label>
                                <select
                                  value={login}
                                  onChange={(e) => setUsername(e.target.value)}
                                  className="form-control"
                                  id="service"
                                  required
                                >
                                  {" "}
                                  {utilisateurs.map((utili, index) => (
                                    <option value={utili.login}>
                                      {utili.nom} {utili.prenom}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="col-md-12 form-group mb-5">
                                <label
                                  className="col-form-label"
                                  style={{ color: "black" }}
                                >
                                  Role *
                                </label>

                                <select
                                  value={roles}
                                  onChange={(e) => setRoles(e.target.value)}
                                  className="form-control"
                                  id="service"
                                  required
                                >
                                  {role.map((rol, index) => (
                                    <option key={index} value={rol.intitule}>
                                      {rol.intitule}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <Button onClick={handleSubmit}>Enregistrer</Button>
                          </Form>

                          <div id="form-message-warning mt-4"></div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="contact-info h-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_user_role;
