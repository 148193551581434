/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import armoiries from "../img/armoiries.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMenuContext } from "./MenuContext";
import UserService from "./service/UserService";

function Header() {

  const editURL = `${process.env.REACT_APP_API_URL}/demandes/user-demandes`;
  const [utilisateurs, setTypeDemande] = useState([]);

  const setUtilisateursData = () => {
    axios
      .get(editURL)
      .then((response) => {
        setTypeDemande(response.data);
      })
      .catch((error) => {
        console.error("Erreur d'optension des données :" + error);
      });
  };

  useEffect(() => {
    setUtilisateursData();
  }, []);

  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );
  const [role, setRole] = useState([]);
  //const userRole = role.map((role) => role.intitule);
  // Définissez les rôles autorisés pour chaque section du menu
  //const allowedRolesDashboard = ["administrateur"];
  //const allowedRolesIntroduireDemande = ["bornage_reception", "administrateur"];
  const userRole = UserService.getRoleInToken();

  // Définissez les rôles autorisés pour chaque section du menu
  const allowedRoleAdmin = 'ADMIN'
  const allowedRoleAdminDsps = 'DSPS'
  const allowedRoleAdminOp = 'OP'
  const allowedRoleAdminCommission = 'COMMISSION'
  const allowedRoleUser = 'USER';



  // Ajoutez d'autres sections du menu avec les rôles autorisés nécessaires...

  // Vérifiez si l'utilisateur a le rôle autorisé pour afficher chaque section du menu
  const showDashboard = allowedRoleAdmin

  const showIntroduireDemande = allowedRoleUser

  // Ajoutez d'autres conditions pour chaque section du menu...
  const [isLinkHovered, setIsLinkHovered] = useState(false);

  // Fonction pour gérer le survol des liens
  const handleLinkHover = () => {
    setIsLinkHovered(!isLinkHovered);
  };

  const token = sessionStorage.getItem("token"); // Récupérer le token du stockage local

  // Vérifier si l'utilisateur a un rôle autorisé

  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.removeItem("token");
    window.location.href = "/login";
  };
  const changeStyle = () => {
    if (
      style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const sidebarContainerStyle = {
    maxHeight: "100vh",
    overflowY: "auto",
    position: "auto", // Mettez "fixed" pour que la barre reste fixée en haut
    width: "100%",
    zIndex: 1000,
  };
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };
  // const sidebarContainerStyle = {
  //   maxHeight: "100vh", // La barre latérale s'étendra jusqu'à 100% de la hauteur de la fenêtre
  //   overflowY: "auto", // Ajoute une barre de défilement si le contenu dépasse la hauteur de la fenêtre
  // };

  // Fonction pour envoyer le token au serveur
  const serverUrl = `${process.env.REACT_APP_API_URL}/users/profil`;
  axios.defaults.debug = true;
  const sendTokenToServer = async () => {
    try {
      // Vérifier si le token existe
      if (!token) {
        console.error("Token not found in sessionStorage");
        return;
      }

      // Envoyer la requête au serveur avec le token inclus dans les en-têtes
      const response = await axios.get(serverUrl, {
        headers: {
          Authorization: `${token}`, // Inclure le token dans l'en-tête Authorization
        },
      });

      // Vérifier la réponse du serveur
      if (response) {
        // Convertir la réponse en JSON
        const userData = response;

        // Assurez-vous que userData.appRoles existe et est un tableau
        const userRole = userData.role.id

        // Mettre à jour l'état ou effectuer d'autres actions avec les rôles de l'utilisateur
        setRole(userRole);



        console.log("Token sent successfully");
      } else {
        console.error("Error sending token to server:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending token to server:", error.message);
    }
  };

  // Appeler la fonction lorsque le composant est monté
  useEffect(() => {
    sendTokenToServer();
  }, []); // La dépendance vide assure que cela ne s'exécute qu'une seule fois lors du montage du composant

  // Le reste de votre composant...

  const { isMenuOpen, activeLink, handleMenuToggle, handleSetActiveLink } =
    useMenuContext();

  useEffect(() => {
    // Mettez à jour activeLink lorsqu'il y a un changement d'URL
    handleSetActiveLink(window.location.pathname);
  }, []);

  return (
    <div>
      <ul /*className={style}*/ className="navbar sidebar" id="accordionSidebar" style={sidebarContainerStyle}>
        {/*  <!-- Sidebar - Brand --> */}
        <Link
          className="sidebar-brand d-flex flex-column align-items-center justify-content-center"
          href="#"
          style={{ textDecoration: 'none', color: 'inherit', fontSize: '12px' }}
        >
          <div className="sidebar-brand-icon">
            <img src={armoiries} alt="Armoiries" width="50" className="img-fluid" />
          </div>
          {/* <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={changeStyle}
          ></button> */}
          <div
            className="sidebar-brand-text text-center"
            style={{
              fontSize: '19px',
              fontWeight: 'normal',
              marginTop: '-10px',
              textTransform: 'none', // s'assure qu'il n'est pas en majuscule// rapprocher le texte de l'image
            }}
          >
            <b>Ministère de la Santé</b>
          </div>
          <div className="text-center d-none d-md-inline">

          </div>
        </Link>
        {/*
        <hr className="sidebar-divider my-0" style={{ width: '100%' }} /> */}

        {/*  <!-- Nav Item - Dashboard --> */}

        <li className={`nav-item ${isLinkHovered ? "hovered-link" : ""}`}>
          <Link
            className={`nav-link ${activeLink === "/admin" ? "menu-actif" : ""
              }`}
            to="/admin"
            onMouseEnter={handleLinkHover}
            onMouseLeave={handleLinkHover}
          >
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Tableau de Bord</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        {showIntroduireDemande === userRole && (
          <li className="nav-item">
            <Link
              className={`nav-link collapsed ${activeLink === "/faire_demande_morale" ||
                activeLink === "/faire_demande_physique" ||
                activeLink === "/faire_demande"
                ? "menu-actif"
                : ""
                }`}
              href="#"
              data-toggle="collapse"
              data-target="#collapsePaeges"
              aria-expanded="true"
              aria-controls="collapsePaeges"
            >
              <i className="fas fa-file"></i>
              <span>Création de Demande</span>
            </Link>
            <div
              id="collapsePaeges"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                {/* <h6 className="collapse-header">Introduire Demande</h6> */}
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                <Link
                  className={`collapse-item ${activeLink === "/faire_demande_physique" ? "active-link" : ""
                    }`}
                  to="/faire_choix"
                  onClick={() => handleSetActiveLink("/faire_demande")}>
                  <b>Faire une demande </b>{" "}
                </Link>
              </div>
            </div>
          </li>)}

        {(userRole === allowedRoleAdmin || userRole === allowedRoleAdminDsps || userRole === allowedRoleAdminOp || userRole === allowedRoleAdminCommission) && (
          <li className="nav-item">
            <Link
              className={`nav-link collapsed ${activeLink === "/liste_demande_recu" ||
                activeLink === "/liste_demande_a_traiter" ||
                activeLink === "/liste_demande_termine" ||
                activeLink === "/liste_demande_retire"
                ? "menu-actif"
                : ""
                }`}
              href="#"
              data-toggle="collapse"
              data-target="#collapsePage"
              aria-expanded="true"
              aria-controls="collapsePage"
              onClick={(e) => {
                e.preventDefault(); // Empêche le comportement de lien par défaut
                handleMenuToggle();
              }}
            >
              <i className="fas fa-fw fa-folder"></i>
              <span>Gestion de Demande</span>
            </Link>
            <div
              id="collapsePage"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              {/* <div className="bg-white py-2 collapse-inner rounded">
         <Link
          className={`collapse-item ${
            activeLink === "/liste_demande_recu" ? "active-link" : ""
          }`}
          to="/liste_demande_recu"
          onClick={() => handleSetActiveLink("/liste_demande_recu")}
        >
          demandes
        </Link> 
      </div> */}
              <div className="bg-white py-2 collapse-inner rounded">
                <Link
                  className={`collapse-item ${activeLink === "/liste_demande_a_traiter" ? "active-link" : ""
                    }`}
                  to="/liste_demande_a_traiter"
                  onClick={() => handleSetActiveLink("/liste_demande_a_traiter")}
                >
                  demandes à traiter
                </Link>
              </div>
              <div className="bg-white py-2 collapse-inner rounded">
                <Link
                  className={`collapse-item ${activeLink === "/liste_demande_termine" ? "active-link" : ""
                    }`}
                  to="/maintenance"
                  onClick={() => handleSetActiveLink("/liste_demande_termine")}
                >
                  demandes terminées
                </Link>
              </div>
            </div>
          </li>
        )}

        {/*   <!-- Heading --> */}

        {showIntroduireDemande === userRole && (
          <li className="nav-item">
            <Link
              className={`nav-link collapsed ${activeLink === "/liste_demande_user" ? "menu-actif" : ""
                }`}
              href="#"

              aria-expanded="true"
              aria-controls="collapsePages"
              to="/liste_demande_user"
            >
              <i className="fas fa-money-bill"></i>
              <span>Suivre mes demandes</span>
            </Link>

          </li>)}

        <hr className="sidebar-divider my-0" />
        {/* <div className="sidebar-heading">Rapports</div> */}
        {showDashboard === userRole && (
          <li className="nav-item">
            <Link
              className={`nav-link collapsed ${activeLink === "/generer_rapport" ? "menu-actif" : ""
                }`}
              href="#"
              data-toggle="collapse"
              data-target="#collapsePag"
              aria-expanded="true"
              aria-controls="collapsePag"
            >
              <i className="fas fa-file-alt"></i>
              <span>Rapports</span>
            </Link>
            <div
              id="collapsePag"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <Link
                  className={`collapse-item ${activeLink === "/generer_rapport" ? "active-link" : ""
                    }`}
                  to="/generer_rapport"
                  onClick={() => handleSetActiveLink("/generer_rapport")}
                >
                  Générer un Rapport
                </Link>
              </div>
            </div>
          </li>
        )}
        <hr className="sidebar-divider my-0" />
        {/* {showDashboard && <div className="sidebar-heading">Paramètres</div>} */}
        {showDashboard === userRole && (
          <li className="nav-item">
            <Link
              className={`nav-link collapsed ${activeLink === "/liste_type_piece_E" ||
                activeLink === "/liste_niveau_validation" ||
                activeLink === "/type_piece_E" ||
                activeLink === "/edit_param-nivo-valid/1"
                ? "menu-actif"
                : ""
                }`}
              href="#"
              data-toggle="collapse"
              data-target="#collapseTw"
              aria-expanded="true"
              aria-controls="collapseTw"
            >
              <i className="fas fa-fw fa-sitemap"></i>
              <span>Worflows</span>
            </Link>
            <div
              id="collapseTw"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <Link
                  to="/liste_type_piece_E"
                  className={`collapse-item ${activeLink === "/liste_type_piece_E" ? "active-link" : ""
                    }`}
                  href="cards.html"
                  onClick={() => handleSetActiveLink("/liste_type_piece_E")}
                >
                  Liste des pièces
                </Link>

                <Link
                  to="/liste_niveau_validation"
                  className={`collapse-item ${activeLink === "/liste_niveau_validation"
                    ? "active-link"
                    : ""
                    }`}
                  href="cards.html"
                  onClick={() =>
                    handleSetActiveLink("/liste_niveau_validation")
                  }
                >
                  Niveaux de Validation
                </Link>
              </div>
            </div>
          </li>
        )}
        {showDashboard === userRole && (
          <li className="nav-item">
            <Link
              className={`nav-link collapsed ${activeLink === "/liste_dmd_t" ||
                activeLink === "/liste_type_piece" ||
                activeLink === "/liste_region" ||
                activeLink === "/liste_province" ||
                activeLink === "/liste_district" ||
                activeLink === "/liste_commune" ||
                activeLink === "/liste_gesp" ||
                activeLink === "/liste_esp" ||
                activeLink === "/demande_type"
                ? "menu-actif"
                : ""
                }`}
              href="#"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <i className="fas fa-fw fa-cog"></i>
              <span>Configurations</span>
            </Link>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                {/* <h6 className="collapse-header">Types de demandes:</h6> */}

                {/* <Link
                  className={`collapse-item ${activeLink === "/liste_dmd_t" ? "active-link" : ""
                    }`}
                  to="/liste_dmd_t"
                  onClick={() => handleSetActiveLink("/liste_dmd_t")}
                >
                  Types de demande
                </Link>
                {/* <h6 className="collapse-header">Pièce à Fournir:</h6> */}

                {/* <Link
                  className={`collapse-item ${activeLink === "/liste_type_piece" ? "active-link" : ""
                    }`}
                  to="/liste_type_piece"
                  onClick={() => handleSetActiveLink("/liste_type_piece")}
                >
                  Liste des pièces
                </Link> */}
                <Link
                  className={`collapse-item ${activeLink === "/liste_region" ? "active-link" : ""
                    }`}
                  to="/liste_region"
                  onClick={() => handleSetActiveLink("/liste_region")}
                >
                  Liste des régions
                </Link>
                <Link
                  className={`collapse-item ${activeLink === "/liste_province" ? "active-link" : ""
                    }`}
                  to="/liste_province"
                  onClick={() => handleSetActiveLink("/liste_province")}
                >
                  Liste des provinces
                </Link>
                <Link
                  className={`collapse-item ${activeLink === "/liste_district" ? "active-link" : ""
                    }`}
                  to="/liste_district"
                  onClick={() => handleSetActiveLink("/liste_district")}
                >
                  Liste des districts
                </Link>
                <Link
                  className={`collapse-item ${activeLink === "/liste_commune" ? "active-link" : ""
                    }`}
                  to="/liste_commune"
                  onClick={() => handleSetActiveLink("/liste_commune")}
                >
                  Liste des communes
                </Link>
                <Link
                  className={`collapse-item ${activeLink === "/liste_gesp" ? "active-link" : ""
                    }`}
                  to="/liste_gesp"
                  onClick={() => handleSetActiveLink("/liste_gesp")}
                >
                  Liste des Groupes d'ESP
                </Link>
                <Link
                  className={`collapse-item ${activeLink === "/liste_esp" ? "active-link" : ""
                    }`}
                  to="/liste_esp"
                  onClick={() => handleSetActiveLink("/liste_esp")}
                >
                  Liste des ESP
                </Link>

              </div>
            </div>
          </li>
        )}
        {showDashboard === userRole && (
          <li className="nav-item">
            <Link
              className={`nav-link collapsed ${activeLink === "/user_metier" ||
                activeLink === "/liste_user_metier" ||
                activeLink === "/ajoute_role" ||
                activeLink === "/liste_role"
                ? "menu-actif"
                : ""
                }`}
              data-toggle="collapse"
              data-target="#collapseUtilities"
              aria-expanded="true"
              aria-controls="collapseUtilities"
            >
              <i className="fas fa-fw fa-wrench"></i>
              <span>Paramètre Utilisateurs</span>
            </Link>
            <div
              id="collapseUtilities"
              className="collapse"
              aria-labelledby="headingUtilities"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                {/* <h6 className="collapse-header">Acteurs-metiers:</h6> */}

                <Link
                  className={`collapse-item ${activeLink === "/user_metier" ? "active-link" : ""
                    }`}
                  to="/user_metier"
                  onClick={() => handleSetActiveLink("/user_metier")}
                >
                  Ajouter un Acteur
                </Link>

                <Link
                  className={`collapse-item ${activeLink === "/liste_user_metier" ? "active-link" : ""
                    }`}
                  to="/liste_user_metier"
                  onClick={() => handleSetActiveLink("/liste_user_metier")}
                >
                  Liste des Acteurs
                </Link>
                <div className="collapse-divider"></div>
                {/* <h6 className="collapse-header">Roles</h6> */}

                <Link
                  className={`collapse-item ${activeLink === "/ajoute_role" ? "active-link" : ""
                    }`}
                  to="/ajoute_role"
                  onClick={() => handleSetActiveLink("/ajoute_role")}
                >
                  Ajouter Role
                </Link>

                <Link
                  className={`collapse-item ${activeLink === "/liste_role" ? "active-link" : ""
                    }`}
                  to="/liste_role"
                  onClick={() => handleSetActiveLink("/liste_role")}
                >
                  Liste des Roles
                </Link>
              </div>
            </div>
          </li>
        )}
        {/*  <!-- Divider --> */}
        <hr className="sidebar-divider" />
        <li className="nav-item">
          <Link
            className={`nav-link ${activeLink === "/user-metiers" ? "menu-actif" : ""
              }`}
            to="/user-metiers"
          >
            <i className="fas fa-fw fa-user"></i>
            <span>Mon Profil</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />
        <li className="nav-item">
          <a className="nav-link" onClick={handleLogout}>
            <i className="fas fa-fw fa-power-off"></i>
            <span>Se déconnecter</span>
          </a>
        </li>
        <hr className="sidebar-divider d-none d-md-block" />
      </ul>

      {showModal && (
        <div className="modal">
          {" "}
          {/*className est modale en production */}
          <div className="modal-content">
            {" "}
            {/*className est modal-contente en production */}
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h4 className="text-center" style={{ color: "black" }}>
              {" "}
              <img src={armoiries} width="50" alt="Armoiries" />
              <b>Choisir le type de Demande d'agrément</b>
            </h4>
            <br></br>
            {utilisateurs.length === 0 ? (
              <tr>
                <td colSpan="9">Aucune demande n'a été enregistrée</td>
              </tr>
            ) : (
              utilisateurs &&
              utilisateurs.map((utili) => (
                <ul className="custom-list">
                  <li key={utili.id}>
                    <h4>
                      {" "}
                      <Link
                        to={
                          "/faire_demande/12d38g8-/" + utili.id + "-a3894DI39"
                        }
                      >
                        {utili.intituleSousType}
                      </Link>
                    </h4>
                  </li>
                </ul>
              ))
            )}
          </div>
        </div>
      )}

    </div>
  );
}

export default Header;