import React, { useState } from "react";

import Foot from "../Foot";
import Head from "../Head";

const Convention = () => {
  const [activeAccordion, setActiveAccordion] = useState("");
  const [footerOpen, setFooterOpen] = useState(false);

  const handleAccordion = (accordionName) => {
    setActiveAccordion(activeAccordion === accordionName ? "" : accordionName);

    setFooterOpen(activeAccordion === accordionName ? false : true);
  };



  return (
    <div>
      <div>
        {/* Head */}
        {<Head />}
      </div>

      <div className="content">
        {/* Contenu scrollable */}
        <br /><br />
        <h3 style={{ textAlign: 'center', color: "black" }}>
          <b>COMPOSITION DU DOSSIER DE DEMANDE D'OUVERTURE/EXPLOITATION</b>
        </h3><br />
        <section id="apropos" className="d-flex flex-column align-items-start accordion">
          <div className="container" id="confidentialite" style={{ color: '#333' }}>
            <h2 style={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: '#0056b3',
              marginBottom: '1rem'
            }} className="accordion-header" onClick={() => handleAccordion("demande1")}>
              DEMANDE DE CONVENTION POUR LA PROMOTION DE LA SANTE
              <i className={`bi ${activeAccordion === "demande1" ? "bi-chevron-up" : "bi-chevron-down"}`}></i>
            </h2>
            <div className={`accordion-content ${activeAccordion === "demande1" ? "active" : ""}`}>
              <p style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#0056b3',
                marginBottom: '1rem'
              }}>
                Les ONG, associations ou fondations nationales sollicitant une convention de partenariat avec le Ministère de la Santé pour mener des activités de promotion de la santé doivent fournir les documents suivants :
              </p>
              <ul style={{ paddingLeft: '20px', color: '#333' }}>
                <li>• Récépissé de l'association ou arrêté autorisant l'exercice pour les ONG/fondations ;</li>
                <li>• Attestation de renouvellement du récépissé, le cas échéant ;</li>
                <li>• Statuts et règlement intérieur, visés par le Ministère de l'administration territoriale ;</li>
                <li>• Projet de convention de promotion de la santé en trois exemplaires signés ;</li>
                <li>• Fiche de renseignements dûment remplie ;</li>
                <li>• Photocopie légalisée du document d'identité du représentant légal de la structure demandeuse ;</li>
                <li>• Tout autre document pertinent, notamment le justificatif du titre honorifique du représentant légal, le cas échéant.</li>
              </ul><br />

              <p style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#0056b3',
                marginBottom: '1rem'
              }}>
                Les ONG, associations ou fondations nationales sollicitant un renouvellement de la convention doivent fournir, outre les documents cités à l'article précédent,
                les pièces suivantes :
              </p>
              <ul style={{ paddingLeft: '20px', color: '#333' }}>
                <li>• la copie de la convention échue ;</li>
                <li>• le rapport global d'activités de sa mise en œuvre.</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="apropos" className="d-flex flex-column align-items-start accordion">
          <div className="container" id="confidentialite" style={{ color: '#333' }}>
            <h2 style={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: '#0056b3',
              marginBottom: '1rem'
            }} className="accordion-header" onClick={() => handleAccordion("demande2")}>
              DEMANDE DE CONVENTION POUR L'OUVERTURE ET L'EXPLOITATION D'UN ETABLISSEMENT SANITAIRE PRIVE DE SOINS
              <i className={`bi ${activeAccordion === "demande2" ? "bi-chevron-up" : "bi-chevron-down"}`}></i>
            </h2>
            <div className={`accordion-content ${activeAccordion === "demande2" ? "active" : ""}`}>
              <p style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#0056b3',
                marginBottom: '1rem'
              }}>
                Les ONG, associations ou fondations sollicitant une convention de partenariat avec le Ministère en charge de la santé pour l'ouverture d'un établissement sanitaire privé de soins doivent fournir les documents suivants :
              </p>
              <ul style={{ paddingLeft: '20px', color: '#333' }}>
                <li>• le récépissé de l'association ou l'arrêté portant autorisation d'exercer pour les ONG/Associations/Fondations étrangères ;</li>
                <li>• l'attestation de renouvellement du récépissé ou de l'arrêté portant autorisation d'exercer le cas échéant ;</li>
                <li>• les statuts et le règlement intérieur qui ont servi à la création de la structure et visés par l'autorité ayant délivré le récépissé ou l'autorisation d'exercer au Burkina Faso ;</li>
                <li>• l'arrêté portant autorisation de création de l'établissement sanitaire privé de soins ;</li>
                <li>• le projet de convention pour l'ouverture et l'exploitation de l'établissement sanitaire privé de soins en 3 exemplaires signés par le responsable de la structure demanderesse ;</li>
                <li>• la fiche de renseignement dûment remplie ;</li>
                <li>• la photocopie légalisée du document d'identité du représentant légal de la structure demanderesse (signataire) de la convention ;</li>
                <li>• tout autre document notamment le justificatif du titre honorifique du représentant légal de la structure demanderesse de la convention s'il y a lieu.</li>
              </ul><br />

              <p style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#0056b3',
                marginBottom: '1rem'
              }}>
                Les ONG /associations /fondations sollicitant la poursuite du bénéfice de leur convention antérieure doivent fournir outre les pièces citées à l'article précèdent les documents suivants :
              </p>
              <ul style={{ paddingLeft: '20px', color: '#333' }}>
                <li>• l'arrêté portant autorisation d'ouverture et d'exploitation de l'établissement sanitaire privé de soins ;</li>
                <li>• la copie de la convention échue ;</li>
                <li>• le rapport global d'activités de la mise en œuvre de la convention ;</li>
                <li>• les trois (03) derniers rapports mensuels d'activités (RMA) visés par
                  le médecin chef du district sanitaire pour la convention de poursuite d'exploitation de l'établissement sanitaire privé de soins ;</li>
                <li>• trois (03) exemplaires de projet de convention pour la poursuite de l'exploitation signés par le représentant de la structure.</li>
              </ul><br />
              <p style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#0056b3',
                marginBottom: '1rem'
              }}>
                Les associations/ fondations/ ONG ayant changé le statut de leur formation sanitaire conventionné et souhaitant la poursuite du bénéfice de leur convention antérieure doivent fournir les documents suivants :
              </p>
              <ul style={{ paddingLeft: '20px', color: '#333' }}>
                <li>• une copie de la convention antérieure </li>
                <li>• l'arrêté portant transformation de l'établissement sanitaire privé de soins.</li>
              </ul>
            </div>

          </div>
        </section>



        <section id="apropos" className="d-flex flex-column align-items-center accordion">
          <div className="container" id="confidentialite" style={{ color: '#333' }}>
            <h2 style={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: '#0056b3',
              marginBottom: '1rem'
            }} className="accordion-header" onClick={() => handleAccordion("demande3")}>
              DEMANDE DE CONVENTION POUR LES ASSOCIATIONS/FONDATIONS/ONG ETRANGERES
              <i className={`bi ${activeAccordion === "demande3" ? "bi-chevron-up" : "bi-chevron-down"}`}></i>
            </h2>
            <div className={`accordion-content ${activeAccordion === "demande3" ? "active" : ""}`}>
              <p style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#0056b3',
                marginBottom: '1rem'
              }}>
                Les associations/ fondations/ ONG étrangères, sollicitant une convention de partenariat avec le Ministère en charge de la santé pour mener les activités dans le domaine de la santé doivent fournir les documents suivants :
              </p>
              <ul style={{ paddingLeft: '20px', color: '#333' }}>
                <li>• une photocopie légalisée de l'arrêté portant autorisation d'exercer au Burkina Faso en vigueur ;</li>
                <li>• les statuts et/ou règlement intérieur visés par l'autorité ayant délivré l'autorisation d'exercer au Burkina Faso ;</li>
                <li>• le projet de convention de partenariat en 5 exemplaires signés ;</li>
                <li>• le programme d'investissement validé en 5 exemplaires ;</li>
                <li>• la photocopie légalisée du document d'identité du représentant légal de
                  la structure ;</li>
                <li>• tout autre document justifiant du titre honorifique du responsable de la structure demanderesse de la convention s'il y a lieu.</li>
              </ul><br />
              <p style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#0056b3',
                marginBottom: '1rem'
              }}>
                Les associations/fondations/ONG étrangères sollicitant un renouvellement de
                la convention doivent fournir, outre les pièces énumérées à l'article ci-dessus,
                les documents suivants :
              </p>
              <ul style={{ paddingLeft: '20px', color: '#333' }}>
                <li>• la copie de la convention antérieure ; </li>
                <li>• le rapport global d'activités de la mise en œuvre de la convention ;</li>
                <li>• le projet de renouvellement de convention de partenariat en 5 exemplaires signés.</li>
              </ul>

            </div>

          </div>

        </section>


      </div>

      <div>
        {/* Footer */}
        <Foot />
      </div>

    </div>



  );

}

export default Convention;