import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate

const ForgotPasswordModal = ({ show, handleClose }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false); // État pour vérifier si l'email a été envoyé
  const baseURL = `${process.env.REACT_APP_API_URL}/users/pwd_forget`;
  const navigate = useNavigate(); // Initialiser useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
      const response = await axios.post(`${baseURL}?email=${encodeURIComponent(email)}`);

      if (response.status === 200) {
        setSuccessMessage('Instructions de réinitialisation envoyées à votre email.');
        setEmailSent(true); // Met à jour l'état lorsque l'email est envoyé
        setEmail(''); // Réinitialiser le champ email
        navigate('/reset-password');
      }
    } catch (err) {
      setError('Erreur lors de l\'envoi des instructions. Veuillez réessayer.');
      console.error(err);
    }
  };

  const handleResetPassword = () => {
    // Redirection vers le composant de réinitialisation
    navigate('/reset-password'); // Remplace '/reset-password' par le chemin de ton composant
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-dialog-centered" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Réinitialiser le Mot de Passe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <p>Veuillez entrer votre adresse email pour recevoir des instructions de réinitialisation.</p>
          <input
            type="email"
            className="form-control"
            placeholder="Votre email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={emailSent} // Désactive le champ si l'email est déjà envoyé
          />
          {error && <div className="text-danger mt-2">{error}</div>}
          {successMessage && <div className="text-success mt-2">{successMessage}</div>}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuler
        </Button>
        {emailSent ? (
          <>
            <Button variant="primary" onClick={handleResetPassword}>
              Réinitialiser mon mot de passe
            </Button>
            {/*<Button variant="primary" onClick={handleSubmit}>
              Envoyer le mail à nouveau
            </Button>*/}
          </>
        ) : (
          <Button variant="primary" onClick={handleSubmit}>
            Envoyer
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ForgotPasswordModal;