import React from 'react';
import { Routes, Route,  } from 'react-router-dom';
import Head from './Head';

import Foot from './Foot';
import Propos from './propos';


const TemplateLayouts = () => {
    return (
        <div>
        { <Head />}
        <Routes>
          <Route exact path="/" element={<Propos />} />


        </Routes>
        
        { <Foot />}
        
      </div>
    );
  };
  
  export default TemplateLayouts;