/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import armoiries from "../../img/armoiries.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import PDFPreviewModal from './PDFPreviewModal';
import DemandeInfoComponent from './DemandeInfoComponent';
import { toast } from "react-toastify";
import DemandeService from "../../service/DemandeService";
import Navbar from "../../Nav";
import MultiSelectWithCheckbox from "./MultiSelectWithCheckbox";
import UserService from "../../service/UserService";
import { GlobalWorkerOptions } from 'pdfjs-dist';

GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.js';

function Info_dmd_rcue() {

  const editURL = `${process.env.REACT_APP_API_URL}/demandes/`;

  //const { id } = useParams();
  const { demandeId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showModaleCommission, setshowModaleCommission] = useState(false);
  const [showModaleArrete, setshowModaleArrete] = useState(false);
  const [showModaleArreteCharge, setshowModaleArreteCharge] = useState(false);
  const [showModaleOP, setShowModaleOP] = useState(false);
  const [showModaleDsps, setShowModaleDsps] = useState(false);
  const [showModaleLettreRejet, setshowModaleLettreRejet] = useState(false);
  const param = useParams();
  const [userDemande, setUserDemande] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = sessionStorage.getItem("token");
  const closeModal = () => {
    setShowModal(false);
  };

  const closeModaleCommission = () => {
    setshowModaleCommission(false);
  };



  const closeModaleOP = () => {
    setShowModaleOP(false);
  };

  const closeModaleDsps = () => {
    setShowModaleDsps(false);
  };
  const [motivation, setmotivation] = useState("");
  const [secteur, setSecteur] = useState("");
  const [signataire, setSignataire] = useState("");
  const [parcelle, setParcelle] = useState("");
  const [lot, setLot] = useState("");
  const [section, setSection] = useState("");
  const [dateDebutSession, setdateDebutSession] = useState("");
  const [arreteCreationDate, setarreteCreationDate] = useState("");
  const [annee, setannee] = useState("");
  const [dateFinSession, setDateFinSession] = useState("");
  const [typeAvisOI, settypeAvisOI] = useState("");
  const [typeAvisOC, settypeAvisOC] = useState("");
  const [avis, setAvis] = useState("");
  const [typeFile, setTypeFile] = useState("");
  const [numeroDocument, setNumeroDocument] = useState("");
  const [dateSignatureDocument, setdateSignatureDocument] = useState("");
  const [file, setFile] = useState(null);

  const openModal = () => {
    setShowModal(true);
  };

  const openModaleCommission = () => {
    setshowModaleCommission(true);
  };

  const openModaleArrete = () => {
    setshowModaleArrete(true);
  };

  
  const openModaleLettreRejet = () => {
    setshowModaleLettreRejet(true);
  };

  const openModaleArreteCharge = () => {
    setshowModaleArreteCharge(true);
  };

  const closeModaleArreteCharge = () => {
    setshowModaleArreteCharge(false);
  };
  
  const closeModaleArrete = () => {
    setshowModaleArrete(false);
  };

  const closeModaleLettreRejet = () => {
    setshowModaleLettreRejet(false);
  };

  const closeModale = () => {
    setshowModaleArrete(false);
  };


  const openModaleDsps = () => {
    setShowModaleDsps(true);
  };
  const openModaleOp = () => {
    setShowModaleOP(true);
  };

  const [ordresSelectionnes, setOrdresSelectionnes] = useState([]);

  const handleOrdreSelection = (e, ordre) => {
    if (e.target.checked) {
      setOrdresSelectionnes([...ordresSelectionnes, ordre]);
    } else {
      setOrdresSelectionnes(ordresSelectionnes.filter(o => o !== ordre));
    }
  };

  const serverUrl = `${process.env.REACT_APP_API_URL}/users/profil`;
  axios.defaults.debug = true;
  const sendTokenToServer = async () => {
    try {
      // Vérifier si le token existe
      if (!token) {
        console.error("Token not found in sessionStorage");
        return;
      }

      // Envoyer la requête au serveur avec le token inclus dans les en-têtes
      const response = await axios.get(serverUrl, {
        headers: {
          Authorization: `${token}`, // Inclure le token dans l'en-tête Authorization
        },
      });

      // Vérifier la réponse du serveur
      if (response) {
        // Convertir la réponse en JSON
        const userData = response;

        console.log("Token sent successfully ", token);
      } else {
        console.error("Error sending token to server:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending token to server:", error.message);
    }
  };

  // Appeler la fonction lorsque le composant est monté
  useEffect(() => {
    sendTokenToServer();
  }, []);

  // Assurez-vous que le chemin d'importation est correct

  const [isAttachmentsExpanded, setAttachmentsExpanded] = useState(true);
  const [selectedPieceJointe, setSelectedPieceJointe] = useState(null);


  const openFileInModal = (pieceJointe) => {
    setSelectedPieceJointe(pieceJointe);
    setIsModalOpen(true);
  };

  const closeFileModal = () => {
    setIsModalOpen(false);
    setSelectedPieceJointe(null);
  };



  const getDemande = async (demandeId) => {
    try {
      const response = await DemandeService.getById(demandeId, token);
      console.log("user_demandes:", response);
      setUserDemande(response);
    } catch (error) {
      console.error("Error to fetch demandes list: :", error.message);
    }
  };

  useEffect(() => {
    if (demandeId) {
      getDemande(demandeId);
    }
  }, [demandeId]);


  /* Style pour la modale */
  const modalStyles = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  /* Style pour le contenu de la modale */
  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '100%',
    maxHeight: '100%', /* Fixe la hauteur maximale à 80% de la fenêtre */
    overflowY: 'auto', /* Active le scroll si le contenu dépasse la hauteur */
  };

  /* Style pour le conteneur du formulaire */
  const formContainerStyles = {
    maxHeight: '70vh', /* Limite la hauteur du formulaire à 70% de la hauteur de la fenêtre */
    overflowY: 'auto', /* Active le défilement vertical */
  };






  const formatDate = (dateStr) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };

    const token = sessionStorage.getItem("token");

    return new Date(dateStr).toLocaleDateString(undefined, options);
  };

  // Mappage des avis en typeAvisOI
  const mapAvisToTypeAvisOI = (selectedAvis) => {
    switch (selectedAvis) {
      case "AVIS_PREVALIDATION":
        return "PREVALIDE";
      case "AVIS_EN_ATTENTE_COMPLEMENT":
        return "FAVORABLE_SOUS_RESERVE";
      case "AVIS_FAVORABLE":
        return "FAVORABLE";
      case "AVIS_INSCRIT_A_OP":
        return "FAVORABLE";
      case "AVIS_NON_INSCRIT_A_OP":
        return "DEFAVORABLE";
      case "ACCEPTATION":
        return "FAVORABLE";
      case "ACCEPTATION_SOUS_RESERVE":
        return "FAVORABLE_SOUS_RESERVE";
      case "AJOURNEMENT":
        return "FAVORABLE_SOUS_RESERVE";
      case "REJETE":
        return "REJETEE";
      default:
        return "";
    }
  };

  // Mappage des avis en typeAvisOI
  const mapAvisToTypeAvisOC = (selectedAvis) => {
    switch (selectedAvis) {
      case "ACCEPTATION":
        return "ACCEPTER";
      case "ACCEPTATION_SOUS_RESERVE":
        return "ACCEPTER_SOUS_RESERVE";
      case "AJOURNEMENT":
        return "AJOURNER";
      case "REJETE":
        return "REJETER";
      default:
        return "";
    }
  };


  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const [fileToDisplay, setFileToDisplay] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isInfoExpanded, setInfoExpanded] = useState(false);
  const [isTraitementExpanded, setTraitementExpanded] = useState(false);
  const [isTraitementeExpanded, setTraitementeExpanded] = useState(false);

  /*const telecharge = (nomFichier) => {
    const downloadURL = `${process.env.REACT_APP_API_URL}/api/downloadFile/${nomFichier}`;
    const link = document.createElement("a");
    link.href = downloadURL;
    link.target = "_blank"; // Pour ouvrir le lien dans une nouvelle fenêtre/onglet
    link.click();
  };*/

  const downloadFile = async (idFile) => {
    try {
      const token = sessionStorage.getItem('token');
      const url = `${process.env.REACT_APP_API_URL}/pieces/download/${idFile}`;

      const response = await axios({
        method: 'GET',
        url: url,
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/pdf'
        }
      });

      // Créer un blob avec le type MIME PDF
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `document_${idFile}.pdf`); // Ajout de l'extension .pdf
      
      // Option 1 : Télécharger directement
      document.body.appendChild(link);
      link.click();

      // Option 2 : Ouvrir dans un nouvel onglet (décommentez si vous préférez cette option)
      // window.open(downloadUrl, '_blank');

      // Nettoyage
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(link);

    } catch (error) {
      console.error('Erreur lors du téléchargement du PDF:', error);
      toast.error('Erreur lors du téléchargement du fichier PDF');
    }
  };

  const [directions, setDirections] = useState([]);
  const [directionsOp, setDirectionsOp] = useState([]);
  const [selectedDirectionsOp, setSelectedDirectionsOp] = useState([]);
  const [selectedDirections, setSelectedDirections] = useState([]);
  const directionIds = directions.map(direction => direction.id);
  const directionIdsOp = directionsOp.map(direction => direction.id);
  const getDirectionsOp = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/directions/direction-op`);
      const data = response.data.data || response.data; // Adaptez selon la structure de l'API
      setDirectionsOp(data);
    } catch (error) {
      console.error("Error fetching directions list:", error.message);
    }
  };
  
  useEffect(() => {
    getDirectionsOp();
  }, []);
  const getDirections = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/directions/direction-dc`);
      const data = response.data.data || response.data; // Adaptez selon la structure de l'API
      setDirections(data);
    } catch (error) {
      console.error("Error fetching directions list:", error.message);
    }
  };
  
  useEffect(() => {
    getDirections();
  }, []);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

        // Afficher une boîte de dialogue de confirmation
        const result = await Swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Voulez-vous vraiment soumettre ce traitement ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, soumettre!',
          cancelButtonText: 'Annuler'
        });
    
        if (!result.isConfirmed) {
          return; // Annuler l'envoi si l'utilisateur ne confirme pas
        }
        let timerInterval;
        Swal.fire({
          title: "Veuillez patienter...",
          html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
          timer: 20000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        });
    const directionsString = selectedDirectionsOp.join(',');
    setIsSubmitting(true);
    const token = sessionStorage.getItem('token');

    const url = `${process.env.REACT_APP_API_URL}/demandes/dsps-prevalide/${demandeId}/${directionsString}?action=${avis}`;
console.log('select :' +directionsString)
    try {
      const response = await axios.post(
        url,
        {
          "motivation": motivation,
          "typeAvisOI": typeAvisOI,
          "demande": {
            "id": demandeId
          },
          "utilisateur": {
            "id": userProfile.id // Ajout de l'objet demande avec l'id
          },
          directionIds: selectedDirectionsOp
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`, // Utilisation du token dans l'en-tête
            'Content-Type': 'application/json',
          },
        }
      );
      setTimeout(() => {
        // Ici, on simule une soumission et on réinitialise l'état
        setIsSubmitting(false);
        // alert("Formulaire soumis avec succès !");
      }, 2000);
      if (response.status === 200) {
        console.log('Demande soumise avec succès:', response.data);
              // Arrêter le minuteur ici
              clearInterval(timerInterval);
              Swal.close(); // Fermer la fenêtre Swal
        // console.log('tokenn user:', token);
        toast.success('La demande a été traitée avec succès');
        navigate('/liste_demande_a_traiter'); // Redirection après succès
        closeModal();
      } else {
        console.error('Erreur lors de l\'envoi de la demande:', response.statusText);
       
        toast.error("Erreur lors de l'envoi du traitement.");
      }
    } catch (error) {
      console.error('Erreur réseau:', error.response ? error.response.data : error.message);
      console.log('select :' +directionsString)
      if (error.response && error.response.data && error.response.data.includes('MailSendException')) {
        toast.error("Erreur lors de l'envoi de l'email. Vérifiez la configuration du serveur de mail.");
      } else if (error.message.includes('Connection refused')) {
        toast.error("Le serveur de mail est injoignable. Veuillez réessayer plus tard.");
      } else {
        toast.error("Erreur lors de l'envoi du traitement. Assurez-vous d'avoir le rôle approprié.");
      }
    }
    finally {
      setIsSubmitting(false); // Réinitialiser l'état pour permettre une nouvelle soumission
    }
  };

  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    const getUserProfile = async () => {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("Token not found in sessionStorage");
        return;
      }
      try {
        const response = await UserService.getProfil(token);
        setUserProfile(response);
      } catch (error) {
        console.error("Error fetching user profile:", error.message);
      }
    };

    getUserProfile();
  }, []);

  const handleSubmiteDsps = async (e) => {
    e.preventDefault();
        // Afficher une boîte de dialogue de confirmation
        const result = await Swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Voulez-vous vraiment soumettre ce traitement ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, soumettre!',
          cancelButtonText: 'Annuler'
        });
    
        if (!result.isConfirmed) {
          return; // Annuler l'envoi si l'utilisateur ne confirme pas
        }

        let timerInterval;
        Swal.fire({
          title: "Veuillez patienter...",
          html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
          timer: 20000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        });
    setIsSubmitting(true);
    const token = sessionStorage.getItem('token'); // Assurez-vous d'avoir le token stocké
    const url = `${process.env.REACT_APP_API_URL}/demandes/dsps-other-avis/${demandeId}?action=${avis}`;

    try {
      const response = await axios.post(
        url,
        { // Corps de la requête
          "motivation": motivation,
          "typeAvisOI": typeAvisOI,
          "demande": {
            "id": demandeId // Ajout de l'objet demande avec l'id
          },
          "utilisateur": {
            "id": userProfile.id // Ajout de l'objet demande avec l'id
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
            'Content-Type': 'application/json',
          },
        }
      );
      setTimeout(() => {
        // Ici, on simule une soumission et on réinitialise l'état
        setIsSubmitting(false);
        // alert("Formulaire soumis avec succès !");
      }, 2000);

      if (response.status === 200) {
        console.log('Demande soumise avec succès:', response.data);
                // Arrêter le minuteur ici
                clearInterval(timerInterval);
                Swal.close(); // Fermer la fenêtre Swal
        toast.success('la demande à été traitée avec succès');
        navigate('/liste_demande_a_traiter')
        closeModaleDsps();

        // Ajoutez ici d'autres actions après le succès (ex: mise à jour de l'état, notification, etc.)
      } else {
        console.error('Erreur lors de l\'envoi de la demande:', response.statusText);
        toast.error("Erreur de l'envoi du traitement ");
      }
    } catch (error) {
      console.error('Erreur réseau:', error.response ? error.response.data : error.message);

      // Vérifiez si l'erreur provient d'un problème avec le serveur de mail
      if (error.response && error.response.data && error.response.data.includes('MailSendException')) {
        toast.error("Erreur lors de l'envoi de l'email. Vérifiez la configuration du serveur de mail.");
      } else if (error.message.includes('Connection refused')) {
        toast.error("Le serveur de mail est injoignable. Veuillez réessayer plus tard.");
      } else {
        // Message d'erreur générique
        toast.error("Erreur lors de l'envoi du traitement. Assurez-vous d'avoir le rôle approprié.");
      }
    }
    finally {
      setIsSubmitting(false); // Réinitialiser l'état pour permettre une nouvelle soumission
    }
  };


  const handleSubmiteOP = async (e) => {
    e.preventDefault();
      // Afficher une boîte de dialogue de confirmation
      const result = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Voulez-vous vraiment soumettre ce traitement ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, soumettre!',
        cancelButtonText: 'Annuler'
      });
  
      if (!result.isConfirmed) {
        return; // Annuler l'envoi si l'utilisateur ne confirme pas
      }

      let timerInterval;
      Swal.fire({
        title: "Veuillez patienter...",
        html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
        timer: 20000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
            timerInterval = setInterval(() => {
              timer.textContent = `${Swal.getTimerLeft()}`;
            }, 100);
          }
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      });
  setIsSubmitting(true);
  const token = sessionStorage.getItem('token'); // Assurez-vous d'avoir le token stocké
  const url = `${process.env.REACT_APP_API_URL}/demandes/op-training/${demandeId}?action=${avis}`;

  try {
    const response = await axios.post(
      url,
      {
        // Corps de la requête
        motivation: motivation,
        typeAvisOI: typeAvisOI,
        demande: {
          id: demandeId, // Ajout de l'objet demande avec l'id
        },
        utilisateur: {
          id: userProfile.id, // Ajout de l'objet user avec l'id
        },
        // directionIds: selectedDirections
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
          'Content-Type': 'application/json',
        },
      }
    );

    setTimeout(() => {
      setIsSubmitting(false);
    }, 2000);

    if (response.status === 200) {
      console.log('Demande soumise avec succès:', response.data);
              // Arrêter le minuteur ici
              clearInterval(timerInterval);
              Swal.close(); // Fermer la fenêtre Swal
      toast.success('La demande a été traitée avec succès');
      navigate('/liste_demande_a_traiter');
      closeModaleOP();
    } else {
      toast.error("Ce dossier ne peut être traité par votre direction ou vous l'avez déjà traité");
      closeModaleOP();
    }
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      const msg = error.response.data.message;
      console.log(status);
      if (status === 403 || status === 409) {
        toast.error(`Erreur : ${msg}`);
      } else {
        toast.error("Une erreur inattendue est survenue !");
      }
    } else {
      console.error('Erreur réseau ou serveur:', error.message);
      toast.error("Erreur réseau ou serveur");
    }
    closeModaleOP();
  }
  finally {
    setIsSubmitting(false); // Réinitialiser l'état pour permettre une nouvelle soumission
  }
};
  const handleSubmiteCommission = async (e) => {
    e.preventDefault();
        // Afficher une boîte de dialogue de confirmation
        const result = await Swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Voulez-vous vraiment soumettre ce traitement ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, soumettre!',
          cancelButtonText: 'Annuler'
        });
    
        if (!result.isConfirmed) {
          return; // Annuler l'envoi si l'utilisateur ne confirme pas
        }
        let timerInterval;
        Swal.fire({
          title: "Veuillez patienter...",
          html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
          timer: 20000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        });
    setIsSubmitting(true);
    const token = sessionStorage.getItem('token'); // Assurez-vous d'avoir le token stocké
    const url = `${process.env.REACT_APP_API_URL}/demandes/commission/${demandeId}?action=${avis}`;

    try {
      const response = await axios.post(url,
        { // Corps de la requête
          "motivation": motivation,
          "typeAvisOC": typeAvisOC,
          "demande": demandeId,
          "utilisateur": {
          "id": userProfile.id // Ajout de l'objet demande avec l'id
        }// Vous pouvez adapter cette valeur selon vos besoins
        },
        
        {
          headers: {
            'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
            'Content-Type': 'application/json',
          },
        }
      );
      setTimeout(() => {
        // Ici, on simule une soumission et on réinitialise l'état
        setIsSubmitting(false);
        // alert("Formulaire soumis avec succès !");
      }, 2000);
      if (response.status === 200) {
        console.log('Demande soumise avec succès:', response.data);
        toast.success('la demande à été traitée avec succès');
        navigate('/liste_demande_a_traiter')
        closeModaleCommission();

        // Ajoutez ici d'autres actions après le succès (ex: mise à jour de l'état, notification, etc.)
      } else {
        console.error('Erreur lors de l\'envoi de la demande:', response.statusText);
        toast.error("Erreur de l'envoi du traitement ");
        closeModaleCommission();
      }
    } catch (error) {
      console.error('Erreur réseau:', error.response ? error.response.data : error.message);

      // Vérifiez si l'erreur provient d'un problème avec le serveur de mail
      if (error.response && error.response.data && error.response.data.includes('MailSendException')) {
        toast.error("Erreur lors de l'envoi de l'email. Vérifiez la configuration du serveur de mail.");
        closeModaleCommission();
      } else if (error.message.includes('Connection refused')) {
        toast.error("Le serveur de mail est injoignable. Veuillez réessayer plus tard.");
        closeModaleCommission();
      } else {
        // Message d'erreur générique
        toast.error("Erreur lors de l'envoi du traitement. Assurez-vous d'avoir le rôle approprié.");
        closeModaleCommission();
      }
    }
    finally {
      setIsSubmitting(false); // Réinitialiser l'état pour permettre une nouvelle soumission
    }
  };

  const handleSubmiteArreteCharge = async (e) => {
    e.preventDefault();
    
        // Afficher une boîte de dialogue de confirmation
        const result = await Swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Voulez-vous vraiment soumettre ce traitement ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, soumettre!',
          cancelButtonText: 'Annuler'
        });
    
        if (!result.isConfirmed) {
          return; // Annuler l'envoi si l'utilisateur ne confirme pas
        }

        let timerInterval;
        Swal.fire({
          title: "Veuillez patienter...",
          html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
          timer: 20000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        });
    // Validation des champs
    if (!typeFile || !numeroDocument || !dateSignatureDocument || !file) {
      toast.error("Veuillez remplir tous les champs obligatoires");
      return;
    }


    setIsSubmitting(true);
    const token = sessionStorage.getItem('token');
    
    try {
      // Création du FormData
      const formData = new FormData();
      formData.append('file', file);
      formData.append('typeFile', typeFile);
      formData.append('numeroDocument', numeroDocument);
      formData.append('demandeId', demandeId);
      formData.append('dateSignatureDocument', dateSignatureDocument);
      
      // Log pour vérifier le contenu du FormData
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      // Envoi de la requête
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/document/upload`,
        data: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 201) {
                // Arrêter le minuteur ici
                clearInterval(timerInterval);
                Swal.close(); // Fermer la fenêtre Swal
        toast.success('Document uploadé avec succès');
        closeModaleArreteCharge();
      }
    } catch (error) {
      console.error('Erreur détaillée:', error.response || error);
      toast.error(error.response?.data?.message || "Erreur lors de l'upload du document");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSubmiteLettreRejet = async (e) => {
    e.preventDefault();
        // Afficher une boîte de dialogue de confirmation
        const result = await Swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Voulez-vous vraiment générer la lettre de rejet ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, générer!',
          cancelButtonText: 'Annuler'
        });
    
        if (!result.isConfirmed) {
          return; // Annuler l'envoi si l'utilisateur ne confirme pas
        }

        let timerInterval;
        Swal.fire({
          title: "Veuillez patienter...",
          html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
          timer: 20000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        });
    setIsSubmitting(true);
    const token =  sessionStorage.getItem('token');
    // const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-arrete/${demandeId}?annee=${annee}&parcelle=${parcelle}&lot=${lot}&section=${section}&secteur=${secteur}&signataire=${signataire}&arreteCreationDate=${arreteCreationDate}&dateDebutSession=${dateDebutSession}&dateFinSession=${dateFinSession}`;
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-lettre-rejet/${demandeId}`;

    try {
      const response = await axios({
        method: 'POST',
        url: url,
        data:{
          "annee": annee,
          // "parcelle": parcelle,
          // "lot": lot,
          // "section": section,
          // "secteur": secteur,
          "signataire": signataire,
          // "arreteCreationDate": arreteCreationDate,
          "dateDebutSession": dateDebutSession,
          "dateFinSession": dateFinSession
        },
        responseType: 'blob', // Important pour les fichiers binaires
        headers: {
          'Accept': 'application/pdf',
          'Content-Type': 'application/json',
        }
      });

      // Vérifier si la réponse contient des données
      if (response.data.size > 0) {
        // Créer le blob avec le type MIME correct
        const blob = new Blob([response.data], { type: 'application/pdf' });
        
        // Créer l'URL du blob
        const downloadUrl = window.URL.createObjectURL(blob);
        
        // Créer un lien temporaire et déclencher le téléchargement
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `lettre_rejet_${demandeId}.pdf`;
        document.body.appendChild(link);
        link.click();
        
        // Nettoyer
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
                // Arrêter le minuteur ici
                clearInterval(timerInterval);
                Swal.close(); // Fermer la fenêtre Swal
        navigate('/liste_demande_a_traiter')
        toast.success("Le fichier a été téléchargé avec succès");
      } else {
        throw new Error('Le fichier PDF généré est vide');
      }

    } catch (error) {
      console.error('Erreur lors du téléchargement:', error);

      // Si l'erreur contient une réponse du serveur
      if (error.response) {
        // Lire le contenu de l'erreur si c'est un blob
        if (error.response.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              toast.error(errorData.message || "Erreur lors de la génération du PDF");
            } catch (e) {
              toast.error("Erreur lors de la génération du PDF");
            }
          };
          reader.readAsText(error.response.data);
        } else {
          toast.error(error.response.data.message || "Erreur lors de la génération du PDF");
        }
      } else {
        toast.error("Erreur de connexion au serveur");
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSubmiteArrete = async (e) => {
    e.preventDefault();
        // Afficher une boîte de dialogue de confirmation
        const result = await Swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Voulez-vous vraiment générer l'arreté ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, générer!',
          cancelButtonText: 'Annuler'
        });
    
        if (!result.isConfirmed) {
          return; // Annuler l'envoi si l'utilisateur ne confirme pas
        }

        let timerInterval;
        Swal.fire({
          title: "Veuillez patienter...",
          html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
          timer: 20000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        });
    setIsSubmitting(true);
    const token =  sessionStorage.getItem('token');
    // const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-arrete/${demandeId}?annee=${annee}&parcelle=${parcelle}&lot=${lot}&section=${section}&secteur=${secteur}&signataire=${signataire}&arreteCreationDate=${arreteCreationDate}&dateDebutSession=${dateDebutSession}&dateFinSession=${dateFinSession}`;
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-arrete/${demandeId}`;

    try {
      const response = await axios({
        method: 'POST',
        url: url,
        data:{
          "annee": annee,
          "parcelle": parcelle,
          "lot": lot,
          "section": section,
          "secteur": secteur,
          "signataire": signataire,
          // "arreteCreationDate": arreteCreationDate,
          "dateDebutSession": dateDebutSession,
          "dateFinSession": dateFinSession
        },
        responseType: 'blob', // Important pour les fichiers binaires
        headers: {
          'Accept': 'application/pdf',
          'Content-Type': 'application/json',
        }
      });

      // Vérifier si la réponse contient des données
      if (response.data.size > 0) {
        // Créer le blob avec le type MIME correct
        const blob = new Blob([response.data], { type: 'application/pdf' });
        
        // Créer l'URL du blob
        const downloadUrl = window.URL.createObjectURL(blob);
        
        // Créer un lien temporaire et déclencher le téléchargement
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `arrete_${demandeId}.pdf`;
        document.body.appendChild(link);
        link.click();
        
        // Nettoyer
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
        navigate('/liste_demande_a_traiter')
                // Arrêter le minuteur ici
                clearInterval(timerInterval);
                Swal.close(); // Fermer la fenêtre Swal
        toast.success("Le fichier a été téléchargé avec succès");
      } else {
        throw new Error('Le fichier PDF généré est vide');
      }

    } catch (error) {
      console.error('Erreur lors du téléchargement:', error);

      // Si l'erreur contient une réponse du serveur
      if (error.response) {
        // Lire le contenu de l'erreur si c'est un blob
        if (error.response.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              toast.error(errorData.message || "Erreur lors de la génération du PDF");
            } catch (e) {
              toast.error("Erreur lors de la génération du PDF");
            }
          };
          reader.readAsText(error.response.data);
        } else {
          toast.error(error.response.data.message || "Erreur lors de la génération du PDF");
        }
      } else {
        toast.error("Erreur de connexion au serveur");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmiteArretePhysique = async (e) => {
    e.preventDefault();
    // Afficher une boîte de dialogue de confirmation
    const result = await Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Voulez-vous vraiment générer l'arreté ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, générer!',
      cancelButtonText: 'Annuler'
    });

    if (!result.isConfirmed) {
      return; // Annuler l'envoi si l'utilisateur ne confirme pas
    }

    let timerInterval;
    Swal.fire({
      title: "Veuillez patienter...",
      html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
          timerInterval = setInterval(() => {
            timer.textContent = `${Swal.getTimerLeft()}`;
          }, 100);
        }
      },
      willClose: () => {
        clearInterval(timerInterval);
      }
    });

    setIsSubmitting(true);
    const token =  sessionStorage.getItem('token');
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-arrete-physique/${demandeId}`;

    try {
      const response = await axios({
        method: 'POST',
        url: url,
        data:{
          "annee": annee,
          "parcelle": parcelle,
          "lot": lot,
          "section": section,
          "secteur": secteur,
          "signataire": signataire,
          // "arreteCreationDate": arreteCreationDate,
          "dateDebutSession": dateDebutSession,
          "dateFinSession": dateFinSession
        },
        responseType: 'blob', // Important pour les fichiers binaires
        headers: {
          'Accept': 'application/pdf',
          'Content-Type': 'application/json',
        }
      });

      // Vérifier si la réponse contient des données
      if (response.data.size > 0) {
        // Créer le blob avec le type MIME correct
        const blob = new Blob([response.data], { type: 'application/pdf' });

        // Créer l'URL du blob
        const downloadUrl = window.URL.createObjectURL(blob);

        // Créer un lien temporaire et déclencher le téléchargement
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `arrete_${demandeId}.pdf`;
        document.body.appendChild(link);
        link.click();

        // Nettoyer
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
        navigate('/liste_demande_a_traiter')
                // Arrêter le minuteur ici
                clearInterval(timerInterval);
                Swal.close(); // Fermer la fenêtre Swal
        toast.success("Le fichier a été téléchargé avec succès");
      } else {
        throw new Error('Le fichier PDF généré est vide');
      }

    } catch (error) {
      console.error('Erreur lors du téléchargement:', error);

      // Si l'erreur contient une réponse du serveur
      if (error.response) {
        // Lire le contenu de l'erreur si c'est un blob
        if (error.response.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
                      // Arrêter le minuteur ici
        clearInterval(timerInterval);
        Swal.close(); // Fermer la fenêtre Swal
              toast.error(errorData.message || "Erreur lors de la génération du PDF");
            } catch (e) {
              toast.error("Erreur lors de la génération du PDF");
            }
          };
          reader.readAsText(error.response.data);
        } else {
          toast.error(error.response.data.message || "Erreur lors de la génération du PDF");
        }
      } else {
        toast.error("Erreur de connexion au serveur");
      }
    } finally {
      setIsSubmitting(false);
    }
  };


  const validateDates = () => {
    const debut = new Date(dateDebutSession);
    const fin = new Date(dateFinSession);
    
    if (fin < debut) {
      alert("La date de fin ne peut pas être antérieure à la date de début");
      return false;
    }
    return true;
  };

  // Vérifiez que demandeId est bien défini
  useEffect(() => {
    if (!demandeId) {
      console.error('demandeId is undefined');
      toast.error("ID de la demande manquant");
      return;
    }
  }, [demandeId]);

  return (
    <body id="page-top">
      <div id="wrapper">

        <Navbar />
        <br></br><br></br>
        <div className="container-fluid">
          <div className="content-wrapper mt-3">
            {/* Informations sur la Demande */}
            <br></br><br></br>
            <div className="row">
              <div className="col-12 mb-4">
                <div className="card shadow border-left-success">
                  <div className="card-body">
                    <details open={isInfoExpanded}>
                      <summary
                        onClick={() => setInfoExpanded(!isInfoExpanded)}
                        style={{ color: "black" }}
                      >
                        Informations sur la Demande reçue
                      </summary>
                      <DemandeInfoComponent userDemande={userDemande} demandeId={demandeId} />
                    </details>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col-xl-12 col-md-6 mb-6">
                    <div className="card border-left-warning shadow h-100 py-1">
                      <div className="card-body">
                        <details open={isAttachmentsExpanded}>
                          <summary
                            onClick={() => setAttachmentsExpanded(!isAttachmentsExpanded)}
                            style={{ color: "black" }}
                          >
                            Pièces Jointes
                          </summary>

                          <div className="attachments-section">
                            {/* Pièces jointes de complément */}
                            {userDemande.pieceJointes?.some(pj => pj.complement?.id) && (
                              <div className="complement-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-folder-plus me-2"></i>
                                  Pièces complémentaires
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                    .filter(pj => pj.complement?.id)
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-info shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "blue" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)} 
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                {userProfile.role.libelle !== "OP" && (
    <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
       style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
)}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {/* Pièces jointes du personnel */}
                            {userDemande.pieceJointes?.some(pj => pj.personnel?.id) &&  (
                              <div className="personnel-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-users me-2"></i>
                                  Documents du personnel
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                    .filter(pj => pj.personnel?.id)
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-primary shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "red" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)}
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                {userProfile.role.libelle !== "OP" && (
                                                  <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
                                                     style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {userDemande.typeProprioDemande !== "PHYSIQUE" && userDemande.pieceJointes?.some(pj => pj.proprietaire?.id) && (
                              <div className="personnel-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-users me-2"></i>
                                  Documents des Responsables Techniques 
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                    .filter(pj => pj.proprietaire?.id)
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-primary shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "red" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)}
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                {userProfile.role.libelle !== "OP" && (
                                                  <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
                                                     style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}
                            
                            {userDemande.typeProprioDemande === "PHYSIQUE" && userDemande.pieceJointes?.some(pj => pj.proprietaire?.id) && (
                              <div className="promoteur-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-user-tie me-2"></i>
                                  Documents de l'établissement et du promoteur 
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                    .filter(pj => pj.proprietaire?.id)
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-success shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "green" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)}
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                {userProfile.role.libelle !== "OP" && (
                                                  <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
                                                     style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {/* Vérifie si le type de propriétaire de la demande n'est pas "PHYSIQUE" et s'il existe des pièces jointes sans propriétaire */}
                            {userDemande.typeProprioDemande !== "PHYSIQUE" && 
                             userDemande.pieceJointes?.some(pj => pj.proprietaire === null) && userDemande.pieceJointes?.some(pj => pj.personnel === null) && (
                              <div className="promoteur-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-user-tie me-2"></i>
                                  Documents de l'établissement  
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                    .filter(pj => pj.proprietaire === null && pj.personnel === null && pj.complement === null) // Filtre les pièces jointes sans propriétaire
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-success shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "green" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)}
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                {userProfile.role.libelle !== "OP" && (
                                                  <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
                                                     style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}
                            {/* Message si aucune pièce jointe */}
                            {(!userDemande.pieceJointes || userDemande.pieceJointes.length === 0) && (
                              <p className="text-center text-muted">Aucun fichier n'est disponible</p>
                            )}
                          </div>
                        </details>
                      </div>
                    </div>
                  </div>

                  {selectedPieceJointe && (
                    <PDFPreviewModal
                      isModalOpen={isModalOpen}
                      closeFileModal={closeFileModal}
                      pieceJointe={selectedPieceJointe}
                    />
                  )}
                </div>

                <br></br>
                <div className="row">
                  <div className="col-xl-12 col-md-6 mb-6">
                    <div className="card border-left-danger shadow h-100 py-1">
                      <div className="card-body">
                        <details open={isTraitementExpanded}>
                          <summary
                            onClick={() =>
                              setTraitementExpanded(!isTraitementExpanded)
                            }
                            style={{ color: "black" }}
                          >
                            Traiter la demande
                          </summary>

                          <div className="row">
                            {/* Partie 1: Niveau de validation actuel */}
                            <div className="col-md-6">
                              <h4>Niveau de validation actuel</h4>
                              <br />
                              <td className="text-end">
                                {userDemande.statutDemande && (
                                  <div>


                                    {/* Condition pour afficher un bouton spécifique en fonction du statut */}
                                    {userDemande.statutDemande === "EN_COURS_PREVALIDATION" && userProfile.role.libelle === "DSPS" && (
                                      <button className="btn btn-warning" onClick={openModal}>En cours de prévalidation</button>
                                    )}
                                        {userDemande.statutDemande === "COMPLETEE" && userProfile.role.libelle === "DSPS" && (
                                      <button className="btn btn-warning" onClick={openModal}>En cours de prévalidation</button>
                                    )}

                                    {userDemande.statutDemande === "PREVALIDEE" && userProfile.role.libelle === "OP" && (
                                      <button className="btn btn-success" onClick={openModaleOp}>Attente avis des Ordres Professionels</button>
                                    )}

                                  

                                    {userDemande.statutDemande === "OK_OPs" && userProfile.role.libelle === "DSPS" && (
                                      <button className="btn btn-primary" onClick={openModaleDsps}>Avis pour Envoie en Commission</button>
                                    )}

                                    {userDemande.statutDemande === "FAVORABLE" && userProfile.role.libelle === "COMMISSION" && (
                                      <button className="btn btn-info" onClick={openModaleCommission}>En Commission</button>
                                    )}
{userDemande.statutDemande === "REJETE_COM" && userProfile.role.libelle === "DSPS" && (
                                      <button className="btn btn-info" onClick={openModaleLettreRejet}>Generer lettre de rejet</button>
                                    
                                    )}
                                    
{userDemande.statutDemande === "REJETE_COM" && userProfile.role.libelle === "COMMISSION" && (
                                      <button className="btn btn-info" onClick={openModaleLettreRejet}>Generer lettre de rejet</button>
                                    
                                    )}
                                    {userDemande.statutDemande === "ACCEPTEE" && userProfile.role.libelle === "COMMISSION" && (
                                      <button className="btn btn-info" onClick={openModaleArrete}>Generer l'arreté</button>
                                    )}
                                       {userDemande.statutDemande === "ACCEPTEE" && userProfile.role.libelle === "DSPS" && (
                                      <button className="btn btn-info" onClick={openModaleArrete}>Generer l'arreté</button>
                                    
                                    )}


                                    <br></br>    <br></br>
                                      {userDemande.statutDemande === "ACCEPTEE" && userProfile.role.libelle === "DSPS" && (
                                      <button className="btn btn-success" onClick={openModaleArreteCharge}>Charger l'arreté</button>
                                    )}
                                    

                                  </div>
                                )}
                              </td>

                              {showModal && (
                                <div className="modal">
                                  <div className="modal-contente">
                                    <span className="close" onClick={closeModal}>
                                      &times;
                                    </span>
                                    <h4 className="text-center" style={{ color: 'black' }}>
                                      <img src={armoiries} width="50" alt="Armoiries" />
                                      <Modal.Title><b>Traitement de la Demande Par DSPS</b></Modal.Title>
                                    </h4>
                                    <br></br>
                                    <form onSubmit={(e) => {
                                      e.preventDefault();
                                      if (avis === "AVIS_EN_ATTENTE_COMPLEMENT") {
                                        handleSubmiteDsps(e);
                                      } else {
                                        handleSubmit(e);
                                      }
                                    }}>
                                      <br />
                                      <div className="row">
                                        <div className="col-md-12 form-group mb-5">
                                          <label htmlFor="statut" className="col-form-label">
                                            Avis:
                                          </label>
                                          <select
                                            className="form-control"
                                            id="statut"
                                            value={avis}
                                            onChange={(e) => {
                                              const selectedAvis = e.target.value;
                                              setAvis(selectedAvis);
                                              settypeAvisOI(mapAvisToTypeAvisOI(selectedAvis));
                                            }}
                                          >
                                            <option value="">Donner votre Avis ...</option>
                                            <option value="AVIS_PREVALIDATION">Prévalider</option>
                                            <option value="AVIS_EN_ATTENTE_COMPLEMENT">En attente de complément</option>
                                          </select>
                                        </div>

                                        <input
                                          className="form-control"
                                          type="text"
                                          id="typeAvisOI"
                                          value={typeAvisOI}
                                          hidden
                                        />

                                        <div className="col-md-12 form-group mb-5">
                                          <label htmlFor="motivation" className="col-form-label">
                                            Motivation:
                                          </label>
                                          <input
                                            className="form-control"
                                            type="text"
                                            id="motivation"
                                            value={motivation}
                                            onChange={(e) => setmotivation(e.target.value)}
                                            required
                                          />
                                        </div>

                                        {/* Section pour les cases à cocher des ordres professionnels - masquée si AVIS_EN_ATTENTE_COMPLEMENT */}
                                        {avis !== "AVIS_EN_ATTENTE_COMPLEMENT" && (
                                          <div className="col-md-12 form-group mb-5">
                                            <MultiSelectWithCheckbox 
                                              directions={directionsOp}
                                              selectedDirections={selectedDirectionsOp}
                                              setSelectedDirections={setSelectedDirectionsOp}
                                            />
                                          </div>
                                        )}
                                      </div>

                                      <button 
                                        type="submit" 
                                        className="btn btn-secondary w-90" 
                                        disabled={isSubmitting || !avis || !motivation}
                                      >
                                        {isSubmitting ? "Soumission en cours..." : 
                                         avis === "AVIS_EN_ATTENTE_COMPLEMENT" ? "Demander un complément" : "Prévalider"}
                                      </button>
                                    </form>

                                  </div>
                                </div>
                              )}

                              {showModaleDsps && (
                                <div className="modal">
                                  <div className="modal-content">
                                    <span className="close" onClick={closeModaleDsps}>
                                      &times;
                                    </span>
                                    <h4 className="text-center" style={{ color: 'black' }}>
                                      <img src={armoiries} width="50" alt="Armoiries" />
                                      <Modal.Title><b>Traitement de la Demande Par DSPS</b></Modal.Title>
                                    </h4>

                                    <form onSubmit={handleSubmiteDsps}>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-md-12 form-group mb-5">
                                          <label htmlFor="statut" className="col-form-label">
                                            Avis:
                                          </label>
                                          <select
                                            className="form-control"
                                            id="statut"
                                            value={avis}
                                            onChange={(e) => {
                                              const selectedAvis = e.target.value;
                                              setAvis(selectedAvis);  // Met à jour l'avis sélectionné
                                              settypeAvisOI(mapAvisToTypeAvisOI(selectedAvis));  // Met à jour typeAvisOI via la fonction de mappage
                                            }}
                                          >
                                            <option value="">Donner votre Avis ...</option>
                                            <option value="AVIS_FAVORABLE">Envoyer en Commission</option>
                                          </select>
                                        </div>

                                        <input
                                          className="form-control"
                                          type="text"
                                          id="typeAvisOI"
                                          value={typeAvisOI}
                                          hidden
                                        />

                                        <div className="col-md-12 form-group mb-5">
                                          <label htmlFor="motivation" className="col-form-label">
                                            Motivation:
                                          </label>
                                          <input
                                            className="form-control"
                                            type="text"
                                            id="motivation"
                                            value={motivation}
                                            onChange={(e) => setmotivation(e.target.value)}
                                          />
                                        </div>
                                      </div>

                                      <button type="submit" className="btn btn-secondary w-90" disabled={isSubmitting}>
                                        {isSubmitting ? "Soumission en cours..." : "Soumettre"}
                                      </button>
                                    </form>

                                  </div>
                                </div>
                              )}

                              {showModaleOP && (
                                <div className="modal" style={{
                                  // Styles pour le conteneur modal principal
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  position: 'fixed',
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                  zIndex: 1050
                                }}>
                                  <div className="modal-content" style={{
                                    // Styles pour le contenu du modal
                                    position: 'relative',
                                    backgroundColor: 'white',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    maxHeight: '80vh', // Hauteur maximale de 80% de la hauteur de la fenêtre
                                    width: '90%', // Largeur de 90% de la fenêtre
                                    maxWidth: '600px', // Largeur maximale
                                    overflowY: 'auto', // Active le défilement vertical
                                    overflowX: 'hidden' // Désactive le défilement horizontal
                                  }}>
                                    <span className="close" onClick={closeModaleOP} style={{
                                      // Styles pour le bouton de fermeture
                                      position: 'absolute',
                                      right: '10px',
                                      top: '10px',
                                      fontSize: '24px',
                                      cursor: 'pointer'
                                    }}>
                                      &times;
                                    </span>

                                    <h4 className="text-center" style={{ color: 'black' }}>
                                      <img src={armoiries} width="50" alt="Armoiries" />
                                      <Modal.Title><b>Traitement de la Demande par Ordre Professionel</b></Modal.Title>
                                    </h4>

                                    <form onSubmit={handleSubmiteOP}>
                                      <br />

                                      <div className="row">
                                        <div className="col-md-12 form-group mb-5">
                                          <label htmlFor="statut" className="col-form-label">
                                            Avis:
                                          </label>
                                          <select
                                            className="form-control"
                                            id="statut"
                                            value={avis}
                                            onChange={(e) => {
                                              const selectedAvis = e.target.value;
                                              setAvis(selectedAvis);  // Met à jour l'avis sélectionné
                                              settypeAvisOI(mapAvisToTypeAvisOI(selectedAvis));  // Met à jour typeAvisOI via la fonction de mappage
                                            }}
                                          >
                                            <option value="">Donner votre Avis ...</option>
                                            <option value="AVIS_INSCRIT_A_OP">Favorable</option>
                                            <option value="AVIS_NON_INSCRIT_A_OP">Défavorable</option>
                                          </select>
                                        </div>
                                        <input
                                          className="form-control"
                                          type="text"
                                          id="typeAvisOI"
                                          value={typeAvisOI}
                                          hidden
                                        />
                                        <div className="col-md-12 form-group mb-5">
                                          <label htmlFor="motivation" className="col-form-label">
                                            motivation:
                                          </label>
                                          <input
                                            className="form-control"
                                            type="text"
                                            id="motivation"
                                            value={motivation}
                                            onChange={(e) => setmotivation(e.target.value)}
                                          />
                                        </div>
                                        {/* <div className="col-md-12 form-group mb-5">
                                    
                                    <MultiSelectWithCheckbox directions={directions}
                                      selectedDirections={selectedDirections}
                                      setSelectedDirections={setSelectedDirections}/>
                              
                                  </div> */}
                                        <button type="submit" className="btn btn-secondary w-90" disabled={isSubmitting}>
                                          {isSubmitting ? "Soumission en cours..." : "Soumettre"}
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}

                              {showModaleCommission && (
                                <div className="modal">
                                  <div className="modal-content">
                                    <span className="close" onClick={closeModaleCommission}>
                                      &times;
                                    </span>
                                    <h4 className="text-center" style={{ color: 'black' }}>
                                      <img src={armoiries} width="50" alt="Armoiries" />
                                      <Modal.Title><b>Traitement de la Demande par Commission</b></Modal.Title>
                                    </h4>

                                    <form onSubmit={handleSubmiteCommission}>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-md-12 form-group mb-5">
                                          <label htmlFor="statut" className="col-form-label">
                                            Avis:
                                          </label>
                                          <select
                                            className="form-control"
                                            id="statut"
                                            value={avis}
                                            onChange={(e) => {
                                              const selectedAvis = e.target.value;
                                              setAvis(selectedAvis);  // Met à jour l'avis sélectionné
                                              settypeAvisOC(mapAvisToTypeAvisOC(selectedAvis));  // Met à jour typeAvisOI via la fonction de mappage
                                            }}
                                          >
                                            <option value="">Donner votre Avis ...</option>
                                            <option value="ACCEPTATION">valider</option>
                                            <option value="ACCEPTATION_SOUS_RESERVE">En attente de complément</option>
                                            <option value="AJOURNEMENT">Ajourner</option>
                                            <option value="REJETE">Rejeter</option>
                                          </select>
                                        </div>
                                        <input
                                          className="form-control"
                                          type="text"
                                          id="typeAvisOI"
                                          value={typeAvisOI}
                                          hidden
                                        />
                                        <div className="col-md-12 form-group mb-5">
                                          <label htmlFor="motivation" className="col-form-label">
                                            motivation:
                                          </label>
                                          <input
                                            className="form-control"
                                            type="text"
                                            id="motivation"
                                            value={motivation}
                                            onChange={(e) => setmotivation(e.target.value)}
                                          />
                                        </div>


                                      </div>
                                      <button type="submit" className="btn btn-secondary w-90" disabled={isSubmitting}>
                                        {isSubmitting ? "Soumission en cours..." : "Soumettre"}
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              )}

                              {showModaleArrete && (
                                <div className="modal" style={modalStyles}>
                                  <div className="modal-content" style={modalContentStyles}>
                                    <span className="close" onClick={closeModaleArrete}>&times;</span>
                                    <h4 className="text-center" style={{ color: 'black' }}>
                                      <img src={armoiries} width="50" alt="Armoiries" />
                                      <Modal.Title><b>Generer l'arreté final</b></Modal.Title>
                                    </h4>

                                    <div style={formContainerStyles}>
                                    <form onSubmit={(e) => {
  e.preventDefault();
  if (userDemande.typeProprioDemande !== "PHYSIQUE") {
    handleSubmiteArrete(e);
  } else {
    handleSubmiteArretePhysique(e);
  }
}}>
                                        <div className="row">
                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="statut" className="col-form-label">
                                              Année :
                                            </label>
                                            <select
                                              className="form-control"
                                              id="annee"
                                              value={annee}
                                              onChange={(e) => setannee(e.target.value)}
                                            >
                                              <option value="">Selectionnez l'année </option>
                                              <option value="2021">2021</option>
                                              <option value="2022">2022</option>
                                              <option value="2023">2023</option>
                                              <option value="2024">2024</option>
                                              <option value="2025">2025</option>
                                            </select>
                                          </div>
                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="secteur" className="col-form-label">
                                              Secteur de la parcelle :
                                            </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              id="secteur"
                                              value={secteur}
                                              onChange={(e) => setSecteur(e.target.value)}
                                            />
                                          </div>

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="section" className="col-form-label">
                                              Numéro Section de la parcelle :
                                            </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              id="section"
                                              value={section}
                                              onChange={(e) => setSection(e.target.value)}
                                            />
                                          </div>

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="lot" className="col-form-label">
                                              Numéro lot de la parcelle :
                                            </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              id="lot"
                                              value={lot}
                                              onChange={(e) => setLot(e.target.value)}
                                            />
                                          </div>

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="parcelle" className="col-form-label">
                                              Numéro de la parcelle:
                                            </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              id="parcelle"
                                              value={parcelle}
                                              onChange={(e) => setParcelle(e.target.value)}
                                            />
                                          </div>

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="signataire" className="col-form-label">
                                              Nom du Signataire :
                                            </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              id="signataire"
                                              value={signataire}
                                              onChange={(e) => setSignataire(e.target.value)}
                                            />
                                          </div>
                                          {/* <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="dateDebutSession" className="col-form-label">
                                              Date de l'arreté de creation :
                                            </label>
                                            <input
                                              className="form-control"
                                              type="date"
                                              id="arreteCreationDate"
                                              value={arreteCreationDate}
                                              onChange={(e) => setarreteCreationDate(e.target.value)}
                                            />
                                          </div> */}

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="dateDebutSession" className="col-form-label">
                                              Date debut de la Commission:
                                            </label>
                                            <input
                                              className="form-control"
                                              type="date"
                                              id="dateDebutSession"
                                              value={dateDebutSession}
                                              onChange={(e) => {
                                                // Validation du format de date
                                                const date = new Date(e.target.value);
                                                const formattedDate = date.toISOString().split('T')[0]; // Format YYYY-MM-DD
                                                setdateDebutSession(formattedDate);
                                              }}
                                              min="2024-01-01" // Date minimale autorisée
                                              pattern="\d{4}-\d{2}-\d{2}" // Pattern pour forcer le format YYYY-MM-DD
                                            />
                                          </div>

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="dateFinSession" className="col-form-label">
                                              Date de fin de la Commission:
                                            </label>
                                            <input
                                              className="form-control"
                                              type="date"
                                              id="dateFinSession"
                                              value={dateFinSession}
                                              onChange={(e) => {
                                                const date = new Date(e.target.value);
                                                const formattedDate = date.toISOString().split('T')[0]; // Format YYYY-MM-DD
                                                setDateFinSession(formattedDate);
                                              }}
                                              min={dateDebutSession} // La date de fin ne peut pas être avant la date de début
                                              pattern="\d{4}-\d{2}-\d{2}" // Pattern pour forcer le format YYYY-MM-DD
                                            />
                                          </div>
                                        </div>
                                        <button type="submit" className="btn btn-secondary w-90" disabled={isSubmitting}>
                                          {isSubmitting ? "Soumission en cours..." : "Soumettre"}
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              )}

{showModaleLettreRejet && (
                                <div className="modal" style={modalStyles}>
                                  <div className="modal-content" style={modalContentStyles}>
                                    <span className="close" onClick={closeModaleLettreRejet}>&times;</span>
                                    <h4 className="text-center" style={{ color: 'black' }}>
                                      <img src={armoiries} width="50" alt="Armoiries" />
                                      <Modal.Title><b>Generer la lettre de rejet</b></Modal.Title>
                                    </h4>

                                    <div style={formContainerStyles}>
                                    <form onSubmit={handleSubmiteLettreRejet}>
                                        <div className="row">
                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="statut" className="col-form-label">
                                              Année :
                                            </label>
                                            <select
                                              className="form-control"
                                              id="annee"
                                              value={annee}
                                              onChange={(e) => setannee(e.target.value)}
                                            >
                                              <option value="">Selectionnez l'année </option>
                                              <option value="2021">2021</option>
                                              <option value="2022">2022</option>
                                              <option value="2023">2023</option>
                                              <option value="2024">2024</option>
                                              <option value="2025">2025</option>
                                            </select>
                                          </div>


                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="signataire" className="col-form-label">
                                              Nom du Signataire :
                                            </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              id="signataire"
                                              value={signataire}
                                              onChange={(e) => setSignataire(e.target.value)}
                                            />
                                          </div>
                                          {/* <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="dateDebutSession" className="col-form-label">
                                              Date de l'arreté de creation :
                                            </label>
                                            <input
                                              className="form-control"
                                              type="date"
                                              id="arreteCreationDate"
                                              value={arreteCreationDate}
                                              onChange={(e) => setarreteCreationDate(e.target.value)}
                                            />
                                          </div> */}

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="dateDebutSession" className="col-form-label">
                                              Date debut de la Commission:
                                            </label>
                                            <input
                                              className="form-control"
                                              type="date"
                                              id="dateDebutSession"
                                              value={dateDebutSession}
                                              onChange={(e) => {
                                                // Validation du format de date
                                                const date = new Date(e.target.value);
                                                const formattedDate = date.toISOString().split('T')[0]; // Format YYYY-MM-DD
                                                setdateDebutSession(formattedDate);
                                              }}
                                              min="2024-01-01" // Date minimale autorisée
                                              pattern="\d{4}-\d{2}-\d{2}" // Pattern pour forcer le format YYYY-MM-DD
                                            />
                                          </div>

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="dateFinSession" className="col-form-label">
                                              Date de fin de la Commission:
                                            </label>
                                            <input
                                              className="form-control"
                                              type="date"
                                              id="dateFinSession"
                                              value={dateFinSession}
                                              onChange={(e) => {
                                                const date = new Date(e.target.value);
                                                const formattedDate = date.toISOString().split('T')[0]; // Format YYYY-MM-DD
                                                setDateFinSession(formattedDate);
                                              }}
                                              min={dateDebutSession} // La date de fin ne peut pas être avant la date de début
                                              pattern="\d{4}-\d{2}-\d{2}" // Pattern pour forcer le format YYYY-MM-DD
                                            />
                                          </div>
                                        </div>
                                        <button type="submit" className="btn btn-secondary w-90" disabled={isSubmitting}>
                                          {isSubmitting ? "Soumission en cours..." : "Soumettre"}
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              )}

{showModaleArreteCharge && (
                                <div className="modal" style={modalStyles}>
                                  <div className="modal-content" style={modalContentStyles}>
                                    <span className="close" onClick={closeModaleArreteCharge}>&times;</span>
                                    <h4 className="text-center" style={{ color: 'black' }}>
                                      <img src={armoiries} width="50" alt="Armoiries" />
                                      <Modal.Title><b>Charger l'arrêté final</b></Modal.Title>
                                    </h4>

                                    <div style={formContainerStyles}>
                                      <form onSubmit={handleSubmiteArreteCharge} encType="multipart/form-data">
                                        <div className="row">
                                          <div className="col-md-12 form-group mb-5">
                          
                                            <label htmlFor="statut" className="col-form-label">
                                              Type de document :
                                            </label>
                                            <select
                                              className="form-control"
                                              id="typeFile"
                                              value={typeFile}
                                              onChange={(e) => setTypeFile(e.target.value)}
                                            >
                                              <option value="">Selectionnez le type de document </option>
                                              <option value="ARRETE">Arrêté</option>
                                              <option value="LETTRE_DE_REJET">Lettre de rejet</option>

                                            </select>
                                          </div>
                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="numeroDocument" className="col-form-label">
                                              Numero du document:
                                            </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              id="numeroDocument"
                                              value={numeroDocument}
                                              onChange={(e) => setNumeroDocument(e.target.value)}
                                            />
                                          </div>

                                      

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="dateDebutSession" className="col-form-label">
                                              Date de l'arreté de creation :
                                            </label>
                                            <input
                                              className="form-control"
                                              type="date"
                                              id="dateSignatureDocument"
                                              value={dateSignatureDocument}
                                              onChange={(e) => setdateSignatureDocument(e.target.value)}
                                            />
                                          </div>

                                          <div className="col-md-12 form-group mb-5">
                                            <label htmlFor="file" className="col-form-label required">
                                              Fichier PDF :
                                            </label>
                                            <input
                                              className="form-control"
                                              type="file"
                                              id="file"
                                              name="file"  // Ajout du name
                                              accept=".pdf"
                                              required     // Ajout de required
                                              onChange={(e) => {
                                                const selectedFile = e.target.files[0];
                                                if (selectedFile && selectedFile.type === 'application/pdf') {
                                                  setFile(selectedFile);
                                                } else {
                                                  e.target.value = '';
                                                  setFile(null);
                                                  toast.error("Veuillez sélectionner un fichier PDF valide");
                                                }
                                              }}
                                            />
                                            {file && (
                                              <small className="text-muted">
                                                Fichier sélectionné : {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                                              </small>
                                            )}
                                          </div>

                                         
                                        </div>
                                        <button 
                                          type="submit" 
                                          className="btn btn-primary w-100"
                                          disabled={!file || !numeroDocument || !dateSignatureDocument || !typeFile || isSubmitting}
                                        >
                                          {isSubmitting ? (
                                            <>
                                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                              Envoi en cours...
                                            </>
                                          ) : (
                                            "Envoyer"
                                          )}
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </details>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </body >

  );
}

export default Info_dmd_rcue;

