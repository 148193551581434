import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../Footer";
import armoiries from "../img/armoiries.jpg";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";

function Info_dmd() {
  const editURL = `${process.env.REACT_APP_API_URL}/api/param-type-demandes/`;
  const param = useParams();

  const [empIntitule, setIntitule] = useState("");
  const [empDescript, setDescript] = useState("");
  const [empPiece, setPiecesFournies] = useState([]);
  const [empPaiement, setPaiement] = useState("");
  const [empNivo, setNivoValid] = useState([]);
  const navigate = useNavigate();

  const retour = () => {
    navigate("/liste_dmd_t");
  };

  useEffect(() => {
    axios
      .get(editURL + param.id)
      .then((response) => {
        const empData = response.data;

        setIntitule(empData.intitule);
        setDescript(empData.description);
        setPiecesFournies(empData.paramTypePieces);
        setPaiement(empData.requirePayement);
        setNivoValid(empData.paramNiveauValidation);
      })
      .catch((error) => {
        alert("Erreur pour trouver les détails : " + error);
      });
  }, []);

  return (
    <div>
      <main id="main" className="main">
        <div className="content center-container">
          <div>
            <Card sx={{ maxWidth: 600 }}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="center-text"
                >
                  <b>
                    <img
                      src={armoiries}
                      alt=""
                      className="img-fluid"
                      width={50}
                    />
                    TYPE DE DEMANDE
                  </b>
                </Typography>
                <br></br>
                <Typography variant="body2" color="text.primary">
                  <h6>
                    <b>Intitulé de la Demande :</b> {empIntitule}
                  </h6>
                  <br></br>
                  <h6>
                    <b>Description :</b> {empDescript}
                  </h6>
                  <br></br>
                  <h6>
                    <b>Paiement sur la plateforme :</b> {empPaiement}
                  </h6>
                  <br></br>
                  <h6>
                    <b>Pièces à fournir :</b>
                  </h6>
                  <ul>
                    {empPiece.map((piece, index) => (
                      <li key={index}>{piece.intitule}</li>
                    ))}
                  </ul>
                  <br></br>
                  <h6>
                    <b>Niveaux de Validation :</b>
                  </h6>
                  <ul>
                    {empNivo.map((piece, index) => (
                      <li key={index}>{piece.intitule}</li>
                    ))}
                  </ul>
                </Typography>
              </CardContent>
              <button
                onClick={() => retour()}
                className="btn btn-sm btn-danger"
              >
                Retour <i className="bi bi-back"></i>
              </button>
            </Card>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Info_dmd;
