import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Navbar from "../Nav";

function Liste_Role() {
  const listURL = `${process.env.REACT_APP_API_URL}/roles/list`;
  const suprimeURL = `${process.env.REACT_APP_API_URL}/roles`;
  const [roles, setRoles] = useState([]);

  const supprimer = (id) => {
    axios
      .delete(`${suprimeURL}/${id}`) // Vous devriez concaténer l'ID à l'URL correctement
      .then((response) => {
        console.info(
          "le role identifiée par ID : " + id + " à été supprimé !"
        );
        window.location.reload();
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression du role : " + error);
      });
  };

  const roleDatas = () => {
    axios
      .get(listURL)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.error("Erreur d'obtention des données :" + error);
      });
  };

  useEffect(() => {
    roleDatas();
  }, []);

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            <Navbar/>
                    <h4 className="title-text">Liste des Roles</h4>

                 
                    {/* <Link to="/ajoute_role">
                      <button className="custom-button">
                        {" "}
                        <i className="fas fa-fw fa-plus"> </i> Ajouter
                      </button>
                    </Link> */}
                    <div className="card-body">
                      <table
                        id="example1"
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Intitulé</th>
                            <th>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {roles.length === 0 ? (
                            <tr>
                              <td colSpan="9" className="text-center">
                                Aucun role n'a été enregistré.
                              </td>
                            </tr>
                          ) : (
                            roles &&
                            roles.map((role) => (
                              <tr key={role.id}>
                                {" "}
                                {/* Assurez-vous que 'id' est un identifiant unique */}
                                <td className="text-noir">{role.id}</td>
                                <td className="text-noir">{role.libelle}</td>
                                <td className="text-noir">
                                  {role.description}
                                </td>
                                <td className="text-noir button-container d-flex align-items-center">
                                  <Link
                                    to={"/role/" + role.id}
                                    title="voir informations"
                                  >
                                    <div className="round-button d-flex justify-content-center align-items-center">
                                      <i className="bi bi-eye white-icon"></i>
                                    </div>
                                  </Link>
                                  <Link
                                    to={"/edit_role/" + role.id}
                                    title="modifier informations"
                                  >
                                    <div className="round-button d-flex justify-content-center align-items-center">
                                      <i className="bi bi-pencil white-icon"></i>
                                    </div>
                                  </Link>
                                  <div className="round-buttonr">
                                        <i
                                          className="bi bi-trash white-icon"
                                          onClick={() => supprimer(role.id)}
                                        ></i>
                                      </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
        </div>
      </body>
      <Footer />
    </div>
  );
}

export default Liste_Role;
