import React, { useState } from "react";

import Card from "./Card";
import Foot from "./Foot";
import Head from "./Head";

const Privacy = () => {
  const [activeAccordion, setActiveAccordion] = useState("");
  const [footerOpen, setFooterOpen] = useState(false);

  const handleAccordion = (accordionName) => {
    setActiveAccordion(activeAccordion === accordionName ? "" : accordionName);

    setFooterOpen(activeAccordion === accordionName ? false : true);
  };

  const privacyData = [
    {
      question: "Principes généraux ",
      answer: `La protection de votre vie privée est de la plus haute importance pour l’Administration publique. La présente déclaration de confidentialité décrit quelles mesures sont prises pour la protection de votre vie privée lors de l’utilisation des services organisés par ou sur ordre du Premier Ministre dans le cadre de l’e-gouvernement, et quels droits vous avez en tant qu’utilisateur de ces services.

      Toutes les données à caractère personnel (à savoir: les données permettant de vous identifier directement ou indirectement) que vous confiez à la Direction du secteur privé de santé seront traitées avec le soin nécessaire. Cela implique naturellement que tous les traitements de ces données à caractère personnel se feront conformément à la Loi n° 001‐2021/AN du 30 mars 2021 portant protection des personnes à l'égard du traitement des données à caractère personnel (Abroge la loi n° 010‐2004/AN du 20 avril 2004 portant protection des données à caractère personnel).
      
      Vous êtes invité(e) à parcourir attentivement la présente déclaration de confidentialité et à prendre connaissance de son contenu. Des adaptations futures de la présente déclaration de confidentialité ne sont pas à exclure. Nous vous demandons dès lors de relire de temps en temps la déclaration de confidentialité afin de rester au courant de ces adaptations. Il va de soi que toutes les nouvelles versions de la déclaration de confidentialité seront toujours conformes à la Loi précitée relative à la protection de la vie privée.
      
      La présente déclaration de confidentialité générale n’examine pas de manière détaillée la spécificité de catégories particulières de données comme les données médicales. Nous vous renvoyons à cette fin aux services publics traitant ce type de données. Ces services prévoiront une déclaration complémentaire pour ce type de données.
      
      Les services publics qui le font néanmoins dans le cadre de leurs tâches, le mentionneront dans une déclaration complémentaire.`
    },
    {
      question: " Champ d’application de la déclaration de confidentialité",
      answer: `La déclaration de confidentialité s’applique à tous les services organisés par ou sur ordre du Ministère de la Santé dans le cadre de la dématérialisation des procédures.

      Au sens de la présente déclaration de confidentialité, sont considérés comme services:
      • les applications offertes aux citoyens et entreprises par ou sur ordre du Ministère de la Santé, soit en tant qu’applications autonomes, soit en tant qu’applications basées sur le web qui sont intégrées dans un site web
      • les sites web créés et maintenus par ou sur ordre du Ministère de la Santé et qui sont destinés à être utilisés par des citoyens ou des entreprises, à l’exception de sites web internes qui ne sont pas accessibles au grand public
      • toute forme de communication électronique qui est utilisée pour l’échange de données entre le Ministère de la Santé d'une part et les citoyens et entreprises d'autre part.
      
      Veuillez de préférence tenir compte du fait que les différents services offerts par ou sur ordre du Ministère de la Santé sont aussi soumis, en raison de leur caractère spécifique, à des déclarations de confidentialité spécifiques. Avant de vous connecter pour utiliser un service, il est donc préférable que vous contrôliez si le service a une déclaration de confidentialité distincte, qui comprend une réglementation plus détaillée pour ce service spécifique. Il va de soi que ces déclarations de confidentialité spécifiques seront aussi conciliables avec la Loi précitée relative à la protection de la vie privée et qu’elles ne seront jamais contraires à la présente déclaration de confidentialité générale.
      
      Notez aussi que les sites web créés et maintenus par ou sur ordre du Ministère de la Santé peuvent comporter des liens vers d’autres sites web d’autorités, d’instances et d’organisations sur lesquels le Ministère de la Santé n’exerce aucun contrôle technique ou relatif au contenu. Le Ministère de la Santé ne peut dès lors offrir aucune garantie sur la politique en matière de vie privée de ces sites web. Le Ministère de la Santé n’accepte donc aucune responsabilité en cas de dommages directs ou indirects découlant de la consultation ou de l’utilisation de ces sites web externes. Nous vous conseillons de prendre connaissance de la politique en matière de vie privée de ces sites web.`
    },
    {
      question: "Accès public et anonyme",
      answer: `L’administration utilise différents niveaux de sécurisation pour les différents services qu’elle offre. Le niveau le plus faible se compose de services offerts sur base publique et anonyme.

      Pour les services offerts sur base publique et anonyme (c’est-à-dire sans procédure d’enregistrement préalable ou sans transfert ultérieur de données), seul un nombre limité de données peuvent être recueillies. Il s’agit de données découlant de l’utilisation de cookies et de la journalisation.
      
      Les cookies sont de petits fichiers d’informations qui sont enregistrés automatiquement sur le disque dur de votre ordinateur quand vous visitez un site, et qui comprennent un numéro d’identification unique. Ces cookies permettent de simplifier l’accès au site ainsi que la navigation, et accroissent la vitesse et l’efficacité d’utilisation de ces derniers. Ils peuvent aussi être utilisés pour individualiser le site selon vos préférences personnelles. Les cookies permettent également d’analyser l’utilisation d’un site pour identifier et supprimer d’éventuels embarras.
      
      La configuration de la plupart des navigateurs vous permet d’accepter ou de refuser des cookies, et de vous signaler à chaque fois quand un cookie est utilisé. Libre à vous de refuser ces cookies, bien que cela puisse nuire à la navigation optimale et à la fonctionnalité des services sur le site. Veuillez consulter la fonction d’aide de votre navigateur pour plus d’informations.
      
      Sauf indication contraire dans la déclaration de confidentialité d’un site spécifique, tous les cookies attribués sont automatiquement effacés à la fin d’une session d’utilisation.
      
      Par ailleurs, toute utilisation que vous faites des services qui sont créés par ou sur ordre du Ministère de la Santé peut être journalisée dans le cadre de la dématérialisation des procédures. Cela signifie que les données suivantes peuvent être collectées:
      
      • l’adresse IP qui vous a été attribuée au moment de la connexion
      • les date et heure d’accès au service
      • les pages éventuellement consultées pendant un certain temps
      • le navigateur éventuellement utilisé
      • la plate-forme et/ou le système d'exploitation installé(e) sur votre ordinateur
      • le moteur de recherche et les mots-clés utilisés pour trouver le site du service
      • les fichiers téléchargés
      • le choix de la langue
      • le site par lequel vous êtes arrivé

      Ces données ne seront traitées que pour connaître le nombre d’utilisateurs des différentes sections des services et pour améliorer le contenu des services.
      
      Elles ne seront jamais utilisées pour établir un profil du citoyen.`
    },
    {
      question: "Transfert volontaire d’informations",
      answer: `Pour pouvoir utiliser certains services avec un niveau de sécurisation supérieur, vous serez invité à communiquer certaines données à caractère personnel au Ministère de la Santé. A cette fin, seules les données nécessaires pour pouvoir fournir un service de haute qualité seront demandées.

      A l’égard de ces données, les mesures nécessaires seront prises pour garantir la protection de votre vie privée, comme il sera décrit ci-dessous. Notez que des services spécifiques peuvent remplir ces garanties de manière plus détaillée au moyen d’une déclaration de confidentialité distincte. Il est donc préférable que vous vérifiez si le service dispose d’une déclaration de confidentialité distincte. Le niveau de sécurité utilisé sera également indiqué clairement dans la déclaration de confidentialité ou le règlement à l’usage des utilisateurs de chaque service.
      
      Les services offerts avec un niveau de sécurité supérieur posent une série d’exigences typiques relatives à votre identification et votre authentification.
      
      Selon le niveau de sécurité, on vous demandera de transmettre une série de données en vue de votre enregistrement, identification et authentification.
      
      Cela permet l’attribution du numéro d'utilisateur afin que les services publics puissent vous identifier correctement lors de l’utilisation des services électroniques.
      
      Pour ce faire, vous pourrez éventuellement aussi utiliser votre carte d'identité.
      
      Outre ces données qui sont nécessaires pour la fourniture des services, vous pourrez aussi être invité(e) à donner un certain feed-back. Bien évidemment, vous restez entièrement libre d’accepter ou de refuser cela. En envoyant ce feed-back, vous nous donnez l’autorisation de traiter les données à caractère personnel envoyées afin d’améliorer l’expérience d’utilisateur ou de permettre de nouveaux services.`
    },
    {
      question: "Utilisation d’informations de contact",
      answer: `Pour pouvoir utiliser les services, on vous demandera parfois vos informations de contact (comme l’adresse e-mail, le numéro de téléphone ou le numéro de fax). Ces données ne seront traitées par le Ministère de la Santé qu’afin d’assurer le bon fonctionnement du service, c’est-à-dire:
      • pour vous contacter le cas échéant pour des données supplémentaires nécessaires afin de garantir le fonctionnement correct du service; ou
      • pour vous fournir toutes les informations utiles et pertinentes concernant des changements dans la prestation de services qui peuvent influencer de manière non-triviale votre expérience d’utilisateur, comme des messages relatifs à la gestion des utilisateurs ou des interruptions de services; ou
      • éventuellement pour signaler des modifications à la déclaration de confidentialité ou aux règlements à l’usage des utilisateurs qui s’appliquent, afin de vous tenir au courant de vos droits et obligations en tant qu’utilisateur; ou
      • si vous en donnez explicitement l’autorisation, votre adresse e-mail sera utilisée par les autorités fédérales, régionales et locales pour communiquer de manière électronique avec vous.
      
      Ces informations de contact ne seront en aucun cas utilisées à des fins publicitaires ou transférées à des tiers. Les institutions chargées de remplir un service public ou de défendre une tâche d’intérêt général sur la base d’une mission de l’administration, indépendamment de leur forme juridique, ne sont pas considérées comme des tiers au sens du présent article s’ils sont directement ou indirectement impliqués dans la fourniture des services auxquels s’applique la présente déclaration de confidentialité.
      
      Il va de soi que ces données seront traitées de manière confidentielle, conformément aux dispositions de la Loi précitée relative à la protection de la vie privée.
      
      Si vous contactez le Ministère de la Santé par e-mail pour obtenir des renseignements ou demander des publications, l’administration obtient automatiquement certaines données à caractère personnel. Ces données seront utilisées pour répondre à votre message. Elles peuvent aussi être enregistrées dans une base de données d’utilisateurs, à laquelle s’appliquent les dispositions de la Loi relative à la protection de la vie privée. Vous avez le droit de consulter vos données, de les modifier ou de les faire supprimer.
      
      `
    },
    {
      question: "Sécurité et confidentialité",
      answer: `Le Ministère de la Santé entreprend toutes les démarches nécessaires pour garantir la sécurisation de vos données à caractère personnel. Pour veiller à ce que vos données soient protégées contre notamment l’accès non autorisé, l’utilisation illégitime, la perte ou des modifications non autorisées, les services utilisent différentes techniques et procédures de sécurisation. Tant sur le plan physique qu’électronique et organisationnel, les mesures nécessaires ont été prises pour garantir la sécurité et la confidentialité de vos données à caractère personnel.

      Ainsi, par exemple, les données à caractère personnel que vous fournissez sont enregistrées sur des serveurs qui ne sont accessibles qu’à du personnel compétent. Ce personnel a été averti de la présente déclaration de confidentialité et de toutes les directives internes applicables qui ont été édictées pour la protection de vos données à caractère personnel. Ils sont obligés de respecter la confidentialité de vos données à caractère personnel.
      
      Les données à caractère personnel sensibles qui sont envoyées via Internet seront en outre sécurisées au moyen de codage, par exemple en utilisant le protocole SSL (Secure Socket Layer).
      
      Les informations spécifiques sur les mesures qui ont été prises pour la protection de la sécurité et de la confidentialité dans un service spécifique seront le cas échéant reprises dans la déclaration de confidentialité distincte de ce service.
      
      Vos données à caractère personnel ne seront transférées à des tiers que moyennant votre autorisation ou dans les conditions décrites ci-dessous.`
    },
    {
      question: "Légalité et transparence",
      answer: `Pour chaque service organisé par ou sur ordre du Ministère de la Santé dans le cadre de la dématérialisation des procédures, les informations suivantes seront clairement reprises dans la déclaration de confidentialité complémentaire de ce service:

      • les données à caractère personnel qui seront traitées
      • les finalités du traitement
      • le responsable du traitement correct
      • si les données peuvent être ou non transférées à des tiers, et si oui, à quels tiers
      • la source des données à caractère personnel éventuelles qui ne sont pas communiquées par vous-même, mais qui sont extraites de sources authentiques (comme le Registre national)
      • les informations de contact pour l’exercice de votre droit d’accès et de correction, comme décrit ci-dessous.
      
      Quand les données à caractère personnel ne sont pas communiquées par vous-même (par exemple parce que l’administration dispose déjà de ces données), et que le Ministère de la Santé n’agit qu’en tant qu’organisation intermédiaire, cette communication peut être omise à condition que le Ministère de la Santé ait reçu par ou en vertu d’une loi la mission formelle de collecter et de coder des données à caractère personnel, et qu’elle soit ainsi soumise à des mesures spécifiques fixées par ou en vertu de la loi protégeant votre vie privée.
      
      La norme juridique éventuelle sur la base de laquelle le traitement des données pour le service est autorisé sera aussi communiquée explicitement dans la déclaration de confidentialité de chaque service spécifique.
      
      Sauf indication explicite contraire dans la déclaration de confidentialité d’un service spécifique, le Ministère de la Santé est le responsable du traitement. Les informations de contact en cas de questions ou de remarques seront aussi enregistrées.`
    },
    {
      question: "Finalité",
      answer: `Dans chaque service organisé par ou sur ordre du Ministère de la Santé dans le cadre de la dématérialisation des procédures, la déclaration de confidentialité complémentaire de ce service décrira clairement quel objectif spécifique le traitement de vos données à caractère personnel sert dans le cadre de ce service, pour autant que cela n’ait pas déjà était clairement indiqué dans la présente déclaration de confidentialité. Les données à caractère personnel collectées lors de l’utilisation du service peuvent être traitées pour gérer et fournir le service.

      Dans le cadre du support des services organisés par ou sur ordre du Ministère de la Santé dans le cadre de la dématérialisation des procédures, vos données à caractère personnel peuvent être traitées afin d’améliorer votre expérience d’utilisateur et de simplifier l’utilisation des services du fait que vous ne devez plus encoder fréquemment les mêmes données ou que les services peuvent être adaptés à vos préférences ou intérêts.
      
      Dans l’organisation de chaque service, on utilisera à cette fin dans la mesure du possible ce qu’on appelle les « sources authentiques ». Il s’agit de sources de données (bases de données) qui sont tenues à jour sur ordre du Ministère de la Santé et où des données officielles relatives à une personne ou à une entreprise sont collectées. L’accès aux données dans les sources authentiques est contrôlé et autorisé par des comités sectoriels (institués au sein de la Commission de la Protection de la Vie Privée ) qui veillent au respect de la législation relative à la protection de la vie privée.
      
      Concrètement, cela signifie que des données des sources authentiques (par exemple le Registre national) sont utilisées pour identifier des utilisateurs, et qu’après cette identification, l’accès peut être donné à d’autres bases de données. La vie privée des utilisateurs peut ainsi être protégée de manière optimale: comme il n’y a qu’une seule source, la protection des données peut être organisée de manière centrale.
      Les informations collectées par l’utilisation d’un service peuvent être combinées aux données à caractère personnel qui sont collectées par l’utilisation d’autres services, afin que votre interaction avec l’Ministère de la Santé soit la plus cohérente possible.
      
      La norme juridique qui sert de base au traitement de données sera aussi communiquée explicitement dans la déclaration de confidentialité de chaque service spécifique.
      
      `
    },
    {
      question: "Proportionnalité",
      answer: `Le Ministère de la Santé veille à ce que les données à caractère personnel collectées soient adéquates, pertinentes et non excessives, compte tenu de la finalité du traitement telle qu’indiquée dans la déclaration de confidentialité de chaque service spécifique. Vos données à caractère personnel ne seront pas conservées pendant une durée excédant celle nécessaire à la réalisation de finalité du traitement, à moins que la conservation des données ne soit imposée par loi, décret ou ordonnance.`
    },
    {
      question: "Droit d’accès et de correction",
      answer: `Vous avez le droit d’accéder à vos données à caractère personnel et de les corriger.
      
      Pour tout service organisé par ou sur ordre du Ministère de la Santé dans le cadre de la dématérialisation des procédures, une procédure sera reprise dans la déclaration de confidentialité de ce service afin d’exercer ces droits.
      
      Y seront reprises les informations de contact nécessaires qui vous permettront d’obtenir des informations sur:
      
      • les données à caractère personnel elles-mêmes qui sont traitées dans le cadre du service et qui vous concernent
      • l’origine de ces données
      • les finalités du traitement
      • les destinataires auxquels les données à caractère personnel peuvent être fournies
      • la logique à la base d’un éventuel traitement automatisé de ces données à caractère personnel si ce traitement a des conséquences juridiques pour vous ou s’il vous affecte autrement de manière significative
      • les démarches que vous pouvez entreprendre pour corriger ou compléter des données éventuellement incorrectes ou incomplètes
      • la possibilité d’éventuellement s’opposer au traitement, lorsque vous disposez de ce droit. C’est seulement le cas lorsque le traitement n’est pas nécessaire pour respecter une obligation à laquelle le responsable du traitement est soumis par ou en vertu d’une loi, d’un décret ou d’une ordonnance, et lorsque vous avez des raisons sérieuses et légitimes de vous opposer en ce qui concerne votre situation particulière.`
    },
    {
      question: "Responsabilité de l’utilisateur",
      answer: `Bien que le Ministère de la Santé fasse tous les efforts possibles pour protéger votre vie privée, une protection effective n’est naturellement possible que si vous prenez aussi vous-même les mesures nécessaires pour préserver votre vie privée.

      Concernant l’utilisation des services organisés par ou sur ordre du Ministère de la Santé dans le cadre de la dématérialisation des procédures, vous êtes tenu de:
      
      • fournir des informations complètes, précises, véridiques et non-mensongères ;
      • veiller dûment à la confidentialité d’éventuelles données d’utilisateur (comme un nom d’utilisateur, un mot de passe, un token ou un code PIN), afin que ces données ne restent accessibles qu’à vous. Ces données sont strictement personnelles et non transmissibles;
      • choisir un mot de passe sûr. Cela signifie qu’il doit être composé de 8 caractères au moins combinant à la fois des lettres et d’autres caractères, qui sont placés dans un ordre ne pouvant être découvert facilement. Chaque utilisateur est personnellement responsable en cas de découverte et/ou d’abus d’un mot de passe n'ayant pas été composé selon ces règles;
      • transmettre des informations de contact valables et utilisables afin que vous puissiez être contacté dans un délai raisonnable et de façon raisonnablement confidentielle.`
    },
    {
      question: " Transfert à des tiers",
      answer: `Vos données à caractère personnel ne seront jamais mises à la disposition de tiers sauf lorsqu’un tel transfert est imposé par ou en vertu d’une loi, d’un décret ou d’une ordonnance. Les institutions chargées de fournir un service public ou de remplir une tâche d’intérêt général sur la base d’une mission de l’administration, indépendamment de leur forme juridique, ne sont pas considérées comme des tiers au sens du présent article quand la collaboration avec ces institutions est indispensable pour la mise en oeuvre correcte des services à fournir. Cette collaboration avec d’autres branches de l’administration (y compris à d’autres niveaux, comme les communautés, les régions et les communes) sera réglée au moyen d’accords-cadres ou d’accords de protocole.

      Si vos données à caractère personnel sont éventuellement transférées à ce type d’institutions, la déclaration de confidentialité du service en question indiquera de quelles données à caractère personnel il s’agit, et à quel traitement elles seront soumises par cette institution.
      
      Vos données à caractère personnel ne seront jamais utilisées à des fins publicitaires ni transférées à des tiers qui utiliseraient ces données à de telles fins.
      
      Vos données à caractère personnel ne seront jamais traitées par une institution ou une entreprise établie en dehors de l'espace burkinabè.`
    },
    {
      question: "Publicité de l’administration",
      answer: `Par la loi relative à la publicité de l'administration, les autorités administratives sont tenues de donner accès aux documents administratifs dont elles disposent. Cela signifie que tout citoyen a le droit de consulter des documents administratifs d'une autorité administrative et d’en recevoir une copie. Toutes les informations dont dispose la Direction du secteur privé de santé dans le cadre des services organisés par ou sur ordre du Ministère de la Santé dans le cadre de la dématérialisation des procédures peuvent être considérées comme des documents administratifs, et sont donc soumises aux dispositions légales relatives à la publicité de l'administration.

      Pour les documents administratifs à caractère personnel (à savoir des documents comportant une appréciation ou un jugement de valeur relatif à une personne physique nommément désignée ou aisément identifiable, ou la description d'un comportement dont la divulgation peut manifestement causer un préjudice à cette personne), ce droit à la publicité ne s’applique cependant que lorsque le demandeur justifie d’un intérêt personnel, légitime, actuel, direct et certain.
      
      Les demandes d’accès à de tels documents administratifs peuvent être adressées à l’autorité administrative compétente qui est désignée dans la déclaration de confidentialité du projet spécifique. Cette autorité peut refuser la demande, notamment lorsqu’elle est tenue à une obligation légale de secret, ou lorsqu’elle juge que la publication du document administratif porte préjudice à la vie privée de la personne concernée, à moins que cette dernière ait donné son accord concernant la consultation du document, les explications y relatives ou sa communication sous forme de copie. L’autorité administrative  qui ne peut répondre immédiatement à une demande de publicité ou qui la rejette, informe le demandeur, dans un délai de trente jours suivant la réception de la demande, des raisons du report ou du rejet. En cas de report, le délai ne peut jamais être prolongé de plus de quinze jours.
      
      Il va de soi que ces règles ne s’appliquent qu’aux autorités administratives. A d’autres niveaux de pouvoir (comme les communautés, les régions et les communes), d’autres règles juridiques peuvent s’appliquer. Si une autorité à un autre niveau de pouvoir dispose d’informations que vous voulez obtenir, il est préférable que vous vérifiez quelles règles s’appliquent à ce niveau concernant la publicité de l’administration.
      
      Les informations traitées en tant que partie des services peuvent, après un certain temps, aussi être déposées volontairement aux Archives du Royaume à la requête de l’autorité publique à laquelle elles appartiennent, si ces informations ne sont plus utiles pour l’administration. Après ce dépôt, les informations sont en principe publiques. Dans ce cas aussi, l’accès aux archives reste soumis à la législation relative à la publicité de l’administration. L’accès peut donc notamment être refusé si l’archiviste juge qu’il est tenu à une obligation légale de secret , ou lorsque la publication du document administratif porte préjudice à la vie privée de la personne concernée, à moins que cette dernière ait donné son accord concernant la consultation du document, les explications y relatives ou sa communication sous forme de copie.
      
      Ces archives peuvent être enregistrées pour une durée illimitée aux Archives, et ne peuvent être détruites qu’avec l’autorisation de l’autorité qui a transmis les documents.`
    },
    {
      question: "Adaptations à la déclaration de confidentialité",
      answer: `Des adaptations futures de la présente déclaration de confidentialité ne sont pas à exclure. Nous vous demandons dès lors de relire de temps en temps la déclaration de confidentialité pour rester au courant de ces adaptations. Après chaque adaptation, la date à laquelle le présent document aura été mis à jour pour la dernière fois sera aussi modifiée. Il va de soi que toutes les nouvelles versions de la déclaration de confidentialité seront toujours conformes à la Loi précitée relative à la protection de la vie privée.

      Si la déclaration de confidentialité est adaptée de manière non-triviale, vous en serez averti(e) dans la mesure du possible. Pour ce faire, on pourra utiliser notamment une communication sur le site web du service et les informations de contact que vous aurez éventuellement communiquées lors de l’enregistrement pour l’utilisation du service.
      
      Toute utilisation faite des services est soumise aux dispositions de la version de la déclaration de confidentialité qui s’applique à ce moment-là.`
    },
    // Ajoutez plus de questions et réponses ici
  ];

  const formatAnswer = (answer) => {
    const keywords = ['Ministère de la Santé', 'Direction du secteur privé de santé', "Loi n° 001‐2021/AN du 30 mars 2021 portant protection des personnes à l'égard du traitement des données à caractère personnel"];
    const regex = new RegExp(`(${keywords.join('|')})`, 'gi');

    //return answer.replace(regex, '<strong>$1</strong>');
    const formattedAnswer = answer.replace(regex, '<strong>$1</strong>');
    return formattedAnswer.replace(/\n/g, '<br>');
  };

  return (
    <div>
      <div>
        {/* Head */}
        {<Head />}
      </div>

      <div className="content">
        {/* Contenu scrollable */}
        <br /><br />
        <h3 style={{ textAlign: 'center', color: "black" }}><b> Politique de confidentialité</b></h3><br />
        <section id="apropos" className="d-flex align-items-center accordion" style={{ color: "black" }}>
          <div className="container" id="confidentialite" >
            <p><b>Le Ministère de la Santé (MS)</b> respecte la vie privée des utilisateurs. Bien que la plupart des informations soient disponibles sur ce site sans devoir fournir des données à caractère personnel, il est possible que des informations personnelles soient demandées à l’utilisateur. Dans ce cas, les données sont traitées conformément aux dispositions de la loi relative au traitement de données à caractère personnel.<br />

              Concrètement, cela signifie notamment que :<br />

              • les données à caractère personnel ne peuvent être recueillies et traitées que dans le but de répondre à la demande d’information que vous avez introduite ;<br />
              • les données à caractère personnel ne seront pas communiquées à des tiers ni utilisées à des fins commerciales ;<br />
              • vous avez le droit de consulter vos données personnelles et que vous pouvez vérifier leur exactitude et faire corriger les éventuelles erreurs les concernant. A cet effet, vous pouvez prendre contact avec le gestionnaire du site.<br />
              • Le Ministère de la Santé s’engage à prendre les meilleures mesures de sécurité afin d’éviter que des tiers n’abusent des données à caractère personnel que vous avez communiquées.</p>

          </div>

        </section>


        <section id="apropos" className="d-flex align-items-center accordion">
          <div className="container" id="confidentialite">

            <div className="accordion-item">

              <div >

                {privacyData.map((privacy, index) => (

                  <div
                    key={index}
                    className={`border rounded-lg overflow-hidden transition-colors duration-300 ${activeAccordion === privacy.question ? 'bg-blue-100' : 'bg-white'
                      }`}
                  >
                    <div
                      className="p-4 cursor-pointer flex items-center"
                      onClick={() => handleAccordion(privacy.question)}
                    >

                      <h4 className="text-lg font-semibold text-gray-700"><span className="mr-2 text-xl">
                        {` ${activeAccordion === privacy.question ? '▲' : '▼'} Article ${index + 1} :`}
                      </span> {privacy.question}</h4>
                    </div>
                    {activeAccordion === privacy.question && (
                      <div className="px-4 pb-4">
                        <p
                          className="text-sm text-gray-600"
                          dangerouslySetInnerHTML={{ __html: formatAnswer(privacy.answer) }}
                        />
                      </div>
                    )}
                  </div>
                ))}

              </div>
            </div>
            <br />
          </div>
        </section>

      </div>
      <div>
        {/* Footer */}
        <Foot />
      </div>

    </div>



  );

}

export default Privacy;