import React from 'react';

import { Form } from "react-bootstrap";

const FormStepMorale = ({ demandeDTO, handleInputChange }) => {

  return (
    <>
      <div className="row">
        <div className="col-md-6 form-group mb-5">
          <label className="col-form-label" style={{ color: 'black' }}>
            <b>  Nom de la structure <span style={{ color: 'red' }}> (obligatoire)</span></b>
          </label>
          <Form.Control
            value={demandeDTO.nomStructure || ''}
            name="nomStructure"
            onChange={handleInputChange}
            placeholder="Nom de la structure"
            className="form-control"
            type="text"
            required
          />
        </div>
        <div className="col-md-6 form-group mb-5">
          <label className="col-form-label" style={{ color: 'black' }}>
            <b>  Numéro de l'Arrêté de création (ex: N°2024-238/MSHP/CAB du 05/07/2024)<span style={{ color: 'red' }}> (obligatoire)</span></b>
          </label>
          <Form.Control
            value={demandeDTO.numArreteCreation || ''}
            name="numArreteCreation"
            onChange={handleInputChange}
            placeholder="Numéro de l'Arrêté de création"
            type="text"
            className="form-control"

            required
          />
        </div>


      </div>
    </>
  );
};

export default FormStepMorale;