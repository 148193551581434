/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../Nav";
import { Form, Button } from "react-bootstrap";
import Header from "../Header";
import UserService from "../service/UserService";
import Swal from "sweetalert2";

function Edit_userm() {

  const navigate = useNavigate();
  const { userId } = useParams();

  const [userData, setUserData] = useState({
    nom: '',
    prenom: '',
    email: '',
    tel: '',
    password: '',
    actif: '',
    role: {
      id: ''
    },
    direction: {
      id: ''
    }
  });

  const getUserById = async (userId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await UserService.getById(userId, token);
      const { nom, prenom, email, tel, actif, role: { id } } = response;
      setUserData({ nom, prenom, email, tel, actif, role: { id }, direction: { id } });
    } catch (error) {
      console.error("Erreur de récupération de user data:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      getUserById(userId)
    }
  }, [userId]);

  const roleUrl = `${process.env.REACT_APP_API_URL}/roles/list`;
  const directionUrl = `${process.env.REACT_APP_API_URL}/directions/list`;

  const [role, setRole] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [direction, setDirection] = useState("");
  const [directionList, setDirectionList] = useState([]);

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
    setUserData({
      ...userData,
      role: {
        id: selectedRole
      }
    });
  };
  const handleDirectionChange = (e) => {
    const selectedDirection = e.target.value;
    setDirection(selectedDirection);
    setUserData({
      ...userData,
      direction: {
        id: selectedDirection
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const roleDatas = () => {
    axios
      .get(roleUrl)
      .then((response) => {
        setRoleList(response.data);
      })
      .catch((error) => {
        console.error("Erreur d'obtention des données :" + error);
      });
  };

  useEffect(() => {
    roleDatas();
  }, []);

  const directionData = () => {
    axios
      .get(directionUrl)
      .then((response) => {
        setDirectionList(response.data);
      })
      .catch((error) => {
        console.error("Erreur d'obtention des données :" + error);
      });
  };

  useEffect(() => {
    directionData();
  }, []);

  const [error, setError] = useState({});

  const validate = () => {
    const newErrors = {};

    if (!userData.nom) {
      newErrors.nom = 'Le nom est requis';
    }

    if (!userData.prenom) {
      newErrors.prenom = 'Le prénom est requis';
    }

    if (!userData.email) {
      newErrors.email = 'L\'email est requis';
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      newErrors.email = 'L\'email n\'est pas valide';
    }

    if (!userData.tel) {
      newErrors.tel = 'Le numéro de téléphone est requis';
    } else if (!/^\d+$/.test(userData.tel)) {
      newErrors.tel = 'Le numéro de téléphone n\'est pas valide';
    }

    /* if (!userData.password) {
      newErrors.password = 'Le mot de passe est requis';
    } else if (userData.password.length < 6) {
      newErrors.password = 'Le mot de passe doit contenir au moins 6 caractères';
    } */

    setError(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {

      try {
        const confirmAction = Swal.fire('Utilisateur modifié avec succès');
        if (confirmAction) {
          const token = sessionStorage.getItem('token');
          const res = await UserService.updaterUser(userId, userData, token);
          navigate('/liste_user_metier');
          //alert("Utilisateur modifié avec succès");
        }
        /* Swal.fire({
          title: "MAJ",
          text: "Êtes-vous sùr de vouloir modifier cet Utilisateur?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, Maj!"
        }).then(async (result) => {
          if (result.isConfirmed) {
            await UserService.updaterUser(userId, userData, token);
            Swal.fire({
              title: "Maj!",
              text: "Utilisateur Maj !.",
              icon: "success"
            });
              
            }
          });
          navigate('/liste_user_metier'); */
      } catch (error) {
        console.error("Erreur de mis à jour de l'utilisateur: ", error);
      }
    }
  }


  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          <Header />
          <div className="custom-wrapper col-md-10">
            {/* <Navbar /> */}

            <h2 className="title-text">Mise à jour de l'utilisateur</h2>
            <Form onSubmit={handleSubmit} className="mb-5">
              <div className="row">
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Nom{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label> {error.nom && <span>{error.nom}</span>}
                  <Form.Control
                    type="text" name="nom"
                    value={userData.nom}
                    onChange={handleInputChange}
                    required
                  />

                </div>
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Prénom(s){" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    type="text" name="prenom"
                    value={userData.prenom}
                    onChange={handleInputChange}
                    required
                  /> {error.prenom && <span>{error.prenom}</span>}

                </div>
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Email{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    type="text" name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                    required
                  /> {error.email && <span>{error.email}</span>}

                </div>
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Téléphone
                  </label>
                  <Form.Control
                    type="text" name="tel"
                    value={userData.tel}
                    onChange={handleInputChange}
                    required
                  /> {error.tel && <span>{error.tel}</span>}

                </div>
                <div className="col-md-6 form-group mb-6">
                  <label className="col-form-label" style={{ color: "black" }}>
                    Mot de passe{" "}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                  <Form.Control
                    type="password" name="password"
                    value={userData.password}
                    onChange={handleInputChange}

                  />
                  {/* {error.password && <span>{error.password}</span>} */}

                </div>
                <div className="col-md-6 form-group mb-5">
                  <label
                    className="col-form-label"
                    style={{ color: "black" }}
                  >
                    Statut{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Select
                    name="actif"
                    value={userData.actif}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  >
                    <option value="actif.value">
                    </option>
                    <option value="true">
                      Activer{" "}
                    </option>
                    <option value="false">
                      Désactiver{" "}
                    </option>

                  </Form.Select>
                </div>
                <div className="col-md-6 form-group mb-5">
                  <label
                    className="col-form-label"
                    style={{ color: "black" }}
                  >
                    Role{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    name="role"
                    value={role}
                    onChange={handleRoleChange}
                    className="form-control"
                    id="role"
                    required
                  >
                    <option value="">
                      Sélectionnez un rôle{" "}
                    </option>

                    {roleList.map((rol, index) => (
                      <option value={rol.id}>
                        {rol.libelle}
                      </option>
                    ))}

                  </select>
                  {console.log("value:", userData)}
                </div>
                <div className="col-md-6 form-group mb-5">
                  <label
                    className="col-form-label"
                    style={{ color: "black" }}
                  >
                    Direction{" "}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>

                  <select
                    name="direction"
                    value={direction}
                    onChange={handleDirectionChange}
                    className="form-control"
                    id="direction"
                     required
                  >
                    <option value="">
                      Sélectionnez une direction{" "}
                    </option>

                    {directionList.map((dir, index) => (
                      <option value={dir.id}>
                        {dir.sigle}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 form-group">
                  <Button
                    className="btn btn-primary w-100"
                    type="submit"
                  >
                    <i className="fas fa-save"></i> Mettre à jour
                  </Button>
                  <span className="submitting"></span>
                </div>
              </div>

            </Form>
          </div>

        </div>

      </body >
    </div >
  );
}

export default Edit_userm;