/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, } from "react-router-dom";

import Header from "../../Header";
import Footer from "../../Footer";
import UserService from "../../service/UserService";
import DemandeService from "../../service/DemandeService";
import Navbar from "../../Nav";

function ListeDemandeUser() {

  const [userDemande, setUserDemande] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6); // Nombre d'éléments par page
  const [userId, setUserId] = useState([]);
  const [userData, setUserData] = useState([]);
  const [role, setRole] = useState([]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUtilisateurs = userDemande.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const formatDate = (dateStr) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateStr).toLocaleDateString(undefined, options);
  };

  // Vérifier si l'utilisateur a un rôle autorisé

  // const sidebarContainerStyle = {
  //   maxHeight: "100vh", // La barre latérale s'étendra jusqu'à 100% de la hauteur de la fenêtre
  //   overflowY: "auto", // Ajoute une barre de défilement si le contenu dépasse la hauteur de la fenêtre
  // };

  // Fonction pour envoyer le token au serveur
  axios.defaults.debug = true;

  const token = sessionStorage.getItem("token");

  const getUser = async () => {
    console.log("Token valuee: ", token);
    try {
      // Vérifier si le token existe
      if (!token) {
        console.error("Token not found in sessionStorage");
        return;
      }

      // Envoyer la requête au serveur avec le token inclus dans les en-têtes
      const response = await UserService.getProfil(token);
      const userData = response
      setUserData(userData);
      setUserId(userData.id);

      const role = userData.role;
      setRole(role);

    } catch (error) {
      console.error("Error sending token to server:", error.message);
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getDemandeData();
  }, []);

  const getDemandeData = async () => {
    try {
      console.log(userId);
      const response = await DemandeService.getDemandeByUser(token);
      console.log("demandes:", response);

      // Trier les demandes par date de soumission du plus récent au plus ancien
      const sortedDemandes = response.sort((a, b) => new Date(b.dateSoumission) - new Date(a.dateSoumission));

      setUserDemande(sortedDemandes);
    } catch (error) {
      console.error("Error to fetch demandes list: :", error.message);
    }
  };

  // Calculez l'index de début et de fin pour l'affichage des éléments actuels sur la page

  /* const currentDemandes = userDemande.slice(
    indexOfFirstItem,
    indexOfLastItem
  ); */

  // Fonction pour changer de page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calcul du nombre total de pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(userDemande.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  // ...
  const telecharger = async (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-recipisse/${demandeId}`;

    try {
      // Envoi de la requête GET avec Axios
      const response = await axios.get(url, {
        responseType: 'blob', // Spécifie que la réponse sera un blob (pour les fichiers)
        headers: {
          'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
          'Content-Type': 'application/json',
        },
      });

      // Créer un objet URL pour le blob
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      link.href = downloadUrl;
      link.setAttribute('download', `recipisse_${demandeId}.pdf`); // Nom du fichier à télécharger
      console.log(token)
      // Ajouter le lien au DOM et simuler un clic pour le téléchargement
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Nettoyer le DOM

      // Libérer l'URL créée
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Erreur lors du téléchargement :', error);
    }
  };

  const telechargerRecepissePhysique = async (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/recipisse-physique/${demandeId}`;

    try {
      // Envoi de la requête GET avec Axios
      const response = await axios.get(url, {
        responseType: 'blob', // Spécifie que la réponse sera un blob (pour les fichiers)
        headers: {
          'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
          'Content-Type': 'application/json',
        },
      });

      // Créer un objet URL pour le blob
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      link.href = downloadUrl;
      link.setAttribute('download', `recipisse_physique_${demandeId}.pdf`); // Nom du fichier à télécharger
      console.log(token)
      // Ajouter le lien au DOM et simuler un clic pour le téléchargement
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Nettoyer le DOM

      // Libérer l'URL créée
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Erreur lors du téléchargement :', error);
    }
  };


  const telechargerLettre = async (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-lettre-rejet/${demandeId}`;

    try {
      // Envoi de la requête GET avec Axios
      const response = await axios.get(url, {
        responseType: 'blob', // Spécifie que la réponse sera un blob (pour les fichiers)
        headers: {
          'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
          'Content-Type': 'application/json',
        },
      });

      // Créer un objet URL pour le blob
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      link.href = downloadUrl;
      link.setAttribute('download', `lettre_de_rejet_${demandeId}.pdf`); // Nom du fichier à télécharger
      console.log(token)
      // Ajouter le lien au DOM et simuler un clic pour le téléchargement
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Nettoyer le DOM

      // Libérer l'URL créée
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Erreur lors du téléchargement :', error);
    }
  };

  const telechargerArrete = async (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-arrete/${demandeId}`;

    try {
      // Envoi de la requête GET avec Axios
      const response = await axios.get(url, {
        responseType: 'blob', // Spécifie que la réponse sera un blob (pour les fichiers)
        headers: {
          'Authorization': `Bearer ${token}`, // Assurez-vous d'utiliser le bon préfixe (Bearer, Token, etc.)
          'Content-Type': 'application/json',
        },
      });

      // Créer un objet URL pour le blob
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      link.href = downloadUrl;
      link.setAttribute('download', `Arrete_${demandeId}.pdf`); // Nom du fichier à télécharger
      console.log(token)
      // Ajouter le lien au DOM et simuler un clic pour le téléchargement
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Nettoyer le DOM

      // Libérer l'URL créée
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Erreur lors du téléchargement :', error);
    }
  };

  // Ajoutez d'autres sections du menu avec les rôles autorisés nécessaires...
  const getReadableStatus = (status) => {
    const statusMap = {
      "EN_COURS_PREVALIDATION": "En cours de prévalidation",
      "PREVALIDEE": "Prévalidée",
      "FAVORABLE": "Favorable",
      "EN_ATTENTE_COMPLEMENT": "En attente de complément",
      "EN_ATTENTE_COMISSION": "En commission",
      "ACCEPTEE_SOUS_RESERVE": "Acceptée sous réserves",
      "REJETEE": "Rejetée",
      "AJOURNEE": "Ajournée",
      "ACCEPTEE": "Acceptée",
      "REJETEE_DSPS": "Rejetée par la DSPS",
      // "NON_INSCRITE_A_OP": "Rejetée par l'Ordre professionnel",
      "REJETE_COM": "Rejetée par la commission",
      "OK_OPs": "Attente des vérifications des Ordres professionnels",
      "EN_ATTENTE_COMPLEMENT_COM": "En attente de complément par la commission"
    };
    return statusMap[status] || status; // Retourne le statut original si non trouvé dans le map
  };

  const getStatusColor = (status) => {
    const colorMap = {
      "EN_COURS_PREVALIDATION": "primary",
      "PREVALIDEE": "info",
      "FAVORABLE": "blue",
      "EN_ATTENTE_COMPLEMENT": "warning",
      "EN_ATTENTE_COMISSION": "secondary",
      "ACCEPTEE_SOUS_RESERVE": "info",
      "REJETEE": "danger",
      "AJOURNEE": "warning",
      "ACCEPTEE": "success",
      "REJETEE_DSPS": "danger",
      "NON_INSCRITE_A_OP": "danger",
      "REJETE_COM": "danger",
      "INSCRITE_A_OP": "info",
      "EN_ATTENTE_COMPLEMENT_COM": "warning"
    };
    return colorMap[status] || "secondary"; // Retourne "secondary" si non trouvé dans le map
  };

  return (

    <div id="page-top">
      <br></br>
      <div id="wrapper">
        {/* <Header /> Sidebar */}
        <div id="content-wrapper">
          <Navbar />
          <div id="content">

            <div className="col-xl-12 col-md-6 mb-6">
              <br></br><br></br><br></br>
              <h4 className="title-text">
                Suivre mes demandes
              </h4>
              <div className="card-body">
                <table className="table table-bordered table-striped  ">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>N° demande</th>
                      <th>Type de demande</th>
                      <th>Statut</th>
                      {/* <th>Observation</th> */}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentUtilisateurs.length === 0 ? (
                      <tr>
                        <td colSpan="9">
                          Aucune demande n'a été enregistré.
                        </td>
                      </tr>
                    ) : (
                      currentUtilisateurs.map((utili, index) => (
                        <tr key={index}>
                          {
                            <>
                              <td className="text-noir">
                                {utili.dateSoumission
                                  ? formatDate(utili.dateSoumission)
                                  : "N/A"}
                              </td>
                              <td className="text-noir">
                                {utili.numDemande}
                              </td>

                              <td className="text-noir">
                                {utili.typeDemande}/EXPLOITATION
                              </td>
                              <td className="text-noir">
                                <span className={`badge bg-${getStatusColor(utili.statutDemande)}`} style={{ top: '10px', right: '10px' }}>
                                  {getReadableStatus(utili.statutDemande)}
                                </span></td>
                            
                              <td className="text-noir">
                                <div className="button-container d-flex align-items-center">
                                  <Link
                                    to={"/demandes-view_user/" + utili.id}
                                    title="voir informations"
                                    className="me-2"
                                  >
                                    <div className="round-button d-flex justify-content-center align-items-center">
                                      <i className="bi bi-eye white-icon"></i>
                                    </div>
                                  </Link>

                                  {utili.statutDemande === "EN_COURS_PREVALIDATION" 
                                  && utili.typeProprioDemande === "MORALECONVENTIONNE" || utili.typeProprioDemande === "MORALESOCIETE" 
                                  && (
                                    <div
                                      className="round-button d-flex justify-content-center align-items-center me-2"
                                      title="Télécharger le récépissé"
                                      onClick={() => telecharger(utili.id)}
                                    >
                                      <i className="bi bi-download white-icon"></i>
                                    </div>
                                  )}
                                  {utili.statutDemande === "EN_COURS_PREVALIDATION" && utili.typeProprioDemande === "PHYSIQUE"&& (
                                    <div
                                      className="round-button d-flex justify-content-center align-items-center me-2"
                                      title="Télécharger le récépissé"
                                      onClick={() => telechargerRecepissePhysique(utili.id)}
                                    >
                                      <i className="bi bi-download white-icon"></i>
                                    </div>
                                  )}

                                  {(utili.statutDemande === "REJETE" || utili.statutDemande === "NON_INSCRITE_A_OP") && (
                                    <div
                                      className="round-buttonr d-flex justify-content-center align-items-center"
                                      title="Télécharger la lettre de rejet"
                                      onClick={() => telechargerLettre(utili.id)}
                                    >
                                      <i className="bi bi-download white-icon"></i>
                                    </div>
                                  )}

                                  {/* {(utili.statutDemande === "ACCEPTEE") && (
                                    <div
                                      className="round-buttonv d-flex justify-content-center align-items-center"
                                      title="Télécharger l'arreté "
                                      onClick={() => telechargerArrete(utili.id)}
                                    >
                                      <i className="bi bi-download white-icon"></i>
                                    </div>
                                  )} */}
                                </div>
                              </td>
                            </>
                          }
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {/* Pagination */}
                <div className="pagination">
                  {pageNumbers.map((pageNumber) => (
                    <button
                      key={pageNumber}
                      onClick={() => paginate(pageNumber)}
                      className={`page-number ${pageNumber === currentPage ? "selected-page" : ""
                        }`}
                    >
                      {pageNumber}
                    </button>
                  ))}
                </div>
              </div>
            </div>

          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ListeDemandeUser;
