import React, { useState } from "react";

import Foot from "../Foot";
import Head from "../Head";

const Ouverture = () => {
  const [activeAccordion, setActiveAccordion] = useState("");
  const [footerOpen, setFooterOpen] = useState(false);

  const handleAccordion = (accordionName) => {
    setActiveAccordion(activeAccordion === accordionName ? "" : accordionName);
    setFooterOpen(activeAccordion === accordionName ? false : true);
  };

  return (
    <div>
      <div>
        {/* Head */}
        {<Head />}
      </div>

      <div className="content">
        <br />
        <h3 style={{ textAlign: 'center', color: "black" }}>
          <b>COMPOSITION DU DOSSIER DE DEMANDE D'OUVERTURE/EXPLOITATION</b>
        </h3>
        <br />
        <section id="apropos" className="d-flex flex-column align-items-start accordion">
          <div className="container" id="confidentialite" style={{ color: '#333' }}>

            <h2
              style={{
                fontWeight: 'bold',
                fontSize: '1.5rem',
                color: '#0056b3',
                marginBottom: '1rem',
                cursor: 'pointer'
              }}
              className="accordion-header"
              onClick={() => handleAccordion("demande1")}
            >
              Composition de dossier à chargés en ligne
              <i className={`bi ${activeAccordion === "demande1" ? " bi-chevron-up" : " bi-chevron-down"}`}></i>
            </h2>
            <div className={`accordion-content ${activeAccordion === "demande1" ? "active" : ""}`} >
              <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                <p style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: '#0056b3',
                  marginBottom: '1rem'
                }}>
                  Personne physique :
                </p>
                <ul style={{ paddingLeft: '20px', color: '#333' }}>
                  <li>• une copie de l'arrêté d'autorisation de création délivrée par le Ministre chargé de la santé en cours de validité</li>
                  <li>• une attestation de non-engagement dans la fonction publique ou un arrêté de démission ou une cessation de service accompagnée d'un arrêté de mise en disponibilité ou un arrêté de mise à la retraite ;</li>
                  <li>• une déclaration sur l'honneur de non-engagement dans un établissement sanitaire privé de soins, de médecine physique et de réadaptation fonctionnelle et d'aide au diagnostic comme responsable technique;</li>
                  <li>• le procès-verbal de l'inspection du site, des locaux et équipements par les services compétents du Ministère en charge de la santé ;</li>
                  <li>• une attestation d'assurance des locaux ;</li>
                  <li>• une attestation d'assurance de responsabilité civile professionnelle ;</li>
                  <li>• un casier judiciaire datant de moins de trois mois du promoteur;</li>
                  <li>• la liste du matériel technique et des équipements requis ;</li>
                  <li>• la liste du personnel de santé requis et un dossier complet de chacun comprenant :
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>- Une copie légalisée de la carte nationale d'identité burkinabè ou du passeport en cours de validité ;</li>
                      <li>- les photocopies légalisées des diplômes ;</li>
                      <li>- une attestation d'inscription à l'ordre professionnel en cours de validité ;</li>
                      <li>- une attestation de non-engagement dans la fonction publique ou un arrêté de démission ou une cessation de service accompagnée d'un arrêté de mise en disponibilité ou un arrêté de mise à la retraite.</li>
                    </ul>
                  </li>
                </ul>
                <br />

                <p style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: '#0056b3',
                  marginBottom: '1rem'
                }}>
                  Personne Morale :
                </p>
                <ul style={{ paddingLeft: '20px', color: '#333' }}>
                  <li>• une copie de l’arrêté d’autorisation de création délivrée  par le Ministre chargé de la santé en cours de validité ;</li>
                  <li>• le procès-verbal de l’inspection du site, des locaux et équipements par les services compétents du Ministère en charge de la santé ;</li>
                  <li>• une attestation d’assurance des locaux ;</li>
                  <li>• une attestation d’assurance de responsabilité civile professionnelle ;</li>
                  <li>• la liste du matériel technique et des équipements requis ;</li>
                  <li>• le récépissé de reconnaissance d’existence à jour ou l’autorisation d’exercice/implantation au Burkina Faso pour la personne morale conventionnée ;</li>
                  <li>• la convention avec le Ministère en charge de la santé pour la personne morale conventionnée ;</li>
                  <li>• la justification d’immatriculation à un registre (RCCM ou registre des sociétés civiles ou des professions libérales) pour la personne morale non conventionnée ;</li>
                  <li>• le certificat d’immatriculation Identifiant Financier Unique (IFU) ;</li>
                  <li>• dossier des responsables techniques comprenant :
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>- un casier judiciaire datant de moins de trois mois  ;</li>
                      <li>- une attestation d'inscription à l'ordre professionnel en cours de validité ;</li>
                      <li>- une attestation de non-engagement dans la fonction publique ou un arrêté de démission ou une cessation de service accompagnée d'un arrêté de mise en disponibilité ou un arrêté de mise à la retraite.</li>
                    </ul>
                  </li>
                  <li>• la liste du personnel de santé requis et un dossier complet de chacun comprenant :
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>- Une copie légalisée de la carte nationale d'identité burkinabè ou du passeport en cours de validité ;</li>
                      <li>- les photocopies légalisées des diplômes ;</li>
                      <li>- une attestation d'inscription à l'ordre professionnel en cours de validité ;</li>
                      <li>- une attestation de non-engagement dans la fonction publique ou un arrêté de démission ou une cessation de service accompagnée d'un arrêté de mise en disponibilité ou un arrêté de mise à la retraite.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div>
        {/* Footer */}
        <Foot />
      </div>
    </div>
  );
}

export default Ouverture;