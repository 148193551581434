import React, { useState } from "react";

import Foot from "../Foot";
import Head from "../Head";

const Fermeture = () => {
  const [activeAccordion, setActiveAccordion] = useState("");
  const [footerOpen, setFooterOpen] = useState(false);

  const handleAccordion = (accordionName) => {
    setActiveAccordion(activeAccordion === accordionName ? "" : accordionName);

    setFooterOpen(activeAccordion === accordionName ? false : true);
  };



  return (
    <div>
      <div>
        {/* Head */}
        {<Head />}
      </div>

      <div className="content">
        {/* Contenu scrollable */}
        <br /><br />
        <h3 style={{ textAlign: 'center' }}>COMPOSITION DU DOSSIER DE DEMANDE DE FERMETURE

        </h3><br />
        <section id="apropos" className="d-flex flex-column align-items-start accordion">
          <div className="container" id="confidentialite" style={{ color: '#333' }}>
            <h2 style={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: '#0056b3',
              marginBottom: '1rem'
            }} className="accordion-header" onClick={() => handleAccordion("demande1")}>
              XXXXXXXXXXXXXXXXXXXXXXXXXXXXX
              <i className={`bi ${activeAccordion === "demande1" ? "bi-chevron-up" : "bi-chevron-down"}`}></i>
            </h2>
            <div className={`accordion-content ${activeAccordion === "demande1" ? "active" : ""}`}>
              <p style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#0056b3',
                marginBottom: '1rem'
              }}>
                XXXXXXXX :
              </p>
              <ul style={{ paddingLeft: '20px', color: '#333' }}>
                <li>• XXXXX ;</li>
                <li>• XXXXX ;</li>
                <li>• XXXXX ;</li>
                
              </ul><br />

              <p style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#0056b3',
                marginBottom: '1rem'
              }}>
                XXXXXXXX :
              </p>
              <ul style={{ paddingLeft: '20px', color: '#333' }}>
                <li>• XXXXX ;</li>
                <li>• XXXXX ;</li>
                <li>• XXXXX ;</li>
              </ul>
            </div>
          </div>
        </section>

      </div>

      <div>
        {/* Footer */}
        <Foot />
      </div>

    </div>



  );

}

export default Fermeture;