import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import Navbar from "../../Nav";

function ListeDemandeTermine() {
  const [demandes, setDemandes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Fonction pour formater la date
  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString('fr-FR');
  };

  // Fonction pour formater le type de demande
  const formatTypeDemande = (type) => {
    const typeMap = {
      'NONCONVENTIONNE': 'NON CONVENTIONNÉ',
      'CONVENTIONNE': 'CONVENTIONNÉ',
      'MORALECONVENTIONNE': 'MORALE CONVENTIONNÉ',
      'MORALENONCONVENTIONNE': 'MORALE NON CONVENTIONNÉ'
    };
    return typeMap[type] || type;
  };

  // Charger les demandes
  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/demandes/find-by-statut?statut=ACCEPTEE`,
          {
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        );
        setDemandes(response.data);
        setError(null);
      } catch (err) {
        setError("Erreur lors du chargement des demandes");
        console.error("Erreur:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchDemandes();
  }, []);

  // Calcul de la pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDemandes = demandes.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(demandes.length / itemsPerPage);

  return (
    <div id="page-top">
      <div id="content-wrapper">     
        <Navbar />
        <div className="d-flex flex-column" style={{ width: '100%', backgroundColor: 'white' }}>
          <div className="content">
            <div className="container-fluid">
              <h4 className="title-text mt-4 mb-4">Liste des Demandes Terminées</h4>

              {loading && <div className="text-center">Chargement...</div>}
              {error && <div className="alert alert-danger">{error}</div>}

              {!loading && !error && (
                <div className="card shadow">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead className="bg-black">
                          <tr>
                            <th className="px-3">Date</th>
                            <th className="px-3">Type de demande</th>
                            <th className="px-3">N° de la demande</th>
                            <th className="px-3">Demandeur</th>
                            <th className="px-3" style={{ width: '100px' }}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentDemandes.length === 0 ? (
                            <tr>
                              <td colSpan="5" className="text-center py-3">
                                Aucune demande terminée
                              </td>
                            </tr>
                          ) : (
                            currentDemandes.map((demande) => (
                              <tr key={demande.id} className="text-noir">
                                <td className="px-3">{formatDate(demande.createdAt)}</td>
                                <td className="px-3">{formatTypeDemande(demande.typeDemande)}</td>
                                <td className="px-3">{demande.numDemande}</td>
                                <td className="px-3">
                                  {demande.utilisateur?.nom} {demande.utilisateur?.prenom}
                                </td>
                                <td className="px-3 text-center">
                                <Link
                                    to={"/demandes-view/" + demande.id}
                                    title="voir informations"
                                    className="me-2"
                                  >
                                    <div className="round-button d-flex justify-content-center align-items-center">
                                      <i className="bi bi-eye white-icon"></i>
                                    </div>
                                  </Link>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>

                    {/* Pagination */}
                    {totalPages > 1 && (
                      <div className="d-flex justify-content-center py-3 bg-light border-top">
                        {[...Array(totalPages)].map((_, index) => (
                          <button
                            key={index + 1}
                            className={`btn btn-sm mx-1 ${
                              currentPage === index + 1 ? 'btn-primary' : 'btn-outline-primary'
                            }`}
                            onClick={() => setCurrentPage(index + 1)}
                          >
                            {index + 1}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ListeDemandeTermine;
