import React, { useEffect, useState } from "react";
import { User, Mail, Phone } from "lucide-react";
import UserService from "../service/UserService";

import Navbar from "../Nav";
import Footer from "../Footer";


const Profile = () => {
  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    const getUserProfile = async () => {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("Token not found in sessionStorage");
        return;
      }
      try {
        const response = await UserService.getProfil(token);
        setUserProfile(response);
      } catch (error) {
        console.error("Error fetching user profile:", error.message);
      }
    };

    getUserProfile();
  }, []);

  return (
    <body className="page-top">
      {/* <Header /> */}
      <Navbar />
      <br></br><br></br>
      <div className="profile-container">
        <div className="profile-header">
          <img
            className="profile-image"
            src={"img/undraw_profile.svg" || "/api/placeholder/150/150"}
            alt={`${userProfile.prenom} ${userProfile.nom}`}
          />
          <div className="profile-info">
            <div className="profile-status">{userProfile.statut}</div>
            <h2 className="profile-name">{userProfile.prenom} {userProfile.nom}</h2>
            <p className="profile-bio">{userProfile.bio || "Aucune biographie disponible."}</p>
          </div>
        </div>
        <div className="profile-details">
          <div className="detail-row">
            <ProfileDetail icon={<User />} label="Nom" value={userProfile.nom} />
            <ProfileDetail icon={<User />} label="Prénom(s)" value={userProfile.prenom || "Non spécifié"} />
          </div>
          <div className="detail-row">
            <ProfileDetail icon={<Mail />} label="Email" value={userProfile.email} />
            <ProfileDetail icon={<Phone />} label="Téléphone" value={userProfile.tel || "Non spécifié"} />
          </div>

          <div className="detail-row">

          </div>
        </div>
        <Footer />
      </div>

    </body>
  );
};

const ProfileDetail = ({ icon, label, value }) => (
  <div className="detail-item">
    <div className="detail-icon">{icon}</div>
    <div className="detail-content">
      <p className="detail-label">{label}</p>
      <p className="detail-value">{value}</p>
    </div>
  </div>
);

export default Profile;
