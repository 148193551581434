/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function Foot() {
  const resetTutorial = () => {
    sessionStorage.removeItem('tutorialCompleted');
    window.location.reload();
  };

  return (
    <div>
      <footer className={"justify-content-between"} style={{ backgroundColor: '#0056b3' }}>
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-6 col-lg-3 text-center">
              <a href="http://ecertificat-nationalite.gov.bf" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                Ministère de la Santé (MS)
              </a>
              <br />
              <span className="ml-1" style={{ color: 'white' }}>&copy; 2024 DSI.</span>
            </div>

            <div className="col-12 col-md-6 col-lg-3 text-center">
              <p style={{ color: 'white' }}>Contacts</p>
              <p style={{ color: 'white' }}>
                Tel: <a href="tel:0022625302213" style={{ color: 'white' }}>(+226)</a><br />
                Email: <a href="mailto:esante@sante.gov.bf" style={{ color: 'white' }}>ministere@sante.gov.bf</a><br />
                Adresse: 03 BP 7013 OUAGA 03
              </p>
                          <button 
        onClick={resetTutorial}
        className="btn btn-link"
        style={{ color: 'white', textDecoration: 'underline' }}
      >
        Relancer le tutoriel
      </button>
            </div>



            <div className="col-12 col-md-6 col-lg-3 text-center">
              <p className="mr-1" style={{ color: 'white' }}>Produit par la</p>
              <p>
                <a href="http://esante.gov.bf" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                  Direction des Systèmes d'Information(DSI/MS)
                </a>
              </p>

            </div>

          </div>
        </div>
      </footer>

    </div>
  );
}

export default Foot;
