import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomTutorial from '../components/CustomTutorial';


function Home() {
  const [showTutorial, setShowTutorial] = useState(false);

  useEffect(() => {
    const tutorialCompleted = sessionStorage.getItem('tutorialCompleted');
    if (!tutorialCompleted) {
      setShowTutorial(true);
    }
  }, []);

  const handleTutorialComplete = () => {
    setShowTutorial(false);
    sessionStorage.setItem('tutorialCompleted', 'true');
  };

  return (
    <div>
      {showTutorial && (
        <CustomTutorial onComplete={handleTutorialComplete} />
      )}
      <section id="hero" className="d-flex align-items-center" style={{ backgroundSize: 'cover', color: '#fff', padding: '60px 0', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
        <div className="container text-center">
          <h1 style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '20px' }}>Bienvenue </h1>
          <h2 style={{ fontSize: '1.8rem', margin: '20px 0', fontWeight: 'normal' }}><b>sur la plateforme de demandes des licences d'affaires</b></h2>
          {/*<Link to={"/consulter_demande"} className="btn-get-started scrollto" style={{ padding: '12px 30px', fontSize: '1rem', backgroundColor: '#28a745', color: '#fff', borderRadius: '5px', textDecoration: 'none', marginTop: '20px' }}>
      Consulter ma Demande
    </Link>*/}
        </div>
      </section>



      {/* <!-- ======= Why Us Section ======= --> */}
      <section id="why-us" class="why-us">
        <div class="container">
          <div class="row">
            {/*<div class="col-lg-4 d-flex align-items-stretch">
              <div class="content">
                <h3>Avant de Commencer</h3>
                1- Déposer votre dossier au niveau de la mairie pour démarrer l'enquete de moralité.
                <br></br> <br></br>
                2- Rétirer le récépissé de la disponibilité de l'enquete de moralité.
                <br></br> <br></br>
                3- Une fois le récépissé disponible, vous pouvez faire votre demande d'agrément sur cette plateforme.

              </div>
            </div>*/}
            <div class="col-lg-12 d-flex align-items-stretch">
              <div class="icon-boxes d-flex flex-column justify-content-center">
                <div class="row d-flex justify-content-between">
                  <Link to="/ouverture" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-success position-absolute" style={{ top: '10px', right: '10px' }}>Actif</span>
                      <i class="bx bx-cube-alt"></i>
                      <h5><b>OUVERTURE ET EXPLOITATION</b></h5>
                      <br />
                      Accéder aux informations essentielles pour initier une demande d'ouverture et d'exploitation d'un ESPS, laboratoire, ...
                    </div>
                  </Link>
                  <Link to="/convention" className="col-xl-3 d-flex align-items-stretch mb-4">
                    <div className="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span className="badge bg-warning position-absolute" style={{ top: '10px', right: '10px' }}>En cour</span>
                      <i className="bx bx-file"></i>
                      <h5><b>CONVENTION</b></h5>
                      <br />
                      Accéder aux informations pour initier une demande de convention.
                    </div>
                  </Link>
                  <Link to="/creation" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-home"></i>
                      <h5><b>CREATION</b></h5>
                      <br />
                      Accéder aux informations essentielles pour initier une demande de création d'un ESPS, laboratoire, ...
                    </div>
                  </Link>
                  <Link to="/transfert" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-arrow-back"></i>
                      <h5><b>TRANSFERT</b></h5>
                      <br />
                      Accéder aux informations essentielles pour initier une demande de transfert.
                    </div>
                  </Link>
                  <Link to="/cession" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-file"></i>
                      <h5><b>TRANSFERT DE PROPRIETE (CESSION)</b></h5>
                      <br />
                      Accéder aux informations essentielles pour initier une demande de transfert de propriété.
                    </div>
                  </Link>
                  <Link to="/exploitation" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-clipboard"></i>
                      <h5><b>POURSUITE D’EXPLOITATION</b></h5>
                      <br />
                      Accéder aux informations essentielles pour initier une demande de poursuite d'exploitation.
                    </div>
                  </Link>
                  <Link to="/transformation" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-heart"></i>
                      <h5><b>TRANSFORMATION DE NATURE</b></h5>
                      <br />
                      Accéder aux informations essentielles pour initier une demande de transformation de nature.
                    </div>
                  </Link>
                  <Link to="/prorogation" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-time-five"></i>
                      <h5><b>PROROGATION DE DELAI</b></h5>
                      <br />
                      Accéder aux informations pour initier une demande de prorogation de délai.
                    </div>
                  </Link>
                  <Link to="/rectification" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-pencil"></i>
                      <h5><b>RECTIFICATION / CORRECTION</b></h5>
                      <br />
                      Accéder aux informations pour initier une demande de rectification.
                    </div>
                  </Link>
                  <Link to="/fermeture" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-door-open"></i>
                      <h5><b>FERMETURE</b></h5>
                      <br />
                      Accéder aux informations pour initier une demande de fermeture.
                    </div>
                  </Link>
                  <Link to="/changement-responsable" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-user-circle"></i>
                      <h5><b>CHANGEMENT DE RESPONSABLE TECHNIQUE</b></h5>
                      <br />
                      Accéder aux informations pour initier une demande de changement de responsable technique.
                    </div>
                  </Link>
                  <Link to="/changement-denomination" class="col-xl-3 d-flex align-items-stretch mb-4">
                    <div class="icon-box mt-4 mt-xl-0 text-black position-relative">
                      <span class="badge bg-secondary position-absolute" style={{ top: '10px', right: '10px' }}>Pas encore</span>
                      <i class="bx bx-edit-alt"></i>
                      <h5><b>CHANGEMENT DE DENOMINATION</b></h5>
                      <br />
                      Accéder aux informations pour initier une demande de changement de dénomination.
                    </div>
                  </Link>


                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
