import React from "react";

import "./Dashboard.css";
import Header from "./Template/admin/Header";
import Footer from "./Template/admin/Footer";
import Home from "./Template/admin/Home";
import Navbar from "./Template/admin/Nav";


function Dashboard() {
  return (
    <div id="page-top">
      <div id="wrapper">
        {/* <Header /> Sidebar */}
        <div id="content-wrapper">
          <Navbar />
          <div id="content">
            <Home />
          </div>
          <Footer /> {/* Footer ici pour rester en bas */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

