/* eslint-disable no-unused-vars */
import axios from "axios";
import { toast } from "react-toastify";

class UserService {

    static BASE_URL = `${process.env.REACT_APP_API_URL}/users`;

    static async login(username, password) {
        try{
            const response = await axios.post(`${UserService.BASE_URL}/login`, {username, password})
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    static async create(userData, token) {
        try{
            const response = await axios.post(`${UserService.BASE_URL}/saveUser`, userData, 
                {
                    headers: {Authorization: `Bearer ${token}`}
                });
            if(response.status === 201) {
                return response.data;
            }
        } catch(error) {
            throw error;
        }
    }

    static async register(userData) {
        try{
            const response = await axios.post(`${UserService.BASE_URL}/register`, userData);
            if(response.status === 201) {
                return response.data;
            }

        } catch(error) {
            throw error;
        }
    }

    static async getAllUsers(token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/list`, 
                {
                    headers: {Authorization: `Bearer ${token}`}
                })
                return response.data;
        } catch(error) {
            throw error;
        }
    }

    static async getProfil(token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/profil`, 
                {
                    headers: {Authorization: `Bearer ${token}`}
                })
                return response.data;
        } catch(error) {
            throw error;
        }
    }

    static async logout(token) {
        try{
            const response = await axios.post(`${UserService.BASE_URL}/logout`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    static async getById(userId, token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/${userId}`, 
                {
                    headers: {Authorization: `Bearer ${token}`}
                })
                return response.data;
        } catch(error) {
            throw error;
        }
    }

    static async updaterUser(userId, userData, token) {
        try{
            const response = await axios.put(`${UserService.BASE_URL}/update/${userId}`, userData,
                {
                    headers: {Authorization: `Bearer ${token}`}
                })
                return response.data;
        } catch(error) {
            throw error;
        }
    }

    static async delete(userId, token) {
        try {
            const response = await axios.delete(`${UserService.BASE_URL}/${userId}`, 
                {
                    headers: {Authorization: `Bearer ${token}`}
                })
                return response.data;
        } catch(error) {
            throw error;
        }
    }

    static getRoleInToken = () => {
        const token = sessionStorage.getItem("token");
        if(!token) 
            return null;

        const payload = token.split('.')[1];    // Diviser le token pour obtenir la partie payload (2e segment)

        // Décoder la partie payload pour obtenir les données JSON
        try {
            const decode = JSON.parse(atob(payload));
            return decode.role;  // Récupérer le rôle à partir des claims

        } catch (error) {
            console.error("Erreur d'obtention du rôle: ", error);
            return null;
        }
    }

        /** CHECKED AUTHENTICATION  */

    static isLogged() {
        const token = sessionStorage.getItem('token')
        return !!token
    }

    static logouted() {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('role')
    }

    static isAdmin() {
        const role = UserService.getProfil
        return role === 'ADMIN'
    }

    static isUser() {
        const role = sessionStorage.getItem('role')
        return role === 'USER'
    }

    static adminOnly() {
        return this.isLogged() && this.isAdmin();
    }

}

export default UserService;