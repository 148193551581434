import React, { useState, useEffect, } from 'react';
import axios from "axios";
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useParams } from "react-router-dom";
const InfoSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const decideurs = " ";
  return (
    <div className="mb-4 border rounded-lg shadow-sm">
      <button
        className="btn btn-light w-100 d-flex justify-content-between align-items-center"
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ color: "black", backgroundColor: "white" }}  // Pas d'animation au survol
      >
        {title}
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isExpanded && <div className="p-4">{children}</div>}
    </div>
  );
};

const InfoRow = ({ label, value }) => (
  <div className="form-group row mb-3">
    <label className="col-sm-4 col-form-label font-weight-bold">{label}</label>
    <div className="col-sm-8">
      <p className="form-control-plaintext">{value}</p> {/* Utilisation de Bootstrap pour une meilleure séparation */}
    </div>
  </div>
);
const ResponsablesTable = ({ title, personnel = [] }) => (
  <InfoSection title={title}>
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
        <thead className="thead-light">
          <tr style={{ color: "black" }}>
            {['Nom', 'Prénom(s)',  'Genre','Nip', 'Tel', 'N°Ordre', 'Date de naissance', 'lieu de naissance', 'Adresse', 'Profession', 'Nationalité'].map((header) => (
              <th key={header} className="text-center" style={{ color: "black" }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ color: "black" }}>
          {personnel && personnel.length > 0 ? (
            personnel.map((person, index) => (
              <tr key={index}>
                <td>{person.nom}</td>
                <td>{person.prenom}</td>
                {/* <td>{person.email}</td> */}
                <td>{person.genre}</td>
                <td>{person.nip}</td>
                <td>{person.tel}</td>
                <td>{person.numOp}</td>
                <td>{person.dateNaissance} </td>
                <td>{person.lieuNaissance}</td>
                <td>{person.adresse}</td>
                <td>{person.profession}</td>
                <td>{person.nationalite}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                {title === "Responsables Techniques"
                  ? "Aucun responsable technique disponible"
                  : "Aucun personnel disponible"}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </InfoSection>
);
const PromoteurTable = ({ title, promoteur = [] }) => (
  <InfoSection title={title}>
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
        <thead className="thead-light">
          <tr style={{ color: "black" }}>
            {['Nom', 'Prénom(s)', 'Email', 'Genre', 'NIP', 'Tel', 'N°Ordre', 'Date de naissance','lieu de naissance', 'Adresse', 'Profession', 'Nationalité'].map((header) => (
              <th key={header} className="text-center" style={{ color: "black" }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ color: "black" }}>
          {promoteur && promoteur.length > 0 ? (
            promoteur.map((person, index) => (
              <tr key={index}>
                <td>{person.nom}</td>
                <td>{person.prenom}</td>
                <td>{person.email}</td>
                <td>{person.genre}</td>
                <td>{person.nip}</td>
                <td>{person.tel}</td>
                <td>{person.numOp}</td>
                <td>{person.dateNaissance} </td>
                <td>{person.lieuNaissance}</td>
                <td>{person.adresse}</td>
                <td>{person.profession}</td>
                <td>{person.nationalite}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                {title === "Responsables Techniques"
                  ? "Aucun responsable technique disponible"
                  : "Aucun personnel disponible"}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </InfoSection>
);
const PersonnelTable = ({ title, personnel = [] }) => ( 
  <InfoSection title={title}>
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
        <thead className="thead-light">
          <tr style={{ color: "black" }}>
            {['Nom', 'Prénom(s)', 'N°Ordre', 'Profession', 'NIP'].map((header) => (
              <th key={header} className="text-center" style={{ color: "black" }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ color: "black" }}>
          {personnel && personnel.length > 0 ? (
            personnel.map((person, index) => (
              <tr key={index}>
                <td>{person.nom}</td>
                <td>{person.prenom}</td>
                <td>{person.numOp}</td>
                <td>{person.profession}</td>
                <td>{person.nip}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                {title === "Responsables Techniques"
                  ? "Aucun responsable technique disponible"
                  : "Aucun personnel disponible"}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </InfoSection>
);

const ComplementTable = ({ title, complement = [] }) => (
  <InfoSection title={title}>
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
        <thead className="thead-light">
          <tr style={{ color: "black" }}>
            {['Date', 'Contenu du complement'].map((header) => (
              <th key={header} className="text-center" style={{ color: "black" }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ color: "black" }}>
          {(complement.length > 0) ? complement.map((person, index) => (
            <tr key={index}>
              <td>{person.dateComplement}</td>
              {/* <td>{person.objet}</td> */}
              <td>{person.observation}</td>

            </tr>
          )) : (
            <tr>
              <td colSpan="3" className="text-center">Aucun complément disponible</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </InfoSection>
);


const AvisTable = ({ title, avis = [] }) => {
  const avisArray = Array.isArray(avis) ? avis : [];

  return (
    <InfoSection title={title}>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-light">
            <tr style={{ color: "black" }}>
              {['Date', 'Decideurs', 'Avis', 'Motivation'].map((header) => (
                <th key={header} className="text-center" style={{ color: "black" }}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody style={{ color: "black" }}>
            {avisArray.length > 0 ? (
              avisArray.map((avis, index) => {
                const num = index + 1;
                let decideurs;
                
                // Attribution du décideur en fonction de l'index
                switch(index + 1) {
                  case 1:
                    decideurs = "DSPS";
                    break;
                  case 2:
                    decideurs = "Ordre professionnel";
                    break;
                  case 3:
                    decideurs = "DSPS";
                    break;
                  case 4:
                    decideurs = "Commission";
                    break;
                  default:
                    decideurs = avis.niveau || "Non défini";
                }

                return (
                  <tr key={index}>
                    {/* <td>{num}</td> */}
                    <td>{avis.dateAvisOI}</td>
                    <td>{avis.utilisateur.direction.description} </td>
                    <td>{avis.typeAvisOI}</td>
                    <td>{avis.motivation}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="text-center">Aucun avis disponible</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </InfoSection>
  );
};


const DemandeInfoComponent = ({ userDemande }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('fr-FR');
  };
  const { demandeId } = useParams();
  const [aviss, setAvis] = useState([]);

  useEffect(() => {
    const fetchAvis = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/avis-oi/list/${demandeId}`);
        setAvis(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Erreur lors de la récupération des avis:", error);
        setAvis([]);
      }
    };

    if (demandeId) {
      fetchAvis();
    }
  }, [demandeId]);

  return (
    <div className="container mt-4"> {/* Utilisation de container pour la mise en page */}
      <InfoSection title="Informations sur la Demande reçue">
        <InfoRow label="Date de soumission " value={userDemande.dateSoumission ? formatDate(userDemande.dateSoumission) : "N/A"} />
        {userDemande.numDemande && <InfoRow label="Identifiant de la Demande" value={userDemande.numDemande} />}
        <InfoRow
          label="Type de demande"
          value={userDemande?.typeDemande === "NonCONVENTIONNE" ? "NON CONVENTIONNÉ" : 
                 userDemande?.typeDemande === "CONVENTIONNE" ? "CONVENTIONNÉ" : 
                 userDemande?.typeDemande}
        />
        <InfoRow label="Type de proprietaire de la demande " value={userDemande.typeProprioDemande ===
          "MORALESOCIETE" ? "MORALE SOCIETE" : userDemande.typeProprioDemande} />
        {userDemande.esp && (
          <>
            <InfoRow label="Type d'établissement sanitaire de soins " value={userDemande.esp.groupeEsp.typeESP} />
            {userDemande.typeProprioDemande === "MORALECONVENTIONNE" && (
        <>
          <InfoRow label="Numéro de la convention " value={userDemande.numConvention} />
          <InfoRow label="Date de la convention " value={formatDate(userDemande.dateConvention)} />
        </>
      )}
            <InfoRow label="Catégorie d'établissement sanitaire de soins " value={userDemande.esp.groupeEsp.libelleGesp} />
            <InfoRow label="Groupe Etablissement sanitaire de soins " value={userDemande.esp.libelleEsp} />
            <InfoRow label="Numéro d'Arret de création " value={userDemande.numArreteCreation} />
          </>
        )}
        {userDemande.commune && (
          <>
                   <InfoRow label="Commune" value={userDemande.commune?.intituleCommune || "N/A"} />
            <InfoRow label="Arrondissement" value={userDemande.arrondissement?.intituleArrdt || "N/A"} />
            {/* <InfoRow label="District" value={userDemande.commune?.district?.libelleDistrict || "N/A"} />
            <InfoRow label="Province" value={userDemande.commune?.district?.province?.libelleProvince || "N/A"} /> */}
            <InfoRow label="Région" value={userDemande.commune?.district?.province?.region?.libelleRegion || "N/A"} />
          </>
        )}

      </InfoSection>

      {/* Espacement supplémentaire entre la demande et les informations de l'utilisateur */}
      <div className="my-5"></div>

      <InfoSection title="Information du Demandeur" style={{ color: "black" }}>
        {userDemande?.utilisateur?.nom && <InfoRow label="Nom du Demandeur" value={userDemande.utilisateur.nom} />}
        {userDemande?.utilisateur?.prenom && <InfoRow label="Prenom(s) du Demandeur" value={userDemande.utilisateur.prenom} />}
        {userDemande?.proprietaire?.genre && <InfoRow label="Genre du Demandeur" value={userDemande.proprietaire.genre} />}
        {userDemande?.utilisateur?.tel && <InfoRow label="Téléphone(s) du Demandeur" value={userDemande.utilisateur.tel} />}
        {userDemande?.utilisateur?.email && <InfoRow label="Email du Demandeur" value={userDemande.utilisateur.email} />}
      </InfoSection>

      {userDemande?.typeProprioDemande !== 'PHYSIQUE' && (
        <ResponsablesTable title="Responsables Techniques" personnel={userDemande?.proprietaireRt || []} />
      )}

{userDemande?.typeProprioDemande === 'PHYSIQUE' && (
        <PromoteurTable title="Promoteur" promoteur={userDemande?.proprietaireRt || []} />
      )}

      <PersonnelTable title="Personnels" personnel={userDemande?.personnels || []} />
      <ComplementTable
        title="Complement"
        complement={userDemande.pieceJointes
          ?.filter(pj => pj.complement)
          .map(pj => pj.complement)
          .filter(Boolean)}
      />
      <AvisTable title="Avis" avis={userDemande?.avisOis || []} />
    </div>
  );
};

export default DemandeInfoComponent;
