'use client'

import * as React from 'react'
import { Check } from 'lucide-react'
import { Form, Button, FormGroup, FormLabel, FormControl, FormCheck } from "react-bootstrap"
import Navbar from "../../Nav";
export default function Form_dmd_convention() {
    const [currentStep, setCurrentStep] = React.useState(1)
    const totalSteps = 6

    const steps = [
        { id: 1, name: 'Identité' },
        { id: 2, name: 'Objet' },
        { id: 3, name: 'Activités' },
        { id: 4, name: 'Ressources' },
        { id: 5, name: 'Gestion' },
        { id: 6, name: 'Collaboration' },
    ]

    const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, totalSteps))
    const handlePrevious = () => setCurrentStep((prev) => Math.max(prev - 1, 1))

    return (
        <div id="page-top">
            <br></br>
            <br></br>
            <div id="wrapper">
                {/*
          <Header /> */}
                <div id="content-wrapper">
                    <Navbar />
                    <div className="container py-4">
                        <div className="mb-4">
                            <div className="d-flex justify-content-between align-items-center">
                                {steps.map((step, index) => (
                                    <React.Fragment key={step.id}>
                                        <div className="d-flex flex-column align-items-center">
                                            <div
                                                className={`d-flex justify-content-center align-items-center rounded-circle border ${currentStep >= step.id
                                                    ? 'bg-primary text-white'
                                                    : 'bg-light text-secondary'
                                                    }`}
                                                style={{ width: '40px', height: '40px' }}
                                            >
                                                {currentStep > step.id ? (
                                                    <Check className="h-5 w-5" />
                                                ) : (
                                                    <span>{step.id}</span>
                                                )}
                                            </div>
                                            <span
                                                className={`mt-2 small ${currentStep >= step.id ? 'text-primary' : 'text-secondary'
                                                    }`}
                                            >
                                                {step.name}
                                            </span>
                                        </div>
                                        {index < steps.length - 1 && (
                                            <div
                                                className={`flex-grow-1 mx-2 ${currentStep > step.id ? 'bg-primary' : 'bg-secondary'
                                                    }`}
                                                style={{ height: '2px' }}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>

                        <div className="bg-white p-4 rounded shadow">
                            <h2 className="text-center mb-4">
                                FICHE DE RENSEIGNEMENTS SUR LES STRUCTURES SOLLICITANT UNE CONVENTION DE COLLABORATION AVEC LE MINISTERE DE LA SANTE
                            </h2>
                            <Form>
                                {currentStep === 1 && (
                                    <div>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="nomComplet">NOM COMPLET</FormLabel>
                                            <FormControl id="nomComplet" required />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="sigle">SIGLE</FormLabel>
                                            <FormControl id="sigle" required />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel>TYPE DE STRUCTURE</FormLabel>
                                            <div>
                                                <FormCheck
                                                    inline
                                                    type="radio"
                                                    id="nationale"
                                                    name="typeStructure"
                                                    label="Nationale"
                                                />
                                                <FormCheck
                                                    inline
                                                    type="radio"
                                                    id="etrangere"
                                                    name="typeStructure"
                                                    label="Etrangère"
                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel>FORME JURIDIQUE</FormLabel>
                                            <div>
                                                {['Association', 'ONG', 'Fondation', 'Autres'].map((type) => (
                                                    <FormCheck
                                                        key={type}
                                                        inline
                                                        type="radio"
                                                        id={type.toLowerCase()}
                                                        name="formeJuridique"
                                                        label={type}
                                                    />
                                                ))}
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="dateCreation">DATE DE CREATION</FormLabel>
                                            <FormControl id="dateCreation" type="date" required />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="zonesIntervention">ZONES D'INTERVENTION (Régions)</FormLabel>
                                            <FormControl id="zonesIntervention" required />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel>ADRESSE DU SIEGE SOCIAL</FormLabel>
                                            <FormControl placeholder="Boîte postale" className="mb-2" />
                                            <FormControl placeholder="Ville (localité)" className="mb-2" />
                                            <FormControl placeholder="Pays" className="mb-2" />
                                            <FormControl placeholder="Téléphone" className="mb-2" />
                                            <FormControl placeholder="E-mail professionnel" type="email" className="mb-2" />
                                            <FormControl placeholder="Site web" type="url" />
                                        </FormGroup>
                                    </div>
                                )}

                                {currentStep === 2 && (
                                    <div>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="objectifGeneral">OBJECTIF GENERAL DE L'ONG/ASSOCIATION /FONDATION</FormLabel>
                                            <FormControl as="textarea" id="objectifGeneral" rows={4} />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="objectifsSpecifiques">OBJECTIFS SPECIFIQUES DANS LE DOMAINE DE LA SANTE</FormLabel>
                                            <FormControl as="textarea" id="objectifsSpecifiques" rows={6} />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel>STRATEGIES ET DOMAINES D'INTERVENTION</FormLabel>
                                            <div className="row">
                                                {[
                                                    'Construction', 'Equipement', 'SBC', 'Stratégie avancée', 'Assurance qualité',
                                                    'Formation', 'Vente MEG', 'Recherche', 'Informations Education', 'Pharmacopée traditionnelle',
                                                    'Vaccination', 'Assainissement / Hygiène', 'Soins curatifs', 'Soins préventifs',
                                                    'Santé de la reproduction', 'Dépistage', 'Récupération nutritionnelle', 'Prise en charge des PVVIH – OEV',
                                                    'PTME', 'Pharmacie hospitalière', 'Prise en charge des indigents', "Financement des plans d'action",
                                                    'Appui institutionnel', 'Appui technique', 'Plaidoyer'
                                                ].map((item) => (
                                                    <div key={item} className="col-md-4 col-lg-3">
                                                        <FormCheck
                                                            type="checkbox"
                                                            id={item}
                                                            label={item}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </FormGroup>
                                    </div>
                                )}

                                {/* Ajoutez les autres étapes ici de manière similaire */}

                                <div className="mt-4 d-flex justify-content-between">
                                    {currentStep > 1 && (
                                        <Button onClick={handlePrevious} variant="outline-primary">
                                            Précédent
                                        </Button>
                                    )}
                                    {currentStep < totalSteps ? (
                                        <Button onClick={handleNext} className="ms-auto">
                                            Suivant
                                        </Button>
                                    ) : (
                                        <Button type="submit" variant="success" className="ms-auto">
                                            Soumettre
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}