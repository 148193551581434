import React from 'react';

const Card = ({ soustitle, title, pdfSrc }) => {
  const openPDF = () => {
    window.open(pdfSrc, '_blank');
  };

  return (
    <div className="card">
      <div className="card-body">
      <h4 className="card-title text-center text-black"><b>{title}</b></h4>
        <h5 className="card-title">{soustitle}</h5>
        <button className="appointment-btn" onClick={openPDF}>
          Voir
        </button>
      </div>
    </div>
  );
};

export default Card;
