/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import * as XLSX from 'xlsx';
import axios from "axios";
import { Form, Button, Modal, Card } from "react-bootstrap";
import CustomButton from './CustomButton';
import Header from "../../Header";
import Footer from "../../Footer";
import Navbar from "../../Nav";
import { useNavigate, useParams } from "react-router-dom";
import PersonnelManagement from "./PersonnelManagement";
import Swal from "sweetalert2";
import UserService from "../../service/UserService";
function Form_dmd_dsps() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState({});

  const token = sessionStorage.getItem("token");
  // console.log("Token val: ", token);

  const getUser = async () => {
    try {
      // Vérifier si le token existe
      if (!token) {
        console.error("Token not found in sessionStorage");
        return;
      }

      // Envoyer la requête au serveur avec le token inclus dans les en-têtes
      const response = await UserService.getProfil(token);
      const userData = response
      // console.log(userData);
      setUserData(userData);
      setUserId(userData.id);

    } catch (error) {
      console.error("Error sending token to server:", error.message);
    }
  }

  // Appeler la fonction lorsque le composant est monté
  useEffect(() => {
    getUser();
  }, []);


  const type = "personne-physique";

  const [isPhysique, setIsPhysique] = useState(true);
  //state for form data

  const [demandeDTO, setDemandeDTO] = useState({
    numDemande: "",
    nomStructure: "",
    nomEsp: "",
    numArreteCreation: "",
    typeDemande: "OUVERTURE",
    typeProprioDemande: "PHYSIQUE",
    utilisateur: {
      id: "",
    },
    commune: {
      id: "",
    },
    arrondissement: {
      id: "",
    },
    esp: {
      id: ""
    },
    promoteur: {
      nom: "",
      prenom: "",
      nip: "",
      dateNaissance: "",
      lieuNaissance: "",
      email: "",
      genre: "",
      tel: "",
      numOp: "",
      adresse: "",
      nationalite: "",
      profession: "",

    },

    personnels: [
      {
        nom: "",
        prenom: "",
        nip: "",
        profession: "",
        numOp: ""
      }
    ]
  });

  const handleFileXlsUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      
      // Process Personnel sheet
      const personnelSheet = workbook.Sheets[workbook.SheetNames[0]]; // Assuming first sheet is Personnel
      const personnelData = XLSX.utils.sheet_to_json(personnelSheet, { header: 1 });
      const updatedPersonnels = personnelData.slice(1).map(row => ({
        nom: row[0] || "",
        prenom: row[1] || "",
        nip: row[2] ? row[2].toString().replace(/[^0-9]/g, '') : "",
        profession: row[3] || "",
        numOp: row[4] || ""
      }));
  
      // Process Promoteur sheet
      const promoteurSheet = workbook.Sheets[workbook.SheetNames[1]]; // Assuming second sheet is Promoteur
      const promoteurData = XLSX.utils.sheet_to_json(promoteurSheet, { header: 1 });
      const promoteurRow = promoteurData[1]; // Assuming data starts from second row
  
      const updatedPromoteur = {
        nom: promoteurRow[0] || "",
        prenom: promoteurRow[1] || "",
        email: promoteurRow[5] || "",
        genre: promoteurRow[6] || "",
        nip: promoteurRow[2] || "",
        dateNaissance: promoteurRow[3] || "",
        lieuNaissance: promoteurRow[4] || "",
        tel: promoteurRow[7] || "",
        profession: promoteurRow[11] || "",
        numOp: promoteurRow[8] || "",
        adresse: promoteurRow[9] || "",
        nationalite: promoteurRow[10] || ""
      };
  
      setDemandeDTO(prevState => ({
        ...prevState,
        personnels: updatedPersonnels,
        promoteur: updatedPromoteur
      }));
    };
  
    reader.readAsArrayBuffer(file);
  };

  // Fonction utilitaire pour formater la date
  const formatD = (date) => {
    if (!date) return ""; // Si la date est vide
    const parsedDate = new Date(date);
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Mois en 2 chiffres
    const day = String(parsedDate.getDate()).padStart(2, '0'); // Jour en 2 chiffres
    return `${year}-${month}-${day}`; // Format YYYY-MM-DD
  };
  const [fichierStructure, setFichierStructure] = useState(null);
  const [personnelSimpleFichiers, setPersonnelSimpleFichiers] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Gérer les propriétés imbriquées pour `utilisateur`
    if (name.startsWith("utilisateur.")) {
      const utilisateurField = name.split(".")[1]; // Obtient la propriété (ex: "id")
      setDemandeDTO(prev => ({
        ...prev,
        utilisateur: {
          ...prev.utilisateur,
          [utilisateurField]: value,
        },
      }));
    }
    // Gérer les propriétés imbriquées pour `commune`
      else if (name.startsWith("commune.")) {
        const communeField = name.split(".")[1]; // Obtient la propriété
        setDemandeDTO(prev => ({
          ...prev,
          commune: {
            ...prev.commune,
            [communeField]: value,
          },
        }));
      }
      else if (name.startsWith("arrondissement.")) {
        const arrondissementField = name.split(".")[1]; // Obtient la propriété
        setDemandeDTO(prev => ({
          ...prev,
          arrondissement: {
            ...prev.arrondissement,
            [arrondissementField]: value,
          },
        }));
      }
    // Gérer les propriétés imbriquées pour `esp`
    else if (name.startsWith("esp.")) {
      const espField = name.split(".")[1]; // Obtient la propriété
      setDemandeDTO(prev => ({
        ...prev,
        esp: {
          ...prev.esp,
          [espField]: value,
        },
      }));
    }

    else if (name.startsWith("promoteur.")) {
      const promoteurField = name.split(".")[1];
      setDemandeDTO(prev => ({
        ...prev,
        promoteur: {
          ...prev.promoteur,
          [promoteurField]: ["nom", "prenom"].includes(promoteurField)
          ? value.toUpperCase() // Conversion en majuscules pour "nom" et "prenom"
          : value,
        },
      }));
    }

    else if (name.startsWith("personnels.")) {
      const personnelsField = name.split(".")[1];
      setDemandeDTO(prev => ({
        ...prev,
        personnels: {
          ...prev.personnels,
          [personnelsField]: value,
        },
      }));
    }

    // Gérer les champs de niveau supérieur
    else {
      setDemandeDTO(prev => ({
        ...prev,
        [name]: name ==="nomEsp" ? value.toUpperCase() : value,
      }));
    }
  };

  const [personnelFichiers, setPersonnelFichiers] = useState([]);
  const handleFileChangeStructure = (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

      if (file.size > maxSize) {
          Swal.fire({
              icon: 'error',
              title: 'Fichier trop volumineux',
              text: 'La taille du fichier ne doit pas dépasser 5 Mo.',
          });
          return;
      }

    if (files.length > 0) {
      setFichierStructure(files[0]); // Mettez à jour l'état avec le fichier sélectionné
      setSelectedFile(files);
    }
  }
  };

  const handleFileChangePersonnel = (index, event) => {
    const { files } = event.target;

    if (files.length > 0) {
        const file = files[0];
        const maxSize = 1 * 1024 * 1024; // 1 MB in bytes

        if (file.size > maxSize) {
            Swal.fire({
                icon: 'error',
                title: 'Fichier trop volumineux',
                text: 'La taille du fichier ne doit pas dépasser 1 Mo.',
            });
            return;
        }

        setPersonnelSimpleFichiers((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index] = file;
            setPersonnelFichiers(updatedFiles); // Assigner le fichier au bon index
            return updatedFiles;
        });
    }
};

  useEffect(() => {
    if (userId && userData.nom) {
      setDemandeDTO({
        ...demandeDTO,
        utilisateur: {
          ...demandeDTO.utilisateur,
          id: userData.id
        }
      });
    }
  }, [userId, userData]);

  const handlePersonnelChange = (index, field, value) => {
    setDemandeDTO(prev => {
      const newproprietairePs = [...prev.personnels];
      newproprietairePs[index] = {
        ...newproprietairePs[index],
        [field]: value
      };
      return { ...prev, personnels: newproprietairePs };
    });
  };


  const addPersonnel = () => {
    setDemandeDTO(prev => ({
      ...prev,
      personnels: [
        ...prev.personnels,
        {
          nom: "",
          prenom: "",
          nip: "",
          profession: "",
          numOp: ""

        }
      ]
    }));
  };

  const removePersonnel = (index) => {
    setDemandeDTO(prev => ({
      ...prev,
      personnels: prev.personnels.filter((_, idx) => idx !== index)
    }));
  };



  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) {
      return; // Reste sur l'étape actuelle si la validation échoue
    }



    // Afficher une boîte de dialogue de confirmation
    const result = await Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Voulez-vous vraiment soumettre cette demande?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, soumettre!',
      cancelButtonText: 'Annuler'
    });

     if (!result.isConfirmed) {
      return; // Annuler l'envoi si l'utilisateur ne confirme pas
     }
     let timerInterval;
     Swal.fire({
       title: "Veuillez patienter...",
       html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
       timer: 20000,
       timerProgressBar: true,
       didOpen: () => {
         Swal.showLoading();
         const timer = Swal.getPopup().querySelector("b");
         if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
           timerInterval = setInterval(() => {
             timer.textContent = `${Swal.getTimerLeft()}`;
           }, 100);
         }
       },
       willClose: () => {
         clearInterval(timerInterval);
       }
     });
    const formData = new FormData();

    // Ajouter le JSON sous forme de chaîne
    formData.append('demandeDTO', new Blob([JSON.stringify(demandeDTO)], { type: 'application/json' }));

    // Ajouter le fichier structure
    if (fichierStructure) {
      formData.append('fichierStructure', fichierStructure);
    }

    personnelSimpleFichiers.forEach((file) => {
      if (file) { // Vérifiez si le fichier existe avant d'ajouter
        formData.append('personnelSimpleFichiers', file);
      }
    });

    formData.append('type', type);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/demandes/creer-demande`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 201 || response.status === 200) {
        const demandeId = response.data.id;
        await Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: 'Votre demande a été créée avec succès!',
        });
        downloadReceipt(demandeId);
        navigate("/admin");
                // Arrêter le minuteur ici
                clearInterval(timerInterval);
                Swal.close(); // Fermer la fenêtre Swal
        
      }
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // Vérifier si l'erreur est due à une clé dupliquée
          if (error.response.data.message && error.response.data.message.includes("clé dupliquée")) {
            await Swal.fire({
              icon: 'error',
              title: 'Erreur de duplication',
              text: 'Un personnel avec ce NIP existe déjà. Veuillez vérifier et corriger les informations.',
            })
          } else {
            // Autres erreurs côté serveur
            await Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: error.response.data.message || "Erreur interne du serveur lors de la soumission.",
            })
          }
        } else if (error.request) {
          // La requête a été envoyée mais aucune réponse n'a été reçue
          await Swal.fire({
            icon: 'error',
            title: 'Erreur de réseau',
            text: "Pas de réponse du serveur. Veuillez vérifier votre connexion et réessayer.",
          })
        } else {
          // Autre erreur
          await Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: error.message,
          })
                  // Arrêter le minuteur ici
        clearInterval(timerInterval);
        Swal.close(); // Fermer la fenêtre Swal
        }
      } else {
        // Erreur non-Axios
        await Swal.fire({
          icon: 'error',
          title: 'Erreur inattendue',
          text: "Une erreur inattendue s'est produite. Veuillez réessayer.",
        })
      }
      console.error('Erreur:', error)
    }
  };


  const steps = ["Informations Etablissement", "Promoteur et Personnels", "Pieces Jointes Personnels", "Récapitulatif"];
  const ProgressBar = ({ currentStep, steps }) => {
    return (
      <div className="progress-bar-container">
        {steps.map((_, index) => (
          <div key={index} className="step-container">
            <div className="line"></div>
            <div
              className={`circle ${index < currentStep ? "completed" : ""} ${index === currentStep - 1 ? "active" : ""
                }`}
            >
              {index < currentStep ? (
                <i className="fas fa-check"></i>
              ) : (
                index + 1
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };


  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // Calculez la largeur de chaque étape en pourcentage
  const stepWidth = 100 / steps.length;

  //contant declarations
  const [regionList, setRegionList] = useState([]);
  const regionUrl = `${process.env.REACT_APP_API_URL}/regions/list`;
  const [province, setProvince] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [arrondissementList, setArrondissementList] = useState([]);
  const [arrondissement, setArrondissement] = useState([]);
  const [communeList, setCommuneList] = useState([]);
  const [commune, setCommune] = useState([]);
  const [region, setRegion] = useState("");
  const getRegions = async () => {
    return await axios
      .get(regionUrl);
  };

  useEffect(() => {
    getRegions()
      .then(response => {
        setRegionList(response.data);
      })
      .catch(error => {
        console.error("Erreur d'obtention de la liste des regions :" + error)
      });
  }, []);
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  }

  const provUrl = `${process.env.REACT_APP_API_URL}/provinces/list/${region}`;
  const getProvinces = async () => {
    return await axios
      .get(provUrl);
  };
  useEffect(() => {
    if (region !== "Sélectionnez une région") {
      getProvinces()
        .then((response) => {
          setProvinceList(response.data);
        })
        .catch(error => {
          console.log("Erreur d'obtention de la liste des provinces: " + error)
        });
    }
  }, [getProvinces, region]);
  const handleProvinceChange = (event) => {
    setProvince(event.target.value);
  }
  const distUrl = `${process.env.REACT_APP_API_URL}/districts/list/${province}`;
  const getDistricts = async () => {
    return await axios.get(distUrl);
  };
  useEffect(() => {
    if (province !== "Sélectionnez une province") {
      getDistricts()
        .then((response) => {
          setDistrictList(response.data);
        })
        .catch(error => {
          console.log("Erreur d'obtention de la liste des districts: " + error)
        });
    }
  }, [province]);

  const handleDistrictChange = (event) => {
    setDistrict(event.target.value);
  }
  const comUrl = `${process.env.REACT_APP_API_URL}/communes/list/${district}`;
  const getCommunes = async () => {
    return await axios.get(comUrl);
  };
  useEffect(() => {
    if (district !== "Sélectionnez un district") {
      getCommunes()
        .then((response) => {
          setCommuneList(response.data);
        })
        .catch(error => {
          console.log("Erreur d'obtention de la liste des communes: " + error)
        });
    }
  }, [district]);

  const arrUrl = `${process.env.REACT_APP_API_URL}/arrdts/list/${commune}`;
  const getArrondissements = async () => {
    return await axios.get(arrUrl);
  };
  useEffect(() => {
    if (commune !== "Sélectionnez une commune") {
      getArrondissements()
        .then((response) => {
          setArrondissementList(response.data);
        })
        .catch(error => {
          console.log("Erreur d'obtention de la liste des communes: " + error)
        });
    }
  }, [commune]);

  const handleArrondissementChange = (event) => {
    setArrondissement(event.target.value);
  }

  const [typeEsp, setTypeEsp] = useState("NonCONVENTIONNE");
  const [gesp, setGesp] = useState("");
  const [gespList, setGespList] = useState([]);

  const gespURL = `${process.env.REACT_APP_API_URL}/gesp/list/${typeEsp}`;
  const getGroupeEsps = () => {
    axios
      .get(gespURL)
      .then((response) => {
        setGespList(response.data);
      })
      .catch((error) => {
        console.log("Erreur d'obtention de la liste des groupe d'esp :" + error);
      });
  };
  useEffect(() => {
    if (typeEsp !== "Sélectionnez le Type d'ESP") {
      getGroupeEsps();
    }
  }, [typeEsp]);

  const handleGespChange = (event) => {
    setGesp(event.target.value);
  };

  const espURL = `${process.env.REACT_APP_API_URL}/esp/list/${gesp}`;
  const [esp, setEsp] = useState("");
  const [espList, setEspList] = useState([]);

  const getEsps = () => {
    axios
      .get(espURL)
      .then((response) => {
        setEspList(response.data);
      })
      .catch((error) => {
        console.log("Erreur d'obtention de la liste des Esp :" + error);
      });
  };
  useEffect(() => {
    if (gesp !== "Sélectionnez un groupe esp") {
      getEsps();
    }
  }, [gesp]
  );

  const handleEspChange = (event) => {
    setEsp(event.target.value);
  }


  const downloadReceipt = async (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/recipisse-physique/${demandeId}`;
    try {
      const resp = await axios.get(url, {
        responseType: "blob",
      });
      const fileUrl = window.URL.createObjectURL(new Blob([resp.data]));
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = `recepisse_${demandeId}.pdf`;
      document.body.appendChild(a);
      a.click();

      //Nettoyage Url
      window.URL.revokeObjectURL(fileUrl);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Erreur lors du télécharrgement: ", error);
     // alert("Le téléchargement du récépissé a échoué !");
    }
    // Création d'un lien a avec l'URL du récépissé
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = `recepisse_${demandeId}.pdf`; // Nom du fichier téléchargé

    // // Ajout du lien au document et déclenchement du clic pour télécharger le fichier
    // document.body.appendChild(a);
    // a.click();

    // // Suppression du lien du document
    // document.body.removeChild(a);
  };

  const validateStep1 = () => {
    let isValid = true;
    const errors = [];

    // Validation des champs de l'étape 1
    if (!demandeDTO.nomEsp?.trim()) {
      errors.push("Le nom de l'établissement est requis");
      isValid = false;
    }
    if (!demandeDTO.commune?.id) {
      errors.push("La commune est requise");
      isValid = false;
    }
    if (!demandeDTO.numArreteCreation?.trim()) {
      errors.push("Le numéro d'arrêté de création est requis");
      isValid = false;
    }

    if (!isValid) {
      Swal.fire({
        icon: 'error',
        title: 'Champs requis',
        html: errors.join('<br>'),
        confirmButtonColor: '#3085d6'
      });
    }

    return isValid;
  };

  const validateStep2 = () => {
    let isValid = true;
    const errors = [];

    if (!demandeDTO.promoteur?.nom?.trim()) {
      errors.push("Le nom du promoteur est requis");
      isValid = false;
    }
    if (!demandeDTO.promoteur?.prenom?.trim()) {
      errors.push("Le prénom du promoteur est requis");
      isValid = false;
    }  if (!demandeDTO.promoteur?.genre?.trim()) {
      errors.push("Le genre du promoteur est requis");
      isValid = false;
    }
    if (!demandeDTO.promoteur?.nip?.trim()) {
      errors.push("Le NIP du promoteur est requis");
      isValid = false;
    } else if (demandeDTO.promoteur.nip.length !== 17) {
      errors.push("Le NIP du promoteur doit contenir exactement 17 caractères");
      isValid = false;
    }
    if (!demandeDTO.promoteur?.tel?.trim() ) {
      errors.push("Un numéro de téléphone est requis");
      isValid = false;
    }
    if (!demandeDTO.promoteur?.numOp?.trim() ) {
      errors.push("Un numéro d'ordre professionnel est requis");
      isValid = false;
    }
    if (!demandeDTO.promoteur?.email?.trim() ) {
      errors.push("l'adresse email est requis");
      isValid = false;
    }

    if (!demandeDTO.promoteur?.dateNaissance?.trim() ) {
      errors.push("la date de naissance est requis");
      isValid = false;
    }
    if (!demandeDTO.promoteur?.lieuNaissance?.trim() ) {
      errors.push("le lieu de naissance est requis");
      isValid = false;
    }
    // Ajoutez d'autres validations selon vos besoins

    if (!isValid) {
      Swal.fire({
        icon: 'error',
        title: 'Champs requis',
        html: errors.join('<br>'),
        confirmButtonColor: '#3085d6'
      });
    }

    return isValid;
  };

  const validateStep3 = () => {
    let isValid = true;
    const errors = [];

    if (!demandeDTO.personnels || demandeDTO.personnels.length === 0) {
      errors.push("Au moins un personnel doit être ajouté");
      isValid = false;
    } else {
      demandeDTO.personnels.forEach((personnel, index) => {
        if (!personnel.nom?.trim()) {
          errors.push(`Le nom est requis pour le personnel ${index + 1}`);
          isValid = false;
        }
        if (!personnel.nip?.trim()) {
          errors.push(`Le NIP est requis pour le personnel ${index + 1}`);
          isValid = false;
        } else if (personnel.nip.length !== 17) {
          errors.push("Le NIP du personnel doit contenir exactement 17 caractères");
          isValid = false;
        }
        
        if (!personnel.prenom?.trim()) {
          errors.push(`Le prenom est requis pour le personnel ${index + 1}`);
          isValid = false;
        }
        if (!personnel.profession?.trim()) {
          errors.push(`La profession est requis pour le personnel ${index + 1}`);
          isValid = false;
        }
        // Ajoutez d'autres validations selon vos besoins
      });
    }

    if (!isValid) {
      Swal.fire({
        icon: 'error',
        title: 'Champs requis',
        html: errors.join('<br>'),
        confirmButtonColor: '#3085d6'
      });
    }

    return isValid;
  };

  const validateStep = () => {
    let isValid = true;
    const errors = [];

    switch (currentStep) {
      case 1:
        isValid = validateStep1();
        break;
      case 2:
        isValid = validateStep2() && validateStep3(); // Assurez-vous que les deux validations sont vérifiées
        break;
      
    }

    if (errors.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Champs requis',
        html: errors.join('<br>'),
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Compléter les informations'
      });
      return false;
    }

    return isValid;
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setCurrentStep(currentStep + 1);
      setError(null);
    }// Retourne le résultat de la validation
  };



  useEffect(() => {
    // Si une erreur est définie, démarrez le minuteur pour la masquer après 3 secondes
    if (error) {
      const timer = setTimeout(() => {
        setError(''); // Réinitialise l'erreur après 3 secondes
      }, 3000);

      // Nettoyez le timer si le composant est démonté ou si l'erreur change
      return () => clearTimeout(timer);
    }
  }, [error]);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [showr, setShowr] = useState(false);
  const handlerShow = () => setShowr(true);
  const handleCloser = () => setShowr(false);

  // Au début du composant, ajoutez cet useEffect pour initialiser les valeurs
  useEffect(() => {
    if (userData && userData.nom && userData.prenom) {
      setDemandeDTO(prevState => ({
        ...prevState,
        promoteur: {
          ...prevState.promoteur,
          nom: userData.nom,
          prenom: userData.prenom,
          email: userData.email,
          tel: userData.tel
        }
      }));
    }
  }, [userData]); // Se déclenche quand userData est chargé

  // Mise à jour de l'état `commune` lors de la sélection
  const handleCommuneChange = (event) => {
    const selectedCommuneId = event.target.value;
    setCommune(selectedCommuneId);
    setDemandeDTO(prev => ({
      ...prev,
      commune: {
        ...prev.commune,
        id: selectedCommuneId,
      },
    }));
  };

  return (
    <div id="page-top">
      <div id="wrapper">
        {/*
        <Header /> Sidebar */}
        <div id="content-wrapper">
          <Navbar />
          <div id="content">
            <br></br><br></br>
            <div className="col-md-12">
              <div className="form h-100">
                <h3 className="title-text">Demande d'Ouverture et D'exploitation</h3>


                <Form.Select
                  name="typeDemande"
                  value="OUVERTURE"
                  onChange={handleInputChange}
                  className="form-control"
                  required
                  hidden
                >


                  <option value="OUVERTURE">
                    Ouverture{" "}
                  </option>

                </Form.Select>


                <div>
                  <h4 className="titles-text">
                    {steps[currentStep - 1]}
                  </h4>


                  <br></br>

                  <ProgressBar currentStep={currentStep} steps={steps} />

                  <br></br>
                  <Form
                    onSubmit={handleSubmit}
                    className="mb-5"
                    noValidate
                  >
                    {currentStep === 1 && (
                      <>
                        <div className="row">


                          {/* Groupe ESP */}
                          <div className="col-md-12 form-group mb-5">
                            <Form.Group className="mb-6">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Catégorie ESP <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select

                                onChange={handleGespChange}
                                className="form-control"
                                required
                              >
                                <option value="">Sélectionnez un groupe esp</option>
                                {gespList.map((ges) => (
                                  <option value={ges.id} key={ges.id}>
                                    {ges.libelleGesp}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* ESP */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Type ESP <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select
                                name="esp.id"
                                value={demandeDTO.esp.id}
                                onChange={handleInputChange}
                                className="form-control"
                              >
                                <option value="">Sélectionnez un ESP</option>
                                {espList.map((esps) => (
                                  <option value={esps.id} key={esps.id}>
                                    {esps.libelleEsp}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* Région */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Région <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select

                                onChange={handleRegionChange}
                                className="form-control"
                                required
                              >
                                <option value="">Sélectionnez une région</option>
                                {regionList.map((reg) => (
                                  <option value={reg.id} key={reg.id}>
                                    {reg.libelleRegion}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* Province */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Province <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select

                                onChange={handleProvinceChange}
                                className="form-control"
                                required
                              >
                                <option value="">Sélectionnez une province</option>
                                {provinceList.map((prov) => (
                                  <option value={prov.id} key={prov.id}>
                                    {prov.intituleProvince}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* District */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                District <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select

                                onChange={handleDistrictChange}
                                className="form-control"
                                required
                              >
                                <option value="">Sélectionnez un district</option>
                                {districtList.map((dist) => (
                                  <option value={dist.id} key={dist.id}>
                                    {dist.intituleDistrict}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* Commune */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Communes <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select
                                name="commune.id"
                                value={demandeDTO.commune.id}
                                onChange={handleCommuneChange}
                                className="form-control"
                              >
                                <option value="">Sélectionnez une commune</option>
                                {communeList.map((comune) => (
                                  <option value={comune.id} key={comune.id}>
                                    {comune.intituleCommune}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* Arrondissement */}
                          {arrondissementList.length > 0 && (
                            <div className="col-md-6 form-group mb-5">
                              <Form.Group className="mb-3">
                                <Form.Label className="col-form-label" style={{ color: "black" }}>
                                  Arrondissement <span style={{ color: "red" }}>(obligatoire)</span>
                                </Form.Label>
                                <Form.Select
                                  name="arrondissement.id"
                                  value={demandeDTO.arrondissement.id}
                                  onChange={handleInputChange}
                                  className="form-control"
                                >
                                  <option value="">Sélectionnez un arrondissement</option>
                                  {arrondissementList.map((arr) => (
                                    <option value={arr.id} key={arr.id}>
                                      {arr.intituleArrdt}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </div>
                          )}
                          {/* Nom de l'ESP */}
                          <div className={`form-group mb-5 ${arrondissementList.length > 0 ? 'col-md-12' : 'col-md-6'}`}>
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Nom de l'ESP <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="nomEsp"
                                value={demandeDTO.nomEsp}
                                onChange={handleInputChange}
                                className={`form-control`}
                                required
                              />
                            </Form.Group>
                          </div>

                          {/* Dossier complet de l'ESP */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Dossier complet de l'ESP{" "}
                                <span style={{ color: "blue" }}>(scanner en un seul fichier)</span>{" "}
                                <span style={{ color: "red" }}>(obligatoire et maximum 5Mo)</span>
                              </Form.Label>
                              <input
                                type="file"
                                className="form-control"
                                accept=".pdf"
                                name="fichierStructure"
                                onChange={handleFileChangeStructure}
                                required
                              />
                              {/* Bouton pour afficher la liste des fichiers requis */}
                              <Button variant="info" onClick={handleShow} style={{ marginTop: "10px" }}>
                                Voir la liste des fichiers à charger
                              </Button>
                              {fichierStructure && (
                                <div style={{ marginTop: "10px" }}>
                                  <p>Fichier sélectionné : {fichierStructure.name}</p>
                                </div>
                              )}
                            </Form.Group>
                            {/* Modale pour afficher la liste des fichiers requis */}
                            <Modal show={show} onHide={handleClose} size="xl">
                              <Modal.Header closeButton>
                                <Modal.Title style={{ color: "black" }}><b>Liste des fichiers à charger (en 1 seul fichier pdf)</b></Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <ul style={{ color: "black" }}>
                                  <li>- Une copie de l'arrêté d'autorisation de création délivrée par le Ministre chargé de la santé en cours de validité</li>
                                  <li>- Une attestation de non-engagement dans la fonction publique ou un arrêté de démission ou une cessation de service accompagnée d'un arrêté de mise en disponibilité ou un arrêté de mise à la retraite</li>
                                  <li>- Une déclaration sur l'honneur de non-engagement dans un établissement sanitaire privé de soins, de médecine physique et de réadaptation fonctionnelle et d'aide au diagnostic comme responsable technique</li>
                                  <li>- Le procès-verbal de l'inspection du site, des locaux et équipements par les services compétents du Ministère en charge de la santé</li>
                                  <li>- Une attestation d'assurance des locaux</li>
                                  <li>- Une attestation d'assurance de responsabilité civile professionnelle</li>
                                  <li>- Un casier judiciaire datant de moins de trois mois du promoteur</li>
                                  <li>- La liste du matériel technique et des équipements requis</li>
                                </ul>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                  Fermer
                                </Button>
                              </Modal.Footer>
                            </Modal>

                          </div>



                          <div className="col-md-6 form-group mb-5">
                            <label className="col-form-label" style={{ color: 'black' }}>
                              <b>  Numéro de l'Arrêté de création (ex: N°2024-238/MSHP/CAB du 05/07/2024)<span style={{ color: 'red' }}> (obligatoire)</span></b>
                            </label>
                            <Form.Control
                              value={demandeDTO.numArreteCreation || ''}
                              name="numArreteCreation"
                              onChange={handleInputChange}
                              placeholder="Numéro de l'Arrêté de création"
                              type="text"
                              className="form-control"

                              required
                            />
                          </div>
                        </div>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;



                        {/*<CustomButton variant="primary" onClick={handleNextStep}>
                          Suivant
                        </CustomButton>*/}
                        <div style={{ textAlign: 'right' }}>
                          {error && <div className="alert alert-danger">{error}</div>}
                          <button className="btn btn-primary" style={{ width: '215px' }} onClick={handleNextStep}>
                            <i className="bi bi-arrow-right"></i> {/* Icône de flèche à droite */}
                            Suivant
                          </button>

                        </div>

                      </>
                    )}

                    {/* {currentStep === 2 && (
  <PersonnelManagement
    demandeDTO={demandeDTO}
    setDemandeDTO={setDemandeDTO}
    handleNextStep={handleNextStep}
    handlePrevStep={handlePrevStep}
  />
)} */}
                    {currentStep === 2 && (
                      <>
                        <div className="row">
                          {/* Ajout d'un bouton de téléchargement du fichier Excel */}
                          <div className="col-md-12 form-group mb-5">
                            <b>
                              <label className="col-form-label" style={{ color: "black" }}>
                                Charger un fichier Excel <span style={{ color: "blue" }}>(charger le fichier Excel contenant les informations de votre personnel)</span>
                              </label>
                            </b>
                            <input
                              className="form-control"
                              type="file"
                              accept=".xlsx, .xls"
                              onChange={handleFileXlsUpload}
                            />

                            {/* Bouton pour télécharger le modèle Excel */}
                            <a
                              href="/Modele_Personnel_Promoteur.xlsx"
                              download="Modele_Personnel_Promoteur.xlsx"
                              className="btn btn-primary mt-3"
                              textTransform='none'
                            >
                              Télécharger le modèle Excel
                            </a>
                          </div>
                          { /* *****Infos du Promoteur***** */}

                          <br />
                          <Card className="mb-4">
                            <Card.Header as="h5">Informations du Promoteur</Card.Header>
                            <Card.Body>
                              <div className="row">
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b>Nom{" "} 
                                   <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="promoteur.nom"
                                    value={demandeDTO.promoteur.nom ? demandeDTO.promoteur.nom.toUpperCase() : ''}
                                    onChange={handleInputChange}
                                    placeholder="Nom"
                                    className="form-control"
                                    id="nom"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b>Prénom(s){" "}
                                   <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="promoteur.prenom"
                                    value={demandeDTO.promoteur.prenom ? demandeDTO.promoteur.prenom.toUpperCase() : ''}
                                    onChange={handleInputChange}
                                    placeholder="Prenom"
                                    className="form-control"
                                    id="prenom"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b>Email{" "} 
                                   <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="promoteur.email"
                                    value={demandeDTO.promoteur.email}
                                    onChange={handleInputChange}
                                    placeholder="Votre email "
                                    className="form-control"
                                    id="email"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                      Genre{" "}
                                      <span style={{ color: "red" }}>(obligatoire)</span>
                                    </Form.Label>

                                    <Form.Select

                                      value={demandeDTO.promoteur.genre}
                                      name="promoteur.genre"
                                      onChange={handleInputChange}
                                      className="form-control"
                                      id="genre"
                                      required
                                    >
                                      <option value="">
                                        Sélectionnez le genre{" "}
                                      </option>
                                      <option value="HOMME">
                                        Homme{" "}
                                      </option>
                                      <option value="FEMME">
                                        Femme{" "}
                                      </option>

                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b>  Numéro Nip de la CNIB{" "} 
                                   <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="promoteur.nip"
                                    value={demandeDTO.promoteur.nip}
                                    onChange={(e) => {
                                      const value = e.target.value.replace(/[^0-9]/g, '');
                                      handleInputChange({
                                        ...e,
                                        target: { ...e.target, value, name: e.target.name }
                                      });
                                    }}
                                    placeholder="Numéro NIP de la CNIB"
                                    className={`form-control ${demandeDTO.promoteur.nip && demandeDTO.promoteur.nip.length !== 17 ? 'is-invalid' : ''}`}
                                    maxLength={17}
                                    minLength={17}
                                    id="nip"
                                    required
                                  />
                                
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label className="col-form-label" style={{ color: "black" }}>
                                    <b> Date de naissance{" "}
                                      <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="date"
                                    name="promoteur.dateNaissance"
                                    value={demandeDTO.promoteur.dateNaissance}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    id="dateNaissance"
                                    // max="2006-12-31"        // Empêche la sélection au-delà de 2006
                                    // defaultValue="2006-01-01"  // Définir l'année de début comme 2006
                                    required
                                  />
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b>  Lieu de naissance{" "} 
                                   <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="promoteur.lieuNaissance"
                                    value={demandeDTO.promoteur.lieuNaissance}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    id="lieuNaissance"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b>Numéro de telephone (ex: +22670XXXXXX) {" "}
                                      <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="promoteur.tel"
                                    value={demandeDTO.promoteur.tel}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      
                                      // Supprime tous les caractères non numériques sauf + et espace
                                      value = value.replace(/[^\d+ ]/g, '');
                                      
                                      // Vérifie seulement si ça commence par +
                                      if (value === '' || value.startsWith('+')) {
                                        handleInputChange({
                                          ...e,
                                          target: { ...e.target, value, name: e.target.name }
                                        });
                                      }
                                    }}
                               
                                    placeholder="+XXXXXXXXXXX"
                                    className={`form-control `}
                                    title="Le numéro doit commencer par +"
                                    required
                                  />
                                
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b> Profession{" "} 
                                   <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="promoteur.profession"
                                    value={demandeDTO.promoteur.profession || ''}
                                    onChange={handleInputChange}
                                    placeholder="Saisir votre profession"
                                    className="form-control"
                                    id="profession"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b> Numéro d'Ordre Professionel{" "}
                                    <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="promoteur.numOp"
                                    value={demandeDTO.promoteur.numOp}
                                    onChange={handleInputChange}
                                    placeholder="Numéro de l'ordre professionel"
                                    className="form-control"
                                    id="numOp"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b>  Lieu de résidence{" "}
                                   <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    name="promoteur.adresse"
                                    value={demandeDTO.promoteur.adresse}
                                    onChange={handleInputChange}
                                    placeholder="Adresse du promoteur"
                                    className="form-control"
                                    id="adresse"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label className="col-form-label" style={{ color: "black" }}>
                                    <b>Nationalité <span style={{ color: "red" }}>(obligatoire)</span></b>
                                  </label>
                                  <Form.Select
                                    name="promoteur.nationalite"
                                    value={demandeDTO.promoteur.nationalite}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    id="nationalite"
                                    required
                                  >
                                    <option value="">Sélectionner une nationalité</option>
                                    <option value="Américaine">Américaine</option>
                                    <option value="Algérien">Algérien</option>
                                    <option value="Allemande">Allemande</option>
                                    <option value="Angolais">Angolais</option>
                                    <option value="Béninois">Béninois</option>
                                    <option value="Botswanais">Botswanais</option>
                                    <option value="Burkinabè">Burkinabè</option>
                                    <option value="Burundais">Burundais</option>
                                    <option value="Canadienne">Canadienne</option>
                                    <option value="Camerounais">Camerounais</option>
                                    <option value="Cap-Verdien">Cap-Verdien</option>
                                    <option value="Centrafricain">Centrafricain</option>
                                    <option value="Comorien">Comorien</option>
                                    <option value="Congolais (Congo-Brazzaville)">Congolais (Congo-Brazzaville)</option>
                                    <option value="Congolais (Congo-Kinshasa)">Congolais (Congo-Kinshasa)</option>
                                    <option value="Djiboutien">Djiboutien</option>
                                    <option value="Espagnole">Espagnole</option>
                                    <option value="Égyptien">Égyptien</option>
                                    <option value="Érythréen">Érythréen</option>
                                    <option value="Eswatinien">Eswatinien</option>
                                    <option value="Éthiopien">Éthiopien</option>
                                    <option value="Française">Française</option>
                                    <option value="Gabonnais">Gabonnais</option>
                                    <option value="Gambien">Gambien</option>
                                    <option value="Ghanéen">Ghanéen</option>
                                    <option value="Guinéen">Guinéen</option>
                                    <option value="Guinéen équatorien">Guinéen équatorien</option>
                                    <option value="Ivoirien">Ivoirien</option>
                                    <option value="Italienne">Italienne</option>
                                    <option value="Kenyan">Kenyan</option>
                                    <option value="Lesothan">Lesothan</option>
                                    <option value="Libérien">Libérien</option>
                                    <option value="Libyen">Libyen</option>
                                    <option value="Malagasy">Malagasy</option>
                                    <option value="Malawite">Malawite</option>
                                    <option value="Malien">Malien</option>
                                    <option value="Marocain">Marocain</option>
                                    <option value="Mauricien">Mauricien</option>
                                    <option value="Mauritanien">Mauritanien</option>
                                    <option value="Mozambicain">Mozambicain</option>
                                    <option value="Namibien">Namibien</option>
                                    <option value="Nigerien">Nigerien</option>
                                    <option value="Nigérian">Nigérian</option>
                                    <option value="Rwandais">Rwandais</option>
                                    <option value="Sao-Toméen">Sao-Toméen</option>
                                    <option value="Sénégalais">Sénégalais</option>
                                    <option value="Seychellois">Seychellois</option>
                                    <option value="Sierra-Léonais">Sierra-Léonais</option>
                                    <option value="Somalien">Somalien</option>
                                    <option value="Soudanais">Soudanais</option>
                                    <option value="Sud-Africain">Sud-Africain</option>
                                    <option value="Sud-Soudanais">Sud-Soudanais</option>
                                    <option value="Tanzanien">Tanzanien</option>
                                    <option value="Tchadien">Tchadien</option>
                                    <option value="Togolais">Togolais</option>
                                    <option value="Tunisien">Tunisien</option>
                                    <option value="Zambien">Zambien</option>
                                    <option value="Zimbabwéen">Zimbabwéen</option>
                                    {/* Ajoute ici d'autres options de nationalité */}
                                  </Form.Select>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                          { /* *****Infos du personnel***** */}

                          <Card className="mb-4">
                            <Card.Header as="h5">Informations du Personnel</Card.Header>
                            <Card.Body>
                              <div>
                                {demandeDTO.personnels.map((membrePersonnel, index) => (
                                  <div className="row" key={index}>


                                    <div className="col-md-12 form-group mb-5">
                                      {" "}
                                      <h4>Personnel {index + 1}</h4>
                                    </div>
                                    <div className="col-md-6 form-group mb-5">
                                      <label
                                        className="col-form-label"
                                        style={{ color: "black" }}
                                      >
                                        <b>   Nom{" "}
                                         <span style={{ color: "red" }}>(obligatoire)</span></b>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        value={membrePersonnel.nom}
                                        onChange={(e) =>
                                          handlePersonnelChange(
                                            index,
                                            "nom",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Nom"
                                        className="form-control"
                                        id={`nom_${index}`}
                                        required
                                      />
                                    </div>
                                    <div className="col-md-6 form-group mb-5">
                                      <label
                                        className="col-form-label"
                                        style={{ color: "black" }}
                                      >
                                        <b>Prénom(s){" "}
                                         <span style={{ color: "red" }}>(obligatoire)</span></b>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        value={membrePersonnel.prenom}
                                        onChange={(e) =>
                                          handlePersonnelChange(
                                            index,
                                            "prenom",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Prénom "
                                        className="form-control"
                                        id={`prenom_${index}`}
                                        required
                                      />
                                    </div>

                                    <div className="col-md-6 form-group mb-5">
                                      <label
                                        className="col-form-label"
                                        style={{ color: "black" }}
                                      >
                                        <b>  Numéro Nip de la CNIB{" "}
                                         <span style={{ color: "red" }}>(obligatoire)</span></b>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        value={membrePersonnel.nip}
                                        onChange={(e) => {
                                          const value = e.target.value.replace(/[^0-9]/g, '');
                                          handlePersonnelChange(
                                            index,
                                            "nip",
                                            value
                                          );
                                        }}
                                        placeholder="Saisir les 17 chiffres situé en haut de la CNIB"
                                        className={`form-control ${membrePersonnel.nip && membrePersonnel.nip.length !== 17 ? 'is-invalid' : ''}`}
                                        maxLength={17}
                                        minLength={17}
                                        id={`nip_${index}`}
                                        required
                                      />
                                      {membrePersonnel.nip && membrePersonnel.nip.length !== 17 && (
                                        <div className="invalid-feedback">
                                          Le numéro NIP doit contenir exactement 17 chiffres
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-md-6 form-group mb-5">
                                      <label
                                        className="col-form-label"
                                        style={{ color: "black" }}
                                      >
                                        <b> Profession{" "}
                                         <span style={{ color: "red" }}>(obligatoire)</span></b>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        name="personnel.profession"
                                        value={membrePersonnel.profession}
                                        onChange={(e) =>
                                          handlePersonnelChange(
                                            index,
                                            "profession",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Saisir votre profession"
                                        className="form-control"
                                        id={`profession_${index}`}
                                        required
                                      />
                                    </div>
                                    <div className="col-md-12 form-group mb-5">
                                      <label
                                        className="col-form-label"
                                        style={{ color: "black" }}
                                      >
                                        <b> Numéro d'Ordre Professionel{" "}
                                          {/* <span style={{ color: "red" }}>
                                        *
                                      </span>*/}</b>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        value={membrePersonnel.numOp}
                                        onChange={(e) =>
                                          handlePersonnelChange(
                                            index,
                                            "numOp",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Numéro d'inscription à l'ordre"
                                        className="form-control"
                                        id={`numOp_${index}`}
                                        required
                                      />
                                    </div>


                                    <div className="col-md-12 form-group mb-5 d-flex justify-content-center">
                                      <div className="d-flex align-items-center mr-3">
                                        <Button
                                          variant="danger"
                                          onClick={() => removePersonnel(index)}
                                          className="d-flex align-items-center"
                                          style={{
                                            padding: '10px 20px',
                                            borderRadius: '5px',
                                            transition: 'background-color 0.3s, border 0.3s',
                                          }}
                                          disabled={index === 0} 
                                        >
                                          <i className="fas fa-trash-alt" style={{ marginRight: "5px" }}></i>
                                          Supprimer le Personnel
                                        </Button>
                                      </div>

                                      <div className="d-flex align-items-center">
                                        <Button
                                          variant="success"
                                          onClick={addPersonnel}
                                          className="d-flex align-items-center"
                                          style={{
                                            padding: '10px 20px',
                                            borderRadius: '5px',
                                            transition: 'background-color 0.3s, border 0.3s',
                                          }}
                                        >
                                          <i className="fas fa-plus" style={{ marginRight: "5px" }}></i>
                                          Ajouter un Personnel
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                )
                                )}
                              </div>

                              <br /><br />      </Card.Body>
                          </Card>
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ textAlign: 'left' }}>
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handlePrevStep}>
                              <i className="bi bi-arrow-left"></i> {/* Icône de flèche à gauche */}
                              Précédent
                            </button>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handleNextStep}>
                              <i className="bi bi-arrow-right"></i> {/* Icône de flèche à droite */}
                              Suivant
                            </button>
                          </div>
                        </div>

                      </>
                    )}

                    {currentStep === 3 && (
                      <>
                        <Button variant="info" onClick={handlerShow} style={{ marginTop: "70px" }}>
                          Voir la liste des fichiers à charger
                        </Button>
                        <br></br>

                

                        {demandeDTO.personnels.map((membrePersonnel, index) => (
                          <div key={index} className="col-md-12 form-group mb-5">
                            <b> <label>Dossier Personnel {index + 1} <span style={{ color: "blue" }}>({membrePersonnel.nom} {membrePersonnel.prenom})</span> <span style={{ color: "red" }}>(obligatoire et maximum 1Mo)</span></label></b>
                            <Form.Control
                              className="form-control"
                              type="file"
                              name={`personnelSimpleFichiers_${index}`}
                              onChange={(e) => handleFileChangePersonnel(index, e)}
                              accept=".pdf"
                            />
                            {/* Afficher le nom du fichier s'il est sélectionné */}
                            {personnelFichiers[index] && (
                              <div style={{ marginTop: "10px" }}>
                                <p>Fichier sélectionné : {personnelFichiers[index].name}</p>
                              </div>
                            )}
                          </div>
                        ))}


                        {/* Modale pour afficher la liste des fichiers requis */}
                        <Modal show={showr} onHide={handleCloser} size="xl">
                          <Modal.Header closeButton>
                            <Modal.Title style={{ color: "black" }}><b>Liste des fichiers à charger (en 1 seul fichier pdf)</b></Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <ul style={{ color: "black" }}>
                              <li>- Une copie légalisée de la carte nationale d'identité burkinabè ou du passeport en cours de validité ;</li>
                              <li>- les photocopies légalisées des diplômes ;</li>
                              <li>- une attestation d'inscription à l'ordre professionnel en cours de validité ;</li>
                              <li>- une attestation de non-engagement dans la fonction publique ou un arrêté de démission ou une cessation de service accompagnée d'un arrêté de mise en disponibilité ou un arrêté de mise à la retraite.</li>
                            </ul>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloser}>
                              Fermer
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        {/* Navigation buttons */}

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                          <div style={{ textAlign: 'left' }}>
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handlePrevStep}>
                              <i className="bi bi-arrow-left"></i> Précédent
                            </button>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                            {error && <div className="alert alert-danger">{error}</div>}

                            {/* Ne montrer le bouton "Suivant" que si tous les fichiers sont chargés */}
                            {personnelSimpleFichiers.length === demandeDTO.personnels.length &&
                              personnelSimpleFichiers.every(file => file !== null && file !== undefined) && (
                                <button className="btn btn-primary" style={{ width: '215px' }} onClick={handleNextStep}>
                                  <i className="bi bi-arrow-right"></i> Suivant
                                </button>
                              )}
                          </div>
                        </div>

                      </>
                    )}


                    {currentStep === 4 && (
                      <>
                        <div className="row">
                          <div className="col-md-12 form-group mb-5">
                            {/* Section Conseils */}
                            <div className="instruction-container mb-4 p-3">
                              <div className="d-flex align-items-center justify-content-center mb-2">
                                <i className="fas fa-fw fa-shield-alt"></i>
                                <h4 className="mb-0">Conseils</h4>
                              </div>
                              <div style={{ textAlign: "center" }}>
                                Merci de vérifier que toutes les informations saisies sont correctes avant de soumettre la demande. <br />
                               Une fois la demande soumise, il ne vous sera plus possible d'apporter des modifications.
                              </div>
                            </div>

                            {/* Tableau Informations de la demande */}
                            <table className="table">
                              <thead>
                                <tr>
                                  <th colSpan="2" style={{ textAlign: "center", color: "white" }}>
                                    Informations de la demande
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr style={{ color: "black" }}>
                                  <td><b>Type de Demande :</b></td>
                                  <td>{demandeDTO.typeDemande}</td>
                                </tr>
                      
<tr style={{ color: "black" }}>
  <td><b>Type Etablissement sanitaire de soins :</b></td>
  {/* <td>{demandeDTO.esp && demandeDTO.esp.libelleEsp ? demandeDTO.esp.libelleEsp : "Non défini"}</td> */}
  <td>  {demandeDTO.esp.id
                                    ? (espList.find(esps => esps.id === parseInt(demandeDTO.esp.id, 10))?.libelleEsp || "Etablissement non trouvé")
                                    : "Aucune Etablissement sélectionné"}
                                  </td>
</tr>

                                <tr style={{ color: "black" }}>
                                  <td><b> Nom de l'Etablissement sanitaire de soins :</b></td>
                                  <td>{demandeDTO.nomEsp}</td>
                                </tr>
                                <tr style={{ color: "black" }}>
                                  <td><b>Groupe de l'établissement sanitaire de soins :</b></td>
                                  <td>{typeEsp === "NonCONVENTIONNE" ? "non conventionné" : typeEsp}</td>
                                </tr>
                                <tr style={{ color: "black" }}>
                                  <td><b>Commune :</b></td>
                                  {/* Récupérer l'ID de la commune et chercher son nom */}
                                  <td>  {demandeDTO.commune.id
                                    ? (communeList.find(commune => commune.id === parseInt(demandeDTO.commune.id, 10))?.intituleCommune || "Commune non trouvée")
                                    : "Aucune commune sélectionnée"}
                                  </td>
                                </tr>
                                <tr style={{ color: "black" }}>
                                  <td><b>Arrondissement :</b></td>
                                  {/* Récupérer l'ID de la commune et chercher son nom */}
                                      <td>  {demandeDTO.arrondissement.id
                                    ? (arrondissementList.find(arrondissement => arrondissement.id === parseInt(demandeDTO.arrondissement.id, 10))?.intituleArrdt || "Arrondissement non trouvé")
                                    : "Aucun arrondissement sélectionné"}
                                  </td>
                                </tr>
                                <tr style={{ color: "black" }}>
                                  <td><b>Numéro d'arrêté de création :</b></td>
                                  <td>{demandeDTO.numArreteCreation}</td>
                                </tr>
                              </tbody>


                            </table>
                            <table className="table table-bordered mt-4">
                              <thead>
                                <tr>
                                  <th colSpan="8" style={{ textAlign: "center", color: "white" }}>
                                    Informations du promoteur
                                  </th>
                                </tr>
                                <tr style={{ color: "black", backgroundColor: "#f1f1f1" }}>
                                  <th>Nom</th>
                                  <th>Prénom</th>
                                  <th>Genre</th>
                                  <th>Date de naissance</th>
                                  <th>Lieu de naissance</th>
                                  <th>NIP</th>
                                  <th>Profession</th>
                                  <th>Numéro OP</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* Vérification si demandeDTO et demandeDTO.promoteur sont définis */}
                                {demandeDTO && demandeDTO.promoteur ? (
                                  <tr style={{ color: "black" }}>
                                    <td>{demandeDTO.promoteur.nom || '-'}</td>
                                    <td>{demandeDTO.promoteur.prenom || '-'}</td>
                                    <td>{demandeDTO.promoteur.genre || '-'}</td>
                                    <td>{demandeDTO.promoteur.dateNaissance || '-'}</td>
                                    <td>{demandeDTO.promoteur.lieuNaissance || '-'}</td>
                                    <td>{demandeDTO.promoteur.nip || '-'}</td>
                                    <td>{demandeDTO.promoteur.profession || '-'}</td>
                                    <td>{demandeDTO.promoteur.numOp || '-'}</td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td colSpan="8" style={{ textAlign: "center", color: "black" }}>
                                      Aucune information sur le promoteur disponible
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            {/* Tableau Informations du personnel */}
                            <table className="table table-bordered mt-4">
                              <thead>
                                <tr>
                                  <th colSpan="8" style={{ textAlign: "center", color: "white" }}>
                                    Informations du personnel
                                  </th>
                                </tr>
                                <tr style={{ color: "black", backgroundColor: "#f1f1f1" }}>
                                  <th>Nom</th>
                                  <th>Prénom</th>
                                  <th>NIP</th>
                                  <th>Profession</th>
                                  <th>Numéro OP</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* Vérification si demandeDTO et demandeDTO.proprietairePs sont définis */}
                                {demandeDTO && demandeDTO.personnels && demandeDTO.personnels.length > 0 ? (
                                  demandeDTO.personnels.map((person, index) => (
                                    <tr key={index} style={{ color: "black" }}>
                                      <td>{person.nom}</td>
                                      <td>{person.prenom}</td>
                                      <td>{person.nip}</td>
                                      <td>{person.profession}</td>
                                      <td>{person.numOp}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="8" style={{ textAlign: "center", color: "black" }}>
                                      Aucune information sur le personnel disponible
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>



                          </div>



                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ textAlign: 'left' }}>
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handlePrevStep}>
                              <i className="bi bi-arrow-left"></i> {/* Icône de flèche à gauche */}
                              Précédent
                            </button>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                            <button type="submit" className="btn btn-primary" style={{ width: '215px' }}>
                              <i className="bi bi-save"></i> {/* Icône de disquette */}
                              Soumettre
                            </button>
                          </div>


                        </div>
                      </>
                    )}

                  </Form>
                </div>



              </div>
            </div>
            <Footer />
          </div>

        </div>
      </div>
    </div >

  );
}



export default Form_dmd_dsps;