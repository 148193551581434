import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Navbar from "../Nav";

function ListeTypePiece_E() {
  const editURL = `${process.env.REACT_APP_API_URL}/api/param-type-pieces-niveau-validation`;
  const [pieces, setTypePiece] = useState([]);

  const setpiecesData = () => {
    axios
      .get(editURL)
      .then((response) => {
        setTypePiece(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention des données :" + error);
      });
  };

  useEffect(() => {
    setpiecesData();
  }, []);

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
          <Navbar />
                    <h4 className="title-text">
                      Liste des Types de pièces Etape validation
                    </h4>

                    <Link to="/type_piece_E">
                      <button className="custom-button">
                        {" "}
                        <i className="fas fa-fw fa-plus"> </i> Ajouter
                      </button>
                    </Link>
                    <div className="card-body">
                      <table
                        id="example1"
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Code Pièce</th>
                            <th>Intitulé</th>
                            <th>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pieces.length === 0 ? (
                            <tr>
                              <td colSpan="9" className="text-center">
                                Aucun fichier n'a été enregistré.
                              </td>
                            </tr>
                          ) : (
                            pieces &&
                            pieces.map((utili) => (
                              <tr key={utili.id}>
                                <td className="text-noir">{utili.code}</td>
                                <td className="text-noir">{utili.intitule}</td>
                                <td className="text-noir">
                                  {utili.description}
                                </td>
                                <td className="text-noir">
                                  <Link
                                    to={"/param-type-pieces_E/" + utili.id}
                                    title="voir informations"
                                  >
                                    <div className="round-button">
                                      <i className="bi bi-eye white-icon"></i>
                                    </div>
                                  </Link>

                                  <Link
                                    to={"/edit_param-type-pieces_E/" + utili.id}
                                    title="modifier informations"
                                  >
                                    <div className="round-button">
                                      <i className="bi bi-pencil white-icon"></i>
                                    </div>
                                  </Link>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
        </div>
        <Footer />
      </body>
    </div>
  );
}

export default ListeTypePiece_E;
