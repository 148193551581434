import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";
import Navbar from "../Nav";

function Form_Rapport() {
  const [annee, setAnnee] = useState("");
  const [fichier, setFichier] = useState("");
  const [codeType, setCodeType] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/reports/demande/filter?codeParamTypeDemande=${codeType}&exportType=${fichier}&year=${annee}`,
        { responseType: "arraybuffer" }
      )
      .then((response) => {
        navigate("/admin");
        let mimeType = "application/octet-stream"; // Par défaut, utilisez un type MIME générique

        // Déterminez le type MIME en fonction de l'extension du fichier ou d'autres informations fournies par votre API
        if (fichier.endsWith(".pdf")) {
          mimeType = "application/pdf";
        } else if (fichier.endsWith(".xlsx")) {
          mimeType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        } else if (fichier.endsWith(".docx")) {
          mimeType =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        }

        const blob = new Blob([response.data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `rapport.${fichier.split(".").pop()}`;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => {
        alert("Erreur : " + error);
      });
  };

  return (
    <div>
      <body id="page-top">
        <div id="row">
          {<Header />}
          <div className="custom-wrapper">
          <Navbar />
            {/*  <!-- Content Row --> */}
            <div className="col-md-12">
              <div className="form h-100">
                <h2 className="title-text">Générer un Rapport</h2>
                <Form
                  onSubmit={handleSubmit}
                  className="mb-5"
                  noValidate
                >
                  <div className="row">
                    <div className="col-md-6 form-group mb-6">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Année
                      </label>

                      <select
                        name="zone"
                        value={annee}
                        onChange={(e) => setAnnee(e.target.value)}
                        className="form-control"
                        id="service"
                        required
                      >
                        <option value="">
                          Sélectionnez une année{" "}
                        </option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                      </select>
                    </div>
                    <div className="col-md-6 form-group mb-5">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Type de Fichier
                      </label>

                      <select
                        name="zone"
                        value={fichier}
                        onChange={(e) => setFichier(e.target.value)}
                        className="form-control"
                        id="service"
                        required
                      >
                        <option value="">
                          Sélectionnez le type du fichier du à générer{" "}
                        </option>
                        <option value="PDF">PDF</option>
                        <option value="xlsx">EXCEL</option>
                        <option value="docx">WORD</option>
                      </select>
                    </div>

                    <div className="col-md-12 form-group mb-5">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Type de Demande
                      </label>

                      <select
                        name="zone"
                        value={codeType}
                        onChange={(e) => setCodeType(e.target.value)}
                        className="form-control"
                        id="service"
                        required
                      >
                        <option value="">
                          Sélectionnez le type de la Demande{" "}
                        </option>
                        <option value="01">Demande d'ouverture d'exploitation</option>
                        <option value="02">DEMANDE de convention</option>
                  
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 form-group">
                      <Button
                        className="btn btn-primary w-100"
                        type="submit"
                      >
                        <i className="fas fa-save"></i> Generer
                      </Button>
                      <span className="submitting"></span>
                    </div>
                  </div>
                </Form>

                <div id="form-message-warning mt-4"></div>
              </div>
            </div>
            
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_Rapport;
