import React, { useState } from "react";

import Card from "./Card";
import Foot from "./Foot";
import Head from "./Head";

const TexteRegle = () => {
  const [activeAccordion, setActiveAccordion] = useState("");
  const [footerOpen, setFooterOpen] = useState(false);

  const handleAccordion = (accordionName) => {
    setActiveAccordion(activeAccordion === accordionName ? "" : accordionName);

    setFooterOpen(activeAccordion === accordionName ? false : true);
  };

  return (
    <div>
      <div>
        {/* Head */}
        {<Head />}
      </div>

      <div className="content">
        {/* Contenu scrollable */}
        <br /><br />
        <h3 style={{ textAlign: 'center', color:'black' }}><b> Textes reglémentaires</b></h3><br />
        <section id="apropos" className="d-flex align-items-center accordion">
          <div className="container" id="reglementaire">
            <div className="container">
                <br></br> <Card
                  title="ARRETES N°2023         /MS/CAB    
   portant autorisation de transfert de propriété 
           d’un cabinet privé de soins infirmiers du Ministère de la Santé "
                  // soustitle="ARRETE_N°2023_MS_CAB_"
                  pdfSrc="./ARRETE.pdf"
                />
              </div>

              <div className="container">
                <br></br> <Card
                  title="ARRETES du Ministère de la Santé"
                  // soustitle="ARRETE_N°2023_MS_CAB_"
                  pdfSrc="./ARRETE N°2023_MSHP_CAB.pdf"
                />
              </div>

            {/*<div className="accordion-item">
              <h2 className="accordion-header" onClick={() => handleAccordion("reglementaire")}>
                Textes reglémentaires
                <i className={`bi ${activeAccordion === "reglementaire" ? "bi-chevron-up" : "bi-chevron-down"}`}></i>
              </h2>

            </div>*/}
            <br />


          </div>
        </section>
      </div>

      <div>
        {/* Footer */}
        <Foot />
      </div>
    </div>



  );

}

export default TexteRegle;