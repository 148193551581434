import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../Footer";
import armoiries from "../img/armoiries.jpg";

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import { Typography } from "@mui/material";

function Info_nivo_valid() {
  const editURL = `${process.env.REACT_APP_API_URL}/api/param-niveau-validations/`;

  const param = useParams();
  const [empId, setId] = useState("");
  const [empIntitule, setIntitule] = useState("");
  const [empRole, setRole] = useState("");
  const [empNum, setNum] = useState("");

  const navigate = useNavigate();

  const retour = () => {
    navigate("/liste_niveau_validation");
  };

  useEffect(() => {
    axios
      .get(editURL + param.id)
      .then((response) => {
        const empData = response.data;
        setId(empData.id);
        setIntitule(empData.intitule);
        setRole(empData.role);
        setNum(empData.num_ordre);
      })
      .catch((error) => {
        alert("erreur pour trouver les details :" + error);
      });
  }, []);
  return (
    <div>
      <main id="main" className="main">
        <div className="content center-container">
          <div>
            <Card sx={{ maxWidth: 600 }}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="center-text"
                >
                  <b>
                    <img
                      src={armoiries}
                      alt=""
                      className="img-fluid"
                      width={50}
                    />
                    Niveau de Validation &nbsp;&nbsp;&nbsp;
                  </b>
                </Typography>
                <br></br>
                <Typography gutterBottom variant="h5" component="div">
                  <b> ID:</b> {empId}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  <h6>
                    <b>N° Ordre :</b> {empNum}
                  </h6>
                  <br></br>
                  <h6>
                    <b> Intitulé du niveau de validation :</b> {empIntitule}{" "}
                  </h6>
                  <br></br>
                  <h6>
                    <b>Description du niveau de validation : </b> {empRole}
                  </h6>

                  <br></br>
                </Typography>
              </CardContent>
              <button
                onClick={() => retour()}
                className="btn btn-sm btn-danger"
              >
                {" "}
                retour <i className="bi bi-back"></i>
              </button>
            </Card>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Info_nivo_valid;
