const NotificationService = {
    getNotifications: () => {
        const notifications = JSON.parse(sessionStorage.getItem('notifications') || '[]');
        return Promise.resolve(notifications);
    },

    markNotificationAsRead: (notificationId) => {
        const notifications = JSON.parse(sessionStorage.getItem('notifications') || '[]');
        const updatedNotifications = notifications.map(notification =>
            notification.id === notificationId ? { ...notification, read: true } : notification
        );
        sessionStorage.setItem('notifications', JSON.stringify(updatedNotifications));
        return Promise.resolve();
    },

    createNotification: (notificationData) => {
        const notifications = JSON.parse(sessionStorage.getItem('notifications') || '[]');
        const newNotification = {
            id: Date.now(),
            ...notificationData,
            read: false,
            createdAt: new Date().toISOString()
        };
        notifications.push(newNotification);
        sessionStorage.setItem('notifications', JSON.stringify(notifications));
        return Promise.resolve(newNotification);
    }
};

export default NotificationService;