import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Navbar from "../Nav";

function ListeCommune() {
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const listeURL = `${process.env.REACT_APP_API_URL}/communes/list`;
  const suprimeURL = `${process.env.REACT_APP_API_URL}/communes`;
  const [pieces, setTypePiece] = useState([]);

  const setpiecesData = () => {
    axios
      .get(listeURL)
      .then((response) => {
        setTypePiece(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention de la liste des communes :" + error);
      });
  };

  useEffect(() => {
    setpiecesData();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6); // Nombre d'éléments par page
  // Calculez l'index de début et de fin pour l'affichage des éléments actuels sur la page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUtilisateurs = pieces.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Fonction pour changer de page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calcul du nombre total de pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(pieces.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const supprimer = (id) => {
    axios
      .delete(`${suprimeURL}/${id}`) // Vous devriez concaténer l'ID à l'URL correctement
      .then((response) => {
        alert(
          "la commune identifiée par ID : " + id + " à été supprimé !"
        );
        window.location.reload();
      })
      .catch((error) => {
        alert("Erreur lors de la suppression du type de demande : " + error);
      });
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            {/* <Navbar /> */}
            <br></br>
            <div className="col-xl-12 col-md-6 mb-6">
              <h4 className="title-text">Liste des communes</h4>
              <Link to="/commune">
                <button className="custom-button">
                  {" "}
                  <i className="fas fa-fw fa-plus"> </i> Ajouter
                </button>
              </Link>
              <div className="card-body">
                <table
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom de la Commune</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pieces.length === 0 ? (
                      <tr>
                        <td colSpan="9" className="text-center">
                          Aucun fichier n'a été enregistré.
                        </td>
                      </tr>
                    ) : (
                      pieces &&
                      pieces.map((commune) => (
                        <tr key={commune.id}>
                          <td className="text-noir">{commune.id}</td>
                          <td className="text-noir">{commune.intituleCommune}</td>
                          <td className="text-noir button-container d-flex align-items-center">
                            <Link to={"/voir-commune/" + commune.id}>
                              <div className="round-button d-flex justify-content-center align-items-center">
                                <i className="bi bi-eye white-icon"></i>
                              </div>
                            </Link>

                            <Link
                              to={"/edit-commune/" + commune.id}
                            >
                              <div className="round-button d-flex justify-content-center align-items-center">
                                <i className="bi bi-pencil white-icon"></i>
                              </div>
                            </Link>
                            <div className="round-buttonr">
                              <i
                                className="bi bi-trash white-icon"
                                onClick={() => supprimer(commune.id)}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                  <div className="pagination">
                    {pageNumbers.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => paginate(pageNumber)}
                        className={`page-number ${pageNumber === currentPage ? "selected-page" : ""
                          }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
}

export default ListeCommune;
