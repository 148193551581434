import React, { useState } from 'react';
import axios from 'axios';

const Test = () => {
  const [demandeDTO, setDemandeDTO] = useState({
    
    numDemande: "",
    nomStructure: "",
    nomEsp: "",
    numArretCreation: "",
    typeDemande: "OUVERTURE",
    dateSoumission: new Date().toISOString().split('T')[0],
    typeProprieteDemande: "",
    utilisateur: {
      id: 1, // Remplacez par l'ID de l'utilisateur actuel si nécessaire
    },
    proprietaireRs: [
      {
        nom: "KOURAOGO",
        prenom: "Fati",
        nip: "123456789",
        email: "john.doe@example.com",
        genre: "HOMME",
        tel: "+1234567890",
        numOp: "OP987654321",
        adresse: "123 Rue Exemple",
        nationalite: "Française",
        profession: "Ingénieur"
      }
    ]
  });

  const [fichierStructure, setFichierStructure] = useState(null);
  const [personnelSimpleFichiers, setPersonnelSimpleFichiers] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDemandeDTO(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    if (e.target.name === 'fichierStructure') {
      setFichierStructure(e.target.files[0]);
    } else if (e.target.name === 'personnelSimpleFichiers') {
      setPersonnelSimpleFichiers(prevFiles => [
        ...prevFiles,
        ...Array.from(e.target.files)
      ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Ajout de l'objet demandeDTO
    formData.append('demandeDTO', new Blob([JSON.stringify(demandeDTO)], { type: 'application/json' }));

    // Ajout du type
    formData.append('type', 'personne-physique');

    // Ajout des fichiers
    if (fichierStructure) {
      formData.append('fichierStructure', fichierStructure);
    }
    personnelSimpleFichiers.forEach((file) => {
      formData.append('personnelSimpleFichiers', file);
    });

    try {
      const response = await axios.post('http://localhost:8080/api/v1/auth/demandes/creer-demande', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Réponse:', response.data);
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="nomStructure"
        value={demandeDTO.nomStructure}
        onChange={handleInputChange}
        placeholder="Nom de la structure"
      />
      <input
        type="text"
        name="nomEsp"
        value={demandeDTO.nomEsp}
        onChange={handleInputChange}
        placeholder="Nom ESP"
      />
      {/* Ajoutez d'autres champs de formulaire ici */}
      <input
        type="file"
        name="fichierStructure"
        onChange={handleFileChange}
        accept=".pdf"
      />
      <input
        type="file"
        name="personnelSimpleFichiers"
        onChange={handleFileChange}
        accept=".pdf"
        multiple
      />
      {/* Si vous avez besoin de plusieurs champs pour les fichiers personnels simples, utilisez le même nom */}
      <input
        type="file"
        name="personnelSimpleFichiers"
        onChange={handleFileChange}
        accept=".pdf"
        multiple
      />
      <input
        type="file"
        name="personnelSimpleFichiers"
        onChange={handleFileChange}
        accept=".pdf"
        multiple
      />
      <button type="submit">Envoyer</button>
    </form>
  );
};

export default Test;
