import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";

function Edit_role() {
  const editURL = `${process.env.REACT_APP_API_URL}/roles/update`;
  const editeURL = `${process.env.REACT_APP_API_URL}/roles/`;
  const [intitule, setIntitule] = useState("");

  const [description, setDescript] = useState("");

  const navigate = useNavigate();

  const param = useParams();
  useEffect(() => {
    axios
      .get(editeURL + param.id)
      .then((response) => {
        const empData = response.data;
        setIntitule(empData.libelle);
        setDescript(empData.description);
      })
      .catch((error) => {
        alert("erreur pour trouver les details :" + error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(editURL, {
        id:param.id,
        libelle: intitule,
        description: description,
      })
      .then((response) => {
        alert(" " + intitule + " modifiées avec succès !!");
        navigate("/liste_role");
      })

      .catch((error) => {
        alert("error===" + error);
      });
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="container-fluid">
            {/*  <!-- Content Row --> */}
            <div className="row">
              <div className="col-xxl-4 col-md-12">
                <div className="card info-card sales-card">
                  <div className="container">
                    <div className="row align-items-stretch no-gutters contact-wrap">
                      <div className="col-md-10">
                        <div className="form h-100">
                          <h2 className="title-text">Modifier Role</h2>
                          <Form
                            onSubmit={handleSubmit}
                            className="mb-5"
                            noValidate
                          >
                            <div className="row">
                              <div className="col-md-6 form-group mb-6">
                                <label className="col-form-label">
                                  Intitulé *
                                </label>
                                <Form.Control
                                  type="text"
                                  value={intitule}
                                  onChange={(e) => setIntitule(e.target.value)}
                                  className="form-control"
                                  id="yourEmail"
                                  required
                                />
                              </div>
                              <div>
                                <div className="col-md-12 form-group mb-5">
                                  <label className="col-form-label">
                                    Description du role *
                                  </label>
                                  <Form.Control
                                    type="text"
                                    value={description}
                                    onChange={(e) =>
                                      setDescript(e.target.value)
                                    }
                                    className="form-control"
                                    id="yourEmail"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 form-group">
                                <Button
                                  className="btn btn-primary w-100"
                                  type="submit"
                                >
                                  Enregistrer
                                </Button>
                                <span className="submitting"></span>
                              </div>
                            </div>
                          </Form>

                          <div id="form-message-warning mt-4"></div>
                          <div id="form-message-success">
                            Veuillez Remplir le Formulaire
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="contact-info h-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Edit_role;
