import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";
import Navbar from "../Nav";
import { toast } from 'react-toastify';
import { AiOutlineWarning } from 'react-icons/ai';

function Form_Commune() {
  const baseURL = `${process.env.REACT_APP_API_URL}/communes/create`;
  const [intitule, setIntitule] = useState("");
  const [district, setDistrict] = useState("");
  const [listedistrict, setListeDistrict] = useState([]);
  const listeURL = `${process.env.REACT_APP_API_URL}/districts/list`;
  const setDistrictData = () => {
    axios
      .get(listeURL)
      .then((response) => {
        setListeDistrict(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention de la liste des districts :" + error);
      });
  };

  useEffect(() => {
    setDistrictData();
  }, []);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Effectuer la soumission du formulaire si la validation réussit.
      axios
        .post(baseURL, {
          intituleCommune: intitule,
          district: {
            id: district,
          },

        })
        .then((response) => {
          /* alert(
             " la commune " +
             intitule +
             " a été enregistré  avec succès dans la district !" +
             district
           );*/
          toast.success(" la commune " +
            intitule +
            " a été enregistré  avec succès dans la district !" +
            district);
          navigate("/liste_commune");
        })
        .catch((error) => {
          //alert("Erreur : " + error);
          toast.error(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AiOutlineWarning style={{ marginRight: '8px', fontSize: '1.5rem' }} />
              <span>Erreur : {error}</span>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              className: "custom-toast", // Utilisez une classe CSS personnalisée
              style: { borderRadius: '8px' }
            }
          );
        });
    } else {
      //alert("Veuillez remplir tous les champs obligatoires.");
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineWarning style={{ marginRight: '8px', fontSize: '1.5rem' }} />
          <span>Veuillez remplir tous les champs obligatoires.</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          className: "custom-toast", // Utilisez une classe CSS personnalisée
          style: { borderRadius: '8px' }
        }
      );
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (intitule.trim() === "") {
      setIntituleIsValid(false);
      isValid = false;
    } else {
      setIntituleIsValid(true);
    }



    return isValid;
  };

  const [intituleIsValid, setIntituleIsValid] = useState(true);


  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            {/* <Navbar /> */}
            {/*  <!-- Content Row --> */}
            <br></br>
            <div className="col-md-12">
              <div className="form h-100">

                <h2 className="title-text">Ajouter une commune</h2>
                <Form
                  onSubmit={handleSubmit}
                  className="mb-5"
                  noValidate
                >
                  <div className="row">
                    <div className="col-md-12 form-group mb-6">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Nom  de la commune *
                      </label>
                      <Form.Control
                        type="text"
                        value={intitule}
                        onChange={(e) => setIntitule(e.target.value)}
                        className={`form-control ${intituleIsValid ? "" : "is-invalid"
                          }`}
                        id="yo"
                        required
                      />
                    </div>
                    <div className="col-md-12 form-group mb-5">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Districts{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <select
                        name="typeEsp"
                        value={district}
                        onChange={(e) => setDistrict(e.target.value)}
                        className="form-control"
                        id="service"
                        required
                      >
                        <option value="">
                          Sélectionnez un district{" "}
                        </option>

                        {listedistrict.map((utili, index) => (
                          <option value={utili.id}>
                            {utili.intituleDistrict}
                          </option>
                        ))}

                      </select>
                    </div>
                  </div>




                  <div className="row">
                    <div className="col-md-12 form-group">
                      <Button
                        className="btn btn-primary w-100"
                        type="submit"
                      >
                        <i className="fas fa-save"></i> Enregistrer
                      </Button>
                      <span className="submitting"></span>
                    </div>
                  </div>
                </Form>

              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_Commune;
