import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";
import Navbar from "../Nav";

function Form_District() {
  const baseURL = `${process.env.REACT_APP_API_URL}/districts/create`;
  const [intitule, setIntitule] = useState("");
  const [province, setProvince] = useState("");
  const [listeprovince, setListeProvince] = useState([]);
  const listeURL = `${process.env.REACT_APP_API_URL}/provinces/list`;
  const setProvinceData = () => {
    axios
      .get(listeURL)
      .then((response) => {
        setListeProvince(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention de la liste des provinces :" + error);
      });
  };

  useEffect(() => {
    setProvinceData();
  }, []);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Effectuer la soumission du formulaire si la validation réussit.
      axios
        .post(baseURL, {
          intituleDistrict: intitule,
          province: {
            id: province,
          },


        })
        .then((response) => {
          alert(
            " le District " +
            intitule +
            " a été enregistré  avec succès dans la province " +
            province
          );
          navigate("/liste_district");
        })
        .catch((error) => {
          alert("Erreur : " + error);
        });
    } else {
      alert("Veuillez remplir tous les champs obligatoires.");
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (intitule.trim() === "") {
      setIntituleIsValid(false);
      isValid = false;
    } else {
      setIntituleIsValid(true);
    }



    return isValid;
  };

  const [intituleIsValid, setIntituleIsValid] = useState(true);


  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            {/* <Navbar /> */}
            {/*  <!-- Content Row --> */}
            <div className="col-md-12">
              <div className="form h-100">

                <h2 className="title-text">Ajouter un District</h2>
                <Form
                  onSubmit={handleSubmit}
                  className="mb-5"
                  noValidate
                >
                  <div className="row">
                    <div className="col-md-12 form-group mb-6">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Nom  du District *
                      </label>
                      <Form.Control
                        type="text"
                        value={intitule}
                        onChange={(e) => setIntitule(e.target.value)}
                        className={`form-control ${intituleIsValid ? "" : "is-invalid"
                          }`}
                        id="yo"
                        required
                      />
                    </div>
                    <div className="col-md-12 form-group mb-5">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Provinces{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <select
                        name="typeEsp"
                        value={province}
                        onChange={(e) => setProvince(e.target.value)}
                        className="form-control"
                        id="service"
                        required
                      >
                        <option value="">
                          Sélectionnez une province{" "}
                        </option>

                        {listeprovince.map((utili, index) => (
                          <option value={utili.id}>
                            {utili.intituleProvince}
                          </option>
                        ))}

                      </select>
                    </div>
                  </div>




                  <div className="row">
                    <div className="col-md-12 form-group">
                      <Button
                        className="btn btn-primary w-100"
                        type="submit"
                      >
                        <i className="fas fa-save"></i> Enregistrer
                      </Button>
                      <span className="submitting"></span>
                    </div>
                  </div>
                </Form>

              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_District;
