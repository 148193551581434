/* eslint-disable react/jsx-pascal-case */
import "./App.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Routes, Route, Navigate } from "react-router-dom";
import TemplateLayouts from "./Template/TemplateLayouts";
import TemplatePropos from "./Template/TemplatePropos";
import Privacy from "./Template/Privacy";
import Faq from "./Template/FAQ";
import Creation from "./Template/Infos/Creation";
import Convention from "./Template/Infos/Convention";
import Ouverture from "./Template/Infos/Ouverture";
import Transfert from "./Template/Infos/Transfert";
import Cession from "./Template/Infos/Cession";
import Prorogation from "./Template/Infos/Prorogation";
import Exploitation from "./Template/Infos/Exploitation";
import Rectification from "./Template/Infos/Rectification";
import Transformation from "./Template/Infos/Transformation";
import Responsable from "./Template/Infos/Responsable";
import Denomination from "./Template/Infos/Denomination";
import Fermeture from "./Template/Infos/Fermeture";
import Contact from "./Template/Contact";
import Dashboard from "./Dashboard";
import React, { useState, useEffect } from "react";
import ListeDemandeT from "./Template/admin/typeDemandes/ListeDemandeT";
import Info_piece from "./Template/admin/typePiece/info_piece";
import Form_typ_piece from "./Template/admin/typePiece/Form_typ_piece";
import Edit_typ_dmd from "./Template/admin/typeDemandes/Edit_type_dmd";
import Form_typ_dmd from "./Template/admin/typeDemandes/Form_typ_dmd";
import ListeTypePiece from "./Template/admin/typePiece/ListeType_piece";
import Form_Nivo_Valid from "./Template/admin/NivoValidation/Form_nivo_valid";
import ListeNivoValid from "./Template/admin/NivoValidation/ListeNivoValid";
import Info_nivo_valid from "./Template/admin/NivoValidation/info_nivo_valid";
import Edit_Nivo_Valid from "./Template/admin/NivoValidation/Edit_nivo_valid";
import Form_Role from "./Template/admin/Role/Form_role";
import Liste_Role from "./Template/admin/Role/Liste_Role";
import Info_role from "./Template/admin/Role/Info_role";
import Edit_role from "./Template/admin/Role/Edit_role";
import Form_userm from "./Template/admin/user_metier/Form_userm";
import Info_userm from "./Template/admin/user_metier/Info_userm";
import Liste_userm from "./Template/admin/user_metier/Liste_userm";
import Info_dmd from "./Template/admin/typeDemandes/info_dmd";
import Edit_typ_piece from "./Template/admin/typePiece/Edit_typ_piece";
import LoginPage from "./Template/admin/LoginPage";
import Info_Typ_dmd from "./Template/admin/typeDemandes/Info_Typ_dmd";
import Form_typ_piece_E from "./Template/admin/typePieceEtapeVali/Form_typ_piece_E";
import ListeTypePiece_E from "./Template/admin/typePieceEtapeVali/ListeType_piece_E";
import Info_piece_E from "./Template/admin/typePieceEtapeVali/info_piece_E";
import Edit_typ_piece_E from "./Template/admin/typePieceEtapeVali/Edit_typ_piece";
import ListesDocument from "./Template/admin/demande/dsps/Listes_document";
import ListeDemandeaTraiter from "./Template/admin/demande/dsps/Liste_demande_a_traiter";
import Form_dmd_dsps from "./Template/admin/demande/dsps/Form_dmd_dsps_physique";
import Form_Rapport from "./Template/admin/Rapport/rapport";
import Form_user_role from "./Template/admin/user_metier/Form_user_role";
import { MenuProvider } from "./Template/admin/MenuContext";
import ListeDemandeRetire from "./Template/admin/demande/dsps/Liste_demande_retirer";
import Form_user from "./Template/Form_user";
import TexteRegle from "./Template/TexteRegle";
import Info_dmd_rcue from "./Template/admin/demande/dsps/Info_dmd_rcue";
import Form_Region from "./Template/admin/Region/Form_Region";
import ListeRegion from "./Template/admin/Region/Liste_Region";
import Info_Region from "./Template/admin/Region/info_Region";
import Edit_Region from "./Template/admin/Region/Edit_Region";
import Form_Province from "./Template/admin/Province/Form_Province";
import ListeProvince from "./Template/admin/Province/Liste_Province";
import Info_Province from "./Template/admin/Province/info_Province";
import Edit_Province from "./Template/admin/Province/Edit_Province";
import Form_District from "./Template/admin/District/Form_Distric";
import ListeDistrict from "./Template/admin/District/Liste_Distric";
import Info_District from "./Template/admin/District/info_District";
import Edit_District from "./Template/admin/District/Edit_District";
import Form_Activation from "./Template/Form_Activation";
import Form_Commune from "./Template/admin/Commune/Form_Commune";
import ListeCommune from "./Template/admin/Commune/Liste_Commune";
import Info_Commune from "./Template/admin/Commune/info_Commune";
import Edit_Commune from "./Template/admin/Commune/Edit_Commune";
import Form_Gesp from "./Template/admin/GroupEsp/Form_Gesp";
import ListeGesp from "./Template/admin/GroupEsp/Liste_Gesp";
import Info_Gesp from "./Template/admin/GroupEsp/info_Gesp";
import Edit_Gesp from "./Template/admin/GroupEsp/Edit_Gesp";
import Edit_Esp from "./Template/admin/Esp/Edit_Esp";
import Info_Esp from "./Template/admin/Esp/info_Esp";
import Form_Esp from "./Template/admin/Esp/Form_Esp";
import ListeEsp from "./Template/admin/Esp/Liste_Esp";
import Edit_userm from "./Template/admin/user_metier/Edit_userm";
import { accountService } from "./Template/admin/AccountService";
import Test from "./Template/admin/demande/dsps/Test";
import Choix_dmd from "./Template/admin/demande/dsps/Choix_dmd";
import Form_dmd_dsps_morale from "./Template/admin/demande/dsps/Form_dmd_dsps_moral";
import Info_dmd_rcue_user from "./Template/admin/demande/dsps/Info_dmd_rcue_user";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ListeDemandeUser from "./Template/admin/demande/dsps/Liste_demande";
import ModifierDemande from "./Template/admin/demande/dsps/ModificationDemande";
import ComplementDemande from "./Template/admin/demande/dsps/ComplementDemande";
import Form_dmd_dsps_convension from "./Template/admin/demande/dsps/Form_dmd_dsps_moralconv";
import ResetPassword from "./Template/admin/ResetPassword";
import Profile from "./Template/admin/user_metier/Info_usermad";
import Choix_type_dmd from "./Template/admin/demande/Choix_type_dmd";
import Form_dmd_dsps_convention from "./Template/admin/demande/dcpp/Form_dmd_convention";
import Form_dmd_convention from "./Template/admin/demande/dcpp/Form_dmd_convention";
import Maintenance from "./components/Maintenance";
import ListeDemandeterminer from "./Template/admin/demande/dsps/Liste_demande_terminées";
// import DashboardPage from "./Template/admin/dashboord/page";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fonction pour vérifier si le token existe et est valide
  const checkToken = () => {
    const token = accountService.getToken(); // Récupérer le token
    if (token) {
      // Vérifier si le token n'est pas expiré
      try {
        // Décodez le token si nécessaire pour vérifier sa validité
        // const decodedToken = jwt_decode(token);
        return true;
      } catch (error) {
        accountService.logout(); // Déconnexion si le token est invalide
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const initializeAuth = () => {
      const tokenExists = checkToken();
      setIsLoggedIn(tokenExists);
      setIsLoading(false); // Fin du chargement
    };

    initializeAuth();
  }, []);

  // Protection des routes pendant le chargement
  if (isLoading) {
    return <div>Chargement...</div>; // Ou votre composant de chargement
  }

  return (
    <div>
      <MenuProvider>
        <Routes>
          {/* Routes publiques */}
          <Route exact path="/" element={<TemplateLayouts />} />
          <Route exact path="/login" element={
            isLoggedIn ? <Navigate to="/admin" replace /> : <LoginPage setIsLoggedIn={setIsLoggedIn} />
          } />
            {/* <Route exact path="/page" element={<DashboardPage />} /> */}
            <Route exact path="/textes" element={<TexteRegle />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/creation" element={<Creation />} />
          <Route exact path="/convention" element={<Convention />} />
          <Route exact path="/ouverture" element={<Ouverture />} />
          <Route exact path="/transfert" element={<Transfert />} />
          <Route exact path="/cession" element={<Cession />} />
          <Route exact path="/exploitation" element={<Exploitation />} />
          <Route exact path="/prorogation" element={<Prorogation />} />
          <Route exact path="/rectification" element={<Rectification />} />
          <Route exact path="/transformation" element={<Transformation />} />
          <Route exact path="/fermeture" element={<Fermeture />} />
          <Route exact path="/changement-responsable" element={<Responsable />} />
          <Route exact path="/changement-denomination" element={<Denomination />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/maintenance" element={<Maintenance />} />
          {/* liste des routes qui ont besoin d'authentification  */}

          {/* Routes protégées */}
          <Route exact path="/admin" element={
            isLoggedIn ? <Dashboard /> : <Navigate to="/login" state={{ from: '/admin' }} replace />
          } />
          
          {/* Autres routes protégées... */}
          <Route exact path="/liste_dmd_t" element={
            isLoggedIn ? <ListeDemandeT /> : <Navigate to="/login" state={{ from: '/liste_dmd_t' }} replace />
          } />

<Route exact path="/propos" element={<TemplatePropos />} />
          <Route exact path="/textes" element={<TexteRegle />} />
          <Route
            exact
            path="/liste_dmd_t"
            element={
              isLoggedIn ? <ListeDemandeT /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/param-type-demande/:id"
            element={
              isLoggedIn ? <Info_Typ_dmd /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/param-type-demandes/:id"
            element={
              isLoggedIn ? <Info_dmd /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/param-type-pieces/:id"
            element={
              isLoggedIn ? <Info_piece /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/edit_param-type-pieces/:id"
            element={
              isLoggedIn ? <Edit_typ_piece /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/param-type-pieces_E/:id"
            element={
              isLoggedIn ? <Info_piece_E /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/edit_param-type-pieces_E/:id"
            element={
              isLoggedIn ? (
                <Edit_typ_piece_E />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/edit_param-type-demandes/:id"
            element={
              isLoggedIn ? <Edit_typ_dmd /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/demande_type"
            element={
              isLoggedIn ? <Form_typ_dmd /> : <Navigate to="/login" replace />
            }
          />


          <Route
            exact
            path="/type_piece"
            element={
              isLoggedIn ? <Form_typ_piece /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/liste_type_piece"
            element={
              isLoggedIn ? <ListeTypePiece /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/type_piece_E"
            element={
              isLoggedIn ? (
                <Form_typ_piece_E />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/liste_type_piece_E"
            element={
              isLoggedIn ? (
                <ListeTypePiece_E />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/niveau_validation"
            element={
              isLoggedIn ? (
                <Form_Nivo_Valid />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/liste_niveau_validation"
            element={
              isLoggedIn ? <ListeNivoValid /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/param-niveau-validation/:id"
            element={
              isLoggedIn ? (
                <Info_nivo_valid />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/edit_param-nivo-valid/:id"
            element={
              isLoggedIn ? (
                <Edit_Nivo_Valid />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />

          {/* Routes pour les regions */}

          <Route
            exact
            path="/region"
            element={isLoggedIn ? (
              <Form_Region />
            ) : (
              <Navigate to="/login" replace />
            )

            }
          />
          <Route
            exact
            path="/liste_region"
            element={isLoggedIn ? (
              <ListeRegion />
            ) : (
              <Navigate to="/login" replace />
            )

            }
          />
          <Route
            exact
            path="/voir-region/:id"
            element={
              isLoggedIn ? (
                < Info_Region />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/edit-region/:id"
            element={
              isLoggedIn ? (
                < Edit_Region />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />

          {/* Routes pour les Provinces */}
          <Route
            exact
            path="/province"
            element={
              isLoggedIn ? (
                < Form_Province />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />
          <Route
            exact
            path="/liste_province"
            element={
              <ListeProvince />
            }
          />
          <Route
            exact
            path="/voir-province/:id"
            element={
              isLoggedIn ? (
                < Info_Province />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/edit-province/:id"
            element={
              isLoggedIn ? (
                < Edit_Province />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />

          {/* Routes pour les Communes */}

          <Route
            exact
            path="/commune"
            element={
              isLoggedIn ? (
                < Form_Commune />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/liste_commune"
            element={
              isLoggedIn ? (
                < ListeCommune />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />
          <Route
            exact
            path="/voir-commune/:id"
            element={
              isLoggedIn ? (
                < Info_Commune />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/edit-commune/:id"
            element={
              isLoggedIn ? (
                < Edit_Commune />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          {/* Routes pour les districts */}

          <Route
            exact
            path="/district"
            element={
              isLoggedIn ? (
                < Form_District />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/liste_district"
            element={
              isLoggedIn ? (
                < ListeDistrict />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />
          <Route
            exact
            path="/voir-district/:id"
            element={
              isLoggedIn ? (
                < Info_District />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/edit-district/:id"
            element={
              isLoggedIn ? (
                < Edit_District />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          {/* Routes pour les esp */}

          <Route
            exact
            path="/esp"
            element={
              isLoggedIn ? (
                < Form_Esp />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/liste_esp"
            element={
              isLoggedIn ? (
                < ListeEsp />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />
              <Route
            exact
            path="/documents-charger"
            element={
              isLoggedIn ? (
                < ListesDocument />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />
          <Route
            exact
            path="/voir-esp/:id"
            element={
              isLoggedIn ? (
                < Info_Esp />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/edit-esp/:id"
            element={
              isLoggedIn ? (
                < Edit_Esp />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          {/* Routes pour les groupes d'esp*/}

          <Route
            exact
            path="/gesp"
            element={
              isLoggedIn ? (
                < Form_Gesp />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/liste_gesp"
            element={
              isLoggedIn ? (
                < ListeGesp />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />
          <Route
            exact
            path="/voir-gesp/:id"
            element={
              isLoggedIn ? (
                < Info_Gesp />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/edit-gesp/:id"
            element={
              isLoggedIn ? (
                < Edit_Gesp />
              ) : (
                <Navigate to="/login" replace />
              )


            }
          />
          <Route
            exact
            path="/generer_rapport"
            element={
              isLoggedIn ? <Form_Rapport /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            //path="/param-type-demandes/:id/param-niveau-validations"
            path="/nivo"
            element={
              <Form_Nivo_Valid />
            }
          />
          <Route
            exact
            path="/ajoute_role"
            element={
              isLoggedIn ? (
                < Form_Role />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />
          <Route
            exact
            path="/liste_role"
            element={
              isLoggedIn ? (
                < Liste_Role />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />
          <Route
            exact
            path="/role/:id"
            element={
              isLoggedIn ? (
                < Info_role />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />
          <Route
            exact
            path="/edit_role/:id"
            element={
              isLoggedIn ? (
                < Edit_role />
              ) : (
                <Navigate to="/login" replace />
              )

            }
          />

          <Route
            exact
            path="/activation_compte"
            element={
              <Form_Activation />
            }
          />
          <Route
            exact
            path="/reset-password"
            element={
              <ResetPassword />
            }
          />
          <Route
            exact
            path="/user_metier"
            element={
              isLoggedIn ? <Form_userm /> : <Navigate to="/login" replace />
            }
          />

          <Route exact path="/user_inscription" element={<Form_user />} />
          <Route
            exact
            path="/login"
            element={<LoginPage setIsLoggedIn={setIsLoggedIn} />}
          />

          <Route
            exact
            path="/liste_demande_user"
            element={
              isLoggedIn ? (
                <ListeDemandeUser />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/liste_demande_a_traiter"
            element={
              isLoggedIn ? (
                <ListeDemandeaTraiter />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/user_metier_role"
            element={
              isLoggedIn ? <Form_user_role /> : <Navigate to="/login" replace />
            }
          />
    
          <Route
            exact
            path="/liste_demande_retire"
            element={
              isLoggedIn ? (
                <ListeDemandeRetire />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/liste_user_metier"
            element={
              isLoggedIn ? <Liste_userm /> : <Navigate to="/login" replace />
            }
          />

          <Route
            exact
            path="/user-maj/:userId"
            element={
              isLoggedIn ? <Edit_userm /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/user-metiers"
            element={
              isLoggedIn ? <Info_userm /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/profile_user"
            element={
              isLoggedIn ? <Profile /> : <Navigate to="/login" replace />
            }
          />
          {/* <Route
            exact
            path="/demandes/12efld/çsd/6-ld133-/:id-236dad/2345FTksmckd"
            element={
              isLoggedIn ? <Info_dmd_rcu /> : <Navigate to="/login" replace />
            }
          /> */}

          <Route
            exact
            path="/demandes-view/:demandeId"
            element={
              isLoggedIn ? <Info_dmd_rcue /> : <Navigate to="/login" replace />
            }
          />

          <Route
            exact
            path="/demandes-view_user/:demandeId"
            element={
              isLoggedIn ? <Info_dmd_rcue_user /> : <Navigate to="/login" replace />
            }
          />

<Route
            exact
            path="/demandes-terminer"
            element={
              isLoggedIn ? <ListeDemandeterminer /> : <Navigate to="/login" replace />
            }
          />

          {/* liste des routes du dossier Using */}


          {/* liste des routes qui n'ont pas besoin d'authentification */}


          <Route
            exact
            path="/faire_demande/12d38g8-/:id-a3894DI39"

            element={
              isLoggedIn ? (
                < Form_dmd_dsps />
              ) : (
                <Navigate to="/login" replace />
              )}
          />

          <Route
            exact
            path="/faire_demande_physique"

            element={isLoggedIn ? (
              < Form_dmd_dsps />
            ) : (
              <Navigate to="/login" replace />
            )}
          />

          <Route
            exact
            path="/faire_choix_type_demande"

            element={isLoggedIn ? (
              < Choix_type_dmd />
            ) : (
              <Navigate to="/login" replace />
            )}
          />

          <Route
            exact
            path="/faire_demande_morale"
            element={isLoggedIn ? (
              <Form_dmd_dsps_morale />
            ) : (
              <Navigate to="/login" replace />
            )}
          />

          <Route
            exact
            path="/faire_demande_morale_conv"
            element={isLoggedIn ? (
              <Form_dmd_dsps_convension />
            ) : (
              <Navigate to="/login" replace />
            )}
          />
          <Route
            exact
            path="/faire_choix"
            element={isLoggedIn ? (
              <Choix_dmd />
            ) : (
              <Navigate to="/login" replace />
            )}
          />

          <Route
            exact
            path="/faire_demande_convention"
            element={isLoggedIn ? (
              <Form_dmd_convention />
            ) : (
              <Navigate to="/login" replace />
            )}
          />

          <Route
            exact
            path="/faire_demande"
            element={<Test />}
          />

          <Route path="/demandes/:demandeId/modifier" element={isLoggedIn ? (
            <ModifierDemande />
          ) : (
            <Navigate to="/login" replace />
          )} />

          <Route path="/demandes/:demandeId/complement" element={isLoggedIn ? (
            <ComplementDemande />
          ) : (
            <Navigate to="/login" replace />
          )} />



          <Route exact path="*" element={<TemplateLayouts />} />





        </Routes>
      </MenuProvider>
      
      <ToastContainer
        position="top-right"
        autoClose={3000}  // Temps d'affichage des toasts (3 secondes)
        hideProgressBar={false}  // Gardez la barre de progression visible
        newestOnTop={false}  // Affiche les toasts les plus récents en bas
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
