import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Navbar from "../Nav";

function ListeDemandeT() {
  const editURL = `${process.env.REACT_APP_API_URL}/api/param-type-demandes`;
  const [utilisateurs, setTypeDemande] = useState([]);

  const setUtilisateursData = () => {
    axios
      .get(editURL)
      .then((response) => {
        setTypeDemande(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention des données :" + error);
      });
  };

  useEffect(() => {
    setUtilisateursData();
  }, []);

  const supprimer = (id) => {
    axios
      .delete(`${editURL}/${id}`) // Vous devriez concaténer l'ID à l'URL correctement
      .then((response) => {
        alert(
          "le type de demande identifié par ID : " + id + " à été supprimé !"
        );
        window.location.reload();
      })
      .catch((error) => {
        alert("Erreur lors de la suppression du type de demande : " + error);
      });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const typesDemandeFiltrés = utilisateurs.filter((utili) => {
    const searchString = searchQuery.toLowerCase();
    return (
      utili.code.toLowerCase().includes(searchString) ||
      utili.intitule.toLowerCase().includes(searchString) ||
      utili.sous_type.toLowerCase().includes(searchString) ||
      utili.paramTypePieces.some((piece) =>
        piece.intitule.toLowerCase().includes(searchString)
      )
    );
  });

  const élémentsParPage = 10;
  const [pageActuelle, setPageActuelle] = useState(1);

  const nombreTotalDePages = Math.ceil(
    typesDemandeFiltrés.length / élémentsParPage
  );

  const handlePageChange = (page) => {
    setPageActuelle(page);
  };

  const donnéesPaginées = typesDemandeFiltrés.slice(
    (pageActuelle - 1) * élémentsParPage,
    pageActuelle * élémentsParPage
  );

  const Pagination = ({ pageActuelle, nombreTotalDePages, onPageChange }) => {
    const pages = [];

    for (let i = 1; i <= nombreTotalDePages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={i === pageActuelle ? "actif" : ""}
        >
          {i}
        </button>
      );
    }

    return <div className="pagination">{pages}</div>;
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}

          <div className="custom-wrapper">
            <Navbar />
            <h4 className="title-text">Liste des Types Demandes</h4>
            <div className="row">
              <div className="col-md-6 form-group mb-6">
                <Link to="/demande_type">
                  <button className="custom-button">
                    {" "}
                    <i className="fas fa-fw fa-plus"> </i> Ajouter
                  </button>
                </Link>
              </div>

              <div className="col-md-6 form-group mb-6">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Recherche"
                />
              </div>
            </div>

            <div className="card-body ">
              <table className="table table-bordered table-striped  ">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Intitulé</th>
                    <th>Sous Type</th>
                    <th>Actions </th>
                    <th>Piècesfournir</th>
                    <th>N/V</th>
                  </tr>
                </thead>
                <tbody>
                  {utilisateurs.length === 0 ? (
                    <tr>
                      <td colSpan="9" className="text-center">
                        Aucun fichier n'a été enregistré.
                      </td>
                    </tr>
                  ) : (
                    utilisateurs &&
                    donnéesPaginées.map((utili) => (
                      <tr>
                        {
                          <>
                            <td className="text-noir">{utili.code}</td>
                            <td className="text-noir">
                              {utili.intitule}
                            </td>
                            <td className="text-noir">
                              {utili.sous_type}
                            </td>

                            <td className="text-noir">
                              <Link
                                to={"/param-type-demandes/" + utili.id}
                                title="voir informations"
                              >
                                <div className="round-button">
                                  <i className="bi bi-eye white-icon"></i>
                                </div>
                              </Link>

                              <Link
                                to={
                                  "/edit_param-type-demandes/" +
                                  utili.id
                                }
                                title="modifier informations"
                              >
                                <div className="round-button">
                                  <i className="bi bi-pencil white-icon"></i>
                                </div>
                              </Link>
                              <div className="round-buttonr">
                                <i
                                  className="bi bi-trash white-icon"
                                  onClick={() => supprimer(utili.id)}
                                ></i>
                              </div>
                            </td>
                            <td className="text-noir">
                              <td className="text-noir">
                                {utili.paramTypePieces.map(
                                  (piece, index) => (
                                    <div key={index}>
                                      --{piece.intitule}
                                    </div>
                                  )
                                )}
                              </td>
                            </td>
                            <td className="text-noir">
                              <Link
                                to={
                                  "/param-type-demandes/" +
                                  utili.id +
                                  "/param-niveau-validations"
                                }
                              >
                                <div className="round-buttonv">
                                  <i className="bi bi-plus white-icon"></i>
                                </div>
                              </Link>
                            </td>
                          </>
                        }
                      </tr>
                    ))
                  )}
                </tbody>
                <div className="pagination">
                  <Pagination
                    className="page-number"
                    pageActuelle={pageActuelle}
                    nombreTotalDePages={nombreTotalDePages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </table>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default ListeDemandeT;
