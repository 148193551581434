import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Navbar from "../Nav";

function ListeTypePiece() {
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const editURL = `${process.env.REACT_APP_API_URL}/api/param-type-pieces`;
  const [pieces, setTypePiece] = useState([]);

  const setpiecesData = () => {
    axios
      .get(editURL)
      .then((response) => {
        setTypePiece(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention des données :" + error);
      });
  };

  useEffect(() => {
    setpiecesData();
  }, []);

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            <Navbar />
                    <h4 className="title-text">Liste des Types de pièces</h4>
                    {/* <table style={customStyles.table}>
                        <thead>
                          <tr>
                            <th style={customStyles.headerCell}>Id</th>
                            <th style={customStyles.headerCell}>Nom</th>
                            <th style={customStyles.headerCell}>Description</th>
                            <th style={customStyles.headerCell}>Cout</th>
                            <th style={customStyles.headerCell}>Dossiers à Fournir</th>
                            <th style={customStyles.headerCell}>Modifier</th>
                            <th style={customStyles.headerCell}>Supprimer</th>
                            <th style={customStyles.headerCell}>Detail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pieces.map((utili) => (
                            <tr key={utili.id}>
                              <td style={customStyles.cell}>{utili.id}</td>
                              <td style={customStyles.cell}>{utili.intitule}</td>
                              <td style={customStyles.cell}>{utili.descript}</td>
                              <td style={customStyles.cell}>{utili.cout}F</td>
                              <td style={customStyles.cell}>
                                {utili.piecesFournies.map((piece, index) => (
                                  <div key={index}>{piece}</div>
                                ))}
                              </td>
                              <td style={customStyles.cell}>       <Link to={"/edit/" + utili.id}><i className="bi bi-pencil"></i>
                          </Link></td>
                          <td style={customStyles.cell}>       <button
                            onClick={() => removeEmployee(utili.id)} className="btn btn-sm btn-danger"
                          > <i className="bi bi-trash"></i>
                          </button></td>
                              <td style={customStyles.cell}><Link to={"/voir/" + utili.id}>
                              <i className="bi bi-eye"></i>
</Link></td>
                           
                            </tr>
                          ))}
                        </tbody>
                      </table> */}
                    {/* <Link to="/type_piece" className="btn btn-primary mb-3">
                      <i className="fas fa-fw fa-plus"></i>
                    </Link> */}
                    <Link to="/type_piece">
                      <button className="custom-button">
                        {" "}
                        <i className="fas fa-fw fa-plus"> </i> Ajouter
                      </button>
                    </Link>
                    <div className="card-body">
                      <table
                        id="example1"
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Intitulé</th>
                            <th>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pieces.length === 0 ? (
                            <tr>
                              <td colSpan="9" className="text-center">
                                Aucun fichier n'a été enregistré.
                              </td>
                            </tr>
                          ) : (
                            pieces &&
                            pieces.map((utili) => (
                              <tr key={utili.id}>
                                <td className="text-noir">{utili.code}</td>
                                <td className="text-noir">{utili.intitule}</td>
                                <td className="text-noir">
                                  {utili.description}
                                </td>
                                <td className="text-noir">
                                  <Link to={"/param-type-pieces/" + utili.id}>
                                    <div className="round-button">
                                      <i className="bi bi-eye white-icon"></i>
                                    </div>
                                  </Link>

                                  <Link
                                    to={"/edit_param-type-pieces/" + utili.id}
                                  >
                                    <div className="round-button">
                                      <i className="bi bi-pencil white-icon"></i>
                                    </div>
                                  </Link>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
        </div>
        <Footer />
      </body>
    </div>
  );
}

export default ListeTypePiece;
