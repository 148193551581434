import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, ResponsiveContainer, LineChart, Line } from 'recharts';
import axios from 'axios';
import { Link, } from "react-router-dom";
import DemandStatusChart from './DemandStatusChart';
import PromotersTable from './PromotersTable';
import PersonnelTable from './PersonnelTable';
const styles = {
  container: {
    padding: '24px',
    backgroundColor: '#f0f2f5',
    fontFamily: 'Arial, sans-serif',
    color: '#000000', // Changed to black
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#000000', // Changed to black
  },
  select: {
    padding: '8px 12px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #d1d5db',
    color: '#000000', // Changed to black
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '24px',
    marginBottom: '24px',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    padding: '20px',
  },
  /*cardTitle: {
    fontSize: '16px',
    color: '#000000', // Changed to black
    marginBottom: '8px',
  },*/

  cardTitle: {
    fontSize: '16px',  // Augmente la taille du texte pour plus de visibilité
    fontWeight: 'bold',  // Applique un texte en gras
    color: '#007BFF',  // Une couleur de texte vive (bleu dans cet exemple)
    marginBottom: '16px',  // Espacement sous le titre
    textAlign: 'center',  // Centrer le texte
    padding: '8px',  // Ajoute du padding autour du titre
    borderRadius: '5px',  // Arrondir les coins pour un effet plus moderne
    backgroundColor: '#f0f8ff',  // Fond léger pour faire ressortir le titre
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',  // Ombre légère autour du titre
  },
  cardContent: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#000000', // Changed to black
  },
  chartContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
    gap: '24px',
    marginBottom: '24px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    marginBottom: '16px',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',

  },
  filterField: {
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
    gap: '10px',
    width: '300px',
    height: '90px',
  },

  input: {
    padding: '8px 12px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #d1d5db',
    color: '#000000', // Changed to black
  },
  button: {
    padding: '8px 16px',
    fontSize: '16px',
    backgroundColor: '#001142e3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  icon: {
    marginRight: '8px',
    fontSize: '18px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    backgroundColor: '#001142e3', // Changed to blue
    padding: '12px',
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white', // Changed to white for better contrast
  },
  td: {
    padding: '12px',
    borderBottom: '1px solid #e5e7eb',
    color: '#000000', // Changed to black
  },
  statusBadge: {
    padding: '4px 8px',
    borderRadius: '9999px',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'white',
  },
  cardIcon: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '16px',
  },
  iconContainer: {
    padding: '12px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

const EnhancedAdminDashboard = () => {
  const [statistics, setStatistics] = useState({
    totalDemands: 0,
    acceptedDemands: 0,
    rejectedDemands: 0,
    byStatus: {},
    overTime: [],
    recentDemands: []
  });
  const [period, setPeriod] = useState('2024');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [statusFilter, setStatusFilter] = useState('EN_COURS_PREVALIDATION');
  const [genreFilter, setGenreFilter] = useState('FEMME');
  const [regionFilter, setRegionFilter] = useState('');
  const [espFilter, setEspFilter] = useState('');
  const [gespFilter, setGespFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [sort, setSort] = React.useState('dateSoumission,desc');

  useEffect(() => {
    fetchAllStatistics();
  }, [period]);

  const fetchAllStatistics = async () => {
    setLoading(true);
    setError(null);
    try {
      const [acceptedResponse, receivedResponse, rejectedResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/demandes/nombre-demandes-acceptees-par-annee?annee=${period}`),
        axios.get(`${process.env.REACT_APP_API_URL}/demandes/nombre-demandes-recues-par-anneex?annee=${period}`),
        axios.get(`${process.env.REACT_APP_API_URL}/demandes/demandes-rejetees?annee=${period}`)
      ]);

      setStatistics(prevStats => ({
        ...prevStats,
        acceptedDemands: acceptedResponse.data || 0,
        totalDemands: receivedResponse.data || 0,
        rejectedDemands: rejectedResponse.data || 0,
        byStatus: receivedResponse.data.byStatus || {},
        overTime: receivedResponse.data.overTime || []
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques:', error);
      setError('Erreur lors de la récupération des données. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    }
  };

  const fetchDemandesByStatusAndDate = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/demandes/find-demande-by-periode`, {
        params: {
          statut: statusFilter,
          startDate,
          endDate
        }
      });
      setStatistics(prevStats => ({
        ...prevStats,
        recentDemands: response.data || []
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes filtrées:', error);
      setError('Erreur lors de la récupération des demandes. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    }
  };


  const fetchDemandesByTypePromoteur = async () => {
    //setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/demandes/find-demande-by-periode`, {
        params: {
          statut: statusFilter,
          startDate,
          endDate
        }
      });
      setStatistics(prevStats => ({
        ...prevStats,
        recentDemands: response.data || []
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes filtrées:', error);
      setError('Erreur lors de la récupération des demandes. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    }
  };


  const fetchDemandesByRegionTypeESP = async () => {
    //setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/demandes/find-demande-by-periode`, {
        params: {
          statut: statusFilter,
          startDate,
          endDate
        }
      });
      setStatistics(prevStats => ({
        ...prevStats,
        recentDemands: response.data || []
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes filtrées:', error);
      setError('Erreur lors de la récupération des demandes. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    }
  };

  const fetchDemandesByFilter = async () => {
    console.log("statusFilter : ", statusFilter)
    console.log("startDate : ", startDate)
    console.log("endDate : ", endDate)
    console.log("regionFilter : ", regionFilter)
    console.log("espFilter : ", espFilter)
    console.log("gespFilter : ", gespFilter)
    console.log("genreFilter : ", genreFilter)


    //setLoading(true);
    setError(null);

    try {
      const requestBody = {
        statutDemande: statusFilter,
        dateSoumissionStart: startDate,
        dateSoumissionEnd: endDate,
        commune: {
          district: {
            province: {
              region: {
                id: regionFilter
              }
            }
          }
        },
        esp: {
          id: espFilter,
          groupeEsp: {
            id: gespFilter
          }
        },
        promoteur: {
          genre: genreFilter
        }
      };


      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/demandes/filter?page=${page}&size=${size}&sort=${sort}`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setStatistics((prevStats) => ({
        ...prevStats,
        recentDemands: response.data.content || [],
      }));

    } catch (error) {
      console.error('Erreur lors de la récupération des demandes filtrées:', error);
      setError('Erreur lors de la récupération des demandes. Veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    }
  };

  const espURL = `${process.env.REACT_APP_API_URL}/esp/list`;
  const [espList, setEspList] = useState([]);

  const getEsps = () => {
    axios
      .get(espURL)
      .then((response) => {
        setEspList(response.data);
      })
      .catch((error) => {
        console.log("Erreur d'obtention de la liste des Esp :" + error);
      });
  };

  const [gespList, setGespList] = useState([]);

  const gespURL = `${process.env.REACT_APP_API_URL}/gesp/list`;
  const getGroupeEsps = () => {
    axios
      .get(gespURL)
      .then((response) => {
        setGespList(response.data);
      })
      .catch((error) => {
        console.log("Erreur d'obtention de la liste des groupe d'esp :" + error);
      });
  };

  const [regionList, setRegionList] = useState([]);

  const regionURL = `${process.env.REACT_APP_API_URL}/regions/list`;
  const getRegions = () => {
    axios
      .get(regionURL)
      .then((response) => {
        setRegionList(response.data);
      })
      .catch((error) => {
        console.log("Erreur d'obtention de la liste des regions :" + error);
      });
  };

  useEffect(() => {

    getEsps();
    getGroupeEsps();
    getRegions();

  }, []
  );

  const statusColors = {
    EN_COURS_PREVALIDATION: '#3498db',
    COMPLETEE: '#3498db',
    PREVALIDEE: '#2ecc71',
    FAVORABLE: '#27ae60',
    EN_ATTENTE_COMPLEMENT: '#f39c12',
    EN_ATTENTE_COMISSION: '#95a5a6',
    ACCEPTEE: '#2980b9',
    REJETEE: '#e74c3c',
    ACCEPTEE_SOUS_RESERVE: '#f39c12',
    AJOURNEE: '#e74c3c',
    REJETEE_DSPS: '#f39c12',
    NON_INSCRITE_A_OP: '#95a5a6',
    REJETE_COM: '#e74c3c',
    INSCRITE_A_OP: '#2ecc71',
    EN_ATTENTE_COMPLEMENT_COM: '#95a5a6',
  };

  const statusLabels = {
    EN_COURS_PREVALIDATION: "En cours de prévalidation",
    PREVALIDEE: "Prévalidée",
    FAVORABLE: "Favorable",
    COMPLETEE:"Complétées",
    EN_ATTENTE_COMPLEMENT: "En attente de complément",
    EN_ATTENTE_COMISSION: "En attente de commission",
    ACCEPTEE: "Acceptée",
    REJETEE: "Rejetée",
    ACCEPTEE_SOUS_RESERVE: "Acceptée sous réserve",
    AJOURNEE: "Ajournée",
    REJETEE_DSPS: "Rejetée DSP",
    NON_INSCRITE_A_OP: "Non inscrite à OP",
    REJETE_COM: "Rejetée COM",
    INSCRITE_A_OP: "Inscrite à OP",
    EN_ATTENTE_COMPLEMENT_COM: "En attente de complément COM",
  };

  const genreLabels = {
    HOMME: "HOMME",
    FEMME: "FEMME",
  };



  if (loading) {
    return <div style={styles.container}>Chargement des données...</div>;
  }

  if (error) {
    return <div style={styles.container}>{error}</div>;
  }


  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.title}>Tableau de bord des statistiques</h2>
        <select
          style={styles.select}
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        >
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </select>
      </div>

      <div style={styles.cardContainer}>
        <div style={styles.card}>
          <div style={styles.cardHeader}>
            <div style={{
              ...styles.iconContainer,
              backgroundColor: 'rgba(0, 117, 255, 0.1)',
            }}>
              <i className="bi bi-file-earmark-text" style={{
                fontSize: '24px',
                color: '#0075ff'
              }}></i>
            </div>
            <div>
              <h3 style={styles.cardTitle}>Total des demandes reçues par année</h3>
              <p style={styles.cardContent}>{statistics.totalDemands}</p>
            </div>
          </div>
        </div>

        <div style={styles.card}>
          <div style={styles.cardHeader}>
            <div style={{
              ...styles.iconContainer,
              backgroundColor: 'rgba(46, 204, 113, 0.1)',
            }}>
              <i className="bi bi-check-circle" style={{
                fontSize: '24px',
                color: '#2ecc71'
              }}></i>
            </div>
            <div>
              <h3 style={styles.cardTitle}>Total des demandes acceptées par année</h3>
              <p style={styles.cardContent}>{statistics.acceptedDemands}</p>
            </div>
          </div>
        </div>

        <div style={styles.card}>
          <div style={styles.cardHeader}>
            <div style={{
              ...styles.iconContainer,
              backgroundColor: 'rgba(231, 76, 60, 0.1)',
            }}>
              <i className="bi bi-x-circle" style={{
                fontSize: '24px',
                color: '#e74c3c'
              }}></i>
            </div>
            <div>
              <h3 style={styles.cardTitle}>Total des demandes rejetées par année</h3>
              <p style={styles.cardContent}>{statistics.rejectedDemands}</p>
            </div>
          </div>
        </div>
        {/* <div style={styles.card}>
          <div style={styles.cardHeader}>
            <div style={{
              ...styles.iconContainer,
              backgroundColor: 'rgba(231, 76, 60, 0.1)',
            }}>
              <i className="fas fa-users" style={{
                fontSize: '24px',
                color: '#e74c3c'
              }}></i>
            </div>
            <div>
              <h3 style={styles.cardTitle}>Total des utilisateurs sur la plateforme</h3>
              <p style={styles.cardContent}>{statistics.rejectedDemands}</p>
            </div>
          </div>
        </div> */}
    
      </div>

      <div style={styles.chartContainer}>
        <div style={styles.card}>
          {/* <h3 style={styles.cardTitle}>Répartition des demandes par statut</h3> */}
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Répartition des demandes acceptées et rejetées</h3>
            <DemandStatusChart
              acceptedDemands={statistics.acceptedDemands}
              rejectedDemands={statistics.rejectedDemands}
            />
          </div>
        </div>

        {/* <div style={styles.card}>
          <h3 style={styles.cardTitle}>Évolution des demandes</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={statistics.overTime}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="count" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div> */}
      </div>


      <div style={styles.chartContainer}>
        <div style={styles.card}>
          {/* <h3 style={styles.cardTitle}>Répartition des demandes par statut</h3> */}
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Liste des promoteurs inscrits</h3>
            <PromotersTable/>
            
          </div>
        </div>

      </div>

      
      <div style={styles.chartContainer}>
        <div style={styles.card}>
          {/* <h3 style={styles.cardTitle}>Répartition des demandes par statut</h3> */}
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Liste des personnels inscrits</h3>
            <PersonnelTable/>
            
          </div>
        </div>

      </div>

      {/*<div style={styles.card}>
        <h3 style={styles.cardTitle}>Liste des Demandes par statut et période</h3>
        <div style={styles.filterContainer}>
          <select
            style={styles.select}
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            {Object.keys(statusColors).map((status) => (
              <option key={status} value={status}>{statusLabels[status]} </option>
            ))}
          </select>
          <input
            type="date"
            style={styles.input}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <input
            type="date"
            style={styles.input}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <button style={styles.button} onClick={fetchDemandesByStatusAndDate}>Filtrer</button>
        </div>
        <div style={{ overflowX: 'auto' }}>
          <table style={styles.table}>
            <thead>
              <tr>

                <th style={styles.th}>Date</th>
                <th style={styles.th}>Numéro</th>
                <th style={styles.th}>Type</th>
                <th style={styles.th}>Statut</th>
                <th style={styles.th}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {statistics.recentDemands.map(demand => (
                <tr key={demand.id}>
                  <td style={styles.td}>{demand.dateSoumission}</td>
                  <td style={styles.td}>{demand.numDemande}</td>
                  <td style={styles.td}>{demand.typeDemande || 'N/A'}</td>
                  <td style={styles.td}>
                    <span style={{
                      ...styles.statusBadge,
                      backgroundColor: statusColors[demand.statutDemande] || '#999'
                    }}>
                      {demand.statutDemande || 'N/A'}
                    </span>
                  </td>
                   <td style={styles.td}>       <Link
                  to={"/demandes-view/" + demand.id}
                  title="voir informations"
                  className="me-2"
                >
                    <div className="round-button d-flex justify-content-center align-items-center">
                    <i className="bi bi-eye white-icon"></i>
                  </div></Link></td> 
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>*/}


      {/*<div style={styles.card}>
        <h3 style={styles.cardTitle}>Liste des Demandes par type de promoteur physique</h3>
        <div style={styles.filterContainer}>
          <select
            style={styles.select}
            value={genreFilter}
            onChange={(e) => setGenreFilter(e.target.value)}
          >
            {Object.keys(genreLabels).map((status) => (
              <option key={status} value={status}>{genreLabels[status]} </option>
            ))}
          </select>

          <button style={styles.button} onClick={fetchDemandesByTypePromoteur}>Filtrer</button>
        </div>
        <div style={{ overflowX: 'auto' }}>
          <table style={styles.table}>
            <thead>
              <tr>

                <th style={styles.th}>Date</th>
                <th style={styles.th}>Numéro</th>
                <th style={styles.th}>Promoteur</th>
                <th style={styles.th}>Genre</th>
                <th style={styles.th}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {statistics.recentDemands.map(demand => (
                <tr key={demand.id}>
                  <td style={styles.td}>{demand.dateSoumission}</td>
                  <td style={styles.td}>{demand.numDemande}</td>
                  <td style={styles.td}>{demand.promoteur.nom} {demand.promoteur.prenom}</td>
                  <td style={styles.td}>{demand.promoteur.genre}</td>

                   <td style={styles.td}>       <Link
                  to={"/demandes-view/" + demand.id}
                  title="voir informations"
                  className="me-2"
                >
                    <div className="round-button d-flex justify-content-center align-items-center">
                    <i className="bi bi-eye white-icon"></i>
                  </div></Link></td> 
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>*/}

      <div style={styles.card}>
        <h3 style={styles.cardTitle}>Filtrage des Demandes</h3>
        <div style={styles.filterContainer}>
          <div style={styles.filterField}>
            <label htmlFor="statusFilter" style={styles.label}>Statut :</label>
            <select
              id="statusFilter"
              style={styles.select}
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              {Object.keys(statusColors).map((status) => (
                <option key={status} value={status}>{statusLabels[status]}</option>
              ))}
            </select>
          </div>

          <div style={styles.filterField}>
            <label htmlFor="startDate" style={styles.label}>Date de début :</label>
            <input
              id="startDate"
              type="date"
              style={styles.input}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          <div style={styles.filterField}>
            <label htmlFor="endDate" style={styles.label}>Date de fin :</label>
            <input
              id="endDate"
              type="date"
              style={styles.input}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

        </div>
        <div style={styles.filterContainer}>

          <div style={styles.filterField}>
            <label htmlFor="regionFilter" style={styles.label}>Région :</label>
            <select
              id="regionFilter"
              style={styles.select}
              value={regionFilter}
              onChange={(e) => setRegionFilter(e.target.value)}
            >
              <option value="">-- Sélectionnez une région --</option>
              {regionList.map((region) => (
                <option key={region.id} value={region.id}>{region.libelleRegion}</option>
              ))}
            </select>
          </div>

          <div style={styles.filterField}>
            <label htmlFor="gespFilter" style={styles.label}>Groupe ESP :</label>
            <select
              id="gespFilter"
              style={styles.select}
              value={gespFilter}
              onChange={(e) => setGespFilter(e.target.value)}
            >
              <option value="">-- Sélectionnez un groupe ESP --</option>
              {gespList.map((gesp) => (
                <option key={gesp.id} value={gesp.id}>{gesp.libelleGesp}</option>
              ))}
            </select>
          </div>

          <div style={styles.filterField}>
            <label htmlFor="espFilter" style={styles.label}>ESP :</label>
            <select
              id="espFilter"
              style={styles.select}
              value={espFilter}
              onChange={(e) => setEspFilter(e.target.value)}
            >
              <option value="">-- Sélectionnez un ESP --</option>
              {espList.map((esp) => (
                <option key={esp.id} value={esp.id}>{esp.libelleEsp}</option>
              ))}
            </select>
          </div>

          <div style={styles.filterField}>
            <label htmlFor="genreFilter" style={styles.label}>Promoteur physique :</label>
            <select
              id="genreFilter"
              style={styles.select}
              value={genreFilter}
              onChange={(e) => setGenreFilter(e.target.value)}
            >
              {Object.keys(genreLabels).map((status) => (
                <option key={status} value={status}>{genreLabels[status]}</option>
              ))}
            </select>
          </div>


        </div>
        <div style={styles.filterContainer}>
          <button style={styles.button} onClick={fetchDemandesByFilter}>
            <i className="fas fa-search" style={styles.icon}></i> Filtrer
          </button>
        </div>
        <h3 style={styles.cardTitle}>Résultats trouvés : {statistics.recentDemands.length}</h3>
        <div style={{ overflowX: 'auto' }}>
          <table style={styles.table}>
            <thead>
              <tr>

                <th style={styles.th}>Date</th>
                <th style={styles.th}>Numéro</th>
                <th style={styles.th}>Région</th>
                <th style={styles.th}>Groupe ESP</th>
                <th style={styles.th}>ESP</th>
                <th style={styles.th}>Promoteur</th>
                <th style={styles.th}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {statistics.recentDemands.map(demand => (
                <tr key={demand.id}>
                  <td style={styles.td}>{demand.dateSoumission}</td>
                  <td style={styles.td}>{demand.numDemande}</td>
                  <td style={styles.td}>{demand.commune.district.province.region.libelleRegion}</td>
                  <td style={styles.td}>{demand.esp.groupeEsp.libelleGesp}</td>
                  <td style={styles.td}>{demand.esp.libelleEsp}</td>
                  <td style={styles.td}>{demand.promoteur.nom} {demand.promoteur.prenom}</td>

                  {/* <td style={styles.td}>       <Link
                  to={"/demandes-view/" + demand.id}
                  title="voir informations"
                  className="me-2"
                >
                    <div className="round-button d-flex justify-content-center align-items-center">
                    <i className="bi bi-eye white-icon"></i>
                  </div></Link></td> */}
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>


    </div>
  );
};

export default EnhancedAdminDashboard;