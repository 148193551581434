import React, { useState } from "react";

import Foot from "./Foot";
import Head from "./Head";

const Faq = () => {
  const [activeAccordion, setActiveAccordion] = useState("");
  const [footerOpen, setFooterOpen] = useState(false);

  const handleAccordion = (accordionName) => {
    setActiveAccordion(activeAccordion === accordionName ? "" : accordionName);
    setFooterOpen(activeAccordion === accordionName ? false : true);
  };

  const faqData = [
    {
      question: "Quels sont les différents types de demandes ?",
      answer: `Les différents types de demandes sont :
          • CREATION (ESPS, LABO)
          • OUVERTURE ET EXPLOITATION / OUVERTURE (ESPS, LABO, PHARMACIE)
          • TRANSFERT
          • TRANSFERT DE PROPRIETE (CESSION)
          • POURSUITE D’EXPLOITATION
          • TRANSFORMATION DE NATURE
          • PROROGATION DE DELAI
          • RECTIFICATION / CORRECTION
          • FERMETURE
          • CHANGEMENT DE RESPONSABLE TECHNIQUE
          • CHANGEMENT DE DENOMINATION

          `
    },
    {
      question: "Quels sont les différents types de demandes possibles actuellement en ligne ?",
      answer: `Les différents types de demandes possibles en ligne actuellement sont :
          • OUVERTURE ET EXPLOITATION / OUVERTURE (ESPS, LABO, PHARMACIE)
          
          `
    },
    {
      question: "Quels sont les délais de traitement ?",
      answer: `...
      
      `
    },
    {
      question: "Quelles sont les conditions pour effectuer les demandes ?",
      answer: `...

          `

    },
    {
      question: "Comment faire les demandes en ligne ?",
      answer: `...
    
      `
    }
    // Ajoutez plus de questions et réponses ici
  ];

  const formatAnswer = (answer) => {
    return answer.replace(/\n/g, '<br>');
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Head />

      <div className="container mx-auto py-8">
        <h3 className="text-2xl font-bold text-center text-gray-800 mb-8">Foire aux questions</h3>
        <br></br> <br /><br />
        <div className="space-y-4">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className={`border rounded-lg overflow-hidden transition-colors duration-300 ${activeAccordion === faq.question ? 'bg-blue-100' : 'bg-white'
                }`}
            >
              <div
                className="p-4 cursor-pointer flex items-center"
                onClick={() => handleAccordion(faq.question)}
              >

                <h4 className="text-lg font-semibold text-gray-700"><span className="mr-2 text-xl">
                  {activeAccordion === faq.question ? '▲' : '▼'}
                </span> {faq.question}</h4>
              </div>
              {activeAccordion === faq.question && (
                <div className="px-4 pb-4">
                  <p
                    className="text-sm text-gray-600"
                    dangerouslySetInnerHTML={{ __html: formatAnswer(faq.answer) }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <br /><br />
      <Foot />
    </div>
  );
}

export default Faq;