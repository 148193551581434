import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import Navbar from "../../Nav";

const ModifierDemande = () => {
    const { demandeId } = useParams();
    const [demandeDTO, setDemandeDTO] = useState(null);
    const [fichierStructure, setFichierStructure] = useState(null);
    const [personnelSimpleFichiers, setPersonnelSimpleFichiers] = useState([]);
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const complementURL = `${process.env.REACT_APP_API_URL}/demandes/personne - physique / complement`;

    // Récupérer les données de la demande
    useEffect(() => {
        const fetchDemande = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/demandes/${demandeId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setDemandeDTO(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération de la demande :", error);
            }
        };
        fetchDemande();
    }, [demandeId, token]);

    // Gérer les changements des champs du formulaire
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDemandeDTO((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Gérer les changements de fichiers pour l'ESP
    const handleFileChangeStructure = (event) => {
        const { files } = event.target;
        if (files.length > 0) {
            setFichierStructure(files[0]);
        }
    };

    // Gérer les changements des informations du personnel
    const handlePersonnelChange = (index, field, value) => {
        setDemandeDTO((prev) => {
            const updatedProprietairePs = [...prev.proprietairePs];
            updatedProprietairePs[index] = { ...updatedProprietairePs[index], [field]: value };
            return { ...prev, proprietairePs: updatedProprietairePs };
        });
    };

    // Gérer le téléchargement des fichiers pour chaque personnel
    const handleFileChangePersonnel = (index, event) => {
        const { files } = event.target;
        if (files.length > 0) {
            setPersonnelSimpleFichiers((prevFiles) => {
                const updatedFiles = [...prevFiles];
                updatedFiles[index] = files[0]; // Assigner le fichier au bon personnel
                return updatedFiles;
            });
        }
    };

    // Soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('demandeDTO', new Blob([JSON.stringify(demandeDTO)], { type: 'application/json' }));

        if (fichierStructure) {
            formData.append('fichierStructure', fichierStructure);
        }

        personnelSimpleFichiers.forEach((file, index) => {
            if (file) {
                formData.append(`personnelSimpleFichiers_${index}`, file); // Assigner le fichier au personnel
            }
        });

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/demandes/personne-physique/complement`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire("Demande mise à jour avec succès !");
            // navigate("/admin");
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la demande :", error);
            Swal.fire("Erreur lors de la mise à jour.");
        }
    };

    if (!demandeDTO) {
        return <p>Chargement...</p>;
    }

    return (
        <div id="page-top">
            <div id="wrapper">

                {/* <Header /> Sidebar */}
                <div id="content-wrapper">
                    <Navbar />
                    <div id="content">
                        <div className="col-md-12">
                            <div className="form h-100">
                                <h3 className="title-text">Modifier la demande {demandeDTO.numDemande}</h3>
                                <Form onSubmit={handleSubmit}>
                                    {/* Nom de l'ESP */}
                                    <Form.Group className="mb-3">
                                        <Form.Label className="col-form-label" style={{ color: "black" }}>Nom ESP</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="nomEsp"
                                            value={demandeDTO.nomEsp}
                                            className="form-control"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    {/* Numéro de l'arrêté de création */}
                                    <Form.Group className="mb-3">
                                        <Form.Label className="col-form-label" style={{ color: "black" }}>Numéro de l'arrêté de création</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="numArreteCreation"
                                            className="form-control"
                                            value={demandeDTO.numArreteCreation}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    {/* Fichier structure ESP */}
                                    <Form.Group className="mb-3">
                                        <Form.Label className="col-form-label" style={{ color: "black" }}>Dossier complet de l'ESP</Form.Label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            accept=".pdf"
                                            name="fichierStructure"

                                            onChange={handleFileChangeStructure}
                                        />
                                    </Form.Group>

                                    {/* Informations du personnel */}
                                    <h4>Propriétaires</h4>
                                    {demandeDTO.proprietairePs.map((personnel, index) => (
                                        <div key={index} className="row">
                                            <div className="col-md-6 form-group mb-3">
                                                <Form.Label className="col-form-label" style={{ color: "black" }}>Nom</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={personnel.nom}
                                                    className="form-control"
                                                    onChange={(e) => handlePersonnelChange(index, "nom", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <Form.Label className="col-form-label" style={{ color: "black" }}>Prénom</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={personnel.prenom}
                                                    className="form-control"
                                                    onChange={(e) => handlePersonnelChange(index, "prenom", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <Form.Label className="col-form-label" style={{ color: "black" }}>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    value={personnel.email}
                                                    onChange={(e) => handlePersonnelChange(index, "email", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <Form.Label className="col-form-label" style={{ color: "black" }}>Date de naissance</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    value={personnel.dateNaissance}
                                                    onChange={(e) => handlePersonnelChange(index, "dateNaissance", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <Form.Label className="col-form-label" style={{ color: "black" }}>Lieu de naissance</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    value={personnel.lieuNaissance}
                                                    onChange={(e) => handlePersonnelChange(index, "lienNaissance", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <Form.Label className="col-form-label" style={{ color: "black" }}>Numéro de téléphone</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={personnel.tel}
                                                    className="form-control"
                                                    onChange={(e) => handlePersonnelChange(index, "tel", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <Form.Label className="col-form-label" style={{ color: "black" }}>Profession</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={personnel.profession}
                                                    className="form-control"
                                                    onChange={(e) => handlePersonnelChange(index, "profession", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <Form.Label className="col-form-label" style={{ color: "black" }}>Numéro d'Ordre Professionel</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={personnel.numOp}
                                                    className="form-control"
                                                    onChange={(e) => handlePersonnelChange(index, "numOp", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <Form.Label className="col-form-label" style={{ color: "black" }}>Dossier du personnel</Form.Label>
                                                <input
                                                    type="file"
                                                    className="form-control"

                                                    accept=".pdf"
                                                    onChange={(e) => handleFileChangePersonnel(index, e)}
                                                />
                                            </div>
                                        </div>
                                    ))}

                                    <Button type="submit">Enregistrer les modifications</Button>
                                </Form>
                            </div>
                        </div>
                        <Footer />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ModifierDemande;
