import React, { useState } from "react";

import Card from "./Card";

function Propos() {
  const [activeAccordion, setActiveAccordion] = useState("");
  const [footerOpen, setFooterOpen] = useState(false);

  const handleAccordion = (accordionName) => {
    setActiveAccordion(activeAccordion === accordionName ? "" : accordionName);

    setFooterOpen(activeAccordion === accordionName ? false : true);
  };

  return (
    <div className={footerOpen ? "footer-open" : ""}>
      {/* <section id="hero" className="d-flex align-items-center"> */}
      <section id="apropos" className="d-flex align-items-center accordion">
        <div className="container">
          {/* <div className="section-title">
            <h2>Sections</h2>
            <p>Cliquez sur une section pour en savoir plus</p>
          </div> */}
          <br /> <br /> <br /> <br /> <br /> <br />   <br />
          <div className="accordion-item">
            <h2
              className="accordion-header"
              onClick={() => handleAccordion("reglementaire")}
            >
              Textes reglémentaires
              <i
                className={`bi ${
                  activeAccordion === "reglementaire"
                    ? "bi-chevron-up"
                    : "bi-chevron-down"
                }`}
              ></i>
            </h2>
            <div
              className={`accordion-content ${
                activeAccordion === "reglementaire" ? "active" : ""
              }`}
            >
              <p>
              <br />
              <Card
        title="ARRETES du Ministère de la Santé et de l'hygiène publique "
        soustitle="ARRETE_N°2023_MS_CAB_"
        pdfSrc="./ARRETE.pdf"
    />

                
              </p>
            </div>
          </div>
          <br />
          <div className="accordion-item">
            <h2
              className="accordion-header"
              onClick={() => handleAccordion("confidentialite")}
            >
              Politiques de confidentialité
              <i
                className={`bi ${
                  activeAccordion === "confidentialite"
                    ? "bi-chevron-up"
                    : "bi-chevron-down"
                }`}
              ></i>
            </h2>
            <div
              className={`accordion-content ${
                activeAccordion === "confidentialite" ? "active" : ""
              }`}
            >
              <p>
                {" "}
                Le Lorem et la standard de l'imprimerie depuis les années 1500,
                quand un imprimeur anonyme assembla ensemble des morceaux de
              </p>
            </div>
          </div>
          <br />
          <div className="accordion-item">
            <h2
              className="accordion-header"
              onClick={() => handleAccordion("faq")}
            >
              FAQ
              <i
                className={`bi ${
                  activeAccordion === "faq"
                    ? "bi-chevron-up"
                    : "bi-chevron-down"
                }`}
              ></i>
            </h2>
            <div
              className={`accordion-content ${
                activeAccordion === "faq" ? "active" : ""
              }`}
            >
              <p>
                {" "}
                Le Lorem Ipsum est simplement du faux texte employé dans la
                composition et la mise en page avant impression. Le Lorem Ipsum
                .
              </p>
              <p>
                {" "}
                Le Lorem Ipsum est simplement du faux texte employé dans la
                composition et la mise en page avant impression. Le Lorem Ipsum
                est le faux texte standard de l'imprimerie depuis les années
                1500, quand un imprimeur anonyme assembla ensemble des morceaux
              </p>
              {/* Contenu de la FAQ */}
              {/* ... */}
            </div>
          </div>
          <br />
          <div className="accordion-item">
            <h2
              className="accordion-header"
              onClick={() => handleAccordion("contacts")}
            >
              Contacts
              <i
                className={`bi ${
                  activeAccordion === "contacts"
                    ? "bi-chevron-up"
                    : "bi-chevron-down"
                }`}
              ></i>
            </h2>
            <div
              className={`accordion-content ${
                activeAccordion === "contacts" ? "active" : ""
              }`}
            >
              {/* <SectionContact/> */}
              <p>
                {" "}
                Le Lorem Ipsum est simplement du faux texte employé dans la
                composition et la mise en page avant impression. Le Lorem Ipsum
                est le faux texte standard de l'imprimerie depuis les années
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* </section> */}

      <section id="footer" className="footer">
        {" "}
        {/* Appliquer la marge négative pour l'écart du footer */}
        <div className="container d-md-flex py-1">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &copy;
              Copyright 2023 MUAFH |{" "}
              <strong>
                <span> Politique de Confidentialité</span>|{" "}
              </strong>
              Condition d'utilisation
            </div>
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <i className="bi bi-phone"></i> +226 66883575 &nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <i className="bi bi-mail"></i> mshp@gmail.com
            </div>
            <a href="#" className="twitter">
              <i className="bx bxl-twitter"></i>
            </a>
            <a href="#" className="facebook">
              <i className="bx bxl-facebook"></i>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Propos;
