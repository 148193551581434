import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import maintenanceIcon from '../assets/maintenance.svg';

function Maintenance() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Retourne à la page précédente
  };

  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#f8f9fa'
    }}>
      <Container className="text-center">
        <div style={{
          padding: '40px',
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: '0 0 20px rgba(0,0,0,0.1)'
        }}>
          <img 
            src={maintenanceIcon} 
            alt="Maintenance en cours"
            style={{
              width: '200px',
              marginBottom: '30px'
            }}
          />
          
          <h1 style={{
            color: '#0056b3',
            marginBottom: '20px',
            fontSize: '2.5rem'
          }}>
            Maintenance en cours
          </h1>
          
          <div style={{
            fontSize: '1.2rem',
            color: '#666',
            maxWidth: '600px',
            margin: '0 auto',
            lineHeight: '1.6'
          }}>
            <p>
              Notre plateforme est temporairement indisponible pour cause de maintenance.
            </p>
            <p>
              Nous mettons tout en œuvre pour améliorer votre expérience utilisateur.
              Nous serons de retour très prochainement.
            </p>
            <p style={{ marginTop: '20px', color: '#0056b3' }}>
              Merci de votre compréhension.
            </p>
          </div>

          <div style={{
            marginTop: '30px',
            padding: '20px',
            backgroundColor: '#f8f9fa',
            borderRadius: '5px',
            display: 'inline-block'
          }}>
            <p style={{ margin: 0, color: '#666' }}>
              <strong>Contact support :</strong><br />
              Email: support@example.com<br />
              Tél: +226 XX XX XX XX
            </p>
          </div>

          {/* Bouton Retour */}
          <div style={{ marginTop: '30px' }}>
            <Button 
              variant="outline-primary"
              onClick={handleGoBack}
              style={{
                padding: '10px 25px',
                fontSize: '1.1rem',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <i className="bi bi-arrow-left"></i>
              Retour
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Maintenance; 