import React from 'react';
import { Button } from 'react-bootstrap';

const CustomButton = ({ variant, onClick, children, className, type }) => {
    const buttonStyle = {
        fontSize: '0.75rem', // Taille de police réduite
        padding: '0.25rem 0.5rem', // Padding réduit
        textTransform: 'lowercase',
        fontWeight: 'normal',
        lineHeight: '0', // Hauteur de ligne réduite
        borderRadius: '0.2rem', 
      };

  return (
    <Button
      variant={variant}
      onClick={onClick}
      type={type}
      style={buttonStyle}
      className={className}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
