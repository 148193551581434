import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import { AiOutlineWarning } from 'react-icons/ai'; // Exemple d'icône

import Head from "../Head";
import Foot from "../Foot";

function ResetPassword() {

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();

  const baseURL = `${process.env.REACT_APP_API_URL}/users/reset_pwd`;
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true); // Activer l'indicateur de chargement

    if (validateForm()) {
      
      const params = new URLSearchParams({
        token: code,
        newPwd: password,
      });

      axios
        .post(`${baseURL}?${params.toString()}`)
        .then((response) => {
          toast.success("Votre mot de passe a été réinitialisé !!");
          setIsLoading(false);
          navigate("/login");
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error.response.status : ", error.response.status)
        });
    } else {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineWarning style={{ marginRight: '8px', fontSize: '1.5rem' }} />
          <span>Veuillez remplir tous les champs obligatoires.</span>
        </div>, 
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          className: "custom-toast", // Utilisez une classe CSS personnalisée
          style: { borderRadius: '8px' }
        }
      );
      setIsLoading(false);
    }
};




  const validateForm = () => {
    let valid = true;

    // Code
    if (!code.trim() || !/^\d{6,}$/.test(code)) {
      setCodeIsValid(false);
      valid = false;
    } else {
      setCodeIsValid(true);
    }

    // Mot de passe
    if (password.length < 6) {
      setPasswordIsValid(false);
      valid = false;
    } else {
      setPasswordIsValid(true);
    }

    // Confirmation du mot de passe
    if (password !== confirmPassword) {
      setConfirmPasswordIsValid(false);
      valid = false;
    } else {
      setConfirmPasswordIsValid(true);
    }

    return valid;
  };

  const [codeIsValid, setCodeIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(true);

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          <div className="container-fluid">
            <Head />
            <section id="hero" className="h-100 custom-section">
              <div className="content">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <br />
                    <div>
                      <div className="card fat shadow-sm">
                        <div className="card-body p-5">
                          <h2 className="title-text text-center mb-4" style={{ color: '#0056b3' }}>Réinitialisation du mot de passe</h2>
                          <Form onSubmit={handleSubmit} className="mb-5" noValidate>
                            <div className="row"> 
                              <div className="col-md-12 form-group mb-4">
                                <label className="col-form-label"><i className="fas fa-envelope fa-fw"></i> Code recu par mail</label>
                                <Form.Control
                                  type="text"
                                  placeholder="Code"
                                  value={code}
                                  maxLength={12}
                                  onChange={(e) => setCode(e.target.value)}
                                  className={`form-control ${codeIsValid ? "" : "is-invalid"}`}
                                  required
                                />
                                {!codeIsValid && <div className="invalid-feedback">Code invalide.</div>}
                              </div>

                              <div className="col-md-12 form-group mb-4">
                                <label className="col-form-label"><i className="fas fa-lock fa-fw"></i> Nouveau mot de passe *</label>
                                <Form.Control
                                  type="password"
                                  placeholder="Nouveau mot de passe"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  className={`form-control ${passwordIsValid ? "" : "is-invalid"}`}
                                  required
                                />
                                {!passwordIsValid && <div className="invalid-feedback">Le mot de passe doit contenir au moins 6 caractères.</div>}
                              </div>

                              <div className="col-md-12 form-group mb-4">
                                <label className="col-form-label"><i className="fas fa-lock fa-fw"></i> Confirmer le nouveau mot de passe *</label>
                                <Form.Control
                                  type="password"
                                  placeholder="Confirmer le nouveau mot de passe"
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                                  className={`form-control ${confirmPasswordIsValid ? "" : "is-invalid"}`}
                                  required
                                />
                                {!confirmPasswordIsValid && <div className="invalid-feedback">Les mots de passe ne correspondent pas.</div>}
                              </div>
                            </div>

                            <Button
                              type="submit"
                              className="btn btn-primary btn-block"
                              style={{ margin: '0 auto', width: '100%' }}
                              disabled={loading || isLoading}
                            >
                              <i className="fas fa-save fa-fw"></i> {isLoading ? "Reinitialisation en cours..." : "Reinitialiser"}
                            </Button>
                          </Form>

                          <div id="form-message-warning mt-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <br /><br /><br /><br /><br />
            <Foot />
          </div>
        </div>
      </body>
    </div>
  );
}

export default ResetPassword;
