const getToken = () => {
    return sessionStorage.getItem('token');
};

const saveToken = (token) => {
    sessionStorage.setItem('token', token);
};

const logout = () => {
    sessionStorage.removeItem('token');
    // Supprimer d'autres données si nécessaire
};

const isLogged = () => {
    const token = sessionStorage.getItem('token');
    return !!token;
};

export const accountService = {
    saveToken,
    logout,
    isLogged,
    getToken
};
