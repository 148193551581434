import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import UserService from "../service/UserService";
import Swal from "sweetalert2";
import Navbar from "../Nav";


function Liste_userm() {

  const [utilisateurs, setUtilisateurs] = useState([]);

  useEffect(() => {
    getUserDatas();
  }, []);

  const getUserDatas = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await UserService.getAllUsers(token);
      setUtilisateurs(response);
      console.log(response)
    } catch (error) {
      console.error("Error to fetch users list: ", error);
    }
  }

  const deleteUser = async (userId) => {
    try {
      // const confirm = window.confirm("Etes-vous sûr de supprimer cet utilisateur?");
      const token = sessionStorage.getItem('token');
      Swal.fire({
        title: "Etes-vous sûr ?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, Supprimer!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          await UserService.delete(userId, token);
          Swal.fire({
            title: "Deleted!",
            text: "Utilisateur supprimé !.",
            icon: "success"
          });
          getUserDatas();
        }
      });

    } catch (error) {
      console.error("Erreur de suppression de l'utilisateur: ", error);

    }

  }


  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            {/* <Navbar /> */} <br></br>
            <h4 className="title-text">Liste des utilisateurs métiers</h4>

            <Link
              to="/user_metier"
              className="btn btn-primary mb-3"
            >
              <i className="fas fa-fw fa-plus"></i>Ajouter un acteur
            </Link>
            <div className="card-body">
              <table
                id="example1"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>N°</th>
                    <th>Nom</th>
                    <th>Prenom(s)</th>
                    <th>Email</th>
                    <th>Tel</th>
                    <th>Role</th>
                    <th>Direction</th>
                    <th>Statut</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {utilisateurs.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        Aucun Utilisateur n'a été enregistré.
                      </td>
                    </tr>
                  ) : (
                    utilisateurs.map((user, index) => (
                      <tr key={user.id}>
                        <td className="text-noir">{index + 1}</td>
                        <td className="text-noir">{user.nom}</td>
                        <td className="text-noir">{user.prenom}</td>
                        <td className="text-noir">{user.email}</td>
                        <td className="text-noir">{user.tel}</td>
                        <td className="text-noir">{user.role?.libelle || 'Non attribué'}</td>
                        <td className="text-noir">{user.direction ? user.direction.sigle : 'N/A'}</td>
                        <td className="text-noir">{user.actif ? 'Actif' : 'Inactif'}</td>
                        <td className="d-flex align-items-center">
                          <Link to={`/user-maj/${user.id}`} className="round-button d-flex justify-content-center align-items-center">
                            <i className="bi bi-pencil white-icon"></i>
                          </Link>
                          <div className="round-buttonr" onClick={() => deleteUser(user.id)}>
                            <i className="bi bi-trash white-icon"></i>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </body>
      <Footer />
    </div>
  );
}

export default Liste_userm;