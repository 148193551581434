import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";
import Navbar from "../Nav";

function Form_typ_piece_E() {
  const baseURL = `${process.env.REACT_APP_API_URL}/api/param-type-pieces-niveau-validation`;
  const [intitule, setIntitule] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Effectuer la soumission du formulaire si la validation réussit.
      axios
        .post(baseURL, {
          intitule: intitule,
          description: description,
          code: code,
        })
        .then((response) => {
          alert(
            " " +
            intitule +
            " a été enregistré comme type de pièce avec succès !"
          );
          navigate("/liste_type_piece_E");
        })
        .catch((error) => {
          alert("Erreur : " + error);
        });
    } else {
      alert("Veuillez remplir tous les champs obligatoires.");
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (intitule.trim() === "") {
      setIntituleIsValid(false);
      isValid = false;
    } else {
      setIntituleIsValid(true);
    }

    if (code.trim() === "") {
      setCodeIsValid(false);
      isValid = false;
    } else {
      setCodeIsValid(true);
    }

    if (description.trim() === "") {
      setDescriptionIsValid(false);
      isValid = false;
    } else {
      setDescriptionIsValid(true);
    }

    return isValid;
  };

  const [intituleIsValid, setIntituleIsValid] = useState(true);
  const [descriptionIsValid, setDescriptionIsValid] = useState(true);
  const [codeIsValid, setCodeIsValid] = useState(true);

  return (
    <div>
      <body id="page-top">
        <div id="row">
          {<Header />}
          <div className="custom-wrapper">
          <Navbar />
            {/*  <!-- Content Row --> */}
            <div className="col-md-12">
              <div className="form h-100">
                <h2 className="title-text">Ajouter type de pièce</h2>
                <Form
                  onSubmit={handleSubmit}
                  className="mb-5"
                  noValidate
                >
                  <div className="row">
                    <div className="col-md-6 form-group mb-6">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Intitulé *
                      </label>
                      <Form.Control
                        type="text"
                        value={intitule}
                        onChange={(e) => setIntitule(e.target.value)}
                        className={`form-control ${intituleIsValid ? "" : "is-invalid"
                          }`}
                        id="yo"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-5">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Code *
                      </label>
                      <Form.Control
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className={`form-control ${codeIsValid ? "" : "is-invalid"
                          }`}
                        id="y"
                        required
                      />
                    </div>

                    <div className="col-md-12 form-group mb-5">
                      <label
                        className="col-form-label"
                        style={{ color: "black" }}
                      >
                        Description *
                      </label>
                      <Form.Control
                        type="text"
                        value={description}
                        onChange={(e) =>
                          setDescription(e.target.value)
                        }
                        className={`form-control ${descriptionIsValid ? "" : "is-invalid"
                          }`}
                        id="you"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 form-group">
                      <Button
                        className="btn btn-primary w-100"
                        type="submit"
                      >
                        <i className="fas fa-save"></i> Enregistrer
                      </Button>
                      <span className="submitting"></span>
                    </div>
                  </div>
                </Form>

                <div id="form-message-warning mt-4"></div>
                <div id="form-message-success">
                  Veuillez Remplir le Formulaire
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_typ_piece_E;
