import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Navbar from "../Nav";
const Choix_type_dmd = () => {
    // Données statiques pour les types de demandes
    const demandes = [
        { num: 1, typeDemande: "Demande d'Ouverture et d'Exploitation d'un ESP", description: "Obtenir un Arrêté d'Ouverture et d'Exploitation d'un ESP.", url: "/faire_choix" },
        { num: 2, typeDemande: "Demande de Convention", description: "Obtenir un Arrêté de convention avec le Ministère de la santé.", url: "/maintenance" },
        // { num: 3, typeDemande: "Demande de Carte d'Identité", description: "Demande de renouvellement de carte d'identité.", url: "/demande/carte-identite" },
        // { num: 4, typeDemande: "Demande de Permis de Conduire", description: "Faire une demande de permis de conduire.", url: "/demande/permis" },
        // { num: 5, typeDemande: "Demande de Visa", description: "Demande de visa pour voyage.", url: "/demande/visa" },
        // { num: 6, typeDemande: "Demande de Casier Judiciaire", description: "Demande d'extrait de casier judiciaire.", url: "/demande/casier-judiciaire" },
        // { num: 7, typeDemande: "Demande de Nationalité", description: "Demande d'acquisition de la nationalité.", url: "/demande/nationalite" },
        // { num: 8, typeDemande: "Demande de Relevé de Notes", description: "Demande de relevé de notes pour études.", url: "/demande/releve-notes" },
        // { num: 9, typeDemande: "Demande de Certificat de Résidence", description: "Certificat de résidence pour justificatif.", url: "/demande/certificat-residence" },
        // Ajoutez d'autres demandes si nécessaire
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const demandesPerPage = 6;

    // Calculer les demandes à afficher pour la page courante
    const indexOfLastDemande = currentPage * demandesPerPage;
    const indexOfFirstDemande = indexOfLastDemande - demandesPerPage;
    const currentDemandes = demandes.slice(indexOfFirstDemande, indexOfLastDemande);

    // Calcul du nombre total de pages
    const totalPages = Math.ceil(demandes.length / demandesPerPage);

    // Fonction pour changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <body id="page-top">
            <Navbar />
            <br></br>
            <div className="col-xl-12 col-md-6 mb-6">
                <br /><br />
                <h4 className="title-text">Liste des types de demandes</h4>
                <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Type de demande</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentDemandes.length === 0 ? (
                                <tr>
                                    <td colSpan="4">Aucune demande n'a été enregistrée.</td>
                                </tr>
                            ) : (
                                currentDemandes.map((demande, index) => (
                                    <tr key={index}>
                                        <td className="text-noir">{demande.num}</td>
                                        <td className="text-noir">{demande.typeDemande}</td>
                                        <td className="text-noir">{demande.description}</td>
                                        <td className="text-noir">
                                            <div className="button-container d-flex align-items-center">
                                                <Link
                                                    to={demande.url}
                                                    title="Déposer cette demande"
                                                    className="me-2"
                                                >
                                                    <div className="round-button d-flex justify-content-center align-items-center">
                                                        <i class="bi bi-send-fill"></i>
                                                    </div>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="pagination">
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                className={`page-number ${index + 1 === currentPage ? "selected-page" : ""}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </body>
    );
};

export default Choix_type_dmd;
