import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { toast } from "react-toastify";
import axios from "axios";
import Navbar from "../../Nav";
import PDFPreviewModal from './PDFPreviewModal';
import DemandeInfoComponent from './DemandeInfoComponent_user';
import DemandeService from "../../service/DemandeService";

// Config PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Info_dmd_rcue() {
  const { demandeId } = useParams();
  const [userDemande, setUserDemande] = useState([]);
  const [isAttachmentsExpanded, setAttachmentsExpanded] = useState(true);
  const [isInfoExpanded, setInfoExpanded] = useState(true);
  const [selectedPieceJointe, setSelectedPieceJointe] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const getDemande = async (demandeId) => {
      try {
        const response = await DemandeService.getById(demandeId, token);
        setUserDemande(response);
      } catch (error) {
        console.error("Error fetching demandes:", error.message);
      }
    };

    if (demandeId) getDemande(demandeId);
  }, [demandeId, token]);

  const openFileInModal = (pieceJointe) => {
    setSelectedPieceJointe(pieceJointe);
    setIsModalOpen(true);
  };

  const closeFileModal = () => {
    setIsModalOpen(false);
    setSelectedPieceJointe(null);
  };

  const downloadFile = async (idFile) => {
    try {
      const token = sessionStorage.getItem('token');
      const url = `${process.env.REACT_APP_API_URL}/pieces/download/${idFile}`;

      const response = await axios({
        method: 'GET',
        url: url,
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/pdf'
        }
      });

      // Créer un blob avec le type MIME PDF
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `document_${idFile}.pdf`); // Ajout de l'extension .pdf
      
      // Option 1 : Télécharger directement
      document.body.appendChild(link);
      link.click();

      // Option 2 : Ouvrir dans un nouvel onglet (décommentez si vous préférez cette option)
      // window.open(downloadUrl, '_blank');

      // Nettoyage
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(link);

    } catch (error) {
      console.error('Erreur lors du téléchargement du PDF:', error);
      toast.error('Erreur lors du téléchargement du fichier PDF');
    }
  };

  return (
    <body id="page-top">
      <div id="wrapper">

        <Navbar />
        <br></br><br></br>
        <div className="container-fluid">
          <div className="content-wrapper mt-3">
            {/* Informations sur la Demande */}
            <br></br><br></br>
            <div className="row">
              <div className="col-12 mb-4">
                <div className="card shadow border-left-success">
                  <div className="card-body">
                    <details open={isInfoExpanded}>
                      <summary onClick={() => setInfoExpanded(!isInfoExpanded)} style={{ color: "black", fontSize: "1.25rem" }}>
                        Informations sur la Demande reçue
                      </summary>
                      <DemandeInfoComponent userDemande={userDemande} demandeId={demandeId} />
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <h4>Complément :</h4>
                          {userDemande.statutDemande && (
                            <div>
                              {(userDemande.statutDemande === "AJOURNEE" ||
                                userDemande.statutDemande === "EN_ATTENTE_COMPLEMENT_COM" ||
                                userDemande.statutDemande === "EN_ATTENTE_COMPLEMENT") && (
                                  <Link to={`/demandes/${demandeId}/complement`}>
                                    <button className="btn btn-primary mt-2">
                                      Envoyer un complément de dossier
                                    </button>
                                  </Link>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </details>
                  </div>
                </div>
              </div>
            </div>

            {/* Pièces Jointes */}
            <div className="row">
              <div className="col-12">
                <div className="card shadow border-left-warning">
                  <div className="card-body">
                    <details open={isAttachmentsExpanded}>
                      <summary onClick={() => setAttachmentsExpanded(!isAttachmentsExpanded)} style={{ color: "black", fontSize: "1.25rem" }}>
                        Pièces Jointes
                      </summary>
                      <div className="attachments-section">
                            {/* Pièces jointes de complément */}
                            {userDemande.pieceJointes?.some(pj => pj.complement?.id) && (
                              <div className="complement-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-folder-plus me-2"></i>
                                  Pièces complémentaires
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                    .filter(pj => pj.complement?.id)
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-info shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "blue" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)} 
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
                                                   style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {/* Pièces jointes du personnel */}
                            {userDemande.pieceJointes?.some(pj => pj.personnel?.id) && (
                              <div className="personnel-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-users me-2"></i>
                                  Documents du personnel
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                    .filter(pj => pj.personnel?.id)
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-primary shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "red" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)}
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
                                                   style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {/* Pièces jointes du promoteur */}
                            {userDemande.typeProprioDemande !== "PHYSIQUE" && userDemande.pieceJointes?.some(pj => pj.proprietaire?.id) && (
                              <div className="personnel-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-users me-2"></i>
                                  Documents des Responsables Techniques 
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                    .filter(pj => pj.proprietaire?.id)
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-primary shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "red" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)}
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
                                                   style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}
                            
                            {userDemande.typeProprioDemande === "PHYSIQUE" && userDemande.pieceJointes?.some(pj => pj.proprietaire?.id) && (
                              <div className="promoteur-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-user-tie me-2"></i>
                                  Documents de l'établissement et du promoteur 
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                    .filter(pj => pj.proprietaire?.id)
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-success shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "green" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)}
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
                                                   style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {/* Pièces jointes du promoteur ou de l'établissement */}

                            {userDemande.typeProprioDemande !== "PHYSIQUE" && userDemande.pieceJointes?.length > 0 && (
                              <div className="promoteur-files mb-4">
                                <h5 style={{ color: 'black', borderBottom: '2px solid #0056b3', paddingBottom: '10px' }}>
                                  <i className="fas fa-user-tie me-2"></i>
                                  Documents de l'établissement  
                                </h5>
                                <div className="row">
                                  {userDemande.pieceJointes
                                 .filter(pj => pj.proprietaire === null && pj.personnel === null && pj.complement === null) // Filtre les pièces jointes sans propriétaire
                                    .map((pieceJointe) => (
                                      <div key={pieceJointe.id} className="col-xl-4 col-md-6 mb-3">
                                        <div className="card border-left-success shadow h-100 py-2">
                                          <div className="card-body">
                                            <div className="attachment-card">
                                              <div className="attachment-info">
                                                <h2><i className="far fa-file-pdf" style={{ color: "green" }}></i></h2>
                                                <h6 style={{ color: "black" }}>{pieceJointe.nomFile}</h6>
                                              </div>
                                              <div className="attachment-actions">
                                                <i className="fas fa-eye" onClick={() => openFileInModal(pieceJointe)}
                                                   style={{ color: "blue", cursor: "pointer" }} title="Voir le fichier"></i>
                                                &nbsp;&nbsp;
                                                <i className="fas fa-download" onClick={() => downloadFile(pieceJointe.id)}
                                                   style={{ color: "orange", cursor: "pointer" }} title="Télécharger le fichier"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {/* Message si aucune pièce jointe */}
                            {(!userDemande.pieceJointes || userDemande.pieceJointes.length === 0) && (
                              <p className="text-center text-muted">Aucun fichier n'est disponible</p>
                            )}
                          </div>
                    </details>
                  </div>
                </div>
              </div>
            </div>

            {selectedPieceJointe && (
              <PDFPreviewModal
                isModalOpen={isModalOpen}
                closeFileModal={closeFileModal}
                pieceJointe={selectedPieceJointe}
              />
            )}
          </div>
        </div>
      </div>
    </body >
  );
}

export default Info_dmd_rcue;
