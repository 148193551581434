import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import armoiries from "../../img/armoiries.jpg";
import CustomButton from './CustomButton';
import Header from "../../../../Template/admin/Home";
function Choix_dmd(props) {

  const [isPhysique, setIsPhysique] = useState(false); // Gère le type physique/morale
  const [typeProprieteDemande, setTypeProprieteDemande] = useState(''); // Variable d'état pour stocker le type choisi
  const navigate = useNavigate(); // Pour rediriger vers d'autres pages

  // Fonction pour gérer les changements dans le select
  const handleInputChange = (e) => {
    const { value } = e.target;
    setTypeProprieteDemande(value); // Met à jour l'état avec le type choisi
    setIsPhysique(value === 'PHYSIQUE'); // Met à jour isPhysique en fonction du choix
  };

  // Fonction pour gérer la soumission du choix
  const handleChoice = () => {
    if (typeProprieteDemande === 'PHYSIQUE') {
      navigate('/faire_demande_physique'); // Redirige vers la page physique
    } else if (typeProprieteDemande === 'MORALESOCIETE') {
      navigate('/faire_demande_morale'); // Redirige vers la page morale
    } else if (typeProprieteDemande) {
      navigate('/faire_demande_morale_conv'); // Redirige vers la page morale
    }
  };
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    navigate(-1);
  };

  //   const openModal = () => {
  //     setShowModal(true);
  //   };
  return (
    <>
      {<Header />}
      <div>

        {/* Modal qui s'affiche au début */}
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>&times;</span>
              <h4 className="text-center" style={{ color: 'black' }}>
                <img src={armoiries} width="50" alt="Armoiries" />
                <Modal.Title><b> Sélectionnez le type de personne</b></Modal.Title>
              </h4>
              <Modal.Body>
                <Form>
                  <Form.Group className="text-noir">
                    <Form.Label>Êtes-vous une personne physique ou morale ?</Form.Label>
                    <Form.Control
                      as="select"
                      className="custom-select text-noir"
                      name="typeProprieteDemande"
                      value={typeProprieteDemande}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="" className="text-noir">Sélectionnez le type de propriétaire</option>
                      <option value="PHYSIQUE" className="text-noir"> Personne Physique</option>
                      <option value="MORALESOCIETE" className="text-noir"> Morale Société</option>
                      <option value="MORALECONVENTIONNE" className="text-noir"> Morale Conventionnée</option>
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <CustomButton variant="primary" onClick={handleChoice} disabled={!typeProprieteDemande}>
                  Continuer
                </CustomButton>
              </Modal.Footer>
            </div>
          </div>
        )}
      </div>
    </>

  );

}

export default Choix_dmd;
