import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DemandStatusChart = ({ acceptedDemands, rejectedDemands }) => {
  const data = [
    { name: 'Acceptées', value: acceptedDemands },
    { name: 'Rejetées', value: rejectedDemands },
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" fill="#8884d8">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={index === 0 ? '#2ecc71' : '#e74c3c'} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DemandStatusChart;