import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import Navbar from "../../Nav";
import UserService from "../../service/UserService";


function ListeDemandeaTraiter() {
  const [utilisateurs, setUtilisateurs] = useState([]); // Correction: utilisation de setUtilisateurs
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6); // Nombre d'éléments par page

  const formatDate = (dateStr) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateStr).toLocaleDateString(undefined, options);
  };
  const [statut, setStatut] = useState("");
  // Calculez l'index de début et de fin pour l'affichage des éléments actuels sur la page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUtilisateurs = utilisateurs.slice(indexOfFirstItem, indexOfLastItem);

  const affURL = `${process.env.REACT_APP_API_URL}/demandes/find-by-statut`;

  // Utiliser useEffect pour récupérer les données
  useEffect(() => {
    if (statut) {
      axios
        .get(`${affURL}?statut=${statut}`)
        .then((response) => {
          setUtilisateurs(response.data);
        })
        .catch((error) => {
          // alert("Erreur lors de la récupération des demandes : " + error);
        });
    }
  }, [statut]);

  // Fonction pour changer de page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calcul du nombre total de pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(utilisateurs.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const userRole = UserService.getRoleInToken();

  const getFilteredOptions = () => {
    const allOptions = [
      { value: "EN_COURS_PREVALIDATION", label: "Demandes en cours de prévalidation" },
      { value: "PREVALIDEE", label: "Demandes prévalidées" },
      { value: "OK_OPs", label: "Demandes traitées par les Ordres professionnels" },
      { value: "EN_ATTENTE_COMPLEMENT", label: "Demandes en attente de complement" },
      { value: "COMPLETEE", label: "Demandes complétées par l'utilisateur" },
      { value: "EN_ATTENTE_COMISSION", label: "Demandes en attente d'envoi à la commission" },
      { value: "FAVORABLE", label: "Demandes en commission" },
      { value: "ACCEPTEE_SOUS_RESERVE", label: "Demandes acceptées sous réserves" },
      { value: "REJETEE", label: "Demandes rejetées par DSPS" },
      { value: "AJOURNEE", label: "Demandes ajournées" },
      { value: "ACCEPTEE", label: "Demandes acceptées par la commission" },
      { value: "REJETEE_DSPS", label: "Demandes rejetées par la DSPS" },
      { value: "REJETE_COM", label: "Demandes rejetées par la commission" },
      { value: "EN_ATTENTE_COMPLEMENT_COM", label: "Demandes mises en attente de complement par la commission" }
    ];

    switch (userRole) {
      case 'ADMIN':
        return allOptions;
      case 'DSPS':
        return allOptions.filter(option =>
          ["EN_COURS_PREVALIDATION", "PREVALIDEE","COMPLETEE", "OK_OPs", "EN_ATTENTE_COMPLEMENT", "ACCEPTEE","FAVORABLE","AJOURNEE",'EN_ATTENTE_COMISSION', "ACCEPTEE_SOUS_RESERVE", "REJETEE", "AJOURNEE", "ACCEPTEE", "FAVORABLE", "REJETE_COM", "EN_ATTENTE_COMPLEMENT_COM"].includes(option.value)
        );
      case 'OP':
        return allOptions.filter(option =>
          ["PREVALIDEE", "OK_OPs"].includes(option.value)
        );
      case 'COMMISSION':
        return allOptions.filter(option =>
          ["EN_ATTENTE_COMISSION", "ACCEPTEE_SOUS_RESERVE", "REJETEE", "AJOURNEE", "ACCEPTEE", "FAVORABLE", "REJETE_COM", "EN_ATTENTE_COMPLEMENT_COM"].includes(option.value)
        );
      default:
        return [];
    }
  };

  const getReadableStatus = (status) => {
    const statusMap = {
      "EN_COURS_PREVALIDATION": "En cours de prévalidation",
      "PREVALIDEE": "Prévalidée",
      "OK_OPs": "En attente de validation pour la commission",
      "FAVORABLE": "Favorable",
      "EN_ATTENTE_COMPLEMENT": "En attente de complément",
      "EN_ATTENTE_COMISSION": "En commission",
      "ACCEPTEE_SOUS_RESERVE": "Acceptée sous réserves",
      "REJETEE": "Rejetée",
      "AJOURNEE": "Ajournée",
      "ACCEPTEE": "Acceptée",
      "REJETEE_DSPS": "Rejetée par la DSPS",
      "COMPLETEE": "Complétée",
      "REJETE_COM": "Rejetée par la commission",
      
      "EN_ATTENTE_COMPLEMENT_COM": "En attente de complément par la commission"
    };
    return statusMap[status] || status; // Retourne le statut original si non trouvé dans le map
  };

  const getStatusColor = (status) => {
    const colorMap = {
      "EN_COURS_PREVALIDATION": "primary",
      "PREVALIDEE": "info",
      "FAVORABLE": "success",
      "EN_ATTENTE_COMPLEMENT": "warning",
      "EN_ATTENTE_COMISSION": "secondary",
      "ACCEPTEE_SOUS_RESERVE": "info",
      "REJETEE": "danger",
      "AJOURNEE": "warning",
      "ACCEPTEE": "success",
      // "REJETEE_DSPS": "danger",
      "COMPLETEE": "info",
      "REJETE_COM": "danger",
      "OK_OPs": "info",
      "EN_ATTENTE_COMPLEMENT_COM": "warning"
    };
    return colorMap[status] || "secondary"; // Retourne "secondary" si non trouvé dans le map
  };

  return (
    <div>
      <body id="page-top">
        <div id="row">
          {/* <Header /> */}
          <div className="content-wrapper">
            <Navbar />
            {/* {userRole === 'ADMIN' && <EnhancedAdminDashboard/>} */}
            <br/><br/>  <br/><br/>
          
            
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="title-text">Etapes de Validation</h4>
                      <div className="select-wrapper" style={{ 
                        width: '100%', 
                        maxWidth: '400px', 
                        margin: '20px auto', 
                        display: 'flex', 
                        justifyContent: 'center'
                      }}>
                        <select
                          className="styled-select"
                          value={statut}
                          onChange={(e) => setStatut(e.target.value)}
                          style={{ 
                            width: '100%',
                            padding: '10px',
                            fontSize: '16px',
                            borderRadius: '5px'
                          }}
                        >
                          <option value="">Sélectionnez une étape</option>
                          {getFilteredOptions().map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h4 className="title-text">Liste des Demandes </h4>
                      <div className="card-body">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>N° demande</th>
                              <th>Type de demande</th>
                              <th>Statut</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentUtilisateurs.length === 0 ? (
                              <tr>
                                <td colSpan="9" className="text-center">
                                  Aucune nouvelle demande n'a été enregistrée.
                                </td>
                              </tr>
                            ) : (
                              currentUtilisateurs.map((utili, index) => (
                                <tr key={index}>
                                  <td className="text-noir">
                                    {utili.dateSoumission ? formatDate(utili.dateSoumission) : "N/A"}
                                  </td>
                                  <td className="text-noir">{utili.numDemande}</td>
                                  <td className="text-noir">{utili.typeDemande}</td>
                                  <td className="text-noir">
                                    <span className={`badge bg-${getStatusColor(utili.statutDemande)}`} style={{ top: '10px', right: '10px' }}>
                                      {getReadableStatus(utili.statutDemande)}
                                    </span></td>
                                  <td className="text-noir">
                                    <Link
                                      to={"/demandes-view/" + utili.id}
                                      title="voir informations"
                                      className="me-2"
                                    >
                                      <div className="round-button d-flex justify-content-center align-items-center">
                                        <i className="bi bi-eye white-icon"></i>
                                      </div>
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {/* Pagination */}
                        <div className="pagination">
                          {pageNumbers.map((pageNumber) => (
                            <button
                              key={pageNumber}
                              onClick={() => paginate(pageNumber)}
                              className={`page-number ${pageNumber === currentPage ? "selected-page" : ""}`}
                            >
                              {pageNumber}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
              
            
          </div>
          <Footer />
        </div>
      </body>
    </div>
  );
}

export default ListeDemandeaTraiter;
