import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { Form, Button } from "react-bootstrap";

import Header from "../Header";
import Navbar from "../Nav";

function Form_Role() {
  const baseURL = `${process.env.REACT_APP_API_URL}/roles/create`;
  const [intitule, setIntitule] = useState("");
  const [description, setDescription] = useState("");

  const [intituleIsValid, setIntituleIsValid] = useState(true);
  const [descriptionIsValid, setDescriptionIsValid] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Effectuer la soumission du formulaire si la validation réussit.
      axios
        .post(baseURL, {
          libelle: intitule,
          description: description,
        })
        .then((response) => {
          alert(" le rôle  " + intitule + " a été enregistré avec succès !!");
          navigate("/liste_role");
        })
        .catch((error) => {
          alert("Erreur : " + error);
        });
    } else {
      alert("Veuillez remplir tous les champs obligatoires.");
    }
  };
  const validateForm = () => {
    let isValid = true;

    if (intitule.trim() === "") {
      setIntituleIsValid(false);
      isValid = false;
    } else {
      setIntituleIsValid(true);
    }

    if (description.trim() === "") {
      setDescriptionIsValid(false);
      isValid = false;
    } else {
      setDescriptionIsValid(true);
    }

    return isValid;
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          {<Header />}
          <div className="custom-wrapper">
            {/* <Navbar /> */}
            {/*  <!-- Content Row --> */}
            <br></br>
            <div className="col-xxl-6 col-md-12">
              <div className="form h-100">
                <h2 className="title-text">Ajouter un Rôle </h2>
                <Form
                  onSubmit={handleSubmit}
                  className="mb-5"
                  noValidate
                >
                  <div className="row">
                    <div className="col-md-12 form-group mb-6">
                      <label className="col-form-label">
                        Intitulé *
                      </label>
                      <Form.Control
                        type="text"
                        value={intitule}
                        onChange={(e) => setIntitule(e.target.value)}
                        className={`form-control ${intituleIsValid ? "" : "is-invalid"
                          }`}
                        id="yourEmail"
                        required
                      />
                    </div>

                    <div className="col-md-12 form-group mb-6">
                      <label className="col-form-label">
                        Description *
                      </label>
                      <Form.Control
                        type="text"
                        value={description}
                        onChange={(e) =>
                          setDescription(e.target.value)
                        }
                        className={`form-control ${descriptionIsValid ? "" : "is-invalid"
                          }`}
                        id="yourEmail"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 form-group">
                      <Button
                        className="btn btn-primary w-100"
                        type="submit"
                      >
                        {" "}
                        <i className="fas fa-save"></i> Enregistrer
                      </Button>
                      <span className="submitting"></span>
                    </div>
                  </div>
                </Form>

                <div id="form-message-warning mt-4"></div>
                <div id="form-message-success">
                  Veuillez Remplir le Formulaire
                </div>
              </div>
            </div>

          </div>
        </div>
      </body>
    </div>
  );
}

export default Form_Role;
