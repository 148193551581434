import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Card from "./Card";
import Foot from "./Foot";
import Head from "./Head";

const Contact = () => {
  const [activeAccordion, setActiveAccordion] = useState("");
  const [footerOpen, setFooterOpen] = useState(false);

  const handleAccordion = (accordionName) => {
    setActiveAccordion(activeAccordion === accordionName ? "" : accordionName);
    setFooterOpen(activeAccordion === accordionName ? false : true);
  };

  const [formData, setFormData] = useState({
    nom: '',
    contact: '',
    body: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Pour gérer l'état de soumission

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      sendEmailFunction();
    }
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = true;
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmailFunction = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/nous_contacter/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nomContactR: formData.nom,
          emailContactR: formData.contact,
          messageContactR: formData.body,
        }),
      });

      if (response.ok) {
        console.log('Email envoyé avec succès');
        handleCancel(); // Réinitialiser le formulaire après l'envoi
        toast.success("Votre email a été envoyé avec succès");
      } else {
        console.error('Erreur lors de l\'envoi de l\'email');
      }
    } catch (error) {
      console.error('Erreur réseau ou autre problème:', error);
    }
    setIsSubmitting(false);
  };

  const handleCancel = () => {
    setFormData({
      nom: '',
      contact: '',
      body: '',
    });
  };

  return (
    <div>
      <div>{<Head />}</div>

      <div className="content">
        <br /><br />
        <h3 style={{ textAlign: 'center', color: "black" }}><b> Contact</b></h3><br />

        <section id="apropos" className="d-flex align-items-center accordion">
          <div className='col-sm-12 col-md-6 col-lg-6 col-form-label mx-auto' style={{ backgroundColor: 'white', margin: 'auto' }}>
            <h2 style={{ padding: '10px' }}>Contactez nous</h2>
            <div style={{ justifyContent: "center" }}>
              <p style={{ marginLeft: "10px" }}>Tel : (+226)</p>
              <p style={{ marginLeft: "10px" }}>Email : sante@sante.gov.bf</p>
              <p style={{ marginLeft: "10px" }}>Adresse : 01 BP 526, Ouagadougou BURKINA FASO</p>
            </div>
            <h2 style={{ padding: '10px', justifyContent: 'center' }}>Envoyez nous un mail</h2>
            <div>
              <Form style={{ padding: '10px' }} onSubmit={handleSubmit}>
                <div className='form-group col-lg-12 col-sm-12 col-md-6'>
                  <Form.Group controlId="nom">
                    <Form.Label>Nom et Prenom(s)</Form.Label>
                    <Form.Control
                      autoComplete='off'
                      type="text"
                      name="nom"
                      value={formData.nom}
                      onChange={handleChange}
                      placeholder="Votre nom"
                      required
                      isInvalid={formErrors.nom}
                    />
                    <Form.Control.Feedback type="invalid">Ce champ est obligatoire.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="contact">
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                      autoComplete='off'
                      type="email"
                      name="contact"
                      value={formData.contact}
                      onChange={handleChange}
                      placeholder="Votre adresse email ou numéro"
                      required
                      isInvalid={formErrors.contact}
                    />
                    <Form.Control.Feedback type="invalid">Ce champ est obligatoire.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="body">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="body"
                      value={formData.body}
                      onChange={handleChange}
                      placeholder="Votre message"
                      required
                      isInvalid={formErrors.body}
                    />
                    <Form.Control.Feedback type="invalid">Ce champ est obligatoire.</Form.Control.Feedback>
                  </Form.Group>

                  <div className='form-group d-flex justify-content-between mt-4'>
                    <Button variant="btn btn-dark" type="submit" className="float-right" disabled={isSubmitting}>
                      {isSubmitting ? 'Envoi...' : 'Envoyer'}
                    </Button>
                    <Button variant="btn btn-dark" onClick={handleCancel} className="float-left">
                      Annuler
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </div>

      <div><Foot /></div>
    </div>
  );
};

export default Contact;
