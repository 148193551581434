import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import armoiries from "../img/armoiries.jpg";
import Footer from "../Footer";

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import { Typography } from "@mui/material";

function Info_piece() {
  const editURL = `${process.env.REACT_APP_API_URL}/api/param-type-pieces/`;

  const param = useParams();
  const [empId, setId] = useState("");
  const [empIntitule, setIntitule] = useState("");
  const [empDescription, setDescription] = useState("");
  const [empCode, setCode] = useState("");
  const [empIs_facul, setis_facultatif] = useState("");

  const navigate = useNavigate();

  const retour = () => {
    navigate("/liste_type_piece");
  };

  useEffect(() => {
    axios
      .get(editURL + param.id)
      .then((response) => {
        const empData = response.data;
        setId(empData.id);
        setIntitule(empData.intitule);
        setDescription(empData.description);
        setCode(empData.code);
        setis_facultatif(empData.is_facultatif);
      })
      .catch((error) => {
        alert("erreur pour trouver les details :" + error);
      });
  });
  return (
    <div>
      <main id="main" className="main">
        <div className="content center-container">
          <div>
            <Card sx={{ maxWidth: 600 }}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="center-text"
                >
                  <b>
                    <img
                      src={armoiries}
                      alt=""
                      className="img-fluid"
                      width={50}
                    />
                    Informations Pièce &nbsp;&nbsp;&nbsp;
                  </b>
                </Typography>
                <br></br>
                <Typography gutterBottom variant="h5" component="div">
                  ID: {empId}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  <h6>
                    <b> Code de la pièce :</b> {empCode}
                  </h6>
                  <br></br>
                  <h6>
                    <b> Intitulé de la pièce :</b> {empIntitule}{" "}
                  </h6>
                  <br></br>
                  <h6>
                    <b> Description :</b> {empDescription}
                  </h6>

                  <br></br>
                  <h6>
                    <b> Facultative :</b> {empIs_facul}
                  </h6>

                  <br></br>
                </Typography>
              </CardContent>
              <button
                onClick={() => retour()}
                className="btn btn-sm btn-danger"
              >
                {" "}
                retour <i className="bi bi-back"></i>
              </button>
            </Card>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Info_piece;
