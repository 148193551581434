/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import CustomButton from './CustomButton';
import axios from "axios";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import * as XLSX from 'xlsx';
import Header from "../../Header";
import Footer from "../../Footer";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import UserService from "../../service/UserService";
import FormStepMorale from "./FormStepMoral";
import Navbar from "../../Nav";


function Form_dmd_dsps_morale() {

  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState({});

  const token = sessionStorage.getItem("token");
  // console.log("Token val: ", token);

  const getUser = async () => {
    try {
      // Vérifier si le token existe
      if (!token) {
        console.error("Token not found in sessionStorage");
        return;
      }

      // Envoyer la requête au serveur avec le token inclus dans les en-têtes
      const response = await UserService.getProfil(token);
      const userData = response
      // console.log(userData);
      setUserData(userData);
      setUserId(userData.id);

    } catch (error) {
      console.error("Error sending token to server:", error.message);
    }
  }

  // Appeler la fonction lorsque le composant est monté
  useEffect(() => {
    getUser();
  }, []);


  const type = "personne-morale";

  const [isPhysique, setIsPhysique] = useState(true);
  //state for form data

  const [demandeDTO, setDemandeDTO] = useState({

    nomStructure: "",
    nomEsp: "",
    numArreteCreation: "",
    typeDemande: "OUVERTURE",
    typeProprioDemande: "MORALESOCIETE",
    utilisateur: {
      id: "",
    },
    commune: {
      id: "",
    },
    arrondissement: {
      id: "",
    },
    esp: {
      id: ""
    },

    personnels: [
      {
        nom: "",
        prenom: "",
        nip: "",
        numOp: "",
        profession: "",

      }
    ],
    proprietaireRt: [
      {
        nom: "",
        prenom: "",
        nip: "",
        dateNaissance: "",
        lieuNaissance: "",
        email: "",
        genre: "",
        tel: "",
        numOp: "",
        adresse: "",
        nationalite: "",
        profession: "",

      }
    ],

  });

  const handleFileXlsUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      // Transforme les données du fichier Excel en un format compatible avec le formulaire
      const updatedpersonnels = jsonData.slice(1).map(row => ({
        nom: row[0] || "",
        prenom: row[1] || "",
        nip: row[2] || "",
        profession: row[3] || "",
        numOp: row[4] || ""
      }));

      setDemandeDTO(prevState => ({
        ...prevState,
        personnels: updatedpersonnels
      }));
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileXlsRTUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      // Transforme les données du fichier Excel en un format compatible avec le formulaire
      const updatedProprietaireRt = jsonData.slice(1).map(row => ({
        nom: row[0] || "",
        prenom: row[1] || "",
        nip: row[2] || "",
        dateNaissance: formatD(row[3]),
        lieuNaissance: row[4] || "",
        email: row[5] || "",
        genre: row[6] || "",
        tel: row[7] || "",
        numOp: row[8] || "",
        adresse: row[9] || "",
        nationalite: row[10] || "",
        profession: row[11] || ""
      }));

      setDemandeDTO(prevState => ({
        ...prevState,
        proprietaireRt: updatedProprietaireRt
      }));
    };

    reader.readAsArrayBuffer(file);
  };
  const [fichierStructure, setFichierStructure] = useState(null);
  const [personnelSimpleFichiers, setPersonnelSimpleFichiers] = useState([]);
  const [personnelTechniqueFichiers, setPersonnelTechniqueFichiers] = useState([]);
  // Fonction utilitaire pour formater la date
  const formatD = (date) => {
    if (!date) return ""; // Si la date est vide
    const parsedDate = new Date(date);
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Mois en 2 chiffres
    const day = String(parsedDate.getDate()).padStart(2, '0'); // Jour en 2 chiffres
    return `${year}-${month}-${day}`; // Format YYYY-MM-DD
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Gérer les propriétés imbriquées pour `utilisateur`
    if (name.startsWith("utilisateur.")) {
      const utilisateurField = name.split(".")[1]; // Obtient la propriété (ex: "id")
      setDemandeDTO(prev => ({
        ...prev,
        utilisateur: {
          ...prev.utilisateur,
          [utilisateurField]: value,
        },
      }));
    }
    // Gérer les propriétés imbriquées pour `commune`
    else if (name.startsWith("commune.")) {
      const communeField = name.split(".")[1]; // Obtient la propriété
      setDemandeDTO(prev => ({
        ...prev,
        commune: {
          ...prev.commune,
          [communeField]: value,
        },
      }));
    }
    else if (name.startsWith("arrondissement.")) {
      const arrondissementField = name.split(".")[1]; // Obtient la propriété
      setDemandeDTO(prev => ({
        ...prev,
        arrondissement: {
          ...prev.arrondissement,
          [arrondissementField]: value,
        },
      }));
    }
    // Gérer les propriétés imbriquées pour `esp`
    else if (name.startsWith("esp.")) {
      const espField = name.split(".")[1]; // Obtient la propriété
      setDemandeDTO(prev => ({
        ...prev,
        esp: {
          ...prev.esp,
          [espField]: value,
        },
      }));
    }

    // Gérer les champs de niveau supérieur
    else {
      setDemandeDTO(prev => ({
        ...prev,
        [name]: ["nomEsp", "nomStructure"].includes(name) ? value.toUpperCase() : value,
      }));
    }
  };

  const validateStep = () => {
    const errors = [];

    switch (currentStep) {
      case 1:
        if (!demandeDTO.nomStructure?.trim()) {
          errors.push("Le nom de la structure est requis");
        }
        if (!demandeDTO.numArreteCreation?.trim()) {
          errors.push("Le numéro d'arrêté de création est requis");
        }
        break;

      case 2:
        if (!demandeDTO.esp?.id) {
          errors.push("Le type d'ESP est requis");
        }
        if (!demandeDTO.commune?.id) {
          errors.push("La commune est requise");
        }
        if (!demandeDTO.nomEsp?.trim()) {
          errors.push("Le nom de l'ESP est requis");
        }
        if (!fichierStructure) {
          errors.push("Le dossier complet de l'ESP est requis");
        }
        break;

      case 3:
        if (!demandeDTO.proprietaireRt || demandeDTO.proprietaireRt.length === 0) {
          errors.push("Au moins un responsable technique est requis");
        } else {
          demandeDTO.proprietaireRt.forEach((rt, index) => {
            const rtNum = index + 1;
            if (!rt.nom?.trim()) errors.push(`Le nom du RT ${rtNum} est requis`);
            if (!rt.prenom?.trim()) errors.push(`Le prénom du RT ${rtNum} est requis`);
            if (!rt.nip?.trim()) {
              errors.push(`Le NIP du RT ${rtNum} est requis`);
            } else if (rt.nip.length !== 17) {
              errors.push(`Le NIP du RT ${rtNum} doit contenir 17 caractères`);
            }
            // if (!rt.tel?.trim()) {
            //   errors.push(`Le téléphone du RT ${rtNum} est requis`);}
            // // } else if (rt.tel.length !== 8) {
            // //   errors.push(`Le numéro de téléphone du RT ${rtNum} doit contenir 8 chiffres`);
            // // }
            if (!rt.dateNaissance) errors.push(`La date de naissance du RT ${rtNum} est requise`);
            if (!rt.lieuNaissance?.trim()) errors.push(`Le lieu de naissance du RT ${rtNum} est requis`);
            if (!rt.profession?.trim()) errors.push(`La profession du RT ${rtNum} est requise`);
            if (!rt.adresse?.trim()) errors.push(`L'adresse du RT ${rtNum} est requise`);
            if (!rt.nationalite?.trim()) errors.push(`La nationalité du RT ${rtNum} est requise`);
            if (!rt.numOp ?.trim()) errors.push(`Le numéro d'ordre du RT ${rtNum} est requis`);
          });
        }
        break;

      case 4:
        demandeDTO.proprietaireRt.forEach((rt, index) => {
          if (!personnelTechniqueFichiers[index]) {
            errors.push(`Le dossier du RT ${index + 1} (${rt.nom} ${rt.prenom}) est requis`);
          }
        });
        break;

      case 5:
        if (!demandeDTO.personnels || demandeDTO.personnels.length === 0) {
          errors.push("Au moins un personnel est requis");
        } else {
          demandeDTO.personnels.forEach((personnel, index) => {
            const persNum = index + 1;
            
            // Conversion en chaîne et vérification pour nom
            if (!String(personnel.nom || '').trim()) {
              errors.push(`Le nom du personnel ${persNum} est requis`);
            }
            
            // Conversion en chaîne et vérification pour prénom
            if (!String(personnel.prenom || '').trim()) {
              errors.push(`Le prénom du personnel ${persNum} est requis`);
            }
            
            // Conversion en chaîne et vérification pour NIP
            const nipValue = String(personnel.nip || '').trim();
            if (!nipValue) {
              errors.push(`Le NIP du personnel ${persNum} est requis`);
            } else if (nipValue.length !== 17) {
              errors.push(`Le NIP du personnel ${persNum} doit contenir 17 caractères`);
            }
            
            // Conversion en chaîne et vérification pour profession
            if (!String(personnel.profession || '').trim()) {
              errors.push(`La profession du personnel ${persNum} est requise`);
            }
            
            // Conversion en chaîne et vérification pour numOp
            // if (!String(personnel.numOp || '').trim()) {
            //   errors.push(`Le numéro d'ordre du personnel ${persNum} est requis`);
            // }
          });
        }
        break;

      case 6:
        demandeDTO.personnels.forEach((personnel, index) => {
          if (!personnelSimpleFichiers[index]) {
            errors.push(`Le dossier du personnel ${index + 1} (${personnel.nom} ${personnel.prenom}) est requis`);
          }
        });
        break;
    }

    if (errors.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Champs requis',
        html: errors.join('<br>'),
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Compléter les informations'
      });
      return false;
    }

    return true;
  };

  // Modification du handleNextStep
  const handleNextStep = () => {
    if (validateStep()) {
      setCurrentStep(currentStep + 1);
      setError(null);
    }
    // Pas de else nécessaire - l'utilisateur reste sur l'étape actuelle si la validation échoue
  };

  const handleFileChangeStructure = (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      const maxSize = 5 * 1024 * 1024; // 1 MB in bytes

      if (file.size > maxSize) {
          Swal.fire({
              icon: 'error',
              title: 'Fichier trop volumineux',
              text: 'La taille du fichier ne doit pas dépasser 5 Mo.',
          });
          return;
      }
    if (files.length > 0) {
      setFichierStructure(files[0]); // Mettez à jour l'état avec le fichier sélectionné
    }
  }
  };
  const [personnelFichiers, setPersonnelFichiers] = useState([]);
  const handleFileChangePersonnel = (index, event) => {
    const { files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      const maxSize = 1 * 1024 * 1024; // 1 MB in bytes

      if (file.size > maxSize) {
          Swal.fire({
              icon: 'error',
              title: 'Fichier trop volumineux',
              text: 'La taille du fichier ne doit pas dépasser 1 Mo.',
          });
          return;
      }
    if (files.length > 0) {
      setPersonnelSimpleFichiers((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[index] = files[0];
        setPersonnelFichiers(updatedFiles); // Assigner le fichier au bon index
        return updatedFiles;
      });
    }
  }
  };

    // Mise à jour de l'état `commune` lors de la sélection
    const handleCommuneChange = (event) => {
      const selectedCommuneId = event.target.value;
      setCommune(selectedCommuneId);
      setDemandeDTO(prev => ({
        ...prev,
        commune: {
          ...prev.commune,
          id: selectedCommuneId,
        },
      }));
    };
  // const [personnelTechniqueFichiers, setPersonnelTechniqueFichiers] = useState([]);
  const handleFileChangePersonnelRT = (index, event) => {
    const { files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      const maxSize = 2 * 1024 * 1024; // 1 MB in bytes

      if (file.size > maxSize) {
          Swal.fire({
              icon: 'error',
              title: 'Fichier trop volumineux',
              text: 'La taille du fichier ne doit pas dépasser 2 Mo.',
          });
          return;
      }
    if (files.length > 0) {
      setPersonnelTechniqueFichiers((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[index] = files[0];
        setPersonnelTechniqueFichiers(updatedFiles); // Assigner le fichier au bon index
        return updatedFiles;
      });
    } }
  };


  const downloadReceipt = (demandeId) => {
    const url = `${process.env.REACT_APP_API_URL}/demandes/telecharger-recipisse/${demandeId}`;

    // Création d'un lien a avec l'URL du récépissé
    const a = document.createElement("a");
    a.href = url;
    a.download = `recepisse_${demandeId}.pdf`; // Nom du fichier téléchargé

    // Ajout du lien au document et déclenchement du clic pour télécharger le fichier
    document.body.appendChild(a);
    a.click();

    // Suppression du lien du document
    document.body.removeChild(a);
  };

  useEffect(() => {
    if (userId && userData.nom) {
      setDemandeDTO({
        ...demandeDTO,
        utilisateur: {
          ...demandeDTO.utilisateur,
          id: userData.id
        }
      });
    }
  }, [userId, userData]);

  const handleRTChange = (index, field, value) => {
    setDemandeDTO(prev => {
      // Crée une copie de l'objet proprietaireRt
      const newproprietaireRt = [...prev.proprietaireRt];
      
      // Met à jour le champ spécifique pour l'index donné
      newproprietaireRt[index] = {
        ...newproprietaireRt[index],
        [field]: value
      };
      
      // Retourne le nouvel état avec la mise à jour
      return { ...prev, proprietaireRt: newproprietaireRt };
    });
  };



  const handlePersonnelChange = (index, field, value) => {
    setDemandeDTO(prev => {
      const newpersonnels = [...prev.personnels];
      newpersonnels[index] = {
        ...newpersonnels[index],
        [field]: value
      };
      return { ...prev, personnels: newpersonnels };
    });
  };

  const addPersonnel = () => {
    setDemandeDTO(prev => ({
      ...prev,
      personnels: [
        ...prev.personnels,
        {
          nom: "",
          prenom: "",
          nip: "",
          numOp: "",
          profession: "",

        }
      ]
    }));
  };

  const removePersonnel = (index) => {
    setDemandeDTO(prev => ({
      ...prev,
      personnels: prev.personnels.filter((_, idx) => idx !== index)
    }));
  };




  const addRT = () => {
    setDemandeDTO({
      ...demandeDTO,
      proprietaireRt: [
        ...demandeDTO.proprietaireRt,
        {

          nom: "",
          prenom: "",
          nip: "",
          dateNaissance: "",
          lieuNaissance: "",
          email: "",
          genre: "",
          tel: "",
          numOp: "",
          adresse: "",
          nationalite: "",
          profession: "",

        },
      ],
    });
  };



  const removeRT = (index) => {
    const updatedRT = [...demandeDTO.proprietaireRt];
    updatedRT.splice(index, 1);
    setDemandeDTO({
      ...demandeDTO,
      proprietaireRt: updatedRT,
    });
  };




  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Ajoutez cet état au début du composant avec les autres états
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Modification du handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation de l'étape courante uniquement
    if (!validateStep()) {
      return; // Reste sur l'étape actuelle si la validation échoue
    }

    const result = await Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Voulez-vous vraiment soumettre cette demande?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, soumettre!',
      cancelButtonText: 'Annuler'
    });

    if (!result.isConfirmed) {
      return; // Annuler l'envoi si l'utilisateur ne confirme pas
    }
    let timerInterval;
    Swal.fire({
      title: "Veuillez patienter...",
      html: "La soumission se terminera dans <b></b> millisecondes.", // Assurez-vous que <b></b> est bien présent ici
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        if (timer) { // Vérifiez que l'élément <b> existe avant de l'utiliser
          timerInterval = setInterval(() => {
            timer.textContent = `${Swal.getTimerLeft()}`;
          }, 100);
        }
      },
      willClose: () => {
        clearInterval(timerInterval);
      }
    });
    try {
      setIsSubmitting(true);
      setError(null);

      // Préparation des données
      const formData = new FormData();
      formData.append('demandeDTO', new Blob([JSON.stringify(demandeDTO)], { type: 'application/json' }));

      // Ajout des fichiers
      if (fichierStructure) {
        formData.append("fichierStructure", fichierStructure);
      }
      formData.append('type', type);
      // Ajout des fichiers RT
      personnelTechniqueFichiers.forEach((file, index) => {
        if (file) {
          formData.append(`personnelTechniqueFichiers`, file);
        }
      });

      // Ajout des fichiers du personnel
      personnelSimpleFichiers.forEach((file, index) => {
        if (file) {
          formData.append(`personnelSimpleFichiers`, file);
        }
      });
      for (let pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]);
      }

      // Envoi de la requête
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/demandes/creer-demande`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 201 || response.status === 200) {
        const demandeId = response.data.id;
        await Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: 'Votre demande a été créée avec succès !',
        });
        downloadReceipt(demandeId);
        navigate("/admin");
                // Arrêter le minuteur ici
                clearInterval(timerInterval);
                Swal.close(); // Fermer la fenêtre Swal
      }

    } catch (error) {
        if (error.response) {
          // Vérifier si l'erreur est due à une clé dupliquée
          if (error.response.data.message && error.response.data.message.includes("clé dupliquée")) {
            await Swal.fire({
              icon: 'error',
              title: 'Erreur de duplication',
              text: 'Un personnel avec ce NIP existe déjà. Veuillez vérifier et corriger les informations.',
            })
            
          } else {
            // Autres erreurs côté serveur
            await Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: error.response.data.message || "Erreur lors de la soumission",
              
            })
                    // Arrêter le minuteur ici
        clearInterval(timerInterval);
        Swal.close(); // Fermer la fenêtre Swal
          }
        }
    } finally {
      setIsSubmitting(false);
    }
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [showr, setShowr] = useState(false);
  const handlerShow = () => setShowr(true);
  const handleCloser = () => setShowr(false);


  const steps = ["Infos propriétaires ", "Informations Etablissement", "Responsables Techniques", "Pieces Jointes Responsables Techniques", "Personnels", "Pieces Jointes Personnels", "Récapitulatif"];
  const ProgressBar = ({ currentStep, steps }) => {
    return (
      <div className="progress-bar-container">
        {steps.map((_, index) => (
          <div key={index} className="step-container">
            <div className="line"></div>
            <div
              className={`circle ${index < currentStep ? "completed" : ""} ${index === currentStep - 1 ? "active" : ""
                }`}
            >
              {index < currentStep ? (
                <i className="fas fa-check"></i>
              ) : (
                index + 1
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };


  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // Calculez la largeur de chaque étape en pourcentage
  const stepWidth = 100 / steps.length;

  //contant declarations
  const [regionList, setRegionList] = useState([]);
  const regionUrl = `${process.env.REACT_APP_API_URL}/regions/list`;
  const [province, setProvince] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [arrondissementList, setArrondissementList] = useState([]);
  const [commune, setCommune] = useState([]);
  const [arrondissement, setArrondissement] = useState([]);
  const [communeList, setCommuneList] = useState([]);

  const [region, setRegion] = useState("");
  const getRegions = async () => {
    return await axios
      .get(regionUrl);
  };

  useEffect(() => {
    getRegions()
      .then(response => {
        setRegionList(response.data);
      })
      .catch(error => {
        console.error("Erreur d'obtention de la liste des regions :" + error)
      });
  }, []);
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  }

  const provUrl = `${process.env.REACT_APP_API_URL}/provinces/list/${region}`;
  const getProvinces = async () => {
    return await axios
      .get(provUrl);
  };
  useEffect(() => {
    if (region !== "Sélectionnez une région") {
      getProvinces()
        .then((response) => {
          setProvinceList(response.data);
        })
        .catch(error => {
          console.log("Erreur d'obtention de la liste des provinces: " + error)
        });
    }
  }, [getProvinces, region]);
  const handleProvinceChange = (event) => {
    setProvince(event.target.value);
  }
  const distUrl = `${process.env.REACT_APP_API_URL}/districts/list/${province}`;
  const getDistricts = async () => {
    return await axios.get(distUrl);
  };
  useEffect(() => {
    if (province !== "Sélectionnez une province") {
      getDistricts()
        .then((response) => {
          setDistrictList(response.data);
        })
        .catch(error => {
          console.log("Erreur d'obtention de la liste des districts: " + error)
        });
    }
  }, [province]);

  const arrUrl = `${process.env.REACT_APP_API_URL}/arrdts/list/${commune}`;
  const getArrondissements = async () => {
    return await axios.get(arrUrl);
  };
  useEffect(() => {
    if (commune !== "Sélectionnez une commune") {
      getArrondissements()
        .then((response) => {
          setArrondissementList(response.data);
        })
        .catch(error => {
          console.log("Erreur d'obtention de la liste des communes: " + error)
        });
    }
  }, [commune]);

  const handleArrondissementChange = (event) => {
    setArrondissement(event.target.value);
  }
  const handleDistrictChange = (event) => {
    setDistrict(event.target.value);
  }
  const comUrl = `${process.env.REACT_APP_API_URL}/communes/list/${district}`;
  const getCommunes = async () => {
    return await axios.get(comUrl);
  };
  useEffect(() => {
    if (district !== "Sélectionnez un district") {
      getCommunes()
        .then((response) => {
          setCommuneList(response.data);
        })
        .catch(error => {
          console.log("Erreur d'obtention de la liste des communes: " + error)
        });
    }
  }, [district]);

  const [typeEsp, setTypeEsp] = useState("");
  const [gesp, setGesp] = useState("");
  const [gespList, setGespList] = useState([]);

  const gespURL = `${process.env.REACT_APP_API_URL}/gesp/list/${typeEsp}`;
  const getGroupeEsps = () => {
    axios
      .get(gespURL)
      .then((response) => {
        setGespList(response.data);
      })
      .catch((error) => {
        console.log("Erreur d'obtention de la liste des groupe d'esp :" + error);
      });
  };
  useEffect(() => {
    if (typeEsp !== "Sélectionnez le Type d'ESP") {
      getGroupeEsps();
    }
  }, [typeEsp]);

  const handleGespChange = (event) => {
    setGesp(event.target.value);
  };

  const espURL = `${process.env.REACT_APP_API_URL}/esp/list/${gesp}`;
  const [esp, setEsp] = useState("");
  const [espList, setEspList] = useState([]);

  const getEsps = () => {
    axios
      .get(espURL)
      .then((response) => {
        setEspList(response.data);
      })
      .catch((error) => {
        console.log("Erreur d'obtention de la liste des Esp :" + error);
      });
  };
  useEffect(() => {
    if (gesp !== "Sélectionnez un groupe esp") {
      getEsps();
    }
  }, [gesp]
  );

  const handleEspChange = (event) => {
    setEsp(event.target.value);
  }


  useEffect(() => {
    // Si une erreur est définie, démarrez le minuteur pour la masquer après 3 secondes
    if (error) {
      const timer = setTimeout(() => {
        setError(''); // Réinitialise l'erreur après 3 secondes
      }, 3000);

      // Nettoyez le timer si le composant est démonté ou si l'erreur change
      return () => clearTimeout(timer);
    }
  }, [error]);


  return (

    <div id="page-top">
      <br></br>
      <br></br>
      <div id="wrapper">
        {/*
        <Header /> */}
        <div id="content-wrapper">
          <Navbar />
          <div id="content">
            <div className="col-md-12">
              <div className="form h-100">
                <h3 className="title-text">Demande d'Ouverture et D'exploitation</h3>


                <Form.Select
                  name="typeDemande"
                  value="OUVERTURE"
                  onChange={handleInputChange}
                  className="form-control"
                  required
                  hidden
                >


                  <option value="OUVERTURE">
                    Ouverture{" "}
                  </option>

                </Form.Select>


                <div>
                  <h4 className="titles-text">
                    {steps[currentStep - 1]}
                  </h4>


                  <br></br>

                  <ProgressBar currentStep={currentStep} steps={steps} />

                  <br></br>
                  <Form
                    onSubmit={handleSubmit}
                    className="mb-5"
                    noValidate
                  >
                    {currentStep === 2 && (
                      <>
                        <div className="row">
                          {/* Type ESP */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-6">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Groupe ESP <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select

                                name="typeEsp"
                                value={typeEsp}
                                onChange={e => setTypeEsp(e.target.value)}
                                className="form-control"
                                required
                              >
                                <option value="">Sélectionnez le Type d'ESP</option>
                                {/* <option value="CONVENTIONNE">Conventionné</option> */}
                                <option value="NonCONVENTIONNE">Non Conventionné</option>
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* Groupe ESP */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-6">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Catégorie ESP <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select

                                onChange={handleGespChange}
                                className="form-control"
                                required
                              >
                                <option value="">Sélectionnez un groupe esp</option>
                                {gespList.map((ges) => (
                                  <option value={ges.id} key={ges.id}>
                                    {ges.libelleGesp}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* ESP */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Type ESP <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select
                                name="esp.id"
                                value={demandeDTO.esp.id}
                                onChange={handleInputChange}
                                className="form-control"
                              >
                                <option value="">Sélectionnez un ESP</option>
                                {espList.map((esps) => (
                                  <option value={esps.id} key={esps.id}>
                                    {esps.libelleEsp}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* Région */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Région <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select

                                onChange={handleRegionChange}
                                className="form-control"
                                required
                              >
                                <option value="">Sélectionnez une région</option>
                                {regionList.map((reg) => (
                                  <option value={reg.id} key={reg.id}>
                                    {reg.libelleRegion}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* Province */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Province <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select

                                onChange={handleProvinceChange}
                                className="form-control"
                                required
                              >
                                <option value="">Sélectionnez une province</option>
                                {provinceList.map((prov) => (
                                  <option value={prov.id} key={prov.id}>
                                    {prov.intituleProvince}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* District */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                District <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select

                                onChange={handleDistrictChange}
                                className="form-control"
                                required
                              >
                                <option value="">Sélectionnez un district</option>
                                {districtList.map((dist) => (
                                  <option value={dist.id} key={dist.id}>
                                    {dist.intituleDistrict}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {/* Commune */}
                          <div className="col-md-6 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Communes <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Select
                                name="commune.id"
                                value={demandeDTO.commune.id || ""}  /* Ajout d'une valeur par défaut vide si id n'est pas défini */
                                onChange={handleCommuneChange}
                                className="form-control"
                              >
                                <option value="">Sélectionnez une commune</option>
                                {communeList.map((commune) => (
                                  <option value={commune.id} key={commune.id}>
                                    {commune.intituleCommune}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </div>

                          {arrondissementList.length > 0 && (
                            <div className="col-md-6 form-group mb-5">
                              <Form.Group className="mb-3">
                                <Form.Label className="col-form-label" style={{ color: "black" }}>
                                  Arrondissement <span style={{ color: "red" }}>(obligatoire)</span>
                                </Form.Label>
                                <Form.Select
                                  name="arrondissement.id"
                                  value={demandeDTO.arrondissement.id}
                                  onChange={handleInputChange}
                                  className="form-control"
                                >
                                  <option value="">Sélectionnez un arrondissement</option>
                                  {arrondissementList.map((arr) => (
                                    <option value={arr.id} key={arr.id}>
                                      {arr.intituleArrdt}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </div>
                          )}
                          {/* Nom de l'ESP */}
                          <div className={`form-group mb-5 ${arrondissementList.length > 0 ? 'col-md-12' : 'col-md-6'}`}>
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Nom de l'ESP <span style={{ color: "red" }}>(obligatoire)</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="nomEsp"
                                value={demandeDTO.nomEsp}
                                onChange={handleInputChange}
                                className={`form-control`}
                                required
                              />
                            </Form.Group>
                          </div>
                          {/* NUMERO de la convension sil ya */}
                      
                          {/* Dossier complet de l'ESP */}
                          <div className="col-md-12 form-group mb-5">
                            <Form.Group className="mb-3">
                              <Form.Label className="col-form-label" style={{ color: "black" }}>
                                Dossier complet de l'ESP{" "}
                                <span style={{ color: "blue" }}>(scanner en un seul fichier)</span>{" "}
                                <span style={{ color: "red" }}>(obligatoire et maximum 5Mo)</span>
                              </Form.Label>
                              <input
                                type="file"
                                className="form-control"
                                accept=".pdf"
                                name="fichierStructure"
                                onChange={handleFileChangeStructure}
                                required
                              />
                              <Button variant="info" onClick={handleShow} style={{ marginTop: "10px" }}>
                                Voir la liste des fichiers à charger
                              </Button>
                              {fichierStructure && (
                                <div style={{ marginTop: "10px" }}>
                                  <p>Fichier sélectionné : {fichierStructure.name}</p>
                                </div>
                              )}
                            </Form.Group>
                            {/* Modale pour afficher la liste des fichiers requis */}
                            <Modal show={show} onHide={handleClose} size="xl">
                              <Modal.Header closeButton>
                                <Modal.Title style={{ color: "black" }}><b>Liste des fichiers à charger (en 1 seul fichier pdf)</b></Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <ul style={{ color: "black" }}>
                                  <li>• une copie de l'arrêté d'autorisation de création délivrée  par le Ministre chargé de la santé en cours de validité ;</li>
                                  <li>• le procès-verbal de l'inspection du site, des locaux et équipements par les services compétents du Ministère en charge de la santé ;</li>
                                  <li>• une attestation d'assurance des locaux ;</li>
                                  <li>• une attestation d'assurance de responsabilité civile professionnelle ;</li>
                                  <li>• la liste du matériel technique et des équipements requis ;</li>
                                  <li>• Acte notarial ;</li>
                                  {/* <li>• le récépissé de reconnaissance d'existence à jour ou l'autorisation d'exercice/implantation au Burkina Faso pour la personne morale conventionnée ;</li> */}
                                  {/* <li>• la convention avec le Ministère en charge de la santé pour la personne morale conventionnée (page 1,2) ;</li> */}
                                  <li>• la justification d'immatriculation à un registre (RCCM ou registre des sociétés civiles ou des professions libérales) pour la personne morale non conventionnée ;</li>
                                  <li>• le certificat d'immatriculation Identifiant Financier Unique (IFU) ;</li>
                                </ul>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                  Fermer
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>

                        {/*<CustomButton onClick={handlePrevStep}>Étape précédente</CustomButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <CustomButton onClick={handleNextStep}>Étape suivante</CustomButton>*/}

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ textAlign: 'left' }}>
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handlePrevStep}>
                              <i className="bi bi-arrow-left"></i> {/* Icône de flèche à gauche */}
                              Précédent
                            </button>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handleNextStep}>
                              <i className="bi bi-arrow-right"></i> {/* Icône de flèche à droite */}
                              Suivant
                            </button>

                          </div>


                        </div>
                      </>
                    )}

                    {currentStep === 1 && (
                      <>

                        <FormStepMorale demandeDTO={demandeDTO} handleInputChange={handleInputChange} />

                        {/*<CustomButton onClick={handleNextStep}>
                          Étape suivante
                        </CustomButton>*/}

                        <div style={{ textAlign: 'right' }}>
                          {error && <div className="alert alert-danger">{error}</div>}
                          <button className="btn btn-primary" style={{ width: '215px' }} onClick={handleNextStep}>
                            <i className="bi bi-arrow-right"></i> {/* Icône de flèche à droite */}
                            Suivant
                          </button>

                        </div>

                      </>
                    )}


                    {currentStep === 3 && (
                      <>
                        <div className="row">
                          <div className="col-md-12 form-group mb-5">
                            <b>
                              <label className="col-form-label" style={{ color: "black" }}>
                                Charger un fichier Excel <span style={{ color: "blue" }}>(charger le fichier Excel contenant les informations de votre personnel)</span>
                              </label>
                            </b>
                            <input
                              className="form-control"
                              type="file"
                              accept=".xlsx, .xls"
                              onChange={handleFileXlsRTUpload}
                            />

                            {/* Bouton pour télécharger le modèle Excel */}
                            <a
                              href="/test_responsable.xlsx"
                              download="Modele_Responsable_Techniques.xlsx"
                              className="btn btn-primary mt-3"
                              textTransform='none'
                            >
                              Télécharger le modèle Excel
                            </a>
                          </div>

                          <div>
                            {Array.isArray(demandeDTO.proprietaireRt) &&
                              demandeDTO.proprietaireRt.map((membre, index) => (

                                <div className="row" key={index}>
                                  <div className="col-md-12 form-group mb-5">
                                    {" "}
                                    <h3>Responsable Techniques {index + 1}</h3>
                                  </div>
                                  <div className="col-md-6 form-group mb-5">
                                    <label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                    <b> Nom{" "}
                                      <span style={{ color: "red" }}>
                                       (obligatoire)
                                      </span></b>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      value={membre.nom}
                                      onChange={(e) =>
                                        handleRTChange(
                                          index,
                                          "nom",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Nom"
                                      className="form-control"
                                      id={`nom_${index}`}
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 form-group mb-5">
                                    <label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                      <b>Prénom(s){" "}
                                      <span style={{ color: "red" }}>
                                       (obligatoire)
                                      </span></b>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      value={membre.prenom}
                                      onChange={(e) =>
                                        handleRTChange(
                                          index,
                                          "prenom",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Prenom "
                                      className="form-control"
                                      id={`prenom_${index}`}
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 form-group mb-5">
                                    <Form.Group className="mb-3">
                                      <Form.Label
                                        className="col-form-label"
                                        style={{ color: "black" }}
                                      >
                                       <b> Genre{" "}
                                        <span style={{ color: "red" }}>(obligatoire)</span> </b>
                                      </Form.Label>

                                      <Form.Select

                                        value={membre.genre}
                                        onChange={(e) =>
                                          handleRTChange(
                                            index,
                                            "genre",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                        id={`genre_${index}`}
                                        required
                                      >
                                        <option value="">
                                          Sélectionnez le genre{" "}
                                        </option>
                                        <option value="HOMME">
                                          Homme{" "}
                                        </option>
                                        <option value="FEMME">
                                          Femme{" "}
                                        </option>

                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div className="col-md-6 form-group mb-5">
                                    <label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                       <b>Date de naissance{" "}
                                      <span style={{ color: "red" }}>
                                       (obligatoire)
                                      </span></b>
                                    </label>
                                    <Form.Control
                                      type="date"
                                      value={membre.dateNaissance}
                                      onChange={(e) =>
                                        handleRTChange(
                                          index,
                                          "dateNaissance",
                                          e.target.value
                                        )
                                      }

                                      className="form-control"
                                      id={`dateNaissance_${index}`}
                                      max="2006-12-31"        // Empêche la sélection au-delà de 2006
                                      defaultValue="2006-01-01"  // Définir l'année de début comme 2006
                                      // onKeyDown={(e) => e.preventDefault()} // Empêche la saisie manuelle
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 form-group mb-5">
                                    <label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                      <b> Lieu de naissance{" "}
                                      <span style={{ color: "red" }}>
                                       (obligatoire)
                                      </span></b>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      value={membre.lieuNaissance}
                                      onChange={(e) =>
                                        handleRTChange(
                                          index,
                                          "lieuNaissance",
                                          e.target.value
                                        )
                                      }

                                      className="form-control"
                                      id={`lieuNaissance_${index}`}
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 form-group mb-5">
                                    <label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                     <b> Numéro Nip de la CNIB{" "}
                                      <span style={{ color: "red" }}>
                                       (obligatoire)
                                      </span></b>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      value={membre.nip}
                                      onChange={(e) =>
                                        handleRTChange(
                                          index,
                                          "nip",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Numéro NIP de la CNIB"
                                      className="form-control"
                                      maxLength={17}
                                      id={`nip_${index}`}
                                      required
                                    />
                                  </div>
                 
                                  <div className="col-md-6 form-group mb-5">
                                    <label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                      <b>Numéro de téléphone (ex: +22670XXXXXX) {" "}
                                        <span style={{ color: "red" }}>(obligatoire)</span></b>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      value={membre.tel}
                                      onChange={(e) =>
                                        handleRTChange(
                                          index,
                                          "tel",
                                          e.target.value.replace(/[^\d+ ]/g, '') // Supprime tous les caractères non numériques sauf + et espace
                                        )
                                      }
                                      placeholder="+XXXXXXXXXXX"
                                      className="form-control"
                                      id={`tel_${index}`}
                                      required
                                    />
                                  </div>

                                  <div className="col-md-6 form-group mb-5">
                                    <label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                     <b> Profession{" "}
                                      <span style={{ color: "red" }}>
                                       (obligatoire)
                                      </span></b>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      value={membre.profession}
                                      onChange={(e) =>
                                        handleRTChange(
                                          index,
                                          "profession",
                                          e.target.value
                                        )
                                      }
                                      placeholder="profession"
                                      className="form-control"
                                      id={`profession_${index}`}
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 form-group mb-5">
                                    <label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                     <b> Numéro d'Ordre Professionel{" "}
                                      <span style={{ color: "red" }}>
                                       (obligatoire)
                                      </span></b>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      value={membre.numOp}
                                      onChange={(e) =>
                                        handleRTChange(
                                          index,
                                          "numOp",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Numéro d'Ordre professionel"
                                      className="form-control"
                                      id={`numOp_${index}`}
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 form-group mb-5">
                                    <label className="col-form-label" style={{ color: "black" }}>
                                    <b> Nationalité <span style={{ color: "red" }}>(obligatoire)</span></b>
                                    </label>
                                    <Form.Select
                                      value={membre.nationalite}
                                      onChange={(e) =>
                                        handleRTChange(index, "nationalite", e.target.value)
                                      }
                                      className="form-control"
                                      id={`nationalite_${index}`}
                                      required
                                    >
                                      <option value="">Sélectionner une nationalité</option>
                                      <option value="Américaine">Américaine</option>
                                      <option value="Algérien">Algérien</option>
                                      <option value="Allemande">Allemande</option>
                                      <option value="Angolais">Angolais</option>
                                      <option value="Béninois">Béninois</option>
                                      <option value="Botswanais">Botswanais</option>
                                      <option value="Burkinabè">Burkinabè</option>
                                      <option value="Burundais">Burundais</option>
                                      <option value="Canadienne">Canadienne</option>
                                      <option value="Camerounais">Camerounais</option>
                                      <option value="Cap-Verdien">Cap-Verdien</option>
                                      <option value="Centrafricain">Centrafricain</option>
                                      <option value="Comorien">Comorien</option>
                                      <option value="Congolais (Congo-Brazzaville)">Congolais (Congo-Brazzaville)</option>
                                      <option value="Congolais (Congo-Kinshasa)">Congolais (Congo-Kinshasa)</option>
                                      <option value="Djiboutien">Djiboutien</option>
                                      <option value="Espagnole">Espagnole</option>
                                      <option value="Égyptien">Égyptien</option>
                                      <option value="Érythréen">Érythréen</option>
                                      <option value="Eswatinien">Eswatinien</option>
                                      <option value="Éthiopien">Éthiopien</option>
                                      <option value="Française">Française</option>
                                      <option value="Gabonnais">Gabonnais</option>
                                      <option value="Gambien">Gambien</option>
                                      <option value="Ghanéen">Ghanéen</option>
                                      <option value="Guinéen">Guinéen</option>
                                      <option value="Guinéen équatorien">Guinéen équatorien</option>
                                      <option value="Ivoirien">Ivoirien</option>
                                      <option value="Italienne">Italienne</option>
                                      <option value="Kenyan">Kenyan</option>
                                      <option value="Lesothan">Lesothan</option>
                                      <option value="Libérien">Libérien</option>
                                      <option value="Libyen">Libyen</option>
                                      <option value="Malagasy">Malagasy</option>
                                      <option value="Malawite">Malawite</option>
                                      <option value="Malien">Malien</option>
                                      <option value="Marocain">Marocain</option>
                                      <option value="Mauricien">Mauricien</option>
                                      <option value="Mauritanien">Mauritanien</option>
                                      <option value="Mozambicain">Mozambicain</option>
                                      <option value="Namibien">Namibien</option>
                                      <option value="Nigerien">Nigerien</option>
                                      <option value="Nigérian">Nigérian</option>
                                      <option value="Rwandais">Rwandais</option>
                                      <option value="Sao-Toméen">Sao-Toméen</option>
                                      <option value="Sénégalais">Sénégalais</option>
                                      <option value="Seychellois">Seychellois</option>
                                      <option value="Sierra-Léonais">Sierra-Léonais</option>
                                      <option value="Somalien">Somalien</option>
                                      <option value="Soudanais">Soudanais</option>
                                      <option value="Sud-Africain">Sud-Africain</option>
                                      <option value="Sud-Soudanais">Sud-Soudanais</option>
                                      <option value="Tanzanien">Tanzanien</option>
                                      <option value="Tchadien">Tchadien</option>
                                      <option value="Togolais">Togolais</option>
                                      <option value="Tunisien">Tunisien</option>
                                      <option value="Zambien">Zambien</option>
                                      <option value="Zimbabwéen">Zimbabwéen</option>
                                      {/* Ajoute ici d'autres options de nationalité */}
                                    </Form.Select>
                                  </div>
                                  <div className="col-md-12 form-group mb-5">
                                    <label
                                      className="col-form-label"
                                      style={{ color: "black" }}
                                    >
                                    <b>  Lieu de résidence{" "}
                                      <span style={{ color: "red" }}>
                                       (obligatoire)
                                      </span></b>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      value={membre.adresse}
                                      onChange={(e) =>
                                        handleRTChange(
                                          index,
                                          "adresse",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Lieu de résidence"
                                      className="form-control"
                                      id={`adresse_${index}`}
                                      required
                                    />
                                  </div>


                                  <div className="col-md-12 form-group mb-5 d-flex justify-content-center">
                                    <div className="d-flex align-items-center mr-3">
                                    <Button
                                        variant="danger"
                                        onClick={() => removeRT(index)}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding: '10px 20px',
                                          borderRadius: '5px',
                                          transition: 'background-color 0.3s, border 0.3s',
                                        }}
                                        disabled={index === 0} // Désactiver le bouton si index est 0
                                      >
                                        <i className="fas fa-trash-alt" style={{ marginRight: "5px" }}></i>
                                        Supprimer le Responsable Technique
                                      </Button>
                                    </div>

                                    <div className="d-flex align-items-center">
                                      <Button
                                        variant="success"
                                        onClick={addRT}
                                        className="d-flex align-items-center"
                                        style={{
                                          padding: '10px 20px',
                                          borderRadius: '5px',
                                          transition: 'background-color 0.3s, border 0.3s',
                                        }}
                                      >
                                        <i className="fas fa-plus" style={{ marginRight: "5px" }}></i>
                                        Ajouter un Responsable Technique
                                      </Button>
                                    </div>
                                  </div>

                                </div>
                              )
                              )}


                          </div>
                          <br /><br />    <br /><br />
                        </div>
                        {/*<CustomButton onClick={handlePrevStep}>
                          Étape précédente
                        </CustomButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <CustomButton onClick={handleNextStep}>
                          Suivant
                        </CustomButton>*/}

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ textAlign: 'left' }}>
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handlePrevStep}>
                              <i className="bi bi-arrow-left"></i> {/* Icône de flèche à gauche */}
                              Précédent
                            </button>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handleNextStep}>
                              <i className="bi bi-arrow-right"></i> {/* Icône de flèche à droite */}
                              Suivant
                            </button>

                          </div>


                        </div>
                      </>
                    )}

                    {currentStep === 4 && (
                      <>
                        <Button variant="info" onClick={handlerShow} style={{ marginTop: "70px" }}>
                          Voir la liste des fichiers à charger
                        </Button>
                        {demandeDTO.proprietaireRt.map((membreResponsable, index) => (

                          <div key={index} className="col-md-12 form-group mb-5">
                            <label
                              className="col-form-label"
                              style={{ color: "black" }}
                            ><b> Dossier Responssable Technique {index + 1} <span style={{ color: "blue" }}>({membreResponsable.nom} {membreResponsable.prenom})</span>  <span style={{ color: "red" }}>(obligatoire et maximum 2Mo)</span>
                              </b></label>
                            <Form.Control className="form-control"
                              type="file"
                              name={`personnelTechniqueFichiers_${index}`} // Utiliser la même clé pour tous les fichiers
                              onChange={(e) => handleFileChangePersonnelRT(index, e)} // L'index peut toujours être utilisé pour référencer chaque fichier individuellement dans la fonction
                              accept=".pdf"
                            />
                            {personnelTechniqueFichiers[index] && (
                              <div style={{ marginTop: "10px" }}>
                                <p>Fichier sélectionné : {personnelTechniqueFichiers[index].name}</p>
                              </div>
                            )}
                          </div>

                        ))}
                        {/* Modale pour afficher la liste des fichiers requis */}
                        <Modal show={showr} onHide={handleCloser} size="xl">
                          <Modal.Header closeButton>
                            <Modal.Title style={{ color: "black" }}><b>Liste des fichiers à charger (en 1 seul fichier pdf) pour chaque responsable technique</b></Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <ul style={{ color: "black" }}>
                              <li>- un casier judiciaire datant de moins de trois mois  ;</li>
                              <li>- une attestation d'inscription à l'ordre professionnel en cours de validité ;</li>
                              <li>- une attestation de non-engagement dans la fonction publique ou un arrêté de démission ou une cessation de service accompagnée d'un arrêté de mise en disponibilité ou un arrêté de mise à la retraite.</li>
                            </ul>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloser}>
                              Fermer
                            </Button>
                          </Modal.Footer>
                        </Modal>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ textAlign: 'left' }}>
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handlePrevStep}>
                              <i className="bi bi-arrow-left"></i> Précédent
                            </button>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                            {error && <div className="alert alert-danger">{error}</div>}

                            {/* Ne montrer le bouton "Suivant" que si tous les fichiers sont chargés */}
                            {personnelTechniqueFichiers.length === demandeDTO.proprietaireRt.length &&
                              personnelTechniqueFichiers.every(file => file !== null && file !== undefined) && (
                                <button className="btn btn-primary" style={{ width: '215px' }} onClick={handleNextStep}>
                                  <i className="bi bi-arrow-right"></i> Suivant
                                </button>
                              )}
                          </div>
                        </div>


                      </>
                    )}


                    {currentStep === 5 && (
                      <>
                        <div className="row">
                          {/* Ajout d'un bouton de téléchargement du fichier Excel */}
                          <div className="col-md-12 form-group mb-5">
                            <b>
                              <label className="col-form-label" style={{ color: "black" }}>
                                Charger un fichier Excel <span style={{ color: "blue" }}>(charger le fichier Excel contenant les informations de votre personnel)</span>
                              </label>
                            </b>
                            <input
                              className="form-control"
                              type="file"
                              accept=".xlsx, .xls"
                              onChange={handleFileXlsUpload}
                            />

                            {/* Bouton pour télécharger le modèle Excel */}
                            <a
                              href="/test_personnel.xlsx"
                              download="Modele_Personnel.xlsx"
                              className="btn btn-primary mt-3"
                              textTransform='none'
                            >
                              Télécharger le modèle Excel
                            </a>
                          </div>
                          {/* <ExcelUploadComponent/> */}

                          <div>
                            {demandeDTO.personnels.map((membrePersonnel, index) => (
                              <div className="row" key={index}>


                                <div className="col-md-12 form-group mb-5">
                                  {" "}
                                  <h3 style={{ color: "black" }}>Personnel {index + 1}</h3>
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                   <b> Nom{" "}
                                    <span style={{ color: "red" }}>
                                      (obligatoire)
                                    </span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    value={membrePersonnel.nom}
                                    onChange={(e) =>
                                      handlePersonnelChange(
                                        index,
                                        "nom",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Nom"
                                    className="form-control"
                                    id={`nom_${index}`}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                   <b> Prénom(s){" "}
                                    <span style={{ color: "red" }}>
                                    (obligatoire)
                                    </span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    value={membrePersonnel.prenom}
                                    onChange={(e) =>
                                      handlePersonnelChange(
                                        index,
                                        "prenom",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Prenom "
                                    className="form-control"
                                    id={`prenom_${index}`}
                                    required
                                  />
                                </div>

                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                   <b> Numéro Nip de la CNIB{" "}
                                    <span style={{ color: "red" }}>
                                    (obligatoire)
                                    </span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    value={membrePersonnel.nip}
                                    onChange={(e) =>
                                      handlePersonnelChange(
                                        index,
                                        "nip",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Numéro NIP de la CNIB"
                                    className="form-control"
                                    maxLength={17}
                                    id={`nip_${index}`}
                                    required
                                  />
                                </div>


                                <div className="col-md-6 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b>Profession{" "}
                                    <span style={{ color: "red" }}>
                                      (obligatoire)
                                    </span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    value={membrePersonnel.profession}
                                    onChange={(e) =>
                                      handlePersonnelChange(
                                        index,
                                        "profession",
                                        e.target.value
                                      )
                                    }
                                    placeholder="profession"
                                    className="form-control"
                                    id={`profession_${index}`}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 form-group mb-5">
                                  <label
                                    className="col-form-label"
                                    style={{ color: "black" }}
                                  >
                                    <b>Numéro d'Ordre Professionel{" "}
                                    <span style={{ color: "red" }}>

                                    </span></b>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    value={membrePersonnel.numOp}
                                    onChange={(e) =>
                                      handlePersonnelChange(
                                        index,
                                        "numOp",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Numéro de l'ordre professionel"
                                    className="form-control"
                                    id={`numOp_${index}`}
                                    required
                                  />
                                </div>




                                <div className="col-md-12 form-group mb-5 d-flex justify-content-center">
                                  <div className="d-flex align-items-center mr-3">
                                    <Button
                                      variant="danger"
                                      onClick={() => removePersonnel(index)}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: '10px 20px',
                                        borderRadius: '5px',
                                        transition: 'background-color 0.3s, border 0.3s',
                                      }}
                                      disabled={index === 0} 
                                    >
                                      <i className="fas fa-trash-alt" style={{ marginRight: "5px" }}></i>
                                      Supprimer le Personnel
                                    </Button>
                                  </div>

                                  <div className="d-flex align-items-center">
                                    <Button
                                      variant="success"
                                      onClick={addPersonnel}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: '10px 20px',
                                        borderRadius: '5px',
                                        transition: 'background-color 0.3s, border 0.3s',
                                      }}
                                    >
                                      <i className="fas fa-plus" style={{ marginRight: "5px" }}></i>
                                      Ajouter un Personnel
                                    </Button>
                                  </div>
                                </div>


                              </div>
                            )
                            )}


                          </div>
                          <br /><br />    <br /><br />
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ textAlign: 'left' }}>
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handlePrevStep}>
                              <i className="bi bi-arrow-left"></i> {/* Icône de flèche à gauche */}
                              Précédent
                            </button>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handleNextStep}>
                              <i className="bi bi-arrow-right"></i> {/* Icône de flèche à droite */}
                              Suivant
                            </button>

                          </div>


                        </div>

                      </>
                    )}

                    {currentStep === 6 && (
                      <>
                        <Button variant="info" onClick={handleShow} style={{ marginTop: "70px" }}>
                          Voir la liste des fichiers à charger
                        </Button>
                        {demandeDTO.personnels.map((membrePersonnel, index) => (
                          <div key={index} className="col-md-12 form-group mb-5">
                            <b> <label>Dossier Personnel {index + 1} <span style={{ color: "blue" }}>({membrePersonnel.nom} {membrePersonnel.prenom})</span>  <span style={{ color: "red" }}>(obligatoire et maximum 1Mo)</span>
                                      </label></b>
                            <Form.Control
                              className="form-control"
                              type="file"
                              name={`personnelSimpleFichiers_${index}`}
                              onChange={(e) => handleFileChangePersonnel(index, e)}
                              accept=".pdf"
                            />
                            {/* Afficher le nom du fichier s'il est sélectionné */}
                            {personnelFichiers[index] && (
                              <div style={{ marginTop: "10px" }}>
                                <p>Fichier sélectionné : {personnelFichiers[index].name}</p>
                              </div>
                            )}
                          </div>
                        ))}
                        {/* Modale pour afficher la liste des fichiers requis */}
                        <Modal show={show} onHide={handleClose} size="xl">
                          <Modal.Header closeButton>
                            <Modal.Title style={{ color: "black" }}><b>Liste des fichiers à charger (en 1 seul fichier pdf) pour chaque personnel</b></Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <ul style={{ color: "black" }}>
                              <li>- Une copie légalisée de la carte nationale d'identité burkinabè ou du passeport en cours de validité ;</li>
                              <li>- les photocopies légalisées des diplômes ;</li>
                              <li>- une attestation d'inscription à l'ordre professionnel en cours de validité ;</li>
                              <li>- une attestation de non-engagement dans la fonction publique ou un arrêté de démission ou une cessation de service accompagnée d'un arrêté de mise en disponibilité ou un arrêté de mise à la retraite.</li>
                            </ul>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Fermer
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        {/* Navigation buttons */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ textAlign: 'left' }}>
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handlePrevStep}>
                              <i className="bi bi-arrow-left"></i> Précédent
                            </button>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                            {error && <div className="alert alert-danger">{error}</div>}

                            {/* Ne montrer le bouton "Suivant" que si tous les fichiers sont chargés */}
                            {personnelSimpleFichiers.length === demandeDTO.personnels.length &&
                              personnelSimpleFichiers.every(file => file !== null && file !== undefined) && (
                                <button className="btn btn-primary" style={{ width: '215px' }} onClick={handleNextStep}>
                                  <i className="bi bi-arrow-right"></i> Suivant
                                </button>
                              )}
                          </div>
                        </div>
                      </>
                    )}

                    {currentStep === 7 && (
                      <>
                        <div className="row">
                          <div className="col-md-12 form-group mb-5">
                            <div className="instruction-container mb-4 p-3">
                              <div className="d-flex align-items-center justify-content-center mb-2">
                                <i className="fas fa-fw fa-shield-alt"> </i>
                                <h4 className="mb-0"> Conseils</h4>
                              </div>

                              <div style={{ textAlign: "center" }}>
                                Merci de vérifier que toutes les informations saisies sont correctes avant de soumettre la demande. <br />
                               Une fois la demande soumise, il ne vous sera plus possible d'apporter des modifications.
                              </div>
                            </div>

                            {/* Informations de la demande */}
                            <table className="table">
                              <thead>
                                <tr>
                                  <th colSpan="2" style={{ textAlign: "center", color: "white" }}>
                                    Informations de la demande
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr style={{ color: "black" }}>
                                  <td><b>Type de Demande:</b></td>
                                  <td>{demandeDTO.typeDemande}</td>
                                </tr>
                                <tr style={{ color: "black" }}>
  <td><b>Type Etablissement sanitaire de soins :</b></td>
  {/* <td>{demandeDTO.esp && demandeDTO.esp.libelleEsp ? demandeDTO.esp.libelleEsp : "Non défini"}</td> */}
  <td>  {demandeDTO.esp.id
                                    ? (espList.find(esps => esps.id === parseInt(demandeDTO.esp.id, 10))?.libelleEsp || "Etablissement non trouvé")
                                    : "Aucune Etablissement sélectionné"}
                                  </td>
</tr>
                                <tr style={{ color: "black" }}>
                                  <td><b>Etablissement sanitaire de soins :</b></td>
                                  <td>{demandeDTO.nomEsp}</td>
                                </tr>
                                <tr style={{ color: "black" }}>
                                  <td><b>Catégorie établissement sanitaire de soins :</b></td>
                                  <td>{typeEsp === "NonCONVENTIONNE" ? "non conventionné" : typeEsp}</td>
                                </tr>
                                <tr style={{ color: "black" }}>
                                  <td>Commune : </td>
                                  {/* Récupérer l'ID de la commune et chercher son nom */}
                                  <td>         {demandeDTO.commune.id
                                    ? (communeList.find(commune => commune.id === parseInt(demandeDTO.commune.id, 10))?.intituleCommune || "Commune non trouvée")
                                    : "Aucune commune sélectionnée"}
                                  </td>
                                </tr>
                                <tr style={{ color: "black" }}>
                                  <td><b>Arrondissement :</b></td>
                                  {/* Récupérer l'ID de la commune et chercher son nom */}
                                      <td>  {demandeDTO.arrondissement.id
                                    ? (arrondissementList.find(arrondissement => arrondissement.id === parseInt(demandeDTO.arrondissement.id, 10))?.intituleArrdt || "Arrondissement non trouvé")
                                    : "Aucun arrondissement sélectionné"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            {/* Informations sur le demandeur */}
                            <table className="table">
                              <thead>
                                <tr>
                                  <th colSpan="2" style={{ textAlign: "center", color: "white" }}>
                                    Informations demandeur (Personne Morale)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr style={{ color: "black" }}>
                                  <td><b>Nom de la structure :</b></td>
                                  <td>{demandeDTO.nomStructure}</td>
                                </tr>
                                <tr style={{ color: "black" }}>
                                  <td><b>Numero d'Arrêté de création :</b></td>
                                  <td>{demandeDTO.numArreteCreation}</td>
                                </tr>
                                {/* <tr style={{ color: "black" }}>
                                  <td><b>Numero de la convention :</b></td>
                                  <td>{demandeDTO.numConvention}</td>
                                </tr> */}
                              </tbody>
                            </table>

                            {/* Tableau Informations des Responsables Techniques */}
                            <table className="table">
                              <thead>
                                <tr>
                                  <th colSpan="10" style={{ textAlign: "center", color: "white" }}>
                                    Informations sur les responsables techniques
                                  </th>
                                </tr>
                                <tr style={{ color: "black", backgroundColor: "#f1f1f1" }}>
                                  <th>Nom</th>
                                  <th>Prénom</th>
                                  {/* <th>Email</th> */}
                                  <th>Téléphone</th>
                                  <th>NIP</th>
                                  <th>Date et Lieu de Naissance</th>
                                  <th>Adresse</th>
                                  <th>Nationalité</th>
                                  <th>Profession</th>
                                </tr>
                              </thead>
                              <tbody>
                                {demandeDTO && demandeDTO.proprietaireRt && demandeDTO.proprietaireRt.length > 0 ? (
                                  demandeDTO.proprietaireRt.map((responsable, index) => (
                                    <tr key={index} style={{ color: "black" }}>
                                      <td>{responsable.nom}</td>
                                      <td>{responsable.prenom}</td>
                                      {/* <td>{responsable.email}</td> */}
                                      <td>{responsable.tel}</td>
                                      <td>{responsable.nip}</td>
                                      <td>{responsable.dateNaissance} {responsable.lieuNaissance}</td>
                                      <td>{responsable.adresse}</td>
                                      <td>{responsable.nationalite}</td>
                                      <td>{responsable.profession}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="9" style={{ textAlign: "center", color: "black" }}>
                                      Aucune information sur les Responsables Techniques disponible
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>

                            {/* Tableau Informations du personnel */}
                            <table className="table table-bordered mt-4">
                              <thead>
                                <tr>
                                  <th colSpan="8" style={{ textAlign: "center", color: "white" }}>
                                    Informations du personnel
                                  </th>
                                </tr>
                                <tr style={{ color: "black", backgroundColor: "#f1f1f1" }}>
                                  <th>Nom</th>
                                  <th>Prénom</th>
                                  <th>NIP</th>
                                  <th>numOp</th>
                                  <th>Profession</th>
                                </tr>
                              </thead>
                              <tbody>
                                {demandeDTO && demandeDTO.personnels && demandeDTO.personnels.length > 0 ? (
                                  demandeDTO.personnels.map((person, index) => (
                                    <tr key={index} style={{ color: "black" }}>
                                      <td>{person.nom}</td>
                                      <td>{person.prenom}</td>
                                      <td>{person.nip}</td>
                                      <td>{person.numOp}</td>
                                      <td>{person.profession}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="8" style={{ textAlign: "center", color: "black" }}>
                                      Aucune information sur le personnel disponible
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>

                          </div>
                          {/*<div className="col-md-6 form-group mb-5">
                            <CustomButton onClick={handlePrevStep}>
                              Étape précédente
                            </CustomButton>
                          </div>
                          <div className="col-md-6 form-group mb-5">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <CustomButton type="submit">Envoyer</CustomButton>
                          </div>*/}


                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ textAlign: 'left' }}>
                            <button className="btn btn-primary" style={{ width: '215px' }} onClick={handlePrevStep}>
                              <i className="bi bi-arrow-left"></i> {/* Icône de flèche à gauche */}
                              Précédent
                            </button>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                          <button 
        type="submit" 
        className="btn btn-primary" 
        style={{ width: '215px' }}
        disabled={isSubmitting} // Désactive le bouton pendant le chargement
      >
        {isSubmitting ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            {' '}Soumission ...
          </>
        ) : (
          <>
            <i className="bi bi-save"></i> Soumettre
          </>
        )}
      </button>
                          </div>


                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>


  );
}



export default Form_dmd_dsps_morale;