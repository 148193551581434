import React, { useState, useEffect } from "react";
import axios from "axios";

import { Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../Header";

function Edit_typ_dmd() {
  const editepieceURL = `${process.env.REACT_APP_API_URL}/api/param-type-pieces`;
  const [utilisateurs, setTypeDemande] = useState([]);
  const setUtilisateursData = () => {
    axios
      .get(editepieceURL)
      .then((response) => {
        setTypeDemande(response.data);
      })
      .catch((error) => {
        alert("Erreur d'obtention des données :" + error);
      });
  };
  useEffect(() => {
    setUtilisateursData();
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const editURL = `${process.env.REACT_APP_API_URL}/api/param-type-demandes/`;
  const [intitule, setIntitule] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [requirePayement, setRequirePayement] = useState("");
  const [sous_type, setIntitules] = useState("");
  const [paramTypePieces, setparamTypePiece] = useState([]);
  const param = useParams();
  useEffect(() => {
    axios
      .get(editURL + param.id)
      .then((response) => {
        const empData = response.data;
        setIntitule(empData.intitule);
        setCode(empData.code);
        setRequirePayement(empData.requirePayement);
        setIntitules(empData.sous_type);
        setDescription(empData.description);
        setparamTypePiece(empData.paramTypePieces);
      })
      .catch((error) => {
        alert("erreur pour trouver les details :" + error);
      });
  }, []);
  const handlePieceChange = (e, pieceId) => {
    const checked = e.target.checked;
    setPiece((prevPiece) => {
      if (checked) {
        // Ajouter l'ID de la pièce sélectionnée au tableau piece
        return [...prevPiece, pieceId];
      } else {
        // Retirer l'ID de la pièce décochée du tableau piece
        return prevPiece.filter((id) => id !== pieceId);
      }
    });
  };
  const [piece, setPiece] = useState([]);

  useEffect(() => {
    // Utilisez paramTypePieces ou tout autre mécanisme pour initialiser piece
    const initialPieces = paramTypePieces.map((piece) => piece.id);
    setPiece(initialPieces);
  }, [paramTypePieces]);

  const [nivovalid, setparamNiveauValidation] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(editURL + param.id, {
        intitule: intitule,
        description: description,
        code: code,
        requirePayement: requirePayement,
        sous_type: sous_type,
        paramTypePieces: piece.map((pieceId) => ({
          id: pieceId,
        })),
        paramNiveauValidation: nivovalid.map((nivoId) => ({
          id: nivoId,
        })),
      })

      .then((response) => {
        alert(
          " " + intitule + " " + sous_type + " a été modifiée avec succès !!"
        );
        navigate("/liste_dmd_t");
      })
      .catch((error) => {
        alert("Erreur : " + error);
      });
  };

  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          <div className="col-md-2">{<Header />}</div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-4 col-md-12">
                <div className="card info-card sales-card">
                  <div className="container">
                    <div className="row align-items-stretch no-gutters contact-wrap">
                      <div className="col-md-10">
                        <div className="form h-100">
                          <h2 className="title-text">Editer type de demande</h2>
                          <Form
                            onSubmit={handleSubmit}
                            className="mb-5"
                            noValidate
                          >
                            <div className="row">
                              <div className="col-md-6 form-group mb-6">
                                <label className="col-form-label">
                                  Intitulé de la demande*
                                </label>
                                <Form.Control
                                  type="text"
                                  value={intitule}
                                  onChange={(e) => setIntitule(e.target.value)}
                                  className="form-control"
                                  id="yourEmail"
                                  required
                                />
                              </div>
                              <div className="col-md-6 form-group mb-5">
                                <label className="col-form-label">
                                  Code du type de demande
                                </label>
                                <Form.Control
                                  type="text"
                                  value={code}
                                  onChange={(e) => setCode(e.target.value)}
                                  className="form-control"
                                  id="yourEmail"
                                  required
                                />
                              </div>
                              <div className="col-md-12 form-group mb-5">
                                <label className="col-form-label">
                                  Description *
                                </label>
                                <Form.Control
                                  type="text"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  className="form-control"
                                  id="yourEmail"
                                  required
                                />
                              </div>

                              <div className="col-md-6 form-group mb-5">
                                <label
                                  className="col-form-label"
                                  style={{ color: "black" }}
                                >
                                  intitulé de sous type *
                                </label>
                                <Form.Control
                                  type="text"
                                  value={sous_type}
                                  onChange={(e) => setIntitules(e.target.value)}
                                  aria-required
                                />
                              </div>
                              <div className="col-md-6 form-group mb-5">
                                <label
                                  className="col-form-label"
                                  style={{ color: "black" }}
                                >
                                  Paiement sur la plateforme *
                                </label>
                                <select
                                  value={requirePayement}
                                  onChange={(e) =>
                                    setRequirePayement(e.target.value)
                                  }
                                  className="form-control"
                                  id="service"
                                  required
                                >
                                  <option value="">Sélectionnez </option>
                                  <option value="true">OUI</option>
                                  <option value="false">Non</option>
                                </select>
                              </div>

                              <div className="col-md-12 form-group mb-5">
                                <label className="col-form-label">
                                  Pièces selectionées *
                                </label>
                                {paramTypePieces.map((utili, index) => (
                                  <ul>
                                    <li key={index}> {utili.intitule}</li>
                                  </ul>
                                ))}
                              </div>

                              <div className="col-md-6 form-group mb-6">
                                <label
                                  className="col-form-label"
                                  style={{ color: "black" }}
                                >
                                  Recherche de pièce
                                </label>
                                <input
                                  type="text"
                                  value={searchTerm}
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                  className="form-control"
                                  placeholder="Entrez le nom de la pièce..."
                                />
                              </div>
                              <div className="col-md-6 form-group mb-6">
                                <label
                                  className="col-form-label"
                                  style={{ color: "black" }}
                                >
                                  Resélectionner les nouvelles pièces à fournir
                                  *
                                </label>
                                {utilisateurs
                                  .filter((utili) =>
                                    utili.intitule
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                                  )
                                  .map((utili, index) => (
                                    <div key={utili.id} className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={utili.id}
                                        onChange={(e) =>
                                          handlePieceChange(e, utili.id)
                                        }
                                        checked={piece.includes(utili.id)}
                                        id={`piece-${utili.id}`}
                                        aria-required
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`piece-${utili.id}`}
                                      >
                                        {utili.intitule}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 form-group">
                                <Button
                                  className="btn btn-primary w-100"
                                  type="submit"
                                >
                                  Enregistrer
                                </Button>
                                <span className="submitting"></span>
                              </div>
                            </div>
                          </Form>

                          <div id="form-message-warning mt-4"></div>
                          <div id="form-message-success">
                            Veuillez Remplir le Formulaire
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="contact-info h-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Edit_typ_dmd;
