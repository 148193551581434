
import embleme from "./img/armoiries.jpg";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// Head
function Head() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Met à jour la classe active en fonction de l'URL
    if (location.pathname === "/") {
      setActiveLink("accueil");
    } else if (location.pathname === "/privacy") {
      setActiveLink("confidentialite");
    } else if (location.pathname === "/faq") {
      setActiveLink("faq");
    } else if (location.pathname === "/textes") {
      setActiveLink("textes");
    } else if (location.pathname === "/contact") {
      setActiveLink("contact");
    } else {
      setActiveLink("");
    }
  }, [location]);

  useEffect(() => {
    // Met à jour l'état isMobile lorsque la fenêtre est redimensionnée
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };

  return (
    <div className="fixed-top">
      <header id="header">
        <div className="container d-flex align-items-center">
          <h1 className="navbar-brand d-inline-block logo me-auto">
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={embleme} alt="Emblème" style={{ maxWidth: "100px", marginBottom: "3px" }} />
                <span style={{ fontSize: "13px", fontWeight: "bold", color: "black", textAlign: "center" }}>Ministère de la Santé</span>
              </div>
            </Link>
          </h1>

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li><Link to="/" className={`nav-link scrollto ${activeLink === "accueil" ? "active" : ""}`}>Accueil</Link></li>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/textes" className={`nav-link scrollto ${activeLink === "textes" ? "active" : ""}`}>Textes réglementaires</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/faq" className={`nav-link scrollto ${activeLink === "faq" ? "active" : ""}`}>FAQ</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/privacy" className={`nav-link scrollto ${activeLink === "confidentialite" ? "active" : ""}`}>Confidentialité</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/contact" className={`nav-link scrollto ${activeLink === "contact" ? "active" : ""}`}>Contacts</Link>
            </ul>

          </nav>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* Boutons conditionnels pour S'inscrire et Se connecter */}
          {!isMobile && (
            <>
              <Link to="/user_inscription" className="appointment-btn scrollto">S'inscrire</Link>
              <Link to="/login" className="appointment-btn scrollto">Se connecter</Link>
            </>
          )}

          <i
            className={`bi ${mobileMenuVisible ? "bi-x" : "bi-list"} mobile-nav-toggle`}
            onClick={toggleMobileMenu}
          ></i>

          {mobileMenuVisible && (
            <div className="navbar-mobile">
              <ul>
                <i className="bi bi-x close-mobile-menu" onClick={toggleMobileMenu}></i>
                <li><Link to="/" className="nav-link scrollto active">Accueil</Link></li>
                <Link to="/user_inscription" className="nav-link scrollto">S'inscrire</Link>
                <Link to="/login" className="nav-link scrollto">Se connecter</Link>
                <Link to="/textes" className="nav-link scrollto">Textes réglementaires</Link>
                <Link to="/faq" className="nav-link scrollto">FAQ</Link>
                <Link to="/privacy" className="nav-link scrollto">Confidentialité</Link>
                <Link to="/contact" className="nav-link scrollto">Contacts</Link>
              </ul>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default Head;
