import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserService from "./service/UserService";
import armoiries from "../img/armoiries.jpg";
import NotificationService from "./service/NotificationService";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isGestionOpen, setIsGestionOpen] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [isParamOpen, setIsParamOpen] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleGestion = (e) => {
    e.stopPropagation();
    setIsGestionOpen(!isGestionOpen);
  };

  const toggleConfig = (e) => {
    e.stopPropagation();
    setIsConfigOpen(!isConfigOpen);
  };

  const toggleParam = (e) => {
    e.stopPropagation();
    setIsParamOpen(!isParamOpen);
  };

  const toggleNotification = (e) => {
    e.stopPropagation();
    setIsNotificationOpen(!isNotificationOpen);
  };

  const toggleAcceuil = () => {
    navigate('/admin');
  };


  const userRole = UserService.getRoleInToken();

  // Fermer les dropdowns quand on clique ailleurs
  useEffect(() => {
    const closeDropdowns = () => {
      setIsDropdownOpen(false);
      setIsGestionOpen(false);
      setIsConfigOpen(false);
      setIsParamOpen(false);
      setIsNotificationOpen(false);
    };
    document.addEventListener('click', closeDropdowns);
    return () => document.removeEventListener('click', closeDropdowns);
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    window.location.href = "/login";
  };

  const getUserConnect = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) return;
      const response = await UserService.getProfil(token);
      if (response) setUserData(response);
    } catch (error) {
      console.error("Error fetching user profile:", error.message);
    }
  };


  useEffect(() => {
    getUserConnect();
    fetchNotifications();
    // Set up a polling mechanism to check for new notifications every minute
    const intervalId = setInterval(fetchNotifications, 60000);
    return () => clearInterval(intervalId);
  }, []);


  const isLinkActive = (paths) => {
    return paths.includes(location.pathname) ? "ms-navbar__link ms-navbar__link--active" : "ms-navbar__link";
  };

  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchNotifications = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) return;
      const response = await NotificationService.getNotifications(token);
      setNotifications(response);
      setUnreadCount(response.filter(n => !n.read).length);
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) return;
      await NotificationService.markNotificationAsRead(token, notificationId);
      fetchNotifications();
    } catch (error) {
      console.error("Error marking notification as read:", error.message);
    }
  };

  // Vérifie si l'utilisateur a accès à certaines fonctionnalités
  const canAccessGestion = ['ADMIN', 'DSPS', 'OP', 'COMMISSION'].includes(userRole);
  const canAccessConfig = ['ADMIN'].includes(userRole);
  const canAccessParam = ['ADMIN'].includes(userRole);
  const canAccessUserFeatures = ['USER'].includes(userRole);

  return (
    <nav className="ms-navbar">
      <button className="ms-navbar__toggler" onClick={toggleMenu}>
        <i className="fa fa-bars"></i>
      </button>

      <Link to="/" className="ms-navbar__brand">
        <div className="ms-navbar__logo-container">
          <img src={armoiries} alt="Armoiries" />
        </div>
        <h1 className="ms-navbar__title">Ministère de la Santé</h1>
      </Link>
      <button className="ms-navbar__dropdown-toggle" onClick={toggleAcceuil}>
        <i className="bi bi-house"></i>
      </button>
      <div className={`ms-navbar__menu ${isMenuOpen ? 'ms-navbar__menu--visible' : ''}`}>
        <ul className="ms-navbar__nav">
          {/* Liens pour USER */}
          {(canAccessUserFeatures || userRole === 'USER') && (
            <>
              <li className="ms-navbar__nav-item">
                <Link
                  className={isLinkActive(["/faire_demande_morale", "/faire_demande_physique", "/faire_demande_morale_conv", "/faire_choix_type_demande"])}
                  to="/faire_choix_type_demande"
                >
                  Faire une demande
                </Link>
              </li>
              <li className="ms-navbar__nav-item">
                <Link className={isLinkActive("/liste_demande_user")} to="/liste_demande_user">
                  Suivre mes demandes
                </Link>
              </li>
            </>
          )}

          {/* Menu Gestion de Demande */}
          {canAccessGestion && (
            <li className="ms-navbar__nav-item">
              <div className="ms-navbar__dropdown">
                <button className="ms-navbar__dropdown-toggle" onClick={toggleGestion}>
                  Gestion de Demande DSPS
                  <i className={`bi bi-chevron-${isGestionOpen ? 'up' : 'down'}`}></i>
                </button>
                {isGestionOpen && (
                  <div className="ms-navbar__dropdown-menu ms-navbar__dropdown-menu--show">
                    <Link to="/liste_demande_a_traiter" className="ms-navbar__dropdown-item">
                      Demandes à traiter
                    </Link>
                    <Link to="/demandes-terminer" className="ms-navbar__dropdown-item">
                      Demandes terminées
                    </Link>
                    <Link to="/documents-charger" className="ms-navbar__dropdown-item">
                      Liste des Documents chargés
                    </Link>
                  </div>
                )}
              </div>
            </li>
          )}

          {/* Menu Configuration */}
          {canAccessConfig && (
            <li className="ms-navbar__nav-item">
              <div className="ms-navbar__dropdown">
                <button className="ms-navbar__dropdown-toggle" onClick={toggleConfig}>
                  Configuration
                  <i className={`bi bi-chevron-${isConfigOpen ? 'up' : 'down'}`}></i>
                </button>
                {isConfigOpen && (
                  <div className="ms-navbar__dropdown-menu ms-navbar__dropdown-menu--show">
                    <Link to="/liste_region" className="ms-navbar__dropdown-item">Liste des régions</Link>
                    <Link to="/liste_province" className="ms-navbar__dropdown-item">Liste des provinces</Link>
                    <Link to="/liste_district" className="ms-navbar__dropdown-item">Liste des districts</Link>
                    <Link to="/liste_commune" className="ms-navbar__dropdown-item">Liste des communes</Link>
                    <Link to="/liste_gesp" className="ms-navbar__dropdown-item">Liste des Groupes d'ESP</Link>
                    <Link to="/liste_esp" className="ms-navbar__dropdown-item">Liste des ESP</Link>
                  </div>
                )}
              </div>
            </li>
          )}

          {/* Menu Paramètres Utilisateurs */}
          {canAccessParam && (
            <li className="ms-navbar__nav-item">
              <div className="ms-navbar__dropdown">
                <button className="ms-navbar__dropdown-toggle" onClick={toggleParam}>
                  Paramètres Utilisateurs
                  <i className={`bi bi-chevron-${isParamOpen ? 'up' : 'down'}`}></i>
                </button>
                {isParamOpen && (
                  <div className="ms-navbar__dropdown-menu ms-navbar__dropdown-menu--show">
                    <Link to="/liste_user_metier" className="ms-navbar__dropdown-item">Liste des Acteurs</Link>
                    <Link to="/liste_role" className="ms-navbar__dropdown-item">Liste des Roles</Link>
                  </div>
                )}
              </div>
            </li>
          )}
        </ul>
      </div>
      {/* Notification Icon */}

      <div className="ms-navbar__dropdown">
        <button className="ms-navbar__dropdown-toggle" onClick={toggleNotification}>
          <i className="bi bi-bell"></i>
          {unreadCount > 0 && (
            <span className="ms-navbar__notification-badge">{unreadCount}</span>
          )}
        </button>
        {isNotificationOpen && (
          <div className="ms-navbar__dropdown-menu ms-navbar__dropdown-menu--show ms-navbar__notification-menu">
            {notifications.length === 0 ? (
              <div className="ms-navbar__dropdown-item">Aucune notification</div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`ms-navbar__dropdown-item ms-navbar__notification-item ${notification.read ? 'ms-navbar__notification-item--read' : ''}`}
                  onClick={() => markAsRead(notification.id)}
                >
                  <div className="ms-navbar__notification-title">{notification.title}</div>
                  <div className="ms-navbar__notification-message">{notification.message}</div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <div className="ms-navbar__user">


        <div className="ms-navbar__dropdown">
          <div className="ms-navbar__user-container" onClick={toggleDropdown}>
            <span className="ms-navbar__user-info">
              {userData.nom} {userData.prenom}
            </span>
            {!imageError ? (
              <img
                className="ms-navbar__user-image"
                src="img/undraw_profile.svg"
                alt="Profile"
                onError={() => setImageError(true)}
              />
            ) : (
              <i
                className="fas fa-user ms-navbar__user-icon"
                style={{ fontSize: "24px", color: "#6c757d" }}
              ></i>
            )}
          </div>

          {isDropdownOpen && (
            <div className="ms-navbar__dropdown-menu ms-navbar__dropdown-menu--show">
              <Link to="/profile_user" className="ms-navbar__dropdown-item">
                <i className="bi bi-person"></i>
                Profil
              </Link>
              <hr className="ms-navbar__dropdown-divider" />
              <button className="ms-navbar__dropdown-item" onClick={handleLogout}>
                <i className="bi bi-box-arrow-right"></i>
                Se déconnecter
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;