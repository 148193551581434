import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import Navbar from '../../Nav';


function ListesDocument() {
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [filterType, setFilterType] = useState('');

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/document/all`);
        setDocuments(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des documents:', error);
      }
    };

    fetchDocuments();
  }, []);

  const handlePreview = async (documentId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/document/previsualiser/${documentId}`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setSelectedDocumentUrl(url);
      setShowPreview(true);
    } catch (error) {
      console.error('Erreur lors de la prévisualisation du document:', error);
    }
  };

  const closePreview = () => {
    setShowPreview(false);
    setSelectedDocumentUrl(null);
  };

   // Fonction pour obtenir le style en fonction du type de document
   const getTypeStyle = (type) => {
    if (type.toLowerCase() === 'arrete') {
      return { color: 'blue' }; // Couleur pour "Arreté"
    } else if (type.toLowerCase() === 'lettre de rejet') {
      return { color: 'red' }; // Couleur pour "Lettre de Rejet"
    }
    return {}; // Style par défaut
  };

  const formatDocumentType = (type) => {
    return type
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const filteredDocuments = filterType
    ? documents.filter((document) => document.typefile === filterType)
    : documents;

  return (
    <div id="page-top">
      <div id="content-wrapper">
        <Navbar />
        <div className="d-flex flex-column" style={{ width: '100%', backgroundColor: 'white' }}>
          <div className="content">
            <div className="container-fluid">
              <h4 className="title-text mt-4 mb-4">Liste des Documents</h4>

              <div className="mb-3">
                <label htmlFor="documentTypeFilter" className="form-label">Filtrer par type de document :</label>
                <select
                  id="documentTypeFilter"
                  className="form-select form-select-custom"
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                >
                  <option value="">Tous les types</option>
                  {[...new Set(documents.map((doc) => doc.typefile))].map((type) => (
                    <option key={type} value={type}>{formatDocumentType(type)}</option>
                  ))}
                </select>
              </div>

              <table className="table text-noir">
                <thead>
                  <tr>
                    <th>N° de Demande</th>
                    <th>Nom du Document</th>
                    <th>Type du Document</th>
                    <th>N° du Document</th>
                    <th>Date de Signature</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDocuments.map((document) => (
                    <tr key={document.id}>
                      <td>{document.numeroDemande}</td>
                      <td>{document.nomFile}</td>
                      <td style={getTypeStyle(formatDocumentType(document.typefile))}>
                        {formatDocumentType(document.typefile)}
                      </td>
                      <td>{document.numeroDocument}</td>
                      <td>{document.dateSignatureDocument}</td>
                      <td>
                        <button
                          className="round-button d-flex justify-content-center align-items-center me-2"
                          onClick={() => handlePreview(document.id)}
                          title="prévisualiser le document"
                        >
                          <i className="bi bi-eye white-icon"></i>
                          {/* Prévisualiser */}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {showPreview && selectedDocumentUrl && (
                <Modal show={showPreview} onHide={closePreview} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>Prévisualisation du Document</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <iframe
                      src={selectedDocumentUrl}
                      title="Document Preview"
                      width="100%"
                      height="800px"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn btn-secondary" onClick={closePreview}>
                      Fermer
                    </button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListesDocument;
